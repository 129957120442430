import TabView from "@features/common/TabView";
import { ITabItem } from "@features/common/TabView/TabView";

export const CustomerManagementWindow = () => {
  let tabs: ITabItem[] = [
    {
      label: "menu-sub-basic-info",
      path: "basicinfo",
      permissions: "",
    },
    {
      label: "menu-sub-secrets",
      path: "secrets",
      permissions: "secrets",
    },
    {
      label: "menu-sub-users-roles",
      path: "usersandroles",
      permissions: "roles",
    },
  ];

  return (
    <>
      <TabView tabs={tabs} primaryPermission="customer" />
    </>
  );
};
