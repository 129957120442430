import React from "react";
import { useTranslation } from "react-i18next";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Alert,
} from "@mui/material";
import LoadingIndicatorBox from "@features/common/LoadingIndicatorBox/LoadingIndicatorBox";

/**
 * Table component for displaying VM applications.
 *
 * @param props
 * - properties: the table headers, <IProperty> elements
 * - children: the table rows, <TableRow> elements
 * - isLoading: boolean, true if data is being loaded
 */

export interface IProperty<T> {
  key: keyof T extends string ? keyof T : never;
  label: string;
}

interface Props<T> {
  properties: IProperty<T>[];
  children: React.ReactNode;
  isLoading: boolean;
  isError?: boolean;
}

const VMApplicationsTable = <T extends Record<string, any>>(
  props: Props<T>,
) => {
  const { properties, children, isLoading, isError } = props;
  const { t } = useTranslation();

  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            {properties.map((property) => (
              <TableCell key={property.key}>
                <span style={{ fontWeight: 600 }}>{property.label}</span>
              </TableCell>
            ))}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{!isLoading && children}</TableBody>
      </Table>
      {!children && !isLoading && isError ? (
        <Alert severity="error">{t("label-error-loading-data")}</Alert>
      ) : !children && !isLoading && !isError ? (
        <Alert severity="info">{t("label-no-data-to-display")}</Alert>
      ) : (
        <></>
      )}
      {isLoading && <LoadingIndicatorBox />}
    </>
  );
};

export default VMApplicationsTable;
