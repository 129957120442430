import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import apiConfig from "@configurations/apiConfig";
import { getAccessToken } from "./apiUtils";
import { expectedUnitTestError } from "@base/testutils/testUtils";
// import { toast } from "react-toastify";

function axiosConfigFactory<PayloadData>(
  method: string,
  endpoint: string,
  headers: {},
  payload?: PayloadData,
) {
  const options: AxiosRequestConfig = {
    method: method,
    url: endpoint,
    headers: headers,
    data: payload,
  };
  return options;
}

// currently only adds authorization token & content type header
// if needed can be extended later to take in optional additional headers
export async function baseHeaders(): Promise<{ [key: string]: any }> {
  let headers: { [key: string]: any } = {};
  const token = await getAccessToken();
  headers["Authorization"] = `Bearer ${token}`;
  headers["Content-Type"] = "application/json";

  return headers;
}

// this makes the actual url that we send the request to
export const formatResourceUrl = (resource: string) => {
  const resource_url = `${apiConfig.url}/${resource}`;
  return resource_url;
};

// IReturnData will be e.g. ITask, ITaskCreate, types for schedules etc
export async function apiGET<IReturnData>(
  resource: string,
  params?: any,
): Promise<AxiosResponse<IReturnData>> {
  const headers = await baseHeaders();
  const endpoint: string = formatResourceUrl(resource);
  const options = axiosConfigFactory("GET", endpoint, headers);
  if (params) {
    options.params = params;
  }
  const resp = axios(options)
    .then((response: AxiosResponse<IReturnData>) => {
      return response;
    })
    .catch((error: AxiosError) => {
      if (error.response?.statusText === expectedUnitTestError) {
        throw { message: expectedUnitTestError, status: 500 };
      }
      console.error(error);
      const responseData: unknown = error.response?.data;
      const responseStatus = error.response?.request.status;

      // Use type assertions or type guards to access the data

      if (typeof responseData === "object" && responseData !== null) {
        // if the data is there and is an object, we check for the "detail" field that FastAPI error messages provide
        // If it exists we throw an error with detail as message, otherwise some generic error message

        const responseMessage: string =
          (responseData as { detail?: string }).detail || "API error";

        const errorObj = { message: responseMessage, status: responseStatus };
        console.log(errorObj); // remove this later, its for testing purposes the feature isnt complete

        throw errorObj;
      }
      // throw generic error if type or field conditions are not met
      const errorObj = {
        message: "something went wrong",
        status: responseStatus,
      };
      throw errorObj;
    });

  return resp;
}

// IReturnData will be e.g. ITask, ITaskCreate, types for schedules etc
// InputData is whatever is being sent to API
export async function apiPOST<InputData, IReturnData>(
  resource: string,
  payload?: InputData,
): Promise<AxiosResponse<IReturnData>> {
  const headers = await baseHeaders();
  const endpoint: string = formatResourceUrl(resource);
  const options = payload
    ? axiosConfigFactory("POST", endpoint, headers, payload)
    : axiosConfigFactory("POST", endpoint, headers);
  const resp = axios(options)
    .then((response: AxiosResponse<IReturnData>) => {
      return response;
    })
    .catch((error: AxiosError) => {
      if (error.response?.statusText === expectedUnitTestError) {
        throw { message: expectedUnitTestError, status: 500 };
      }
      console.error(error);
      const responseData: unknown = error.response?.data;
      const responseStatus = error.response?.request.status;

      // Use type assertions or type guards to access the data
      if (typeof responseData === "object" && responseData !== null) {
        // if the data is there and is an object, we check for the "detail" field that FastAPI error messages provide
        // If it exists we throw an error with detail as message, otherwise some generic error message

        const responseMessage: string =
          (responseData as { detail?: string }).detail || "API error";

        const errorObj = { message: responseMessage, status: responseStatus };
        console.log(errorObj); // remove this later, its for testing purposes the feature isnt complete

        throw errorObj;
      }
      // throw generic error if type or field conditions are not met
      const errorObj = {
        message: "something went wrong",
        status: responseStatus,
      };
      throw errorObj;
    });
  return resp;
}

export async function apiPUT<InputData, IReturnData>(
  resource: string,
  payload: InputData,
): Promise<AxiosResponse<IReturnData>> {
  const headers = await baseHeaders();
  const endpoint: string = formatResourceUrl(resource);
  const options = axiosConfigFactory("PUT", endpoint, headers, payload);
  const resp = axios(options)
    .then((response: AxiosResponse<IReturnData>) => {
      return response;
    })
    .catch((error: AxiosError) => {
      if (error.response?.statusText === expectedUnitTestError) {
        throw { message: expectedUnitTestError, status: 500 };
      }
      console.error(error);
      const responseData: unknown = error.response?.data;
      const responseStatus = error.response?.request.status;

      // Use type assertions or type guards to access the data

      if (typeof responseData === "object" && responseData !== null) {
        // if the data is there and is an object, we check for the "detail" field that FastAPI error messages provide
        // If it exists we throw an error with detail as message, otherwise some generic error message

        const responseMessage: string =
          (responseData as { detail?: string }).detail || "API error";

        const errorObj = { message: responseMessage, status: responseStatus };
        console.log(errorObj); // remove this later, its for testing purposes the feature isnt complete

        throw errorObj;
      }
      // throw generic error if type or field conditions are not met
      const errorObj = {
        message: "something went wrong",
        status: responseStatus,
      };
      throw errorObj;
    });
  return resp;
}

export async function apiDELETE<IReturnData>(
  resource: string,
): Promise<AxiosResponse<IReturnData>> {
  const headers = await baseHeaders();
  const endpoint: string = formatResourceUrl(resource);
  const options = axiosConfigFactory("DELETE", endpoint, headers);
  const resp = axios(options)
    .then((response: AxiosResponse<IReturnData>) => {
      return response;
    })
    .catch((error: AxiosError) => {
      if (error.response?.statusText === expectedUnitTestError) {
        throw { message: expectedUnitTestError, status: 500 };
      }
      console.error(error);
      const responseData: unknown = error.response?.data;
      const responseStatus = error.response?.request.status;

      // Use type assertions or type guards to access the data

      if (typeof responseData === "object" && responseData !== null) {
        // if the data is there and is an object, we check for the "detail" field that FastAPI error messages provide
        // If it exists we throw an error with detail as message, otherwise some generic error message

        const responseMessage: string =
          (responseData as { detail?: string }).detail || "API error";

        const errorObj = { message: responseMessage, status: responseStatus };
        console.log(errorObj); // remove this later, its for testing purposes the feature isnt complete

        throw errorObj;
      }
      // throw generic error if type or field conditions are not met
      const errorObj = {
        message: "something went wrong",
        status: responseStatus,
      };
      throw errorObj;
    });
  return resp;
}
