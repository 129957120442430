import React, { useEffect, useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectVMStatus } from "@features/environments/environmentsSlice";
import { getSelectedTask, updateTask } from "../tasksSlice";
import { IVirtualMachine } from "@features/environments/types";
import { ITask } from "../types";
import { RootState } from "@app/store";
import LoadingIndicatorBox from "@features/common/LoadingIndicatorBox/LoadingIndicatorBox";

interface LeftRightProps {
  vmCategory: string;
  leftGlobal: string[];
  rightGlobal: string[];
  virtualmachines: IVirtualMachine[];
  setleftGlobal: React.Dispatch<React.SetStateAction<string[]>>;
  setrightGlobal: React.Dispatch<React.SetStateAction<string[]>>;
  isEditing: boolean;
}

export const LeftRightSelector = (props: LeftRightProps) => {
  const { t } = useTranslation();

  const {
    vmCategory,
    leftGlobal,
    rightGlobal,
    virtualmachines,
    setleftGlobal,
    setrightGlobal,
    isEditing,
  } = props;

  const [leftList, setLeftListLocal] = useState<string[]>([]);
  const [rightList, setRightListLocal] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [leftButtonDisabled, setLeftButtonDisabled] = useState<boolean>(true);
  const vmListLoading = useSelector(selectVMStatus) === "loading";

  const selectedTask: ITask | undefined = useSelector((state: RootState) =>
    getSelectedTask(state),
  );

  // populate left and right lists.
  // left lists = task.vm_names field, right list = the rest (of the correct category)
  useEffect(() => {
    if (virtualmachines) {
      setLeftListLocal([]);
      setRightListLocal([]);
      const vmNamesLeft: string[] = virtualmachines
        .map((vm: IVirtualMachine) => {
          if (
            vm.category === vmCategory &&
            selectedTask?.vm_names.includes(vm.name)
          ) {
            return vm.name;
          } else return "";
        })
        .filter((str) => str !== "");

      const vmNamesRight: string[] = virtualmachines
        .map((vm: IVirtualMachine) => {
          if (vm.category === vmCategory) {
            return vm.name;
          } else return "";
        })
        .filter((str) => str !== "" && !selectedTask?.vm_names.includes(str));

      setRightListLocal(vmNamesRight);
      setLeftListLocal(vmNamesLeft);
    }
  }, [virtualmachines, isEditing]);

  // extract the selected "option" from the Select menu (left or right TextField)
  const handleSelectedVM = (event: any) => {
    setSelectedOption(event.target.value);
  };

  // moves vm from right to left view
  // currently de-selects item after, this makes general state management easier
  const shiftVMLeft = () => {
    if (selectedOption) {
      let list = leftList;
      list.push(selectedOption);
      setLeftListLocal(list);
      list = leftGlobal;
      list.push(selectedOption);
      setleftGlobal(list);
      list = rightList.filter((str) => str !== selectedOption);
      setRightListLocal(list);
      list = rightGlobal.filter((str) => str !== selectedOption);
      setrightGlobal(list);
      setSelectedOption("");
    }
  };

  // moves vm from left to right view
  // currently de-selects item after, this makes general state management easier
  const shiftVMRight = () => {
    if (selectedOption) {
      let list = rightList;
      list.push(selectedOption);
      setRightListLocal(list);
      list = rightGlobal;
      list.push(selectedOption);
      setrightGlobal(list);
      list = leftList.filter((str) => str !== selectedOption);
      setLeftListLocal(list);
      list = leftGlobal.filter((str) => str !== selectedOption);
      setleftGlobal(list);
      setSelectedOption("");
    }
  };

  useEffect(() => {
    if (leftList.includes(selectedOption)) {
      setLeftButtonDisabled(true);
    } else if (rightList.includes(selectedOption)) {
      setLeftButtonDisabled(false);
    }
  }, [selectedOption]);

  return (
    <>
      <Grid
        item
        container
        justifyContent={"center"}
        alignContent={"center"}
        spacing={0}
      >
        <Grid item justifyContent={"center"} xs={12} sm={5}>
          {!vmListLoading && (
            <>
              <Typography variant="subtitle1" align="center">
                {t("label-split-selected")}
              </Typography>
              <TextField
                fullWidth
                data-testid="vms-left"
                select
                SelectProps={{
                  native: true,
                  multiple: true,
                }}
                disabled={false}
                onChange={handleSelectedVM}
                value={[selectedOption]}
                variant="outlined"
                sx={{ paddingBottom: { sm: 2.5, xs: 1 } }}
              >
                {leftList.map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </TextField>
            </>
          )}
          <LoadingIndicatorBox show={vmListLoading} />
        </Grid>

        <Grid
          item
          xs={12}
          sm={2}
          container
          justifyContent="center"
          direction="column"
          alignContent="center"
        >
          <Grid item>
            <Button
              variant="contained"
              data-testid="right-arrow"
              disabled={
                !leftButtonDisabled || selectedOption === "" || !isEditing
              }
              onClick={shiftVMRight}
              style={{ margin: "4px" }}
            >
              <ChevronRight />
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              data-testid="left-arrow"
              disabled={
                leftButtonDisabled || selectedOption === "" || !isEditing
              }
              onClick={shiftVMLeft}
              style={{ margin: "4px" }}
            >
              <ChevronLeft />
            </Button>
          </Grid>
        </Grid>

        <Grid item justifyContent={"center"} xs={12} sm={5}>
          {!vmListLoading && (
            <>
              <Typography variant="subtitle1" align="center">
                {t("label-split-available")}
              </Typography>
              <TextField
                // disabled={true}
                data-testid="vms-right"
                select
                SelectProps={{
                  native: true,
                  multiple: true,
                }}
                fullWidth
                onChange={handleSelectedVM}
                value={[selectedOption]}
                variant="outlined"
              >
                {rightList.map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </TextField>
            </>
          )}
          <LoadingIndicatorBox show={vmListLoading} />
        </Grid>
      </Grid>
    </>
  );
};
