import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

interface SelectMenuProps {
  items: any[];
  value: string;
  readOnly: boolean;
  label: string;
  handleChange: (value: any) => void;
  name: string;
  helpText?: string | React.ReactNode;
}

export const SelectMenu = (props: SelectMenuProps) => {
  const { items, label, handleChange, value, name, readOnly, helpText } = props;

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="select-menu-label">{label}</InputLabel>
        <Select
          labelId="select-menu-label"
          value={value}
          label={label}
          name={name}
          readOnly={readOnly}
          onChange={handleChange}
          endAdornment={
            helpText && (
              <InputAdornment position="end" sx={{ marginRight: 1 }}>
                <Tooltip arrow placement="top-start" title={helpText}>
                  <IconButton>
                    <HelpIcon sx={{ color: "#7eccff" }} />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            )
          }
        >
          {items.map((i) => (
            <MenuItem key={i.id} value={i.value}>
              {i.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default SelectMenu;
