import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TextField, Grid, FormControl, FormLabel, Button } from "@mui/material";
import { LinkOff, Link } from "@mui/icons-material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { formControlStyle, formLabelStyle } from "@features/common/styles";
import {
  getSelectedSecret,
  updateSecret,
  SecretUpdateBundle,
  selectSecret,
  selectSecretsStatus,
} from "./secretsSlice";
import { ISecret } from "./types";
import { getSelectedCustomerId } from "@features/customers/customerSlice";
import { useAppDispatch } from "@features/common/StoreHook";
import { useState, ChangeEvent, useEffect } from "react";
import EditButtonGroup from "@features/common/ButtonGroups/EditButtonGroup";
import { useCollection } from "@features/common/collectionHook";
import {
  FormDropdownSelect,
  FormTextArea,
  FormTextField,
} from "@features/common/FormFields";
import { permissionsApi } from "@features/authorization/authService";
import { checkPermissions } from "@features/authorization/types";
import { toast } from "react-toastify";
import LoadingIndicatorBox from "@features/common/LoadingIndicatorBox/LoadingIndicatorBox";

/**
 *
 * Editor to update selected secret
 * part of "main" secrets view.
 *
 * TODO: Refresh button because now when updating, we immediately re-fetch values.
 * Sometimes they don't have time to probably update in Azure and thus the old value is shown
 *
 */

const validationSchema = yup.object({
  name: yup.string().required(),
  status: yup.string().nullable(),
  description: yup.string().nullable(),
  additional_info: yup.string().nullable(),
  type: yup.string(),
  // production_value: yup.string(),
  // test_value: yup.string(),
});

export const SecretEditor = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const customerId = useSelector(getSelectedCustomerId);
  const selectedSecret: ISecret | undefined = useSelector(getSelectedSecret);
  const { data, isLoading: isLoadingPermissions } =
    permissionsApi.useGetCustomerPermissionsQuery(customerId);
  const secretPermissions = checkPermissions("secrets", data);
  const isSubmitting = useSelector(selectSecretsStatus) === "submitting";

  const [linkActive, setLinkActive] = useState<boolean>(false);

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: selectedSecret?.name || "",
      status: selectedSecret?.status || "",
      description: selectedSecret?.description || "",
      type: selectedSecret?.type || "",
      additional_info: selectedSecret?.additional_info || "",
    },
  });

  const [prodState, setProdState] = useState<string>("");
  const [devState, setDevState] = useState<string>("");
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const secretTypes = useCollection(9);

  useEffect(() => {
    reset(selectedSecret);
  }, [selectedSecret]);

  const handleDevChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDevState(event.target.value);
    if (linkActive) setProdState(event.target.value);
  };

  const handleProdChange = (event: ChangeEvent<HTMLInputElement>) => {
    setProdState(event.target.value);
    if (linkActive) setDevState(event.target.value);
  };

  const handleSave = (data: any) => {
    if (selectedSecret) {
      const payload: ISecret = {
        name: data.name,
        type: data.type,
        description: data.description,
        additional_info: data.additional_info,
        status: selectedSecret.status,
        test_value: devState ? devState : "",
        production_value: prodState ? prodState : "",
      };

      const dataBundle: SecretUpdateBundle = {
        customerId: customerId,
        secretName: selectedSecret.name,
        payload: payload,
      };

      dispatch(updateSecret(dataBundle))
        .unwrap()
        .then(() => {
          setIsEditing(false);
          toast.success("Secret updated successfully");
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setLinkActive(false);
    setDevState("");
    setProdState("");
    reset(selectedSecret);
  };

  const handleEdit = () => {
    secretPermissions.update
      ? setIsEditing(true)
      : toast.error("No update permission");
  };

  return (
    <FormControl component="fieldset" sx={formControlStyle}>
      <FormLabel component="legend" sx={formLabelStyle}>
        {t("label-secret")}
      </FormLabel>
      {isLoadingPermissions ? (
        <LoadingIndicatorBox show={isLoadingPermissions} />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormTextField
              name="name"
              control={control}
              label={t("label-secret-name")}
              readOnly={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormDropdownSelect
              name="type"
              control={control}
              label={t("label-secret-type")}
              selectItems={secretTypes}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextArea
              name="description"
              control={control}
              label={t("label-secret-description")}
              readOnly={!isEditing || isSubmitting}
              rows={4}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextArea
              name="additional_info"
              control={control}
              label={t("label-additional-information")}
              readOnly={!isEditing || isSubmitting}
              rows={4}
            />
          </Grid>
          <Grid
            container
            item
            textAlign={"center"}
            alignItems={"center"}
            rowSpacing={1}
          >
            <Grid item xs={12} sm={5}>
              <TextField
                value={prodState}
                label={t("label-production-keyvault-value")}
                onChange={handleProdChange}
                fullWidth
                inputProps={{ readOnly: !isEditing || isSubmitting }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                variant="contained"
                onClick={() => setLinkActive(!linkActive)}
                disabled={!isEditing || isSubmitting}
              >
                {linkActive ? <Link /> : <LinkOff />}
              </Button>
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                value={devState}
                label={t("label-development-keyvault-value")}
                onChange={handleDevChange}
                fullWidth
                inputProps={{ readOnly: !isEditing || isSubmitting }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <EditButtonGroup
              handleEdit={handleEdit}
              disabled={isSubmitting}
              handleSave={handleSubmit(handleSave)}
              handleCancel={handleCancel}
              isEditing={isEditing}
            />
          </Grid>
        </Grid>
      )}
    </FormControl>
  );
};
