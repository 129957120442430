import dayjs from "dayjs";
import { ActiveTimelineItem, EventType, TimelineEvent } from "../../types";

export type RuntimeData = {
  scheduled: number;
  actual: number;
};

export const calculateEventRuntimes = (event: any) => {
  const scheduledRuntime = dayjs(event.scheduled_stop_time).diff(
    dayjs(event.scheduled_start_time),
    "minutes",
  );
  const runtime: RuntimeData = {
    scheduled: scheduledRuntime,
    actual: 0,
  };
  if (event.actual_start_time && event.actual_stop_time) {
    const actualRuntime = dayjs(event.actual_stop_time).diff(
      dayjs(event.actual_start_time),
      "minutes",
    );
    runtime.actual = actualRuntime;
  }
  return runtime;
};

export const calculateRuntimeData = (
  events: TimelineEvent[],
  selectedItem: ActiveTimelineItem,
  startDate: any,
  endDate: any,
) => {
  const runtimeData: any = [];
  if (selectedItem.type === EventType.Event) {
    const runtime = calculateEventRuntimes(selectedItem.item);
    runtimeData.push(runtime);
  } else if (selectedItem.type === EventType.Task) {
    events.forEach((event: TimelineEvent) => {
      if (event.resourceId === selectedItem.id) {
        if (
          dayjs(event.scheduled_start_time).isAfter(startDate) &&
          dayjs(event.scheduled_stop_time).isBefore(endDate)
        ) {
          const runtime = calculateEventRuntimes(event);
          runtimeData.push(runtime);
        }
      }
    });
  } else if (selectedItem.type === EventType.VM) {
    events.forEach((event: any) => {
      if (event.vmId === selectedItem.id) {
        if (
          dayjs(event.scheduled_start_time).isAfter(startDate) &&
          dayjs(event.scheduled_stop_time).isBefore(endDate)
        ) {
          const runtime = calculateEventRuntimes(event);
          runtimeData.push(runtime);
        }
      }
    });
  }
  return runtimeData;
};
