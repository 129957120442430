import React from "react";
import { TextField, MenuItem } from "@mui/material";

/**
 *
 * Common component for a Textfield that contains a dropdown selection menu
 * Main input argument (selectItems: SelectItem[]) is an array of SelectItem objects, rendered as MenuItems.
 * selectedItem defines which item to render selected in the dropdown list
 * Other props include metadata (id, label) and it is assumed that parent component wants to know
 * selection state (e.g. to dispatch to database), thats what the setParentComponentState prop is for.
 *
 */
export type SelectItem = {
  id: string | number;
  value: string;
  label: string;
};

interface DropdownSelectProps {
  selectItems: SelectItem[];
  selectedItem: string | undefined | null;
  textFieldLabel: string;
  menuId: string;
  setParentComponentState: React.Dispatch<React.SetStateAction<any>>;
  style: any;
  disabled: boolean;
}

export const DropdownSelect = (props: DropdownSelectProps) => {
  const {
    selectItems,
    selectedItem,
    textFieldLabel,
    menuId,
    setParentComponentState,
    style,
    disabled,
  } = props;

  const handleSelectedItem = (event: React.ChangeEvent<HTMLInputElement>) => {
    setParentComponentState(event.target.value);
  };

  return (
    <>
      <TextField
        sx={style}
        select
        name="basic-textfield"
        data-testid={textFieldLabel}
        id={menuId}
        label={textFieldLabel}
        value={selectedItem || ""}
        onChange={handleSelectedItem}
        disabled={disabled}
      >
        {selectItems.map((item) => (
          <MenuItem key={item.id} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};
