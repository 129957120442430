import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { AlertColor } from "@mui/material/Alert/Alert";

import { useTranslation } from "react-i18next";

interface IProps {
  variant?: "outlined" | "filled"; // by default `standard`
  severity: AlertColor; // `error`, `warning`, `info` or `success`
  text: string;
  additionalText?: string; //string
}

const infoBoxAdditionalTextStyle = {
  alignSelf: "center",
  paddingBottom: "4px",
  paddingRight: "8px",
  marginLeft: "-5px",
  fontWeight: "bold",
};

const InfoBox = (props: IProps) => {
  const { variant, severity, text, additionalText } = props;
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", justifyContent: "center", paddingTop: "10%" }}>
      <Alert
        action={
          !!additionalText ? (
            <Tooltip title={additionalText}>
              <span role="read-more" style={infoBoxAdditionalTextStyle}>
                {t("label-read-more")}
              </span>
            </Tooltip>
          ) : null
        }
        variant={variant || "standard"}
        severity={severity}
      >
        {text}
      </Alert>
    </Box>
  );
};

export default InfoBox;
