import { useTranslation } from "react-i18next";
import { containerStyle } from "./style";
import LanguageListMenu from "./LanguageListMenu";
import LanguagePopoverMenu from "./LanguagePopoverMenu";
import { useCollection } from "@features/common/collectionHook";

const LanguageMenu = () => {
  const { i18n } = useTranslation();
  const languages = useCollection(1);

  const changeLanguage: (lng: string) => void = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div style={containerStyle}>
      <LanguageListMenu
        languages={languages}
        changeLanguage={changeLanguage}
        currentLang={i18n.language}
      />
      <LanguagePopoverMenu
        languages={languages}
        changeLanguage={changeLanguage}
        currentLang={i18n.language}
      />
    </div>
  );
};

export default LanguageMenu;
