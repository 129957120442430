import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  FormLabel,
  Grid,
  TableRow,
  TableCell,
  Typography,
  IconButton,
  MenuItem,
  Menu,
  Button,
  useTheme,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import RefreshIcon from "@mui/icons-material/Refresh";
import { getSelectedCustomerId } from "@features/customers/customerSlice";
import {
  GalleryApplication,
  VMApplication,
} from "@features/environments/types";
import {
  useGetVMApplicationsQuery,
  useGetGalleryApplicationsQuery,
  useInstallApplicationMutation,
  useUninstallApplicationMutation,
} from "../../enviromentsApi";
import { formControlStyle, formLabelStyle } from "../../style";
import VMApplicationsTable, { IProperty } from "./VMApplicationsTable";
import VMApplicationDetails from "./VMApplicationDetails";
import VMApplicationsInstall from "./VMApplicationsInstall";
import ConfirmUninstallDialog from "./ConfirmUninstallDialog";

/**
 * The view that shows the applications installed on a VM and the available applications to install.
 */

interface Props {
  vmId: string;
  vmCategory: string;
  vmStatus: string;
}

interface GalleryApp {
  name: string;
  latestVersion: string;
}

const VMApplications = ({ vmId, vmCategory, vmStatus }: Props) => {
  const customerId = useSelector(getSelectedCustomerId);
  const { t } = useTranslation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<{
    [x: string]: EventTarget & HTMLButtonElement;
  } | null>(null);
  const [selectedApp, setSelectedApp] = useState<
    GalleryApplication | undefined
  >();
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [openInstall, setOpenInstall] = useState<boolean>(false);
  const [openUninstall, setOpenUninstall] = useState<boolean>(false);

  // toasts for error messages are controlled from the RTK query hooks
  const {
    data: applications,
    isLoading: loadingApps,
    isFetching: fetchingApps,
    isError: applicationsError,
    refetch: refetchApps,
  } = useGetVMApplicationsQuery(
    { customerId, vmId },
    { skip: !customerId || !vmId },
  );
  const {
    data: galleryApplications,
    isLoading: loadingGallery,
    isFetching: fetchingGallery,
    isError: galleryApplicationsError,
    refetch: refetchGallery,
  } = useGetGalleryApplicationsQuery({ customerId }, { skip: !customerId });

  const [installApp, { isLoading: loadingInstallApp }] =
    useInstallApplicationMutation();
  const [uninstallApp, { isLoading: loadingUninstallApp }] =
    useUninstallApplicationMutation();

  // properties to show in the table
  const installedProperties: IProperty<VMApplication>[] = [
    { key: "name", label: t("label-name") },
    { key: "version", label: t("label-version") },
  ];

  const availableProperties: IProperty<GalleryApp>[] = [
    { key: "name", label: t("label-name") },
    { key: "latestVersion", label: t("label-latest-version") },
  ];

  const handleClickMenu = (
    app: string,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    // open the popup menu for the clicked application
    setAnchorEl({ [app]: event.currentTarget });
  };

  const handleCloseMenu = () => {
    // close the application popup menu
    setAnchorEl(null);
  };

  const handleOpenUninstallDialog = (appName: string) => {
    setSelectedApp(
      galleryApplications?.find(
        (app: GalleryApplication) => app.name === appName,
      ),
    );
    setOpenUninstall(true);
    handleCloseMenu();
  };

  const handleUninstall = () => {
    if (selectedApp) {
      const version = applications?.find(
        (app: VMApplication) => app.name === selectedApp.name,
      )?.version;
      uninstallApp({
        customerId,
        vmId,
        applicationId: selectedApp.name,
        version,
      })
        .unwrap()
        .then(() => toast.success("Application uninstalled"))
        .catch((error) => {
          toast.error(
            `Could not uninstall application${
              error?.data?.detail ? ": " + error?.data?.detail : ""
            }`,
          );
        })
        .then(() => setOpenUninstall(false));
      handleCloseMenu();
    }
  };

  const handleOpenInstall = (appName: string) => {
    setSelectedApp(
      galleryApplications?.find(
        (app: GalleryApplication) => app.name === appName,
      ),
    );
    setOpenInstall(true);
    handleCloseMenu();
  };

  const handleInstall = (app: VMApplication) => {
    installApp({
      customerId,
      vmId,
      applicationId: app.name,
      version: app.version,
    })
      .unwrap()
      .then(() => toast.success("Application installed"))
      .catch((error) => {
        toast.error(
          `Could not install application${
            error?.data?.detail ? ": " + error?.data?.detail : ""
          }`,
        );
      })
      .finally(() => setOpenInstall(false));
  };

  const handleShowDetails = (appName: string) => {
    const app = galleryApplications?.find(
      (app: GalleryApplication) => app.name === appName,
    );
    setSelectedApp(app);
    setOpenDetails(true);
    handleCloseMenu();
  };

  const handleRefresh = () => {
    // refresh the applications
    refetchApps();
    refetchGallery();
  };

  const galleryApplicationsToShow = galleryApplications?.reduce(
    (acc: (GalleryApplication & GalleryApp)[], app: GalleryApplication) => {
      // check if the application is already installed and if it is in the correct category
      if (
        !applications?.find(
          (vmApp: VMApplication) => vmApp.name === app.name,
        ) &&
        app.tags.includes(vmCategory)
      ) {
        // add the latest version to the app object,
        // latest version is the last element in the versions array, as azure presumably returns them in order
        acc.push({
          ...app,
          latestVersion: app.versions[app.versions.length - 1],
        });
      }
      return acc;
    },
    [],
  );

  const getSelectedAppDetails = (app: GalleryApplication) => {
    // find the application in the installed applications if it exists
    // and return the status as "Installed" otherwise "Available"
    const vmApp = applications?.find(
      (vmApp: VMApplication) => vmApp.name === app.name,
    );
    const status = vmApp ? "Installed" : "Available";
    return {
      ...app,
      version: vmApp?.version,
      status,
    };
  };

  const applicationsTableRowStyle = {
    "&:last-child td, &:last-child th": { border: 0 },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.secondary.main,
    },
  };

  return (
    <>
      <Grid item xs={12}>
        <FormControl
          component="fieldset"
          sx={{ ...formControlStyle, paddingTop: 0 }}
        >
          <FormLabel component="legend" sx={formLabelStyle}>
            VM Applications
          </FormLabel>
          <Button
            variant="contained"
            size="small"
            startIcon={<RefreshIcon />}
            onClick={handleRefresh}
            sx={{
              backgroundColor: "primary.main",
              float: "right",
              margin: "-20px 0 -20px 0",
              top: "20px",
            }}
          >
            Refresh
          </Button>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography>{t("label-installed-apps")}</Typography>
              <VMApplicationsTable
                properties={installedProperties}
                isLoading={loadingApps || fetchingApps}
                isError={applicationsError}
              >
                {applications?.map((app: VMApplication) => (
                  <TableRow key={app.name} sx={applicationsTableRowStyle}>
                    {installedProperties.map((property) => (
                      <TableCell key={property.key}>
                        {app[property.key]}
                      </TableCell>
                    ))}
                    <TableCell
                      align="right"
                      sx={{ padding: 0, verticalAlign: "bottom" }}
                    >
                      <IconButton
                        onClick={(e) => handleClickMenu(app.name, e)}
                        aria-label="vm-app-menu"
                      >
                        <MoreHorizIcon color="primary" />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl && anchorEl[app.name]}
                        open={Boolean(anchorEl && anchorEl[app.name])}
                        onClose={handleCloseMenu}
                      >
                        <MenuItem onClick={() => handleShowDetails(app.name)}>
                          {t("label-details")}
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleOpenUninstallDialog(app.name)}
                          sx={{
                            color: "error.dark",
                          }}
                          disabled={loadingUninstallApp}
                        >
                          {t("label-button-uninstall")}
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </VMApplicationsTable>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>{t("label-available-apps")}</Typography>
              <VMApplicationsTable
                properties={availableProperties}
                isLoading={loadingGallery || fetchingGallery}
                isError={galleryApplicationsError}
              >
                {galleryApplicationsToShow?.map((app: GalleryApp) => (
                  <TableRow
                    key={app.name + "-gallery-app"}
                    sx={applicationsTableRowStyle}
                  >
                    {availableProperties.map((property) => (
                      <TableCell key={property.key}>
                        {app[property.key]}
                      </TableCell>
                    ))}
                    <TableCell
                      align="right"
                      sx={{ padding: 0, verticalAlign: "bottom" }}
                    >
                      <IconButton
                        onClick={(e) => handleClickMenu(app.name, e)}
                        aria-label="vm-app-menu"
                      >
                        <MoreHorizIcon color="primary" />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl && anchorEl[app.name]}
                        open={Boolean(anchorEl && anchorEl[app.name])}
                        onClose={handleCloseMenu}
                      >
                        <MenuItem onClick={() => handleShowDetails(app.name)}>
                          {t("label-details")}
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleOpenInstall(app.name)}
                          disabled={loadingInstallApp}
                          sx={{ color: "#01c001" }}
                        >
                          {t("label-button-install")}
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </VMApplicationsTable>
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
      <VMApplicationDetails
        vmApplication={selectedApp && getSelectedAppDetails(selectedApp)}
        open={openDetails}
        onClose={() => setOpenDetails(false)}
      />
      <VMApplicationsInstall
        key={selectedApp?.name + "-install"}
        open={openInstall}
        onClose={() => setOpenInstall(false)}
        vmApplication={selectedApp}
        handleInstall={handleInstall}
        loading={loadingInstallApp}
        vmStatus={vmStatus}
      />
      {selectedApp && (
        <ConfirmUninstallDialog
          key={selectedApp.name + "-uninstall"}
          show={openUninstall}
          appName={selectedApp.name}
          onConfirm={handleUninstall}
          onClose={() => setOpenUninstall(false)}
          loading={loadingUninstallApp}
          vmStatus={vmStatus}
        />
      )}
    </>
  );
};

export default VMApplications;
