import { useTranslation } from "react-i18next";
import {
  CartesianGrid,
  ResponsiveContainer,
  BarChart,
  Bar,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import ChartInfoBox from "../ChartInfoBox";
import { chartColors } from "../utils";
import RuntimeChartTooltip from "./RuntimesChartTooltip";
import { RuntimeData } from "./runtimesChartUtils";
import { useTheme } from "@mui/material";

interface RuntimeChartProps {
  runtimeData: RuntimeData[];
}

const RuntimesChart = (props: RuntimeChartProps) => {
  const { runtimeData } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  if (!runtimeData || runtimeData.length === 0) {
    return <ChartInfoBox severity="info" text={t("label-no-data")} />;
  }

  const getFillColor = () => {
    if (theme.palette.mode == "dark") {
      return theme.palette.secondary.light;
    } else {
      return "#ebf4fb";
    }
  };

  return (
    <>
      <ResponsiveContainer>
        <BarChart
          data={runtimeData}
          margin={{
            top: 20,
            right: 10,
            left: 0,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="5 5" />
          <XAxis hide />
          <YAxis
            width={80}
            tick={{ fontSize: 12 }}
            unit={` ${t("label-minutes")}`}
          />
          <Tooltip
            cursor={{ fill: getFillColor() }}
            content={<RuntimeChartTooltip />}
          />
          <Legend wrapperStyle={{ fontSize: "90%", marginBottom: "-10px" }} />
          <Bar
            name={t("label-scheduled-runtime")}
            dataKey="scheduled"
            fill={chartColors.orange}
            unit={` ${t("label-minutes")}`}
          />
          <Bar
            name={t("label-actual-runtime")}
            dataKey="actual"
            fill={chartColors.green}
            unit={` ${t("label-minutes")}`}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default RuntimesChart;
