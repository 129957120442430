import { ITask, ITaskCreate, ITaskUpdate } from "./types";
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  getAllTasksRequest,
  postTaskRequest,
  updateTaskRequest,
  deleteTaskRequest,
} from "./taskApiService";
import { RootState } from "@app/store";

const taskAdapter = createEntityAdapter<ITask>({
  selectId: (task) => task.task_guid,
});

const initialState = taskAdapter.getInitialState({
  selectedTask: "",
  status: "",
});

export const taskSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    selectTask(state, action: PayloadAction<string>) {
      state.selectedTask = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTasks.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchTasks.fulfilled,
        (state, { payload }: PayloadAction<ITask[]>) => {
          taskAdapter.setAll(state, payload);
          state.status = "";
        },
      )
      .addCase(fetchTasks.rejected, (state) => {
        state.status = "";
      })
      .addCase(createTask.pending, (state) => {
        state.status = "submitting";
      })
      .addCase(
        createTask.fulfilled,
        (state, { payload }: PayloadAction<ITask>) => {
          taskAdapter.addOne(state, payload);
          state.status = "";
        },
      )
      .addCase(createTask.rejected, (state) => {
        state.status = "";
      })
      .addCase(updateTask.pending, (state) => {
        state.status = "submitting";
      })
      .addCase(
        updateTask.fulfilled,
        (state, { payload }: PayloadAction<ITask>) => {
          taskAdapter.upsertOne(state, payload);
          state.status = "";
        },
      )
      .addCase(updateTask.rejected, (state) => {
        state.status = "";
      })
      .addCase(deleteTask.pending, (state) => {
        state.status = "deleting";
      })
      .addCase(
        deleteTask.fulfilled,
        (state, { payload }: PayloadAction<string>) => {
          taskAdapter.removeOne(state, payload);
          state.status = "";
        },
      )
      .addCase(deleteTask.rejected, (state) => {
        state.status = "";
      });
  },
});

export const fetchTasks = createAsyncThunk(
  "tasks/fetchTasks",
  async (customerId: string) => {
    try {
      const res = await getAllTasksRequest(customerId);
      if (res) return res.data;
      else return res;
    } catch (err) {
      console.error(err);
      throw new Error("failed to fetch task data");
    }
  },
);

export const createTask = createAsyncThunk(
  "tasks/createTask",
  async (payload: ITaskCreate) => {
    const customerId = payload.customer_guid;
    const res = await postTaskRequest(customerId, payload);

    return res.data;
  },
);

export const updateTask = createAsyncThunk(
  "tasks/updateTask",
  async (payload: ITaskUpdate | ITask) => {
    const customerId = payload.customer_guid;
    const res = await updateTaskRequest(customerId, payload);
    return res.data;
  },
);

export const deleteTask = createAsyncThunk(
  "tasks/deleteTask",
  async (payload: ITask) => {
    const customerId = payload.customer_guid;
    const taskId = payload.task_guid;
    await deleteTaskRequest(customerId, taskId);
    return taskId;
  },
);

export const { selectTask } = taskSlice.actions;
export default taskSlice.reducer;

const taskSelectors = taskAdapter.getSelectors(
  (state: RootState) => state.tasks,
);

// Selector for getting customer's tasks from the state
export const getAllTasks = (state: RootState, customerId: string) => {
  return taskSelectors
    .selectAll(state)
    .filter((task: ITask) => task.customer_guid === customerId);
};

export const getSelectedTask = (state: RootState) => {
  return taskSelectors.selectById(state, state.tasks.selectedTask);
};
export const getSelectedTaskId = (state: RootState) => state.tasks.selectedTask;

export const getSelectedTaskName = (state: RootState) => {
  return (
    taskSelectors.selectById(state, state.tasks.selectedTask)?.task_name || ""
  );
};

export const selectTasksStatus = (state: RootState) => state.tasks.status;
