import { CircularProgress, Paper } from "@mui/material";

interface IProps {
  show?: boolean;
}

/**
 * Renders a card with a CircularProgress if show is true, otherwise renders nothing.
 * @param
 * @returns
 */
const LoadingIndicatorBox = ({ show = true }: IProps) => {
  return show ? (
    <Paper
      sx={{
        marginTop: "12px",
        marginBottom: "12px",
        padding: "16px",
        textAlign: "center",
        backgroundColor: "primary.light",
      }}
    >
      <CircularProgress
        data-testid="loading-spinner"
        sx={{
          color: "primary.dark",
        }}
      />
    </Paper>
  ) : (
    <></>
  );
};

export default LoadingIndicatorBox;
