import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

const reactPlugin = new ReactPlugin();
// AppInsight setup
const appInsights = new ApplicationInsights({
  config: {
    // connectionstring for azure AppInsight connection in order to forward usage data to azure
    connectionString:
      "InstrumentationKey=4c7c5fc2-0956-4b9d-bdb0-bffca7eb183d;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/",
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
  },
});
appInsights.loadAppInsights();

export { reactPlugin, appInsights };
