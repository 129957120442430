import { useTranslation } from "react-i18next";
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { chartColors, formatToNDecimals } from "../utils";
import { StampData } from "./types";

interface StampsRuntimesChartProps {
  data: any[] | null;
}

const StampsRuntimesChart = (props: StampsRuntimesChartProps) => {
  const { data } = props;
  const { t } = useTranslation();

  if (!data) {
    return <></>;
  }

  return (
    <>
      <ResponsiveContainer>
        <BarChart
          data={data}
          margin={{
            top: 10,
            right: 10,
            left: 0,
            bottom: 10,
          }}
        >
          <XAxis dataKey="name" tick={false} tickLine={false} />
          <YAxis
            domain={[0, "dataMax"]}
            allowDecimals={false}
            tick={{ fontSize: 12 }}
            unit={" min"}
          />
          <Tooltip
            cursor={{ fill: "#ebf4fb" }}
            formatter={(value) => formatToNDecimals(value, 1)}
          />
          <Legend wrapperStyle={{ marginBottom: "10px" }} />
          <Bar
            name={t("label-time")}
            dataKey={"duration"}
            fill={chartColors.blue}
            unit={" " + t("label-minutes")}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default StampsRuntimesChart;
