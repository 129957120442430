import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  PayloadAction,
} from "@reduxjs/toolkit";

import { getAllVMsRequest } from "./environmentsApiService";
import { RootState } from "@app/store";
import { IVirtualMachine } from "./types";

const environmentAdapter = createEntityAdapter<IVirtualMachine>({
  selectId: (item) => item.name,
});

const initialState = environmentAdapter.getInitialState({
  selectedEnvironment: "",
  status: "",
});

const environmentsSlice = createSlice({
  name: "environments",
  initialState,
  reducers: {
    selectVM(state, action: PayloadAction<string>) {
      state.selectedEnvironment = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVMs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchVMs.fulfilled,
        (state, { payload }: PayloadAction<IVirtualMachine[]>) => {
          environmentAdapter.setAll(state, payload);
          state.status = "";
        },
      )
      .addCase(fetchVMs.rejected, (state) => {
        state.status = "";
      });
  },
});

export const fetchVMs = createAsyncThunk(
  "environments/fetchVMs",
  async (customerId: string) => {
    try {
      const res = await getAllVMsRequest(customerId);
      if (res) return res.data;
      else return res;
    } catch (err) {
      console.error(err);
      throw new Error("Failed to fetch virtual machines");
    }
  },
);

export const { selectVM } = environmentsSlice.actions;
export default environmentsSlice.reducer;

const vmSelectors = environmentAdapter.getSelectors(
  (state: RootState) => state.environments,
);

export const getAllVMs = (state: RootState) => {
  return vmSelectors.selectAll(state);
};

export const selectVMStatus = (state: RootState) => state.environments.status;
