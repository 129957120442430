import { baseTheme } from "@app/theme";

/**
 * Styling for the FormControl that contains table and buttons
 */
export const formControlStyle = {
  border: `1px solid ${baseTheme.palette.secondary.light}`,
  borderRadius: "3px",
  display: "block",
  marginBottom: "16px",
  padding: baseTheme.spacing(2),
};

/**
 * Styling for the FormControlLabel that has a label for table and buttons
 */
export const formLabelStyle = {
  fontSize: "18px",
  padding: baseTheme.spacing(0, 2),
  textAlign: "center",
};

export const dialogFormStyle = {
  display: "block",
  marginBottom: "16px",
  marginTop: 2,
};
