import { useState } from "react";
import { IconButton, List, SwipeableDrawer } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
import { IRoute } from "../types";
import NavigationDrawerLink from "./NavigationDrawerLink";

interface IProps {
  routes: IRoute[];
}
/**
 * Left-hand drawer and icon button for navigation when screen size is sm or xs.
 */
const NavDrawer = (props: IProps) => {
  const { routes } = props;
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Box sx={{ display: { xs: "block", md: "none" } }}>
      <IconButton
        data-testid="drawer-trigger-button"
        sx={{ color: "inherit", marginLeft: "-12px" }}
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        PaperProps={{ sx: { backgroundColor: grey[600] } }}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        onOpen={() => setMenuOpen(true)}
      >
        <List component="nav">
          {routes.map((item) => (
            <NavigationDrawerLink
              key={item.path}
              to={item.path}
              label={item.label}
              hasPermission={item.permission}
              closeDrawer={() => setMenuOpen(false)}
            />
          ))}
        </List>
      </SwipeableDrawer>
    </Box>
  );
};

export default NavDrawer;
