import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";

/**
 * Text area for forms using react-hook-form.
 *
 * @param control: react-hook-form control object
 */

interface IFormTextAreaProps {
  name: string;
  control: any;
  label: string;
  rows?: number;
  style?: any;
  readOnly?: boolean;
}

const FormTextArea = (props: IFormTextAreaProps) => {
  const { style, name, control, label, rows, readOnly } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          helperText={error ? error.message : null}
          error={!!error}
          data-testid={label}
          onChange={onChange}
          value={value ?? ""}
          fullWidth
          label={label}
          variant="outlined"
          sx={{
            ...style,
            color: "secondary.light",
          }}
          multiline
          rows={rows || 3}
          inputProps={{ readOnly: readOnly || false }}
        />
      )}
    />
  );
};

export default FormTextArea;
