import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { getSelectedCustomerName } from "@features/customers/customerSlice";
import { getSelectedTaskName } from "@features/tasks/tasksSlice";
import { RootState } from "@app/store";

const LogoBar = () => {
  const customerName = useSelector<RootState, string>(
    getSelectedCustomerName,
    shallowEqual,
  );

  let taskName = useSelector<RootState>(getSelectedTaskName);

  return (
    <Box
      maxWidth="xs"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "20px",
      }}
    >
      <Link to={"/"}>
        <img
          alt="logo"
          src={require("@assets/dolphin-logo.png")}
          style={{
            boxSizing: "content-box",
            height: "35px",
            marginTop: "7px",
          }}
        />
      </Link>
      {!!customerName ? (
        <Divider
          orientation="vertical"
          variant="middle"
          sx={{ backgroundColor: "white" }}
          flexItem
        />
      ) : null}
      <Typography variant="h2">
        {`${customerName} ${!!taskName ? ` - ${taskName}` : ""}`}
      </Typography>
    </Box>
  );
};

export default LogoBar;
