import { useSelector } from "react-redux";
import { Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { selectEventLogs, selectSelectedEventId } from "./eventLogsSlice";
import EventLogDisplay from "./EventLogDisplay";
import { logInfoPaperStyle } from "./style";

/**
 * Lists the event logs for the selected event after the logs are fetched.
 */

interface IProps {}

const EventLogList = (props: IProps) => {
  const { t } = useTranslation();
  const selectedEvent = useSelector(selectSelectedEventId);
  const eventLogs = useSelector(selectEventLogs);

  if (!selectedEvent) {
    return <></>;
  }

  return (
    <>
      {eventLogs && eventLogs.length !== 0 ? (
        eventLogs.map((log) => (
          <EventLogDisplay
            filename={log.filename}
            uri={log.uri}
            name={log.name}
            type={log.content_type}
            key={log.name}
          />
        ))
      ) : (
        <Paper sx={{ ...logInfoPaperStyle }}>
          <Typography>{t("label-no-event-logs")}</Typography>
        </Paper>
      )}
    </>
  );
};

export default EventLogList;
