import { FormControl, FormLabel, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { useState } from "react";
import { formLabelStyle, resourceFormStyle } from "../style";
import { getCustomerObj } from "@features/customers/customerSlice";
import EditButtonGroup from "../../common/ButtonGroups/EditButtonGroup";

const VNet = () => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const customer = useSelector(getCustomerObj);

  return (
    <Box>
      <FormControl component="fieldset" sx={resourceFormStyle}>
        <FormLabel component="legend" sx={formLabelStyle}>
          Virtual Network
        </FormLabel>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="name"
              label="Virtual Network Name"
              value={customer?.vnet_name}
              InputProps={{ disabled: true }} // original: InputProps={{ readOnly: !isEditing }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="address_space"
              label="Address Space"
              value={customer?.vnet_address_space}
              InputProps={{ disabled: !isEditing }} // original: InputProps={{ readOnly: !isEditing }}
            />
          </Grid>
        </Grid>
        <EditButtonGroup
          disabled={true}
          isEditing={isEditing}
          handleEdit={() => setIsEditing(!isEditing)}
          handleCancel={() => setIsEditing(false)}
        />
      </FormControl>
    </Box>
  );
};

export default VNet;
