import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppBar, ThemeProvider, Toolbar } from "@mui/material";
import CustomersDialog from "@features/customers";
import { PermissionButton } from "@features/authorization/PermissionButton";
import { getSelectedCustomerId } from "@features/customers/customerSlice";
import { permissionsApi } from "@features/authorization/authService";
import { checkPermissions, IPermissions } from "@features/authorization/types";
import { headerTheme } from "./theme";
import LogoBar from "./logobar";
import NavBar from "./navbar";
import LanguageMenu from "./languagemenu";
import NavDrawer from "./navbar/NavDrawer";
import UserMenu from "./usermenu";
import DarkMode from "./darkmode/DarkMode";

export const Header = () => {
  const selectedCustomerId = useSelector(getSelectedCustomerId);
  const { data: permissionDataCustomer } =
    permissionsApi.useGetCustomerPermissionsQuery(selectedCustomerId, {
      skip: !selectedCustomerId,
    });
  const {
    data: permissionDataOiva,
    isLoading: isLoadingOiva,
    isFetching: isFetchingOiva,
  } = permissionsApi.useGetOivaPermissionsQuery();

  let routes = [{ label: "menu-main-home", path: "/", permission: true }];
  const [activeRoutes, setActiveRoutes] = useState<any>(routes);
  const [adminPermissions, setAdminPermissions] = useState<IPermissions>();

  useEffect(() => {
    if (permissionDataOiva) {
      const adminperms = checkPermissions("administration", permissionDataOiva);

      setAdminPermissions(adminperms);
    }
  }, [isLoadingOiva, isFetchingOiva]);

  useEffect(() => {
    const custperms = checkPermissions("customer", permissionDataCustomer);
    const taskperms = checkPermissions("tasks", permissionDataCustomer);
    const adminperms = checkPermissions("administration", permissionDataOiva);

    const routes = [
      {
        label: "menu-main-home",
        path: "/",
        permission: true,
      },
      {
        label: "menu-main-dashboard",
        path: `customer/${selectedCustomerId}/dashboard`,
        permission: selectedCustomerId || false,
      },
      {
        label: "menu-main-customer",
        path: `customer/${selectedCustomerId}/customermanagement`,
        permission: custperms?.read || false,
      },
      {
        label: "menu-main-environment",
        path: `customer/${selectedCustomerId}/environments`,
        permission: taskperms?.read || false,
      },
      {
        label: "menu-main-task",
        path: `customer/${selectedCustomerId}/tasks`,
        permission: taskperms?.read || false,
      },
      {
        label: "menu-main-admin",
        path: "/admin",
        permission: adminperms?.read || false,
      },
    ];

    setActiveRoutes(routes);
  }, [permissionDataCustomer, permissionDataOiva]);

  return (
    <ThemeProvider theme={headerTheme}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "primary.main",
          position: "sticky",
          zIndex: 999,
          top: "-10px",
          boxShadow: "0px -6px 10px 5px #0000009c",
        }}
      >
        <Toolbar variant="dense">
          <LogoBar />
          <LanguageMenu />
          {adminPermissions && adminPermissions.read ? (
            <PermissionButton />
          ) : null}
          <DarkMode />
          <UserMenu />
          <CustomersDialog initialOpen={true} />
        </Toolbar>
        <Toolbar>
          <NavBar routes={activeRoutes} />
          <NavDrawer routes={activeRoutes} />
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};
