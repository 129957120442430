import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from "recharts";
import { useTranslation } from "react-i18next";
import { chartColors, formatToNDecimals } from "../utils";
import { StampData } from "./types";

interface StampsCountChartProps {
  data: StampData[] | null;
  selectedType: string;
}

const StampsCountChart = (props: StampsCountChartProps) => {
  const { data, selectedType } = props;
  const { t } = useTranslation();

  if (!data || data.length === 0) {
    return <></>;
  }

  const stampsToShow =
    selectedType === "all"
      ? data
      : data.filter((stamp: StampData) => {
          if (selectedType === "double") {
            return Object.hasOwn(stamp, "loop_information");
          } else if (selectedType === "single") {
            return !Object.hasOwn(stamp, "loop_information");
          }
        });

  return (
    <>
      <ResponsiveContainer>
        <BarChart
          data={stampsToShow.length > 0 ? stampsToShow : [{ total_count: 0 }]}
          margin={{
            top: 10,
            right: 10,
            left: 0,
            bottom: 10,
          }}
        >
          <XAxis dataKey="name" tick={false} tickLine={false} />
          <YAxis
            domain={[0, "dataMax"]}
            allowDecimals={false}
            tick={{ fontSize: 12 }}
          />
          <Tooltip cursor={{ fill: "#ebf4fb" }} formatter={formatToNDecimals} />
          <Legend wrapperStyle={{ marginBottom: "10px" }} />
          <Bar
            name={t("label-run-count")}
            dataKey={"total_count"}
            fill={chartColors.blue}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default StampsCountChart;
