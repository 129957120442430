import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { Order } from "./tableSortFunctions";

interface ISortTableHeadProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: Order;
  orderBy: string;
  properties: any[];
}

/**
 * Implements table head rows with sort. This can be used in place of a normal MUI table head.
 * More info on MUI table sorting: https://mui.com/material-ui/react-table/#sorting-amp-selecting
 * @param props
 * @returns
 */

const SortTableHead = (props: ISortTableHeadProps) => {
  const { order, orderBy, onRequestSort, properties } = props;
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          key={"radiobutton-cells"}
          sx={{ backgroundColor: "primary.light" }}
        />
        {properties.map((property: any) => (
          <TableCell
            key={property.key}
            sortDirection={orderBy === property.key ? order : false}
            sx={{ backgroundColor: "primary.light" }}
          >
            <TableSortLabel
              active={orderBy === property.key}
              direction={orderBy === property.key ? order : "asc"}
              onClick={createSortHandler(property.key)}
            >
              <Typography>{property.label}</Typography>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default SortTableHead;
