import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useAppDispatch } from "@features/common/StoreHook";
import FormTextField from "@features/common/FormFields/FormTextField";
import FormTextArea from "@features/common/FormFields/FormTextArea";
import { getSelectedCustomerId } from "@features/customers/customerSlice";
import { RootState } from "@app/store";
import SaveCancelButtonGroup from "@features/common/ButtonGroups/SaveCancelButtonGroup";

import {
  createStamp,
  StampDataCollection,
  selectStampsStatus,
} from "./stampsSlice";
import { getSelectedTaskId } from "../tasksSlice";
import { IStamp } from "../types";

interface AddStampProps {
  onCancel: () => void;
}

const validationSchema = yup.object({
  stamp_name: yup.string().required(),
  stamp_screen_name: yup.string().required(),
  stamp_description: yup.string(),
});

export const AddStamp = (props: AddStampProps) => {
  const { onCancel } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const customerId = useSelector(getSelectedCustomerId);
  const selectedTaskId = useSelector((state: RootState) =>
    getSelectedTaskId(state),
  );
  const isSubmitting = useSelector(selectStampsStatus) === "submitting";

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      stamp_name: "",
      stamp_screen_name: "",
      stamp_description: "",
    },
  });

  const handleCancel = () => {
    onCancel();
  };

  const handleFormSubmit = (formData: any) => {
    const { stamp_name, stamp_screen_name, stamp_description } = formData;
    const payload: IStamp = {
      customer_guid: customerId,
      task_guid: selectedTaskId,
      stamp_name: stamp_name,
      stamp_screen_name: stamp_screen_name,
      stamp_description: stamp_description,
    };
    const data: StampDataCollection = {
      payload: payload,
    };
    dispatch(createStamp(data))
      .unwrap()
      .then(() => {
        toast.success(t("Stamp metadata created"));
      })
      .catch(() => {
        toast.error(t("Failed to create stamp data"));
      });
    onCancel();
  };

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: "1px" }}>
        <Grid item xs={12} sm={6}>
          <FormTextField
            name="stamp_name"
            control={control}
            label={t("label-stamp-name")}
            readOnly={isSubmitting}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            name="stamp_screen_name"
            control={control}
            label={t("label-stamp-screen-name")}
            readOnly={isSubmitting}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextArea
            name="stamp_description"
            control={control}
            label={t("label-stamp-description")}
            readOnly={isSubmitting}
          />
        </Grid>
        <Grid item xs={12}>
          <SaveCancelButtonGroup
            disabled={isSubmitting}
            handleCancel={handleCancel}
            handleSave={handleSubmit(handleFormSubmit)}
          />
        </Grid>
      </Grid>
    </>
  );
};
