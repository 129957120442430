import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { languageListMenuStyle } from "./style";

interface IComponentProps {
  languages: { id: string; value: string; label: string }[];
  changeLanguage: (langCode: string) => void;
  currentLang: string;
}

/**
 * Language list menu, displayed on md and larger screens.
 *
 * @param props
 * @returns
 */
const LanguageListMenu = (props: IComponentProps) => {
  const { languages, changeLanguage, currentLang } = props;

  return (
    <Box sx={{ display: { xs: "none", md: "block" } }}>
      <div style={languageListMenuStyle.linkContainerStyle}>
        {languages &&
          languages.map((l) => (
            <Typography
              key={l.id}
              variant="h3"
              style={{
                ...languageListMenuStyle.linkStyle,
                fontWeight: l.id === currentLang ? 700 : 400,
              }}
              onClick={() => changeLanguage(l.id)}
            >
              {l.label}
            </Typography>
          ))}
      </div>
    </Box>
  );
};

export default LanguageListMenu;
