/**
 * Contains the API queries for permissions endpoints.
 * Here we use RTK Query for data fetching.
 */
import { oivaApi } from "@features/common/oivaApi";

export const permissionsApi = oivaApi.injectEndpoints({
  endpoints: (builder) => ({
    getOivaPermissions: builder.query<string[], void>({
      query: () => `/permissions/oiva`,
    }),
    getCustomerPermissions: builder.query<string[], string>({
      query: (customer_id) => `/permissions/customer/${customer_id}`,
    }),
  }),
});

export const { useGetOivaPermissionsQuery, useGetCustomerPermissionsQuery } =
  permissionsApi;
