import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { Grid, FormLabel, FormControl } from "@mui/material";

import { RootState } from "@app/store";
import { DropdownSelect } from "@features/common/DropdownSelect";
import { useAppDispatch } from "@features/common/StoreHook";
import { formControlStyle, formLabelStyle } from "@features/common/styles";
import EditButtonGroup from "@features/common/ButtonGroups/EditButtonGroup";
import { useCollection } from "@features/common/collectionHook";
import {
  ISchedule,
  IScheduleUpdate,
  mapIScheduleToIScheduleUpdate,
} from "../types";

import {
  getSelectedSchedule,
  updateSchedule,
  selectSchedulesStatus,
} from "./scheduleSlice";
import { CRONRecurrenceSelector } from "./CRONRecurrenceSelector";
import { DailyRecurrenceSelector } from "./DailyRecurrenceSelector";
import { WeeklyRecurrenceSelector } from "./WeeklyRecurrenceSelector";
import { MonthlyRecurrenceSelector } from "./MonthlyRecurrenceSelector";
import { LeftRightSelector } from "./LeftRightSelector";
import FormDateField from "./FormDateField";
import FormTimeField from "./FormTimeField";
import { permissionsApi } from "@features/authorization/authService";
import { checkPermissions } from "@features/authorization/types";
import { getSelectedCustomerId } from "@features/customers/customerSlice";
import { schedulerGridStyle } from "./styles";

/**
 *
 * Component to schedule vm runs for selected task.
 * Part of the larger Schedules view, visible when a schedule is selected
 * Options are pre-filled if selected schedule has them available
 *
 */

const validationSchema = yup.object({
  start_date: yup.string().required(),
  end_date: yup.string().required(),
  start_time: yup.string().required(),
  end_time: yup.string().required(),
  frequency: yup.number().typeError("Frequency has to be a number").required(),
  day_number: yup.number(),
  runs_monday: yup.boolean(),
  runs_tuesday: yup.boolean(),
  runs_wednesday: yup.boolean(),
  runs_thursday: yup.boolean(),
  runs_friday: yup.boolean(),
  runs_saturday: yup.boolean(),
  runs_sunday: yup.boolean(),
  cron_string: yup.string(),
  duration: yup.number(),
});

export const Scheduler = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const selectedSchedule: ISchedule | undefined = useSelector(
    (state: RootState) => getSelectedSchedule(state),
  );
  const isSubmitting = useSelector(selectSchedulesStatus) === "submitting";

  const selectedCustomerId = useSelector(getSelectedCustomerId);
  const { data, error, isLoading } =
    permissionsApi.useGetCustomerPermissionsQuery(selectedCustomerId);
  const schedulePermissions = checkPermissions("schedules", data);

  const [selectedVm, setSelectedVm] = useState<string>("");
  const [vmFieldError, setVmFieldError] = useState<string>("");
  const [selectedRecurrence, setSelectedRecurrence] = useState<string>("");
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      start_date: "",
      end_date: "",
      start_time: "",
      end_time: "",
      frequency: 0,
      day_number: 0,
      runs_monday: false,
      runs_tuesday: false,
      runs_wednesday: false,
      runs_thursday: false,
      runs_friday: false,
      runs_saturday: false,
      runs_sunday: false,
      cron_string: "",
      duration: 0,
    },
  });

  const recurrenceTypes = useCollection(13);

  const resetFormValues = () => {
    if (selectedSchedule) {
      setVmFieldError("");
      reset({
        start_date: dayjs(selectedSchedule.start_datetime).format("YYYY-MM-DD"),
        end_date: dayjs(selectedSchedule.end_datetime).format("YYYY-MM-DD"),
        start_time: dayjs(selectedSchedule.start_datetime).format("HH:mm"),
        end_time: dayjs(selectedSchedule.end_datetime).format("HH:mm"),
        runs_monday: selectedSchedule.weekly_recurrence?.runs_monday,
        runs_tuesday: selectedSchedule.weekly_recurrence?.runs_tuesday,
        runs_wednesday: selectedSchedule.weekly_recurrence?.runs_wednesday,
        runs_thursday: selectedSchedule.weekly_recurrence?.runs_thursday,
        runs_friday: selectedSchedule.weekly_recurrence?.runs_friday,
        runs_saturday: selectedSchedule.weekly_recurrence?.runs_saturday,
        runs_sunday: selectedSchedule.weekly_recurrence?.runs_sunday,
        day_number: selectedSchedule.monthly_recurrence?.day_number || 0,
        cron_string: selectedSchedule.cron_recurrence?.cron_string || "",
        duration: selectedSchedule.cron_recurrence?.duration || 0,
        frequency: selectedSchedule.daily_recurrence
          ? selectedSchedule.daily_recurrence.frequency
          : selectedSchedule.weekly_recurrence
          ? selectedSchedule.weekly_recurrence.frequency
          : selectedSchedule.monthly_recurrence
          ? selectedSchedule.monthly_recurrence.frequency
          : 0,
      });
    }
  };

  useEffect(() => {
    if (selectedSchedule) {
      resetFormValues();
      if (selectedSchedule.target_vm) setSelectedVm(selectedSchedule.target_vm);

      if (selectedSchedule.start_datetime) {
        const recurrence = determineRecurrenceType(selectedSchedule);
        setSelectedRecurrence(recurrence);
      }
    }
  }, [selectedSchedule]);

  const determineRecurrenceType = (schedule: any) => {
    const recurrenceIndex = schedule.daily_recurrence
      ? "Daily"
      : schedule.weekly_recurrence
      ? "Weekly"
      : schedule.monthly_recurrence
      ? "Monthly"
      : schedule.cron_recurrence
      ? "CRON"
      : "None";
    return recurrenceIndex;
  };

  const handleDropdownChange = (event: string) => {
    setSelectedRecurrence(event);
  };

  const validateFormAndSubmit = () => {
    // Enables validation and showing errors for both the VM field and the rest of the form at the same time.
    if (!selectedVm) {
      setVmFieldError("Please select a VM");
    }
    handleSubmit((data) => {
      // handleSubmit submits form if useForm values are valid by default,
      // so VM field check is done separately
      if (!selectedVm) return;
      handleSave(data);
    })();
  };

  const handleSave = (data: any) => {
    if (selectedSchedule) {
      let scheduleUpdateObj: IScheduleUpdate =
        mapIScheduleToIScheduleUpdate(selectedSchedule);

      const startDateString = `${data.start_date}T${data.start_time}`;
      const endDateString = `${data.end_date}T${data.end_time}`;
      const start = new Date(startDateString);
      const end = new Date(endDateString);

      scheduleUpdateObj.start_time = start;
      scheduleUpdateObj.end_time = end;

      scheduleUpdateObj.vm_name = selectedVm;

      scheduleUpdateObj.daily_recurrence = null;
      scheduleUpdateObj.weekly_recurrence = null;
      scheduleUpdateObj.monthly_recurrence = null;
      scheduleUpdateObj.cron_recurrence = null;
      switch (selectedRecurrence) {
        case "Daily":
          scheduleUpdateObj.daily_recurrence = {
            frequency: data.frequency,
          };
          break;
        case "Weekly":
          scheduleUpdateObj.weekly_recurrence = {
            frequency: data.frequency,
            runs_monday: data.runs_monday,
            runs_tuesday: data.runs_tuesday,
            runs_wednesday: data.runs_wednesday,
            runs_thursday: data.runs_thursday,
            runs_friday: data.runs_friday,
            runs_saturday: data.runs_saturday,
            runs_sunday: data.runs_sunday,
          };
          break;
        case "Monthly":
          scheduleUpdateObj.monthly_recurrence = {
            frequency: data.frequency,
            day_number: data.day_number,
          };
          break;
        case "CRON":
          scheduleUpdateObj.cron_recurrence = {
            cron_string: data.cron_string,
            duration: data.duration,
          };
          break;
      }
      dispatch(updateSchedule(scheduleUpdateObj))
        .unwrap()
        .then(() => {
          setIsEditing(false);
          toast.success(t("Schedule updated"));
        })
        .catch(() => {
          toast.error(t("Failed to update schedule"));
        });
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    if (selectedSchedule) {
      resetFormValues();
      if (selectedSchedule.target_vm) setSelectedVm(selectedSchedule.target_vm);
      const recurrenceType = determineRecurrenceType(selectedSchedule);
      setSelectedRecurrence(recurrenceType);
    }
  };

  const handleEdit = () => {
    schedulePermissions.update
      ? setIsEditing(true)
      : toast.error("No update permission");
  };

  return (
    <>
      <FormControl component="fieldset" sx={formControlStyle}>
        <FormLabel component="legend" sx={formLabelStyle}>
          {t("label-scheduler")}
        </FormLabel>

        <Grid style={schedulerGridStyle}>
          <LeftRightSelector
            setleftGlobal={setSelectedVm}
            selectedSchedule={selectedSchedule}
            isEditing={isEditing}
            error={vmFieldError}
            setError={setVmFieldError}
          />
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormDateField
              name="start_date"
              control={control}
              label={t("label-start-date")}
              readOnly={!isEditing}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormDateField
              name="end_date"
              control={control}
              label={t("label-end-date")}
              readOnly={!isEditing}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTimeField
              name="start_time"
              control={control}
              label={t("label-start-time")}
              readOnly={!isEditing}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTimeField
              name="end_time"
              control={control}
              label={t("label-end-time")}
              readOnly={!isEditing}
            />
          </Grid>
          <Grid item xs={12}>
            <DropdownSelect
              style={{ width: "100%" }}
              setParentComponentState={handleDropdownChange}
              selectedItem={selectedRecurrence}
              selectItems={recurrenceTypes}
              menuId="SchedulerRecurrenceSelect"
              textFieldLabel={t("label-recurrence")}
              disabled={!isEditing}
            />
          </Grid>

          <Grid item xs={12}>
            {selectedRecurrence === "Daily" ? (
              <DailyRecurrenceSelector
                isEditing={isEditing}
                control={control}
              />
            ) : selectedRecurrence === "Weekly" ? (
              <WeeklyRecurrenceSelector
                isEditing={isEditing}
                control={control}
              />
            ) : selectedRecurrence === "Monthly" ? (
              <MonthlyRecurrenceSelector
                isEditing={isEditing}
                control={control}
              />
            ) : selectedRecurrence === "CRON" ? (
              <CRONRecurrenceSelector isEditing={isEditing} control={control} />
            ) : null}
          </Grid>
        </Grid>

        <EditButtonGroup
          disabled={isSubmitting}
          isEditing={isEditing}
          handleSave={validateFormAndSubmit}
          handleEdit={handleEdit}
          handleCancel={handleCancel}
        />
      </FormControl>
    </>
  );
};
