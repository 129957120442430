import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormControl, FormLabel, Box } from "@mui/material";
import { useAppDispatch } from "@features/common/StoreHook";
import { formControlStyle, formLabelStyle } from "../style";
import { ISubnet } from "../types";
import {
  fetchSubnets,
  getAllSubnets,
  selectSubnetStatus,
} from "./subnetsSlice";
import { getSelectedCustomerId } from "@features/customers/customerSlice";
import SubnetContainer from "./SubnetContainer";
import AddDuplicateDeleteButtonGroup from "../../common/ButtonGroups/AddDuplicateDeleteButtonGroup";
import AddSubnet from "./AddSubnet";
import SortTable, { IProperty } from "@features/common/Table/SortTable";
import ExportExcel from "@features/common/ExportExcel";

/**
 * Table that contains customer's subnets.
 *
 * TODO:
 *  - Many of the buttons are placeholders at the moment and have been disabled until they can actually be used.
 *  - Button for exporting excel file has only a placeholder icon for now
 * @returns
 */
const Subnets = () => {
  const [selected, setSelected] = useState<string | undefined>("");
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const customerId = useSelector(getSelectedCustomerId);
  const subnets = useSelector(getAllSubnets);
  const isLoading = useSelector(selectSubnetStatus);

  const properties: IProperty<ISubnet>[] = [
    { key: "subnet_name", label: t("label-subnet-name") },
    { key: "subnet_address_space", label: t("label-address-space") },
    { key: "subnet_type", label: t("label-type") },
  ];

  useEffect(() => {
    if (customerId) {
      dispatch(fetchSubnets(customerId));
    }
  }, [customerId]);

  const handleChange = (id: string) => {
    setSelected(id);
  };

  return (
    <Box>
      <FormControl component="fieldset" sx={formControlStyle}>
        <FormLabel component="legend" sx={formLabelStyle}>
          {t("network-label-subnet")}
        </FormLabel>
        {!isLoading && (
          <Box sx={{ textAlign: "right" }}>
            <ExportExcel
              data={subnets}
              fileName="virtual_machines"
              coloredButton={true}
            />
          </Box>
        )}
        <SortTable
          properties={properties}
          idProperty={"subnet_name"}
          handleChange={handleChange}
          isLoading={isLoading === "loading"}
          selectedId={selected}
          data={subnets}
        />
        <AddDuplicateDeleteButtonGroup
          disabled={true}
          handleAdd={() => setShowAddDialog(true)}
        />
      </FormControl>
      <AddSubnet
        show={showAddDialog}
        onCancel={() => setShowAddDialog(false)}
      />
      <SubnetContainer subnetId={selected} key={selected} />
    </Box>
  );
};

export default Subnets;
