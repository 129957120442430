import { useTranslation } from "react-i18next";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Tooltip, IconButton, useTheme } from "@mui/material";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";

interface ChartCardProps {
  title: string;
  children?: React.ReactNode;
  openFullscreen?: () => void;
}

const ChartCard = (props: ChartCardProps) => {
  const { title, children, openFullscreen } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  const handleOpenFullscreen = () => {
    if (openFullscreen) {
      openFullscreen();
    }
  };

  return (
    <Card sx={{ backgroundColor: theme.palette.secondary.main }}>
      <CardContent>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5">{title}</Typography>
          <Tooltip title={t("label-fullscreen")}>
            <IconButton onClick={handleOpenFullscreen}>
              <AspectRatioIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={{ height: "310px" }}>{children}</Box>
      </CardContent>
    </Card>
  );
};

export default ChartCard;
