export const logPaperStyle = {
  marginTop: "12px",
  marginBottom: "12px",
  padding: "16px",
  backgroundColor: "primary.light",
};

export const logInfoPaperStyle = {
  ...logPaperStyle,
  textAlign: "center",
};

export const logTextFileContainerStyle = {
  height: "600px",
  width: "100%",
  border: "1px solid",
  overflow: "auto",
  whiteSpace: "pre-wrap",
  fontSize: "0.9rem",
};
