import { TextField, Grid, FormControl, FormLabel } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { formControlStyle, formLabelStyle } from "@features/common/styles";
import { getCustomerObj } from "@features/customers/customerSlice";

export const BasicInfo = () => {
  const { t } = useTranslation();

  // Since editing customers is not a feature right now, this isEditing is always false
  // This should be replaced with a useState when editing is available
  const isEditing = false;

  const customer = useSelector(getCustomerObj);

  return (
    <>
      <FormControl component="fieldset" sx={formControlStyle}>
        <FormLabel component="legend" sx={formLabelStyle}>
          {t("basic-info")}
        </FormLabel>

        <Grid container spacing={2}>
          <Grid
            container
            item
            xs={12}
            sm={6}
            spacing={2}
            direction={{ sm: "column" }}
          >
            <Grid item xs={12} sm={2}>
              <TextField
                label={t("customer-name")}
                data-testid="customer-name"
                value={customer?.name}
                fullWidth
                inputProps={{ readOnly: !isEditing }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label={t("customer-description")}
                data-testid="customer-description"
                disabled={true}
                value={customer?.customer_description}
                multiline
                rows={4}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label={t("customer-type")}
                data-testid="customer-type"
                disabled={true}
                value={customer?.on_site_implementation ? "Onsite" : "Default"}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label={t("customer-timezone")}
                data-testid="customer-timezone"
                disabled={true}
                value={""}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={6} spacing={3} direction={"column"}>
            <Grid item xs={12}>
              <FormControl component="fieldset" sx={formControlStyle}>
                <FormLabel component="legend" sx={formLabelStyle}>
                  Generated IDs
                </FormLabel>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label={t("customer-guid")}
                      data-testid="customer-guid"
                      value={customer?.id}
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("lowercase-customer-name")}
                      data-testid="lowercase-customer-name"
                      value={customer?.lowercase_name}
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("tech-customer-name")}
                      data-testid="tech-customer-name"
                      value={customer?.tech_customer_name}
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("short-customer-name")}
                      data-testid="short-customer-name"
                      value={customer?.short_customer_name}
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("short-lowercase-customer-name")}
                      data-testid="short-lowercase-customer-name"
                      value={customer?.short_lowercase_customer_name}
                      disabled
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
    </>
  );
};
