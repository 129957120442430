export const listItemButtonStyles = {
  paddingLeft: "10px",
  display: "flex",
  gap: "12px",
  alignItems: "center",
};

export const listItemTextStyles = {
  height: "100%", // Expand the actual button size so that it covers the whole element (improves UX)
  span: {
    display: "flex",
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
  },
};

export const titleStyles = {
  display: "flex",
  justifyContent: "center",
};

export const buttonGridStyles = {
  marginBottom: "16px",
  display: "flex",
  justifyContent: "center",
  gap: "15px",
};

export const serviceProviderGridStyles = {
  display: "flex",
  justifyContent: "center",
};
