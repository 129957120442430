import { Button, Grid, useTheme } from "@mui/material";

interface IComponentProps {
  handleStart: () => void;
  handleRestart: () => void;
  handleDeallocate: () => void;
  handleRedeploy: () => void;
  disabled: boolean;
}

const ResourceControlButtonGroup = (props: IComponentProps) => {
  const {
    disabled,
    handleStart,
    handleRestart,
    handleDeallocate,
    handleRedeploy,
  } = props;
  const theme = useTheme();

  return (
    <Grid container direction="row" gap={1}>
      <Button
        variant="contained"
        disabled={disabled}
        sx={{ backgroundColor: theme.palette.secondary.dark }}
      >
        Start
      </Button>
      <Button
        variant="contained"
        disabled={disabled}
        sx={{ backgroundColor: theme.palette.secondary.dark }}
      >
        Restart
      </Button>
      <Button
        variant="contained"
        disabled={disabled}
        sx={{ backgroundColor: theme.palette.secondary.dark }}
      >
        Deallocate
      </Button>
      <Button
        variant="contained"
        disabled={disabled}
        sx={{ backgroundColor: theme.palette.secondary.dark }}
      >
        Redeploy
      </Button>
    </Grid>
  );
};

export default ResourceControlButtonGroup;
