import { AxiosResponse } from "axios";
import { apiGET } from "@features/common/baseApiService";
import { IVirtualMachine } from "./types";

// TODO: Replace "any" with type information

export async function getAllVMsRequest(
  customerId: string,
): Promise<AxiosResponse<IVirtualMachine[]>> {
  const resource = `customers/${customerId}/environments/machines`;
  const response = await apiGET<IVirtualMachine[]>(resource);
  return response;
}
