import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { Grid, FormControl, FormLabel } from "@mui/material";

import { useAppDispatch } from "@features/common/StoreHook";
import { formControlStyle, formLabelStyle } from "@features/common/styles";
import FormTextField from "@features/common/FormFields/FormTextField";
import FormTextArea from "@features/common/FormFields/FormTextArea";
import EditButtonGroup from "@features/common/ButtonGroups/EditButtonGroup";
import {
  selectCollectionsStatus,
  updateCollectionMember,
} from "./collectionsSlice";
import {
  ICollectionMember,
  ICollectionMemberUpdate,
  ICollectionValue,
} from "./types";

const getCollectionLanguageValue = (
  collectionValues: ICollectionValue[],
  lang: string,
) => {
  if (!collectionValues) {
    return "";
  }
  return (
    collectionValues.find((value) => value.language_code === lang)?.value ||
    collectionValues.find((value) => value.language_code === "xx-xx")?.value ||
    ""
  );
};

interface CollectionValueProps {
  value: ICollectionMember | undefined;
  collectionId: number;
}

const validationSchema = yup.object({
  id: yup.string().required(),
  description: yup.string().nullable(),
  value_en: yup.string().required(),
  value_fi: yup.string().required(),
});

const CollectionValue = (props: CollectionValueProps) => {
  const { value, collectionId } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const isSubmitting = useSelector(selectCollectionsStatus) === "submitting";

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      id: value?.id,
      description: value?.description,
      value_en: value ? getCollectionLanguageValue(value?.values, "en-US") : "",
      value_fi: value ? getCollectionLanguageValue(value?.values, "fi-FI") : "",
    },
  });

  const handleUpdate = (formData: any) => {
    const updatedCollectionValue: ICollectionMemberUpdate = {
      collectionId: collectionId,
      id: formData.id,
      description: formData.description,
      values: [
        { language_code: "en-US", value: formData.value_en },
        { language_code: "fi-FI", value: formData.value_fi },
      ],
    };
    dispatch(updateCollectionMember(updatedCollectionValue))
      .unwrap()
      .then(() => {
        setIsEditing(false);
        toast.success(t("Collection value updated"));
      })
      .catch(() => {
        toast.error(t("Failed to update collection value"));
      });
  };

  const handleCancel = () => {
    reset({
      id: value?.id,
      description: value?.description,
      value_en: value ? getCollectionLanguageValue(value?.values, "en-US") : "",
      value_fi: value ? getCollectionLanguageValue(value?.values, "fi-FI") : "",
    });
    setIsEditing(false);
  };

  return (
    <>
      <FormControl component="fieldset" sx={formControlStyle}>
        <FormLabel component="legend" sx={formLabelStyle}>
          {t("label-collection-value")}
        </FormLabel>
        <Grid container spacing={2}>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormTextField
                name="id"
                control={control}
                label={t("label-collection-value-id")}
                readOnly={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextArea
                name="description"
                control={control}
                label={t("label-collection-value-description")}
                readOnly={!isEditing || isSubmitting}
                rows={4}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormTextArea
              name="value_en"
              control={control}
              label={t("label-text-value-en")}
              readOnly={!isEditing || isSubmitting}
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextArea
              name="value_fi"
              control={control}
              label={t("label-text-value-fi")}
              readOnly={!isEditing || isSubmitting}
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <EditButtonGroup
              handleSave={handleSubmit(handleUpdate)}
              isEditing={isEditing}
              disabled={isSubmitting}
              handleEdit={() => setIsEditing(true)}
              handleCancel={handleCancel}
            />
          </Grid>
        </Grid>
      </FormControl>
    </>
  );
};

export default CollectionValue;
