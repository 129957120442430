import {
  createBrowserRouter,
  Route,
  createRoutesFromElements,
} from "react-router-dom";
import { Layout } from "./Layout";
import TaskWindow from "@features/tasks";
import TaskSelector from "@features/tasks/TaskSelector/TaskSelector";
import { TaskBasicInfo } from "@features/tasks/BasicInfo";
import { Environments as TaskEnvironments } from "@features/tasks/Environments/Environments";
import { ScheduledEvents } from "@features/tasks/Scheduler/ScheduledEvents";
import { Stamps } from "@features/tasks/Stamps";
import { DashboardWindowPlaceholder } from "@features/dashboard";
import { BasicInfo as CustomerBasicInfo } from "@features/customermanagement/BasicInfo";
import { Secrets } from "@features/customermanagement/Secrets/Secrets";
import { UsersRolesWindow } from "@features/customermanagement/UsersRoles";
import { CustomerManagementWindow } from "@features/customermanagement";
import {
  Compute,
  Connections,
  Environments,
  Networks,
} from "@features/environments";
import { AdminWindow } from "@features/admin";
import CustomerPage from "@features/customers/CustomerPage";
import CustomerLayout from "./pages/CustomerLayout";
import EventLogs from "@features/dashboard/EventLogs/EventLogs";
import Dashboard from "@features/dashboard/Dashboard/Dashboard";
import Texts from "@features/admin/Texts/Texts";
import Collections from "@features/admin/Collections/Collections";

/**
 * The router used by the app. At first we should have the following routes:
 *  / - the root route, customer selection dialog opens
 *  /customer/:customerId - customer selected, navbar shows all the tabs for the customer
 *  /customer/:customerId/dashboard - the customer's dashboard
 *  /customer/:customerId/tasks - the customer's tasks
 *  /customer/:customerId/customermanagement - the 'customer management' tab
 *  /customer/:customerId/environments - customer's environments
 *  /admin - dolphin administration
 *
 * Later we will add routes to specific subtabs in customer management, as well as routes to individual tasks etc.
 * Note that going to one of the customer's routes should trigger a check whether user has access to the customer by obtaining the list of user's customers, and checking that the customer with the given ID is in the result
 * the route component should then set the state to point to that customer, or show an error page if the user does not have access to that customer.
 */

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route path="/" element={<CustomerLayout />}>
        <Route index element={<CustomerPage customer={null} />} />
        <Route path="customer/:customerId">
          <Route path="dashboard" element={<DashboardWindowPlaceholder />}>
            {/** This is commented out for now, as the views are not ready.
             * Uncomment views when they've been implemented and are ready for use.
             * 
             {/** This is commented out for now, as the views are not ready.
             * Uncomment views when they've been implemented and are ready for use.
             * 
             <Route path="consumption" element={<>Consumption</>} />
            */}
            <Route path="automations" element={<Dashboard />} />
            <Route path="eventlogs" element={<EventLogs />} />
          </Route>
          <Route
            path="customermanagement"
            element={<CustomerManagementWindow />}
          >
            <Route path="basicinfo" element={<CustomerBasicInfo />} />
            <Route path="secrets" element={<Secrets />} />
            <Route path="usersandroles" element={<UsersRolesWindow />} />
          </Route>
          <Route path="environments" element={<Environments />}>
            <Route path="" element={<Compute />} />
            <Route path="networks" element={<Networks />} />
            {/** This is commented out for now, as the view is not ready.
             * For this view, API endpoints need to be implemented first.
            <Route path="connections" element={<Connections />} />
            */}
          </Route>
          <Route path="tasks" element={<TaskWindow />}>
            <Route path="" element={<TaskSelector />} />
            <Route path="basicinfo" element={<TaskBasicInfo />} />
            <Route path="environments" element={<TaskEnvironments />} />
            <Route path="schedules" element={<ScheduledEvents />} />
            <Route path="stamps" element={<Stamps />} />
          </Route>
        </Route>
      </Route>
      <Route path="admin" element={<AdminWindow />}>
        <Route path="texts" element={<Texts />} />
        <Route path="collections" element={<Collections />} />
      </Route>
      {/** Admin routes 
      * This is commented out for now, as we don't have any of the views ready yet.
      * Uncomment views when they've been implemented and are ready for use.
      <Route path="admin" element={<AdminWindowPlaceholder />}>
        <Route path="roles" element={<>Role management</>} />
        <Route path="permissions" element={<>Permission management</>} />
      </Route>
      */}
    </Route>,
  ),
);
