import { createTheme } from "@mui/material";
import { baseTheme } from "@app/theme";

// Extends the base theme with header specific theming
export const headerTheme = createTheme(baseTheme, {
  typography: {
    h2: {
      color: "white",
    },
    h3: {
      fontSize: "18px",
      lineHeight: 1.5,
      fontWeight: 400,
      fontStyle: "normal",
      textTransform: "none",
    },
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "27.5px",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: "12px",
        },
      },
    },
    // Fix for a bug where a '--Paper-overlay' component is added
    // which makes the header background color act strange
    // https://github.com/mui/material-ui/issues/43683#issuecomment-2492787970
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
  },
});
