import { Controller } from "react-hook-form";
import {
  MenuItem,
  TextField,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

/**
 * Dropdown select for forms using react-hook-form.
 *
 * @param control: react-hook-form control object
 */

export type SelectItem = {
  id: string | number;
  value: string;
  label: string;
};

interface IProps {
  name: string;
  control: any;
  label: string;
  style?: any;
  disabled?: boolean;
  selectItems: SelectItem[];
  helpText?: string | React.ReactNode;
}

const FormDropdownSelect = (props: IProps) => {
  const { style, name, control, label, disabled, selectItems, helpText } =
    props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          fullWidth
          sx={{
            ...style,
            color: "secondary.light",
          }}
          select
          label={label}
          value={value || ""}
          onChange={onChange}
          disabled={disabled}
          helperText={error ? error.message : null}
          error={!!error}
          InputProps={{
            endAdornment: helpText && (
              <InputAdornment
                data-testid="help-text"
                position="end"
                sx={{ marginRight: 1 }}
              >
                <Tooltip arrow placement="top-start" title={helpText}>
                  <IconButton>
                    <HelpIcon sx={{ color: "#7eccff" }} />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        >
          {selectItems.find((t) => t.value === value || !value) ? (
            selectItems.map((item) => (
              <MenuItem key={item.id} value={item.value}>
                {item.label}
              </MenuItem>
            ))
          ) : (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          )}
        </TextField>
      )}
    />
  );
};

export default FormDropdownSelect;
