import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { toast } from "react-toastify";

import LoadingIndicatorBox from "@features/common/LoadingIndicatorBox/LoadingIndicatorBox";
import { useGetSecretQuery } from "@features/common/oivaSecretsApi";
import { getSelectedCustomerId } from "@features/customers/customerSlice";
import { useGetEventsQuery, useGetResourcesQuery } from "./dashboardApi";
import DataCharts from "./DataCharts";
import SelectorPane from "./SelectorPane";
import Timeline from "./Timeline";
import { ActiveTimelineItem } from "./types";

/**
 * Top component for the dashboard
 */

enum ViewType {
  Day = "day",
  Week = "week",
  Month = "month",
}

const Dashboard = () => {
  const [startDate, setStartDate] = useState<Date>(
    dayjs().startOf(ViewType.Month).toDate(),
  );
  const [endDate, setEndDate] = useState<Date>(
    dayjs(startDate).endOf(ViewType.Month).add(1, "day").toDate(),
  );
  const [selectedTimelineItem, setSelectedTimelineItem] =
    useState<ActiveTimelineItem>();
  // selector start and end date are used for the date range selection in the SelectorPane
  const [selectorStartDate, setSelectorStartDate] = useState<any>(
    dayjs().startOf(ViewType.Week).add(1, "day").toDate(),
  );
  const [selectorEndDate, setSelectorEndDate] = useState<any>(
    dayjs(selectorStartDate).endOf(ViewType.Week).add(1, "day").toDate(),
  );

  const customerId = useSelector(getSelectedCustomerId);

  const { data: licenseKey, isLoading: isLoadingLicenseKey } =
    useGetSecretQuery("Fullcalendar-licence-key");

  const {
    data: events = [],
    error: eventsError,
    isLoading: isLoadingEvents,
    isFetching,
    refetch: refetchEvents,
  } = useGetEventsQuery(
    {
      customerId: customerId,
      start_date: dayjs(startDate).format("YYYY-MM-DDTHH:mm:ss"),
      end_date: dayjs(endDate).format("YYYY-MM-DDTHH:mm:ss"),
    },
    // Refetches the data from API in 10 minutes if the component subscriptions stay active and a new request is made
    { refetchOnMountOrArgChange: 600 },
  );

  const {
    data: resources = [],
    error: resourcesError,
    isLoading: isLoadingResources,
    refetch: refetchResources,
  } = useGetResourcesQuery(
    {
      customerId: customerId,
      start_date: dayjs(startDate).format("YYYY-MM-DDTHH:mm:ss"),
      end_date: dayjs(endDate).format("YYYY-MM-DDTHH:mm:ss"),
    },
    // Refetches the data from API in 10 minutes if the component subscriptions stay active and a new request is made
    { refetchOnMountOrArgChange: 600 },
  );

  useEffect(() => {
    if (eventsError && resourcesError) {
      toast.error(
        "Failed to fetch data for timeline",
        // providing a toastId prevents duplicate toasts from being displayed,
        // in this case shows only one toast when both/either of the requests fail
        { toastId: "dashboard-fetch-failed" },
      );
    }
  }, [eventsError, resourcesError]);

  const refetchAll = () => {
    refetchEvents();
    refetchResources();
  };

  const changeTimelineDates = (start: Date, end: Date) => {
    // updates the start and end dates to trigger fetch for
    // (at least) the current month when changing timeline date
    handleDateRangeChange(start, end);
    changeSelectorDates(start, end);
  };

  const handleDateRangeChange = (start: Date, end: Date) => {
    if (
      dayjs(start).isBefore(dayjs(startDate)) ||
      dayjs(end).isAfter(dayjs(endDate))
    ) {
      // new date range is outside of the current date range -> event data needs to be updated
      const newStartDate = dayjs(start).startOf(ViewType.Month).toDate();
      const newEndDate = dayjs(end)
        .endOf(ViewType.Month)
        .add(1, "day")
        .toDate();
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
  };

  const changeSelectorDates = (start: Date, end: Date) => {
    setSelectorStartDate(dayjs(start).toDate());
    setSelectorEndDate(dayjs(end).toDate());
  };

  const handleSetSelectorEndDate = (date: Date) => {
    setSelectorEndDate(dayjs(date).toDate());
    if (dayjs(date).isBefore(selectorStartDate)) {
      // if the new end date is before the start date, update the start date to match
      setSelectorStartDate(dayjs(date).toDate());
    } else {
      handleDateRangeChange(selectorStartDate, dayjs(date).toDate());
    }
  };

  const handleSetSelectorStartDate = (date: Date) => {
    setSelectorStartDate(dayjs(date).toDate());
    handleDateRangeChange(dayjs(date).toDate(), selectorEndDate);
  };

  if (isLoadingEvents || isLoadingResources || isLoadingLicenseKey) {
    return <LoadingIndicatorBox />;
  }

  return (
    <>
      <Timeline
        resources={resources}
        events={events}
        changeDates={changeTimelineDates}
        isUpdatingEvents={isFetching}
        refetchItems={refetchAll}
        setSelectedItem={setSelectedTimelineItem}
        licenseKey={licenseKey}
      />
      {selectedTimelineItem && (
        <>
          <SelectorPane
            key={selectedTimelineItem.id}
            selectedItem={selectedTimelineItem}
            resources={resources}
            startDate={selectorStartDate}
            endDate={selectorEndDate}
            setStartDate={handleSetSelectorStartDate}
            setEndDate={handleSetSelectorEndDate}
          />
          <DataCharts
            selectedItem={selectedTimelineItem}
            startDate={selectorStartDate}
            endDate={selectorEndDate}
            events={events}
          />
        </>
      )}
    </>
  );
};

export default Dashboard;
