import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Checkbox,
} from "@mui/material";
import { Order } from "@features/common/Table/SortTableHead/tableSortFunctions";
import { useTheme } from "@mui/material/styles";
import React from "react";

interface ISortTableHeadProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: Order;
  orderBy: string;
  properties: any[];
  checkedAmount: number; // number of checked items, needed for rendering select all checkbox
  scheduleAmount: number; // number of total items, needed for rendering select all checkbox
  handleSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * Implements schedule table head rows with sort.
 * More info on MUI table sorting: https://mui.com/material-ui/react-table/#sorting-amp-selecting
 * @param props
 * @returns
 */

const SchedulerTableHeader = (props: ISortTableHeadProps) => {
  const {
    order,
    orderBy,
    onRequestSort,
    properties,
    handleSelectAll,
    checkedAmount,
    scheduleAmount,
  } = props;
  const theme = useTheme();

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const isChecked = checkedAmount === scheduleAmount && scheduleAmount > 0;

  return (
    <TableHead>
      <TableRow>
        <TableCell
          key={"checkbox-cells"}
          sx={{ backgroundColor: "primary.light" }}
        >
          {scheduleAmount > 0 ? (
            <Checkbox
              style={{
                color: isChecked
                  ? theme.palette.primary.main
                  : theme.palette.primary.dark,
              }}
              checked={isChecked}
              indeterminate={
                checkedAmount > 0 && checkedAmount < scheduleAmount
              }
              onChange={(e) => handleSelectAll(e)}
            />
          ) : (
            <> </>
          )}
        </TableCell>
        {properties.map((property: any) => (
          <TableCell
            key={property.key}
            sortDirection={orderBy === property.key ? order : false}
            sx={{ backgroundColor: "primary.light" }}
          >
            <TableSortLabel
              active={orderBy === property.key}
              direction={orderBy === property.key ? order : "asc"}
              onClick={createSortHandler(property.key)}
            >
              <Typography>{property.label}</Typography>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default SchedulerTableHeader;
