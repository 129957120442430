import { useTranslation } from "react-i18next";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography/Typography";

import { formatDateToFinnishStyle } from "./errorsChartUtils";

const RunErrorsByDayChartTooltip = (data: any) => {
  const { t } = useTranslation();
  if (data.payload.length === 0) {
    return null;
  }
  const payload = data.payload[0];
  const fontStyles = { color: payload.stroke, fontSize: "2vh" };
  const cardStyles = { opacity: "0.85" };
  const cardContentStyles = {
    display: "flex",
    alignContent: "center",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: "2vh",
    height: "13vh",
  };
  return (
    <Card variant="elevation" elevation={10} style={cardStyles}>
      <CardContent sx={cardContentStyles}>
        <Typography variant="h1" component="h2" style={fontStyles}>
          {t("label-date")}: {formatDateToFinnishStyle(data.label)}
        </Typography>
        <Typography variant="h1" component="h2" style={fontStyles}>
          {payload.name}: {payload.value}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default RunErrorsByDayChartTooltip;
