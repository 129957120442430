import { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
} from "@mui/material";

import { RootState } from "@app/store";
import { useAppDispatch } from "@features/common/StoreHook";
import { getSelectedCustomerId } from "@features/customers/customerSlice";
import { formControlStyle, formLabelStyle } from "@features/common/styles";
import SortTable, { IProperty } from "@features/common/Table/SortTable";
import AddDeleteButtonGroup from "@features/common/ButtonGroups/AddDeleteButtonGroup";
import { ConfirmActionDialog } from "@features/common/ConfirmAction";
import ExportExcel from "@features/common/ExportExcel";
import { ITask } from "../types";
import {
  deleteTask,
  getAllTasks,
  getSelectedTask,
  getSelectedTaskId,
  selectTask,
  selectTasksStatus,
} from "../tasksSlice";

import { permissionsApi } from "@features/authorization/authService";
import { checkPermissions } from "@features/authorization/types";
import AddTask from "./AddTask";

const TaskSelector = () => {
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
  const [activeRadioButton, setActiveRadioButton] = useState<string>("");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const customerId = useSelector(getSelectedCustomerId);
  const { data, isLoading: permissionsAreLoading } =
    permissionsApi.useGetCustomerPermissionsQuery(customerId);
  const taskPermissions = checkPermissions("tasks", data);
  const tasks = useSelector(
    (state: RootState) => getAllTasks(state, customerId),
    shallowEqual,
  );
  const selectedTask = useSelector((state: RootState) =>
    getSelectedTask(state),
  );
  const selectedTaskId = useSelector((state: RootState) =>
    getSelectedTaskId(state),
  );
  const isLoading = useSelector(selectTasksStatus) === "loading";

  const setSelectedTaskId = (id: string) => dispatch(selectTask(id));

  const handleSelectItem: (id: number | string) => void = (id) => {
    setSelectedTaskId(String(id));
    setActiveRadioButton(String(id));
  };

  useEffect(() => {
    setActiveRadioButton(selectedTaskId);
  }, [selectedTaskId]);

  const handleClickOpenAdd = () => {
    taskPermissions.create
      ? setAddDialogOpen(true)
      : toast.error("No create permission");
  };

  const onDelete = () => {
    taskPermissions.delete
      ? setConfirmDialogOpen(true)
      : toast.error("No delete permission");
  };

  const handleDelete = () => {
    if (selectedTask) {
      dispatch(deleteTask(selectedTask))
        .unwrap()
        .then(() => {
          toast.success("Task deleted successfully");
        })
        .catch((e) => {
          toast.error("Failed to delete task");
        });
      setConfirmDialogOpen(false);
    }
  };

  const properties: IProperty<ITask>[] = [
    { key: "task_name", label: t("label-task-name") },
    { key: "task_description", label: t("label-task-description") },
    { key: "task_type", label: t("label-type") },
    // status is not working atm so it's commented out for now for simplicity
    //{ key: 'status', label: t('label-status') },
  ];

  return (
    <>
      <FormControl component="fieldset" sx={formControlStyle}>
        <FormLabel component="legend" sx={formLabelStyle}>
          Tasks
        </FormLabel>
        <Grid
          container
          item
          spacing={0}
          direction="row"
          justifyContent="flex-end"
          style={{ paddingBottom: "1rem" }}
        >
          {!isLoading && (
            <ExportExcel data={tasks} fileName="tasks" coloredButton={true} />
          )}
        </Grid>
        <SortTable
          properties={properties}
          idProperty={"task_guid"}
          handleChange={handleSelectItem}
          isLoading={isLoading}
          selectedId={activeRadioButton}
          data={tasks}
          pagination={true}
        />
        <AddDeleteButtonGroup
          disabled={permissionsAreLoading || addDialogOpen || confirmDialogOpen}
          handleAdd={handleClickOpenAdd}
          handleDelete={selectedTask && onDelete}
        />
      </FormControl>
      <Dialog open={addDialogOpen} fullWidth={true} maxWidth={"sm"}>
        <DialogTitle>{t("label-add-new-task")}</DialogTitle>
        <AddTask
          customerId={customerId}
          onCancel={() => setAddDialogOpen(false)}
        />
      </Dialog>
      <ConfirmActionDialog
        show={confirmDialogOpen}
        action={"DELETE"}
        resource={selectedTask?.task_name}
        onConfirm={handleDelete}
        onCancel={() => setConfirmDialogOpen(false)}
      />
    </>
  );
};

export default TaskSelector;
