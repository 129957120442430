import { AxiosResponse } from "axios";
import {
  apiGET,
  apiPOST,
  apiPUT,
  apiDELETE,
} from "@features/common/baseApiService";
import { ISchedule, IScheduleCreate, IScheduleUpdate } from "../types";

// TODO replace any with SCHEDULE correct type
export async function getTaskSchedulesRequest(
  customerId: string,
  taskId: string,
): Promise<AxiosResponse<ISchedule[]>> {
  const resource = `customers/${customerId}/tasks/${taskId}/schedules`;
  const response = await apiGET<ISchedule[]>(resource);
  return response;
}

// TODO replace the copypasted task requests with appropriate schedule requests

export async function postScheduleRequest(
  customerId: string,
  taskId: string,
  payload: IScheduleCreate,
) {
  const resource = `customers/${customerId}/tasks/${taskId}/schedules`;
  const response = await apiPOST<IScheduleCreate, ISchedule>(resource, payload);
  return response;
}

export async function updateScheduleRequest(
  customerId: string,
  taskId: string,
  scheduleId: string,
  payload: IScheduleUpdate,
): Promise<AxiosResponse<ISchedule>> {
  const resource = `customers/${customerId}/tasks/${taskId}/schedules/${scheduleId}`;
  const response = await apiPUT<IScheduleUpdate, ISchedule>(resource, payload);
  return response;
}

export async function deleteScheduleRequest(
  customerId: string,
  taskId: string,
  scheudleId: string,
): Promise<AxiosResponse<any>> {
  const resource = `customers/${customerId}/tasks/${taskId}/schedules/${scheudleId}`;
  const response = await apiDELETE<ISchedule>(resource);
  return response;
}
