import { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import { FormControl, FormLabel, Grid } from "@mui/material";

import { RootState } from "@app/store";
import { formControlStyle, formLabelStyle } from "@features/common/styles";
import { useAppDispatch } from "@features/common/StoreHook";
import { getSelectedCustomerId } from "@features/customers/customerSlice";
import SortTable, { IProperty } from "@features/common/Table/SortTable";
import AddDeleteButtonGroup from "@features/common/ButtonGroups/AddDeleteButtonGroup";
import ExportExcel from "@features/common/ExportExcel";
import { IRole, IUser } from "../types";
import { fetchRoles, getAllRoles, selectRolesStatus } from "./rolesSlice";
import {
  fetchUsers,
  getAllUsers,
  getSelectedUser,
  selectUser,
  selectUsersStatus,
} from "./userSlice";
import { AddUserDialog } from "./AddUserDialog";
import { permissionsApi } from "@features/authorization/authService";
import { checkPermissions } from "@features/authorization/types";
import { toast } from "react-toastify";

/**
 *
 * Main Table element of the customer management view
 * Lists Users that have roles assigned to them
 * Does not show "internal" users that have no assigned roles.
 * If a user is on this list and has all roles removed, do they stay here or are they moved back to "internal users"?
 * Cannot remember. But depends also on backend logic.
 *
 */

export const UserSelector = () => {
  const { t } = useTranslation();
  const customerId = useSelector(getSelectedCustomerId);
  const dispatch = useAppDispatch();
  const isLoadingUser: boolean = useSelector(selectUsersStatus) === "loading";
  const isLoadingRoles: boolean = useSelector(selectRolesStatus) === "loading";
  const selectedUser: IUser | undefined = useSelector(getSelectedUser);

  const users: IUser[] = useSelector(
    (state: RootState) => getAllUsers(state),
    shallowEqual,
  );

  const roles: IRole[] = useSelector(
    (state: RootState) => getAllRoles(state),
    shallowEqual,
  );

  const { data } = permissionsApi.useGetCustomerPermissionsQuery(customerId);
  const userPermissions = checkPermissions("users", data);
  const rolePermissions = checkPermissions("roles", data);
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);

  // this is is the list of users thats actually rendered to table
  // from db user roles are integers, a function below maps them to correct role names
  const [roleModdedUsers, setRoleModdedUsers] = useState<any[]>([]);

  useEffect(() => {
    dispatch(selectUser("")); // when site is reloaded, de-select user
    dispatch(fetchUsers(customerId));
    dispatch(fetchRoles(customerId));
  }, []);

  const extractTextRoles = (rolesArray: string[]) => {
    let result: string[] = [];
    rolesArray.forEach((roleNum: string) => {
      if (roles) {
        roles.forEach((roleObj) => {
          //@ts-ignore
          if (roleNum == roleObj.role_id) {
            // the comparison between types is intentional, hence @ts-ignore
            result.push(roleObj.role_name);
          }
        });
      }
    });
    return result;
  };

  // user.roles = array of numbers, which map to role strings. This function converts the mapping to the UI.
  useEffect(() => {
    const moddedUsers = users.map((user) => {
      const newRoles = extractTextRoles(user.roles);
      const newUser: IUser = {
        // can IUser be used here?
        ...user,
        roles: newRoles,
      };
      return newUser;
    });
    setRoleModdedUsers(moddedUsers);
  }, [users, roles]);

  const handleRadioButtonChange = (id: string) => {
    dispatch(selectUser(id));
  };

  const tableHeadProperties: IProperty<IUser>[] = [
    { key: "name", label: t("label-user-name") },
    { key: "email", label: t("label-email") },
    { key: "roles", label: t("label-rolegroups") },
    { key: "type", label: t("label-type") },
  ];

  useEffect(() => {
    dispatch(selectUser("")); // when site is reloaded, de-select user
    dispatch(fetchUsers(customerId));
    dispatch(fetchRoles(customerId));
  }, []);

  const roleUsersData = roleModdedUsers.map((user) => ({
    ...user,
    roles: user.roles.join(", "),
  }));

  const handleAdd = () => {
    userPermissions.create
      ? setAddDialogOpen(true)
      : toast.error("No create permission");
  };

  return (
    <>
      <FormControl component="fieldset" sx={formControlStyle}>
        <FormLabel component="legend" sx={formLabelStyle}>
          {t("label-users")}
        </FormLabel>
        <Grid
          container
          justifyContent="flex-end"
          style={{ paddingBottom: "1rem" }}
        >
          <ExportExcel
            data={roleUsersData}
            fileName="users"
            coloredButton={true}
          />
        </Grid>
        <SortTable
          properties={tableHeadProperties}
          idProperty={"id"}
          data={roleUsersData}
          isLoading={isLoadingUser || isLoadingRoles}
          selectedId={selectedUser?.id}
          handleChange={handleRadioButtonChange}
          pagination={true}
        />
        <AddDeleteButtonGroup
          disabled={false}
          handleAdd={handleAdd}
          //handleDelete={() => {}}
        />

        <AddUserDialog
          onCancel={() => setAddDialogOpen(false)}
          open={addDialogOpen}
        />
      </FormControl>
    </>
  );
};
