import { AxiosResponse } from "axios";
import { apiGET } from "@features/common/baseApiService";
import { Connection } from "./types";

export async function getAllConnectionsRequest(
  customerId: string,
): Promise<AxiosResponse<Connection[]>> {
  const resource = `customers/${customerId}/environments/connections`;
  const response = await apiGET<Connection[]>(resource);
  return response;
}
