import { Controller } from "react-hook-form";
import { FormControlLabel, Switch } from "@mui/material";

/**
 * Switch field for forms using react-hook-form.
 *
 * @param control: react-hook-form control object
 */

interface IProps {
  name: string;
  control: any;
  label: string;
  style?: any;
  disabled?: boolean;
}

const FormSwitch = (props: IProps) => {
  const { style, name, control, label, disabled } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <FormControlLabel
          label={label}
          control={
            <Switch
              name={name}
              sx={{
                ...style,
                color: "secondary.light",
              }}
              checked={value ?? false}
              disabled={disabled || false}
              onChange={onChange}
            />
          }
        />
      )}
    />
  );
};

export default FormSwitch;
