import React from "react";
import { Link, useMatch } from "react-router-dom";
import { ListItemText, ListItemButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { drawerNavigationLinkStyles } from "./style";
import { useTheme } from "@mui/material/styles";

interface INavigationDrawerLinkProps {
  to: string;
  label: string;
  hasPermission: boolean; // has user permission to this link/route
  closeDrawer: () => void; // click handler (pass function to close drawer on click)
}

/**
 * A link component for navigation drawer (NavDrawer).
 * @param props
 * @returns
 */
const NavigationDrawerLink = (props: INavigationDrawerLinkProps) => {
  const { to, label, hasPermission, closeDrawer } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  /**
   * Hook useMatch checks if path matches the current route.
   * If parameter end is true, returns true only if the current route ends with the specified path;
   * this is used in this case to specify extra rule to set ListItemButton as selected for home path "/"
   */
  const match = useMatch({ path: to, end: to === "/" ? true : false });

  return hasPermission ? (
    <Link to={to} style={{ textDecoration: "none" }} onClick={closeDrawer}>
      <ListItemButton sx={drawerNavigationLinkStyles} selected={!!match}>
        <ListItemText sx={{ color: theme.palette.primary.contrastText }}>
          <Typography variant="h3">{t(label)}</Typography>
        </ListItemText>
      </ListItemButton>
    </Link>
  ) : null;
};

export default NavigationDrawerLink;
