import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { RootState } from "@app/store";
import { getAllCloudSubscriptionsRequest } from "./subscriptionApiService";
import { CloudSubscription } from "../types";

const subscriptionAdapter = createEntityAdapter<CloudSubscription>({
  selectId: (cloudSubscription) => cloudSubscription.tenant_id,
});

const initialState = subscriptionAdapter.getInitialState({
  status: "",
});

const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchSubscriptions.fulfilled,
        (state, { payload }: PayloadAction<CloudSubscription[]>) => {
          subscriptionAdapter.setAll(state, payload);
          state.status = "";
        },
      )
      .addCase(fetchSubscriptions.rejected, (state) => {
        state.status = "";
      });
  },
});

export default subscriptionsSlice.reducer;

export const fetchSubscriptions = createAsyncThunk(
  "subscriptions/fetchSubscriptions",
  async () => {
    try {
      const res = await getAllCloudSubscriptionsRequest();
      return res.data;
    } catch (err) {
      throw new Error("Failed to fetch subscriptions");
    }
  },
);

const subscriptionSelectors = subscriptionAdapter.getSelectors(
  (state: RootState) => state.subscriptions,
);

/* [{ tenant_id: <id>, subscriptions: [ <id>, <id>, <id>,... ] }, ...] */
export const selectAllSubscriptions = (state: RootState) =>
  subscriptionSelectors.selectAll(state);

export const selectSubscriptionsStatus = (state: RootState) =>
  state.subscriptions.status;
