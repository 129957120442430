import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@mui/material";
import { useAppDispatch } from "@features/common/StoreHook";
import LabelButton from "@features/common/LabelButton/LabelButton";
import {
  fetchCustomers,
  getSelectedCustomerId,
  selectCustomer,
  getCustomers,
  selectCustomersStatus,
} from "../customerSlice";
import { ICustomer } from "../types";
import AddCustomerDialog from "../addCustomerDialog/AddCustomerDialog";
import SearchField from "./SearchField";
import CustomerList from "./CustomerList";
import { titleStyles, buttonGridStyles } from "./style";
import { permissionsApi } from "@features/authorization/authService";
import { checkPermissions } from "@features/authorization/types";

const compare = (a: ICustomer, b: ICustomer) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

interface IComponentProps {
  initialOpen: boolean;
}

const CustomersDialog = (props: IComponentProps) => {
  // Variables
  const { initialOpen } = props;

  // Hooks
  const { data: permissionDataOiva } =
    permissionsApi.useGetOivaPermissionsQuery();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [openCreateCustomer, setOpenCreateCustomer] = useState<boolean>(false);

  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer | null>(
    null,
  );
  const [searchParameters, setSearchParameters] = useState<string>("");
  const [currentlyDisplayedCustomers, setCurrentlyDisplayedCustomers] =
    useState<ICustomer[]>();

  const customersData = useSelector(getCustomers);
  const customersStatus = useSelector(selectCustomersStatus);
  const selectedCustomerId = useSelector(getSelectedCustomerId);
  const match = useMatch("/");
  const theme = useTheme();

  // Redux
  const setCustomer: (customer: ICustomer) => void = (customer) => {
    dispatch(selectCustomer(customer.id));
  };

  const customers = [...(customersData ? customersData : [])].sort(compare);

  useEffect(() => {
    dispatch(fetchCustomers());
  }, []);

  useEffect(() => {
    setCurrentlyDisplayedCustomers(customers);
  }, [customersData]);

  useEffect(() => {
    // Set dialog to open when
    // route matches root route / or there is no customer selected
    if (initialOpen && match && !selectedCustomerId) {
      setOpen(true);
    }
  }, [match]);

  useEffect(() => {
    if (searchParameters) {
      const filtered = customers?.filter((customer: ICustomer) => {
        const nameLower = customer.name.toLowerCase();
        const searchParamsLower = searchParameters.toLowerCase();
        return nameLower.includes(searchParamsLower);
      });
      setCurrentlyDisplayedCustomers(filtered);
    } else {
      setCurrentlyDisplayedCustomers(customers);
    }
  }, [searchParameters]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOpenClose = () => {
    setOpen(!open);
  };

  const handleClose = (customer: ICustomer | null) => {
    if (customer) {
      setCustomer(customer);
    }
    setOpen(false);
  };

  const handleSelect = (customer: ICustomer) => {
    if (customer !== selectedCustomer) {
      setSelectedCustomer(customer);
    } else {
      setSelectedCustomer(null);
    }
  };

  const handleShowCreateCustomerDialog = (show: boolean) => {
    setOpenCreateCustomer(show);
    setOpen(!show);
  };

  const adminPermissions = checkPermissions(
    "administration",
    permissionDataOiva,
  );

  return (
    <>
      <Tooltip title={t("label-switch-customers")}>
        <IconButton onClick={handleOpenClose}>
          <SwapHorizontalCircleIcon style={{ color: "white" }} />
        </IconButton>
      </Tooltip>
      <Dialog open={open}>
        <DialogTitle sx={titleStyles}>{t("label-select-customer")}</DialogTitle>
        <DialogContent>
          <Divider />
          <CustomerList
            currentlyDisplayedCustomers={currentlyDisplayedCustomers}
            isLoading={customersStatus === "loading"}
            selectedCustomer={selectedCustomer}
            handleSelectCustomer={handleSelect}
          />
          <SearchField
            searchParameters={searchParameters}
            setSearchParameters={setSearchParameters}
          />
          <Divider />
          <Grid
            sx={{ display: "flex", justifyContent: "center", marginTop: 1 }}
          >
            {adminPermissions.read ? (
              <Button
                variant="text"
                startIcon={<AddIcon />}
                sx={{ color: "primary.dark" }}
                onClick={() => handleShowCreateCustomerDialog(true)}
              >
                {t("label-create-customer")}
              </Button>
            ) : (
              <></>
            )}
          </Grid>
        </DialogContent>
        <Grid sx={buttonGridStyles}>
          <LabelButton
            variant="outlined"
            handleClick={() => handleClose(selectedCustomer)}
            label={t("Cancel")}
          />
          <LabelButton
            disabled={!selectedCustomer}
            handleClick={() => handleClose(selectedCustomer)}
            label="OK"
          />
        </Grid>
      </Dialog>
      <AddCustomerDialog
        open={openCreateCustomer}
        handleClose={() => handleShowCreateCustomerDialog(false)}
      />
    </>
  );
};

export default CustomersDialog;
