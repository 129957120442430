import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface IVMApplicationDetails {
  name: string;
  description: string;
  version?: string;
  versions: string[];
  status: string;
}

interface Props {
  vmApplication?: IVMApplicationDetails;
  open: boolean;
  onClose: () => void;
}

const VMApplicationDetails = (props: Props) => {
  const { vmApplication, open, onClose } = props;
  const { t } = useTranslation();

  if (!vmApplication) {
    return <></>;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{vmApplication?.name}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant="head">{t("label-description")}</TableCell>
              <TableCell>{vmApplication?.description}</TableCell>
            </TableRow>
            {vmApplication?.version ? (
              <TableRow>
                <TableCell variant="head">{t("label-version")}</TableCell>
                <TableCell>{vmApplication?.version}</TableCell>
              </TableRow>
            ) : (
              <></>
            )}
            <TableRow>
              <TableCell variant="head">
                {t("label-available-versions")}
              </TableCell>
              <TableCell>
                {vmApplication?.versions.map((v: string, index: number) => (
                  <span key={v} style={{ display: "block" }}>
                    {v}{" "}
                    {index === vmApplication?.versions.length - 1 && "(latest)"}
                  </span>
                ))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Status</TableCell>
              <TableCell>{vmApplication?.status}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

export default VMApplicationDetails;
