import {
  apiGET,
  apiPUT,
  apiPOST,
  apiDELETE,
} from "@features/common/baseApiService";
import { Translation } from "./types";

// TODO: Resource URL should be updated with the correct path when API is ready

export async function getTranslationsRequest() {
  const resource = `translations/texts`;
  const response = await apiGET<Translation[]>(resource);
  return response;
}

export async function updateTranslationRequest(payload: Translation) {
  const resource = `translations/texts`;
  const response = await apiPUT<Translation, any>(resource, payload);
  return response;
}

export async function createTranslationRequest(payload: Translation) {
  const resource = `translations/texts`;
  const response = await apiPOST<Translation, Translation>(resource, payload);
  return response;
}

export async function deleteTranslationRequest(id: string) {
  const resource = `translations/texts/${id}`;
  const response = await apiDELETE<any>(resource);
  return response;
}
