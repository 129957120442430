import { useEffect, useState } from "react";
import RuntimesChart from "./RuntimesChart";
import { calculateRuntimeData } from "./runtimesChartUtils";
import ChartWrapper from "../ChartWrapper";
import RuntimesTable from "./RuntimesTable";
import { ActiveTimelineItem, TimelineEvent } from "../../types";
import { RuntimeData } from "./runtimesChartUtils";

/**
 * Container for the runtimes chart
 */

interface RuntimesChartContainerProps {
  selectedItem: ActiveTimelineItem;
  startDate: Date;
  endDate: Date;
  events: TimelineEvent[];
  title: string;
}

const RuntimesChartContainer = (props: RuntimesChartContainerProps) => {
  const { selectedItem, startDate, endDate, events, title } = props;
  const [runtimeData, setRuntimeData] = useState<RuntimeData[]>([]);

  useEffect(() => {
    const data = calculateRuntimeData(events, selectedItem, startDate, endDate);
    setRuntimeData(data);
  }, [selectedItem, startDate, endDate, events]);

  return (
    <>
      <ChartWrapper
        title={title}
        chart={<RuntimesChart runtimeData={runtimeData} />}
        table={<RuntimesTable data={runtimeData || []} />}
        isTableAvailable={runtimeData.length > 0}
        excelData={runtimeData}
      />
    </>
  );
};

export default RuntimesChartContainer;
