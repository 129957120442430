import { useTranslation } from "react-i18next";
import { FormControl, FormLabel } from "@mui/material";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import AddDuplicateDeleteButtonGroup from "../../common/ButtonGroups/AddDuplicateDeleteButtonGroup";
import { useSelector } from "react-redux";
import { fetchVMs, getAllVMs, selectVMStatus } from "../environmentsSlice";
import { useState, useEffect } from "react";
import { useAppDispatch } from "@features/common/StoreHook";
import { IVirtualMachine } from "../types";
import { formControlStyle, formLabelStyle } from "../style";
import { getSelectedCustomerId } from "@features/customers/customerSlice";
import ExportExcel from "@features/common/ExportExcel";
import ResourceContainer from "./ResourceContainer";
import AddCompute from "./AddCompute";
import SortTable, { IProperty } from "@features/common/Table/SortTable";

/**
 * The view that shows customer's VMs.
 *
 * TODO:
 *  - Many of the buttons are placeholders at the moment and have been disabled until they can actually be used.
 *  - Button for exporting excel file has only a placeholder icon for now
 * @returns
 */

const Compute = () => {
  const [checked, setChecked] = useState<string | undefined>();
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const customerId = useSelector(getSelectedCustomerId);
  const vmResources = useSelector(getAllVMs);
  const isLoading = useSelector(selectVMStatus);

  useEffect(() => {
    if (customerId) {
      dispatch(fetchVMs(customerId));
    }
  }, [customerId]);

  const properties: IProperty<IVirtualMachine>[] = [
    { key: "name", label: t("label-machine-name") },
    { key: "type", label: t("label-type") },
    { key: "category", label: t("label-category") },
    { key: "level", label: t("label-level") },
    { key: "status", label: t("label-status") },
    { key: "enable_shutdown", label: t("label-shutdown-enabled") },
  ];

  const handleChange = (id: string) => {
    setChecked(id);
  };

  const getSelectedVM = () =>
    vmResources.find((v: IVirtualMachine) => v.name === checked);

  return (
    <Box>
      <FormControl component="fieldset" sx={formControlStyle}>
        <FormLabel component="legend" sx={formLabelStyle}>
          Virtual Machines/Servers/Containers
        </FormLabel>
        {!isLoading && (
          <Box sx={{ textAlign: "right" }}>
            <ExportExcel
              data={vmResources}
              fileName="virtual_machines"
              coloredButton={true}
            />
          </Box>
        )}
        <SortTable
          properties={properties}
          idProperty={"name"}
          handleChange={handleChange}
          isLoading={isLoading === "loading"}
          selectedId={checked}
          data={vmResources}
          key={vmResources.length}
        />
        <AddDuplicateDeleteButtonGroup
          disabled={true}
          handleAdd={() => setShowAddDialog(true)}
        />
      </FormControl>
      <AddCompute
        show={showAddDialog}
        onCancel={() => setShowAddDialog(false)}
      />
      <ResourceContainer key={checked} resource={getSelectedVM()} />
    </Box>
  );
};

export default Compute;
