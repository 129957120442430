import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "@app/store";
import { getAllConnectionsRequest } from "./connectionsApiService";
import { Connection } from "./types";

const connectionsAdapter = createEntityAdapter<Connection>({
  selectId: (item) => item.name,
});

const initialState = connectionsAdapter.getInitialState({
  status: "",
});

const connectionsSlice = createSlice({
  name: "connections",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConnections.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchConnections.fulfilled,
        (state, { payload }: PayloadAction<Connection[]>) => {
          connectionsAdapter.setAll(state, payload);
          state.status = "";
        },
      )
      .addCase(fetchConnections.rejected, (state) => {
        state.status = "";
      });
  },
});

export const fetchConnections = createAsyncThunk(
  "connections/fetchConnections",
  async (customerId: string) => {
    const res = await getAllConnectionsRequest(customerId);
    return res.data;
  },
);

export const {} = connectionsSlice.actions;
export default connectionsSlice.reducer;

const connectionSelectors = connectionsAdapter.getSelectors(
  (state: RootState) => state.connections,
);

export const getAllConnections = (state: RootState) =>
  connectionSelectors.selectAll(state);
export const selectConnectionsStatus = (state: RootState) =>
  state.connections.status;
export const selectConnectionById = (
  state: RootState,
  connectionId: string | undefined,
) => {
  return connectionId
    ? connectionSelectors.selectById(state, connectionId)
    : undefined;
};
