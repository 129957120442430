import { useTranslation } from "react-i18next";
import ChartSetSelect from "../ChartSetSelect";
import Box from "@mui/material/Box";

const StampChartSelectsWrapper = (props: any) => {
  const {
    selectedStamp,
    selectedViewOption,
    selectedStampType,
    handleSelectView,
    handleSelectStamp,
    handleSelectStampType,
    selectableDoubleStamps,
  } = props;
  const { t } = useTranslation();

  const dataSetOptions = selectedStamp
    ? [
        { label: "label-total-count", value: "count" },
        { label: "label-sum", value: "sum" },
        { label: "label-status", value: "status" },
        { label: "label-runtimes", value: "runtimes" },
      ]
    : [{ label: "label-total-count", value: "count" }];

  const stampTypeOptions = [
    { label: "label-stamp-all", value: "all" },
    { label: "label-stamp-double", value: "double" },
    { label: "label-stamp-single", value: "single" },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: "10px",
        gap: 2,
      }}
    >
      <ChartSetSelect
        style={{ width: "60%" }}
        selectItems={dataSetOptions.map(
          (option: { label: string; value: string }) => ({
            label: t(option.label),
            value: option.value,
          }),
        )}
        selectedItem={selectedViewOption}
        handleSelectItem={handleSelectView}
        textFieldLabel={t("label-view")}
      />
      {selectedViewOption === "count" ? (
        <ChartSetSelect
          style={{ width: "100%" }}
          selectItems={stampTypeOptions.map(
            (option: { label: string; value: string }) => ({
              label: t(option.label),
              value: option.value,
            }),
          )}
          selectedItem={selectedStampType}
          handleSelectItem={handleSelectStampType}
          textFieldLabel={t("label-stamp-type-picker")}
        />
      ) : (
        <ChartSetSelect
          style={{ width: "100%" }}
          selectItems={selectableDoubleStamps}
          selectedItem={selectedStamp}
          handleSelectItem={handleSelectStamp}
          textFieldLabel={t("label-stamp-type")}
        />
      )}
    </Box>
  );
};

export default StampChartSelectsWrapper;
