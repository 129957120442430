import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  FormControl,
} from "@mui/material";
import { dialogFormStyle } from "../style";
import SaveCancelButtonGroup from "@features/common/ButtonGroups/SaveCancelButtonGroup";
import SelectMenu from "../SelectMenu";
import { ISubnet } from "../types";
import { useCollection } from "@features/common/collectionHook";

interface IProps {
  show: boolean;
  onCancel: () => void;
}

/**
 * A dialog form for adding a new Subnet.
 *
 * TODO:
 *  - Save button is just a placeholder at the moment
 *  - subnetTypes mock should be replaced with the collection
 * @param props
 * @returns
 */

const AddSubnet = (props: IProps) => {
  const { show, onCancel } = props;
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [subnet, setSubnet] = useState<ISubnet>({
    subnet_name: "",
    subnet_type: "",
    description: "",
    subnet_address_space: "",
  });

  const subnetTypes = useCollection(5);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (subnet) {
      setSubnet({ ...subnet, [name]: value });
    }
  };

  const handleCancel = () => {
    // set fields back to default when closing the form
    setSubnet({
      subnet_name: "",
      subnet_type: "",
      description: "",
      subnet_address_space: "",
    });
    onCancel();
  };

  return (
    <>
      <Dialog fullWidth={true} maxWidth="lg" open={show} onClose={onCancel}>
        <DialogTitle>{t("label-add-new-subnet")}</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset" sx={dialogFormStyle}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={subnet?.subnet_name}
                  name="name"
                  label={t("label-subnet-name")}
                  disabled={isSubmitting}
                  onChange={handleChange}
                />
                <TextField
                  value={subnet?.subnet_address_space}
                  name="address_space"
                  label={t("label-address-space")}
                  disabled={isSubmitting}
                  onChange={handleChange}
                />
                <SelectMenu
                  items={subnetTypes}
                  value={subnet?.subnet_type || ""}
                  name="type"
                  label={t("label-subnet-type")}
                  readOnly={isSubmitting}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={subnet?.description}
                  name="description"
                  label={t("subnet-description")}
                  disabled={isSubmitting}
                  onChange={handleChange}
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>
          </FormControl>
          <SaveCancelButtonGroup
            disabled={isSubmitting}
            handleCancel={handleCancel}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddSubnet;
