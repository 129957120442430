import TabView from "@features/common/TabView";
import { ITabItem } from "@features/common/TabView/TabView";

export const AdminWindow = () => {
  const tabs: ITabItem[] = [
    {
      label: "menu-sub-text-management",
      path: "texts",
      permissions: "administration",
      disabled: false,
    },
    {
      label: "menu-sub-collection-management",
      path: "collections",
      permissions: "administration",
      disabled: false,
    },
    /**
      * This is commented out for now, as the views are not ready.
      * Uncomment views when they've been implemented and are ready for use.
      * 
     {
       label: "Role management",
       path: "roles",
       permissions: [],
     },
     {
       label: "Permission management",
       path: "permissions",
       permissions: [],
     },
      */
  ];

  return (
    <>
      <TabView tabs={tabs} primaryPermission="administration" />
    </>
  );
};
