import TabView from "@features/common/TabView/TabView";
import { ITabItem } from "@features/common/TabView/TabView";

export const Environments = () => {
  const tabs: ITabItem[] = [
    {
      label: "menu-sub-environments",
      path: "",
      permissions: "",
    },
    {
      label: "menu-sub-networks",
      path: "networks",
      permissions: "",
    },
    /**
     * This is commented out for now, as the views are not ready.
     * Uncomment views when they've been implemented and are ready for use.
    {
      label: "menu-sub-connections",
      path: "connections",
      permissions: [],
    },
     */
  ];

  return (
    <>
      <TabView tabs={tabs} primaryPermission="tasks" />
    </>
  );
};

export default Environments;
