import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "@app/store";
import { IRole } from "../types";
import { getAllRolesRequest } from "./rolesApiService";

const rolesAdapter = createEntityAdapter<IRole>({
  selectId: (role) => role.role_id,
});

const initialState = rolesAdapter.getInitialState({
  selectedRole: "",
  status: "",
});

export const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    selectRole(state, action: PayloadAction<string>) {
      state.selectedRole = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchRoles.fulfilled,
        (state, { payload }: PayloadAction<IRole[]>) => {
          rolesAdapter.setAll(state, payload);
          state.status = "";
        },
      )
      .addCase(fetchRoles.rejected, (state) => {
        state.status = "";
      });
  },
});

export const fetchRoles = createAsyncThunk(
  "roles/fetchRoles",
  async (customerId: string) => {
    try {
      const res = await getAllRolesRequest(customerId);
      if (res) return res.data;
      else return res;
    } catch (err) {
      console.error(err);
      throw new Error("failed to fetch role data");
    }
  },
);

export const { selectRole } = rolesSlice.actions;
export default rolesSlice.reducer;

const rolesSelectors = rolesAdapter.getSelectors(
  (state: RootState) => state.roles,
);

export const getAllRoles = (state: RootState) => {
  return rolesSelectors.selectAll(state);
};

export const selectRolesStatus = (state: RootState) => state.roles.status;
