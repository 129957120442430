import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, DialogTitle, DialogContent, Grid } from "@mui/material";

import { FormTextField, FormTextArea } from "@features/common/FormFields";
import SaveCancelButtonGroup from "@features/common/ButtonGroups/SaveCancelButtonGroup";
import { useAppDispatch } from "@features/common/StoreHook";
import { ICollectionCreate } from "./types";
import { createCollection, selectCollectionsStatus } from "./collectionsSlice";

interface AddCollectionProps {
  open: boolean;
  onClose: () => void;
}

const validationSchema = yup.object({
  name: yup.string().required(),
  description: yup.string().nullable(),
});

const AddCollection = (props: AddCollectionProps) => {
  const { open, onClose } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isSubmitting = useSelector(selectCollectionsStatus) === "submitting";

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      description: "",
    },
  });

  const handleFormSubmit = (formData: any) => {
    const newCollection: ICollectionCreate = {
      name: formData.name,
      description: formData.description,
      multilanguage: true, // all collections will be multilanguage in the new db, this field should be removed in the future
    };
    dispatch(createCollection(newCollection))
      .unwrap()
      .then(() => {
        reset();
        onClose();
        toast.success(t("Collection created"));
      })
      .catch(() => {
        toast.error(t("Failed to create collection"));
      });
  };

  const handleCancel = () => {
    reset();
    onClose();
  };

  return (
    <>
      <Dialog open={open} maxWidth="sm" fullWidth>
        <DialogTitle>{t("title-add-new-collection")}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} marginTop={0.5}>
            <Grid item xs={12}>
              <FormTextField
                name="name"
                control={control}
                label={t("label-collection-name")}
                readOnly={isSubmitting}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextArea
                name="description"
                control={control}
                label={t("label-collection-description")}
                readOnly={isSubmitting}
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <SaveCancelButtonGroup
                disabled={isSubmitting}
                handleCancel={handleCancel}
                handleSave={handleSubmit(handleFormSubmit)}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddCollection;
