/**
 * The slice setup for customer related reducers
 */
import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { RootState } from "@app/store";
import { CustomerCreate, ICustomer } from "./types";
import {
  getAllCustomersRequest,
  createCustomerRequest,
} from "./customerApiService";

const customerAdapter = createEntityAdapter<ICustomer>({
  selectId: (customer) => customer.id,
});

const initialState = customerAdapter.getInitialState({
  selectedCustomer: "",
  status: "",
});

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    selectCustomer: (state, action: PayloadAction<string>) => {
      state.selectedCustomer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchCustomers.fulfilled,
        (state, { payload }: PayloadAction<ICustomer[]>) => {
          customerAdapter.setAll(state, payload);
          state.status = "";
        },
      )
      .addCase(fetchCustomers.rejected, (state) => {
        state.status = "";
      })
      .addCase(createCustomer.pending, (state) => {
        state.status = "submitting";
      })
      .addCase(createCustomer.fulfilled, (state) => {
        state.status = "";
      })
      .addCase(createCustomer.rejected, (state) => {
        state.status = "";
      });
  },
});

export default customerSlice.reducer;
export const { selectCustomer } = customerSlice.actions;

export const fetchCustomers = createAsyncThunk(
  "customers/fetchCustomers",
  async () => {
    try {
      const res = await getAllCustomersRequest();
      return res.data;
    } catch (err) {
      throw new Error("Failed to fetch customers");
    }
  },
);

export const createCustomer = createAsyncThunk(
  "customers/createCustomer",
  async (customer: CustomerCreate) => {
    try {
      const res = await createCustomerRequest(customer);
      return res.data;
    } catch (err) {
      throw new Error("Failed to create customer environment");
    }
  },
);

const customerSelectors = customerAdapter.getSelectors(
  (state: RootState) => state.customer,
);

export const getSelectedCustomerName = (state: RootState) =>
  customerSelectors.selectById(state, state.customer.selectedCustomer)?.name ||
  "";

export const getSelectedCustomerId = (state: RootState) =>
  state.customer.selectedCustomer;

export const getCustomerObj = (state: RootState) =>
  customerSelectors.selectById(state, state.customer.selectedCustomer);

export const getCustomers = (state: RootState) =>
  customerSelectors.selectAll(state);

export const selectCustomersStatus = (state: RootState) =>
  state.customer.status;
