import { shallowEqual, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  ICollectionContent,
  ICollectionMember,
} from "@features/admin/Collections/types";
import { getCollectionById } from "@features/admin/Collections/collectionsSlice";
import { RootState } from "@app/store";

/**
 * Hook for getting collection values by collection id.
 * @param collectionId
 * @returns
 */

export const useCollection: (collectionId: number) => ICollectionContent[] = (
  collectionId,
) => {
  const { i18n } = useTranslation();
  const collection = useSelector(
    (state: RootState) => getCollectionById(state, collectionId),
    shallowEqual,
  );
  // map collection member values to an array of objects with id, value and label properties
  const content = collection?.members.map((m: ICollectionMember) => {
    // if collection is not multilanguage, return the first value
    if (!collection?.multilanguage) {
      return { id: m.id, value: m.values[0].value, label: m.values[0].value };
    }
    // if collection is multilanguage, return the value as label for the current language or the first available value
    const label = m.values.find((v) => v.language_code === i18n.language);
    // value is by default the value for en-US, since database values are based on it
    const value = m.values.find((v) => v.language_code === "en-US");
    return {
      id: m.id,
      value: value?.value || m.values[0].value,
      label: label?.value || m.values[0].value,
    };
  });

  return content || [];
};
