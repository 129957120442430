/**
 * Functions for sorting tables. These are required when using SortTableHead.
 * According to MUI docs stableSort() brings sort stability to non-modern browsers (IE11).
 * @param array
 * @param comparator
 * @returns
 */

export type Order = "asc" | "desc";

export function sortData<T>(array: T[], order: Order, orderBy: keyof T) {
  // Filter out null values
  const nullValueData = array.filter((el) => !el[orderBy]);
  const dataToSort = array.filter((el) => !!el[orderBy]);
  // Sort the data and add the nulls back to the end
  return stableSort(dataToSort, getComparator(order, orderBy)).concat(
    nullValueData,
  );
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const aVal =
    typeof a[orderBy] === "string"
      ? String(a[orderBy]).trim().toLowerCase()
      : a[orderBy];
  const bVal =
    typeof b[orderBy] === "string"
      ? String(b[orderBy]).trim().toLowerCase()
      : b[orderBy];
  if (bVal < aVal) {
    return -1;
  }
  if (bVal > aVal) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: any, b: any) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
