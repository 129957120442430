import { ICustomer } from "./types";

interface CustomerPageProps {
  customer: ICustomer | null;
}

const CustomerPage = (props: CustomerPageProps) => {
  const { customer } = props;
  return (
    <>
      <>
        <h1>{customer?.name}</h1>
      </>
      <></>
    </>
  );
};

export default CustomerPage;
