import {
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from "react-i18next";

import ChartInfoBox from "../ChartInfoBox";
import { formatToNDecimals, chartColors } from "../utils";
import VmUtilizationChartTooltip from "./VmUtilizationChartTooltip";

interface VmUtilizationChartProps {
  data: any; // Array of objects with keys 'name' (string) and 'value' (integer)
  legend: string[];
}

const VmUtilizationChart = (props: VmUtilizationChartProps) => {
  const { t } = useTranslation();
  const { data, legend } = props;

  if (!Array.isArray(data) || !data.length) {
    return <ChartInfoBox severity="info" text={t("label-no-data")} />;
  }

  const colors = [
    chartColors.orange,
    chartColors.yellow,
    chartColors.green,
    chartColors.wine,
  ];

  const customizedLabel = (props: any) => {
    return (
      <text
        style={{ fontSize: "90%" }}
        x={props.x}
        y={props.y}
        fill={props.fill}
        textAnchor={props.x > props.cx ? "start" : "end"}
      >
        {`${formatToNDecimals(props.value / 60, 1)} ${t("label-hours")}`}
      </text>
    );
  };

  return (
    <>
      <ResponsiveContainer>
        <PieChart>
          <Legend
            wrapperStyle={{ fontSize: "90%" }}
            payload={legend.map((translationKey: string, index: number) => ({
              id: translationKey,
              value: t(translationKey),
              color: colors[index],
            }))}
          />
          <Pie
            data={data}
            dataKey="value"
            isAnimationActive={false}
            label={customizedLabel}
          >
            {data.map((entry: any, index: any) => (
              <Cell key={entry.name} fill={colors[index]} />
            ))}
          </Pie>
          <Tooltip
            content={<VmUtilizationChartTooltip data={data} />}
            animationDuration={500}
          />
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};

export default VmUtilizationChart;
