import { useState } from "react";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Table,
  Radio,
  Typography,
  TablePagination,
  useTheme,
} from "@mui/material";
import {
  Order,
  sortData,
} from "@features/common/Table/SortTableHead/tableSortFunctions";
import SortTableHead from "../../common/Table/SortTableHead/SortTableHead";
import LoadingIndicatorBox from "../LoadingIndicatorBox/LoadingIndicatorBox";
import { emptyTableIndicatorText } from "./styles";
import { useTranslation } from "react-i18next";

/**
 * MUI Table with sort enabled in the header.
 *
 * About using this component:
 * This table component should be primarily used for displaying e.g. data that is fetched from the API.
 * If you need a table with custom functionality that is not in this version and is not needed for other
 * components using this table, create a new custom table and place it in the folder with the component using it.
 *
 * @param {ISortTableProps<T>} props
 * @param {string} props.idProperty - The property that is used as the id for the row.
 * Since there's no guarantee that the identifying property is named id for all types of objects,
 * it has to be provided as a prop idProperty, which is then used for mapping the data with an id property.
 */

export interface IProperty<T> {
  key: keyof T;
  label: string;
}

interface ITableIdProps {
  id: string;
}

interface ISortTableProps<T> {
  properties: IProperty<T>[];
  idProperty: string;
  handleChange: any;
  isLoading: boolean;
  selectedId?: string;
  data: T[];
  maxHeight?: string;
  pagination?: boolean; // If true, pagination is enabled
  rowCount?: number; // Number of rows per page, only used if pagination is enabled
}

const SortTable = <T extends Record<string, any>>(
  props: ISortTableProps<T>,
) => {
  const {
    properties,
    idProperty,
    handleChange,
    isLoading,
    data,
    selectedId,
    maxHeight,
    pagination,
    rowCount,
  } = props;
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(rowCount || 100);
  const [page, setPage] = useState<number>(0);
  const { t } = useTranslation();
  const theme = useTheme();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (e: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Data has to be mapped to include the id property, which is used for selecting the row.
  const tableData: (T & ITableIdProps)[] =
    data && data.map((d) => ({ ...d, id: d[idProperty as keyof T] }));

  const renderTable = () => {
    if (!isLoading && tableData.length > 0) {
      return (
        <>
          <SortTableHead
            properties={properties}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {sortData(tableData, order, orderBy)
              .slice(
                pagination ? page * rowsPerPage : 0,
                pagination ? page * rowsPerPage + rowsPerPage : data.length,
              )
              .map((resource) => (
                <TableRow
                  key={resource.id}
                  data-testid="data-row"
                  onClick={() => handleChange(resource.id)}
                  sx={{
                    backgroundColor:
                      selectedId === resource.id
                        ? theme.palette.secondary.main
                        : undefined,
                  }}
                >
                  <TableCell key={"id_field"}>
                    <Radio
                      checked={selectedId === resource.id}
                      role="row-item-radio-button"
                      style={{
                        color:
                          selectedId === resource.id
                            ? theme.palette.primary.main
                            : theme.palette.primary.dark,
                      }}
                    />
                  </TableCell>
                  {properties.map((property) => (
                    <TableCell key={`${String(property.key)}-${resource.id}`}>
                      <Typography>{resource[property.key]}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </>
      );
    } else if (!isLoading && tableData.length < 1) {
      return (
        <TableBody>
          <TableRow>
            <TableCell style={emptyTableIndicatorText}>
              {t("label-empty-table-text")}
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }
  };

  return (
    <>
      <TableContainer sx={{ maxHeight: maxHeight || "45vh" }}>
        <Table stickyHeader size="small">
          {renderTable()}
        </Table>
      </TableContainer>
      <LoadingIndicatorBox show={isLoading} />
      {props.pagination && (
        <TablePagination
          rowsPerPageOptions={[
            10,
            25,
            50,
            100,
            { label: "All", value: data.length },
          ]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

export default SortTable;
