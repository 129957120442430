import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  FormControl,
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import SaveCancelButtonGroup from "@features/common/ButtonGroups/SaveCancelButtonGroup";
import { useCollection } from "@features/common/collectionHook";
import FormTextField from "@features/common/FormFields/FormTextField";
import FormTextArea from "@features/common/FormFields/FormTextArea";

import TypeSpecificInfo from "./TypeSpecificInfo";
import { dialogFormStyle } from "../style";
import FormDropdownSelect from "../../common/FormFields/FormDropdownSelect";

interface IProps {
  show: boolean;
  onCancel: () => void;
}

/**
 * Form for adding a new VM.
 * @param props
 * @returns
 */

const validationSchema = yup.object({
  name: yup.string().required(),
  description: yup.string().nullable(),
  type: yup.string().required(),
  category: yup.string().required(),
  level: yup.string().required(),
  status: yup.string(),
});

const AddCompute = (props: IProps) => {
  const { show, onCancel } = props;
  const { t } = useTranslation();
  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      description: "",
      type: "",
      status: "",
      category: "",
      level: "",
    },
  });

  const environmentTypes = useCollection(2);
  const statusValues = useCollection(7);

  const isSubmitting = false;

  useEffect(() => {
    // set type and status to have a default value
    reset({
      type: environmentTypes[0]?.value || "",
      status: statusValues[0]?.value || "",
    });
  }, []);

  const handleFormSubmit = (data: any) => {
    // TODO: handle submit logic (requires API endpoint)
    console.log("Form submitted", data);
    onCancel();
  };

  const handleCancel = () => {
    // set fields back to default when closing the form
    reset({
      name: "",
      type: environmentTypes[0]?.value || "",
      description: "",
      status: statusValues[0]?.value || "",
      category: "",
      level: "",
    });
    onCancel();
  };

  return (
    <>
      <Dialog fullWidth={true} maxWidth="lg" open={show} onClose={onCancel}>
        <DialogTitle>Add a new environment</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset" sx={dialogFormStyle}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormTextField
                  name="name"
                  control={control}
                  label={t("label-machine-name")}
                  readOnly={isSubmitting}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormDropdownSelect
                  name="status"
                  control={control}
                  label={t("label-status")}
                  selectItems={statusValues}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormDropdownSelect
                  name="type"
                  control={control}
                  label={t("label-type")}
                  selectItems={environmentTypes}
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextArea
                  name="description"
                  control={control}
                  label={t("label-machine-description")}
                  readOnly={isSubmitting}
                  rows={4}
                />
              </Grid>
              <TypeSpecificInfo readOnly={isSubmitting} control={control} />
            </Grid>
          </FormControl>
          <SaveCancelButtonGroup
            disabled={isSubmitting}
            handleSave={handleSubmit(handleFormSubmit)}
            handleCancel={handleCancel}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddCompute;
