import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

interface ITabPanelProps {
  children?: React.ReactNode;
}

/**
 * Component for content area associated with a tab.
 * @param props
 * @returns
 */

const TabPanel = (props: ITabPanelProps) => {
  const { children } = props;

  return (
    <>
      <Box padding={3}>{children}</Box>
    </>
  );
};

export default TabPanel;
