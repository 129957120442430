import { AxiosResponse } from "axios";
import { apiGET, apiPOST } from "@features/common/baseApiService";
import { EventLog } from "./types";

export async function getEventLogsRequest(
  customerId: string,
  eventId: string,
): Promise<AxiosResponse<EventLog[]>> {
  const resource = `customers/${customerId}/events/${eventId}/logs`;
  const response = await apiGET<EventLog[]>(resource);
  return response;
}

export async function getEventLogBlobContent(
  customerId: string,
  eventId: string,
  filename: string,
): Promise<AxiosResponse<string>> {
  const resource = `customers/${customerId}/events/${eventId}/log`;
  const response = await apiGET<string>(resource, { file_name: filename });
  return response;
}

export async function rerunEventRequest(
  eventId: string,
): Promise<AxiosResponse<string>> {
  const resource = `tasks/${eventId}/rerun`;
  const response = await apiPOST<string, any>(resource);
  return response;
}
