import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "@app/store";
import { IUser, IUserCreate, IRole } from "../types";
import { getAllUsersRequest, postUserRequest } from "./userApiService";
import { addUserRoleRequest, deleteUserRoleRequest } from "./rolesApiService";

const userAdapter = createEntityAdapter<IUser>({
  selectId: (user) => user.id,
});

const initialState = userAdapter.getInitialState({
  selectedUser: "",
  status: "",
});

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    selectUser(state, action: PayloadAction<string>) {
      state.selectedUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchUsers.fulfilled,
        (state, { payload }: PayloadAction<IUser[]>) => {
          userAdapter.setAll(state, payload);
          state.status = "";
        },
      )
      .addCase(fetchUsers.rejected, (state) => {
        state.status = "";
      })
      .addCase(createUser.pending, (state) => {
        state.status = "submitting";
      })
      .addCase(createUser.fulfilled, (state) => {
        state.status = "";
      })
      .addCase(createUser.rejected, (state) => {
        state.status = "";
      })
      .addCase(addUserRole.pending, (state) => {
        state.status = "submitting";
      })
      .addCase(
        addUserRole.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          const userroles = state.entities[payload.userId]?.roles;
          userroles?.push(payload.payload.role_id.toString());
          userAdapter.updateOne(state, {
            id: payload.userId,
            changes: { roles: userroles },
          });
          state.status = "";
        },
      )
      .addCase(addUserRole.rejected, (state) => {
        state.status = "";
      })
      .addCase(deleteUserRole.pending, (state) => {
        state.status = "submitting";
      })
      .addCase(
        deleteUserRole.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          const userroles = state.entities[payload.userId]?.roles;
          const udpatedRoles = userroles?.filter(
            (role) => role !== payload.roleId.toString(),
          );
          userAdapter.updateOne(state, {
            id: payload.userId,
            changes: { roles: udpatedRoles },
          });
          state.status = "";
        },
      )
      .addCase(deleteUserRole.rejected, (state) => {
        state.status = "";
      })
      .addCase(addInternalUserRole.pending, (state) => {
        state.status = "submitting";
      })
      .addCase(addInternalUserRole.fulfilled, (state) => {
        state.status = "";
      })
      .addCase(addInternalUserRole.rejected, (state) => {
        state.status = "";
      });
  },
});

export const fetchUsers = createAsyncThunk(
  "users/fetchUsers",
  async (customerId: string) => {
    try {
      const res = await getAllUsersRequest(customerId);
      if (res) return res.data;
      else return res;
    } catch (err) {
      console.error(err);
      throw new Error("failed to fetch user data");
    }
  },
);

export interface CreateUserDataBundle {
  payload: IUserCreate;
  customerId: string;
}

export const createUser = createAsyncThunk(
  "users/createUser",
  async (data: CreateUserDataBundle) => {
    try {
      const res = await postUserRequest(data.customerId, data.payload);
      if (res) return res.data;
      else return res;
    } catch (err) {
      console.error(err);
      throw new Error("failed to create user");
    }
  },
);

export const { selectUser } = userSlice.actions;
export default userSlice.reducer;

// Add and delete user roles requests are done here for simplicity.
// User role change requests could be done from another file but are done here for now,
// because the user objects with the user roles in them are stored in the user slice.
export interface AddUserRoleData {
  customerId: string;
  userId: string;
  payload: IRole;
}

export const addUserRole = createAsyncThunk(
  "userroles/addUserRole",
  async (data: AddUserRoleData) => {
    try {
      await addUserRoleRequest(data.customerId, data.userId, data.payload);
      return data;
    } catch (err) {
      console.error(err);
      throw new Error("failed to post role");
    }
  },
);

export interface DeleteUserRoleData {
  customerId: string;
  userId: string;
  roleId: number;
}

export const deleteUserRole = createAsyncThunk(
  "userroles/deleteUserRole",
  async (data: DeleteUserRoleData) => {
    try {
      await deleteUserRoleRequest(data.customerId, data.userId, data.roleId);
      return data;
    } catch (err) {
      console.error(err);
      throw new Error("failed to delete role");
    }
  },
);

export const addInternalUserRole = createAsyncThunk(
  "userroles/addInternalUserRole",
  async (data: AddUserRoleData) => {
    try {
      const res = await addUserRoleRequest(
        data.customerId,
        data.userId,
        data.payload,
      );
      return res.data;
    } catch (err) {
      console.error(err);
      throw new Error("Failed to add role to internal user");
    }
  },
);

const userSelectors = userAdapter.getSelectors(
  (state: RootState) => state.users,
);

export const getAllUsers = (state: RootState) => {
  return userSelectors.selectAll(state);
};

export const getSelectedUser = (state: RootState) => {
  return userSelectors.selectById(state, state.users.selectedUser);
};

export const selectUsersStatus = (state: RootState) => state.users.status;
