import { Outlet } from "react-router-dom";
import { Header } from "@features/header";
import { useEffect } from "react";
import { fetchCollections } from "@features/admin/Collections/collectionsSlice";
import { useAppDispatch } from "@features/common/StoreHook";
import { baseTheme } from "@app/theme";
import { Card, ThemeProvider } from "@mui/material";

export const AuthenticatedLayout = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCollections());
  }, []);

  return (
    <ThemeProvider theme={baseTheme}>
      <Header />
      <Card
        sx={{
          height: "calc(100vh - 116px)",
          overflowX: "auto",
          overflowY: "scroll",
          borderRadius: "0px",
          backgroundColor: "primary.light",
        }}
      >
        <Outlet />
      </Card>
    </ThemeProvider>
  );
};

export default AuthenticatedLayout;
