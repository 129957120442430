import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useSelector, shallowEqual } from "react-redux";
import { FormControl, FormLabel } from "@mui/material";

import { RootState } from "@app/store";
import { formControlStyle, formLabelStyle } from "@features/common/styles";
import SortTable, { IProperty } from "@features/common/Table/SortTable";
import AddDeleteButtonGroup from "@features/common/ButtonGroups/AddDeleteButtonGroup";
import { ConfirmActionDialog } from "@features/common/ConfirmAction";
import { useAppDispatch } from "@features/common/StoreHook";
import CollectionEdit from "./CollectionEdit";
import AddCollection from "./AddCollection";
import { deleteCollection } from "./collectionsSlice";
import { selectAllCollections } from "./collectionsSlice";
import { ICollection } from "./types";

const Collections = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectedId, setSelectedId] = useState<string>("");
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);

  const collections: ICollection[] = useSelector(
    (state: RootState) => selectAllCollections(state),
    shallowEqual,
  );

  const properties: IProperty<ICollection>[] = [
    { key: "name", label: t("label-collection-name") },
    { key: "id", label: t("label-collection-id") },
  ];

  const handleDelete = () => {
    dispatch(deleteCollection(selectedId))
      .unwrap()
      .then(() => {
        toast.success("Collection deleted");
        setSelectedId("");
      })
      .catch(() => {
        toast.error("Failed to delete collection");
      });
    setConfirmDialogOpen(false);
  };

  return (
    <>
      <FormControl component="fieldset" sx={formControlStyle}>
        <FormLabel component="legend" sx={formLabelStyle}>
          {t("label-collections")}
        </FormLabel>
        <SortTable
          properties={properties}
          idProperty="id"
          selectedId={selectedId}
          isLoading={false}
          handleChange={(id: string) => setSelectedId(id)}
          maxHeight="70vh"
          data={collections}
        />
        <AddDeleteButtonGroup
          disabled={false}
          handleAdd={() => setAddDialogOpen(true)}
          handleDelete={
            selectedId ? () => setConfirmDialogOpen(true) : undefined
          }
        />
      </FormControl>
      {selectedId && (
        <CollectionEdit key={selectedId} collectionId={selectedId} />
      )}
      <AddCollection
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
      />
      <ConfirmActionDialog
        show={confirmDialogOpen}
        action="DELETE"
        resource={collections.find((c) => c.id === parseInt(selectedId))?.name}
        onConfirm={handleDelete}
        onCancel={() => setConfirmDialogOpen(false)}
      />
    </>
  );
};

export default Collections;
