import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "@app/store";
import { getInternalUsersRequest } from "./userApiService";
import { InternalUser } from "../types";

const internalUserAdapter = createEntityAdapter<InternalUser>({
  selectId: (user) => user.user_id,
});

const initialState = internalUserAdapter.getInitialState({
  selectedInternalUser: "",
  status: "",
});

export const internalUserSlice = createSlice({
  name: "internalUsers",
  initialState,
  reducers: {
    selectInternalUser(state, action: PayloadAction<string>) {
      state.selectedInternalUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInternalUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchInternalUsers.fulfilled,
        (state, { payload }: PayloadAction<any[]>) => {
          internalUserAdapter.setAll(state, payload);
          state.status = "";
        },
      )
      .addDefaultCase((state) => {
        state.status = "";
      });
  },
});

export const fetchInternalUsers = createAsyncThunk(
  "users/fetchInternalUsers",
  async (customerId: string) => {
    try {
      const res = await getInternalUsersRequest(customerId);
      if (res) return res.data;
      else return res;
    } catch (err) {
      console.error(err);
      throw new Error("failed to fetch internal user data");
    }
  },
);

export const { selectInternalUser } = internalUserSlice.actions;
export default internalUserSlice.reducer;

const internalUserSelectors = internalUserAdapter.getSelectors(
  (state: RootState) => state.internalUsers,
);

export const getInternalUsers = (state: RootState) => {
  return internalUserSelectors.selectAll(state);
};

export const getSelectedInternalUser = (state: RootState) => {
  return internalUserSelectors.selectById(
    state,
    state.internalUsers.selectedInternalUser,
  );
};
