import { oivaApi } from "@features/common/oivaApi";
import { TimelineEvent, TimelineResource } from "./types";

interface DashboardRequestPayload {
  customerId: string;
  start_date: string;
  end_date: string;
}

/**
 * Contains API queries for dashboard timeline events and resources.
 * RTK Query is used for data fetching.
 */

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const dashboardApi = oivaApi.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query<TimelineEvent[], DashboardRequestPayload>({
      query: ({ customerId, start_date, end_date }) => ({
        url: `/customers/${customerId}/tasks/schedules/compute_dashboard_events?start_date=${start_date}&end_date=${end_date}&tz=${timezone}`,
      }),
    }),
    getResources: builder.query<TimelineResource[], DashboardRequestPayload>({
      query: ({ customerId, start_date, end_date }) => ({
        url: `/customers/${customerId}/tasks/schedules/compute_dashboard_resources?start_date=${start_date}&end_date=${end_date}`,
      }),
    }),
  }),
});

export const { useGetEventsQuery, useGetResourcesQuery } = dashboardApi;
