export const navBarStyles = {
  display: "flex",
  gap: "10px", // adds a margin between navigation items
};

export const navigationLinkStyles = {
  padding: "10px 15px",
  "&:hover": {
    backgroundColor: "primary.dark",
  },
};

export const drawerNavigationLinkStyles = {
  "&.Mui-selected": {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
  },
  "&.Mui-selected:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
  },
  ".MuiTouchRipple-rippleVisible": {
    color: "white",
  },
};
