import dayjs from "dayjs";
import { EventType } from "../../types";

export const calculateFinishedStatusData = (
  events: any,
  activeEvent: any,
  startDate: any,
  endDate: any,
) => {
  const finishedEvents = events.reduce(
    (acc: any, event: any) => {
      const isInDateRange =
        dayjs(event.scheduled_start_time).isAfter(startDate) &&
        dayjs(event.scheduled_stop_time).isBefore(endDate);
      const isPartOfActiveItem =
        (activeEvent.type === EventType.VM && activeEvent.id === event.vmId) ||
        (activeEvent.type === EventType.Task &&
          activeEvent.id === event.resourceId) ||
        (activeEvent.type === EventType.Event && activeEvent.id === event.id);
      if (event.type === "actual" && isInDateRange && isPartOfActiveItem) {
        if (event.status === 4 || event.status === 5) {
          acc.ok++;
        } else if (
          event.status === 0 ||
          event.status === 1 ||
          event.status === 3 ||
          event.status === 6
        ) {
          acc.notOk++;
        }
      }
      return acc;
    },
    { ok: 0, notOk: 0 },
  );

  return [
    { name: "ok", value: finishedEvents.ok },
    { name: "nok", value: finishedEvents.notOk },
  ];
};
