import { useState } from "react";
import { Grid, FormControl } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { toast } from "react-toastify";

import { useAppDispatch } from "@features/common/StoreHook";
import { getSelectedCustomerId } from "@features/customers/customerSlice";
import { RootState } from "@app/store";
import SaveCancelButtonGroup from "@features/common/ButtonGroups/SaveCancelButtonGroup";
import { dialogFormStyle } from "@features/common/styles";
import { useCollection } from "@features/common/collectionHook";
import { DropdownSelect } from "@features/common/DropdownSelect";

import { LeftRightSelector } from "./LeftRightSelector";
import { DailyRecurrenceSelector } from "./DailyRecurrenceSelector";
import { WeeklyRecurrenceSelector } from "./WeeklyRecurrenceSelector";
import { MonthlyRecurrenceSelector } from "./MonthlyRecurrenceSelector";
import { CRONRecurrenceSelector } from "./CRONRecurrenceSelector";
import { ITask, IScheduleCreate } from "../types";
import { schedulerGridStyle } from "./styles";
import { getSelectedTask } from "../tasksSlice";
import FormDateField from "./FormDateField";
import FormTimeField from "./FormTimeField";
import { selectSchedulesStatus, createSchedule } from "./scheduleSlice";

/**
 * Component for add schedule form.
 *
 */

interface AddScheduleProps {
  onCancel: () => void;
}

const validationSchema = yup.object({
  start_date: yup.string().required(),
  end_date: yup.string().required(),
  start_time: yup.string().required(),
  end_time: yup.string().required(),
  frequency: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required(),
  day_number: yup.number(),
  runs_monday: yup.boolean(),
  runs_tuesday: yup.boolean(),
  runs_wednesday: yup.boolean(),
  runs_thursday: yup.boolean(),
  runs_friday: yup.boolean(),
  runs_saturday: yup.boolean(),
  runs_sunday: yup.boolean(),
  cron_string: yup.string(),
  duration: yup.number(),
});

export const AddSchedule = (props: AddScheduleProps) => {
  const { onCancel } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const recurrenceTypes = useCollection(13);
  const isSubmitting = useSelector(selectSchedulesStatus) === "submitting";
  const [selectedVm, setSelectedVm] = useState<string>("");
  const [vmFieldError, setVmFieldError] = useState<string>("");
  const [selectedRecurrence, setSelectedRecurrence] = useState<string>("None"); // sets None as the default recurrence option

  const customerId = useSelector(getSelectedCustomerId);
  const selectedTask: ITask | undefined = useSelector((state: RootState) =>
    getSelectedTask(state),
  );

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      start_date: dayjs().format("YYYY-MM-DD"),
      end_date: dayjs().format("YYYY-MM-DD"),
      start_time: dayjs().format("HH:mm"),
      end_time: dayjs().format("HH:mm"),
      frequency: 0,
      day_number: 0,
      runs_monday: false,
      runs_tuesday: false,
      runs_wednesday: false,
      runs_thursday: false,
      runs_friday: false,
      runs_saturday: false,
      runs_sunday: false,
      cron_string: "",
      duration: 0,
    },
  });

  const handleDropdownChange = (event: string) => {
    setSelectedRecurrence(event);
  };

  const validateFormAndSubmit = () => {
    // Enables validation and showing errors for both the VM field and the rest of the form at the same time.
    if (!selectedVm) {
      setVmFieldError("Please select a VM");
    }
    handleSubmit((data) => {
      // handleSubmit submits form if useForm values are valid by default,
      // so VM field check is done separately
      if (!selectedVm) return;
      handleSave(data);
    })();
  };

  const handleSave = (data: any) => {
    if (selectedTask && customerId) {
      const startDateString = `${data.start_date}T${data.start_time}`;
      const endDateString = `${data.end_date}T${data.end_time}`;
      const start = new Date(startDateString);
      const end = new Date(endDateString);
      if (start > end) {
        toast.error(t("error-start-time-later-than-end-time"));
        return;
      }
      let daily_recurrence = null;
      let weekly_recurrence = null;
      let monthly_recurrence = null;
      let cron_recurrence = null;
      switch (selectedRecurrence) {
        case "Daily":
          daily_recurrence = {
            frequency: data.frequency,
          };
          break;
        case "Weekly":
          weekly_recurrence = {
            frequency: data.frequency,
            runs_monday: data.runs_monday,
            runs_tuesday: data.runs_tuesday,
            runs_wednesday: data.runs_wednesday,
            runs_thursday: data.runs_thursday,
            runs_friday: data.runs_friday,
            runs_saturday: data.runs_saturday,
            runs_sunday: data.runs_sunday,
          };
          break;
        case "Monthly":
          monthly_recurrence = {
            frequency: data.frequency,
            day_number: data.day_number,
          };
          break;
        case "CRON":
          cron_recurrence = {
            cron_string: data.cron_string,
            duration: data.duration,
          };
          break;
      }

      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const payload: IScheduleCreate = {
        customer_id: customerId,
        task_id: selectedTask.task_guid,
        vm_name: selectedVm,
        start_time: start,
        end_time: end,
        daily_recurrence: daily_recurrence,
        weekly_recurrence: weekly_recurrence,
        monthly_recurrence: monthly_recurrence,
        cron_recurrence: cron_recurrence,
        timezone: timeZone,
      };

      dispatch(createSchedule(payload))
        .unwrap()
        .then(() => {
          toast.success(t("Schedule added"));
          onCancel();
        })
        .catch(() => {
          toast.error(t("Could not add schedule"));
        });
    }
  };

  return (
    <FormControl component="fieldset" style={dialogFormStyle}>
      <Grid style={schedulerGridStyle}>
        <LeftRightSelector
          setleftGlobal={setSelectedVm}
          isEditing={true}
          isSubmitting={isSubmitting}
          error={vmFieldError}
          setError={setVmFieldError}
        />
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormDateField
            name="start_date"
            control={control}
            label={t("label-start-date")}
            readOnly={isSubmitting}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormDateField
            name="end_date"
            control={control}
            label={t("label-end-date")}
            readOnly={isSubmitting}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTimeField
            name="start_time"
            control={control}
            label={t("label-start-time")}
            readOnly={isSubmitting}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTimeField
            name="end_time"
            control={control}
            label={t("label-end-time")}
            readOnly={isSubmitting}
          />
        </Grid>
        <Grid item xs={12}>
          <DropdownSelect
            style={{ width: "100%" }}
            setParentComponentState={handleDropdownChange}
            selectItems={recurrenceTypes || []}
            menuId="SchedulerRecurrenceSelect"
            textFieldLabel={t("label-recurrence")}
            selectedItem={selectedRecurrence}
            disabled={isSubmitting}
          />
        </Grid>

        <Grid item xs={12}>
          {selectedRecurrence === "Daily" ? (
            <DailyRecurrenceSelector
              isEditing={!isSubmitting}
              control={control}
            />
          ) : selectedRecurrence === "Weekly" ? (
            <WeeklyRecurrenceSelector
              isEditing={!isSubmitting}
              control={control}
            />
          ) : selectedRecurrence === "Monthly" ? (
            <MonthlyRecurrenceSelector
              isEditing={!isSubmitting}
              control={control}
            />
          ) : selectedRecurrence === "CRON" ? (
            <CRONRecurrenceSelector
              isEditing={!isSubmitting}
              control={control}
            />
          ) : null}
        </Grid>
      </Grid>
      <SaveCancelButtonGroup
        disabled={isSubmitting}
        handleCancel={onCancel}
        handleSave={validateFormAndSubmit}
      />
    </FormControl>
  );
};
