import { InteractionType } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalAuthenticationResult,
  MsalAuthenticationTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "@configurations/authConfig";
import { Typography } from "@mui/material";
import { appInsights } from "../appInsights/AppInsights";
import { ITelemetryItem } from "@microsoft/applicationinsights-web";
import { userOidFromToken } from "@features/common/apiUtils";
import { ThemeProvider } from "@mui/material/styles";
import { baseTheme } from "./theme";
import { useEffect } from "react";
import AuthenticatedLayout from "./pages/AuthenticatedLayout";

/**
 * The component which will be rendered in case of an authentication error
 * @param error: the error encountered during authentication
 * @returns
 */
const ErrorComponent: React.FC<MsalAuthenticationResult> = ({ error }) => {
  return (
    <Typography variant="h6">
      An Error Occurred: {error ? error.errorCode : "unknown error"}
    </Typography>
  );
};

/**
 * The component which will be shown during the silent authentication process
 * @returns
 */
function LoadingComponent() {
  return <p>Authentication in progress...</p>;
}

/**
 * The main Layout component. This component should dictate the layout of the page: headers, footers, pages etc.
 * @returns
 */
export const Layout = () => {
  /**
   * useMsal is a hook that returns the PublicClientApplication instance.
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  /**
   * AppInsight diagnostic logging of Dolphin usage data. Here we check if user is logged into Dolphin, unfolds userOid from accessToken if there are active accounts logged in the service.
   */

  useEffect(() => {
    if (activeAccount) {
      userOidFromToken().then((userOid) => {
        appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
          env.tags = env.tags || [];
          env.tags["ai.user.id"] = userOid;
        });
      });
    } else {
      appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
        env.tags = env.tags || [];
        env.tags["ai.user.id"] = "User not logged in";
      });
    }
  }, [activeAccount]);

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  /**
   * Everything that goes under AuthenticatedTemplate will be shown to authenticated users
   * Everything under UnauthenticatedTemplate will be shown to unauthenticated users
   */
  return (
    <ThemeProvider theme={baseTheme}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        errorComponent={ErrorComponent}
        loadingComponent={LoadingComponent}
      >
        <div className="App">
          <AuthenticatedTemplate>
            <AuthenticatedLayout />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <h5 className="card-title">
              Please sign-in to see your profile information.
            </h5>
            <div onClick={handleLoginRedirect}>log in</div>
          </UnauthenticatedTemplate>
        </div>
      </MsalAuthenticationTemplate>
    </ThemeProvider>
  );
};
