export const containerStyle = {
  display: "flex",
  alignItems: "center",
  flexGrow: 1,
  justifyContent: "flex-end",
};

export const languageListMenuStyle = {
  linkContainerStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  linkStyle: {
    cursor: "pointer",
    margin: "0px 10px",
  },
};
