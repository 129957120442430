import { AxiosResponse } from "axios";
import {
  apiGET,
  apiPOST,
  apiPUT,
  apiDELETE,
} from "@features/common/baseApiService";
import { IStamp } from "../types";

export async function getTaskStampsRequest(
  customerId: string,
  taskId: string,
): Promise<AxiosResponse<IStamp[]>> {
  const resource = `customers/${customerId}/tasks/${taskId}/stamps`;
  const response = await apiGET<IStamp[]>(resource);
  return response;
}

export async function getCustomerStampsRequest(
  customerId: string,
): Promise<AxiosResponse<IStamp[]>> {
  const resource = `customers/stamps?customer_id=${customerId}`;
  const response = await apiGET<IStamp[]>(resource);
  return response;
}

export async function postStampRequest(
  customerId: string,
  taskId: string,
  payload: IStamp,
) {
  const resource = `customers/${customerId}/tasks/${taskId}/stamps`;
  const response = await apiPOST<IStamp, IStamp>(resource, payload);
  return response;
}

export async function updateStampRequest(
  customerId: string,
  taskId: string,
  stampName: string,
  payload: IStamp,
): Promise<AxiosResponse<IStamp>> {
  const resource = `customers/${customerId}/stamps/${taskId}/${stampName}`;
  const response = await apiPUT<IStamp, IStamp>(resource, payload);
  return response;
}

export async function deleteStampRequest(
  customerId: string,
  taskId: string,
  stampName: string,
): Promise<AxiosResponse<any>> {
  const resource = `customers/${customerId}/stamps/${taskId}/${stampName}`;
  const response = await apiDELETE<any>(resource);
  return response;
}
