export interface IPermissions {
  read: boolean;
  create: boolean;
  update: boolean;
  delete: boolean;
  rerun: boolean;
}

export const checkPermissions = (
  feature: string,
  permissionArr: string[] | undefined,
) => {
  const permissions: IPermissions = {
    create: false,
    read: false,
    update: false,
    delete: false,
    rerun: false,
  };

  if (permissionArr) {
    permissionArr.forEach((pair: string) => {
      switch (pair) {
        case feature + "/create":
          permissions.create = true;
          break;

        case feature + "/read":
          permissions.read = true;
          break;

        case feature + "/update":
          permissions.update = true;
          break;

        case feature + "/delete":
          permissions.delete = true;
          break;

        case feature + "/rerun":
          permissions.rerun = true;
          break;
      }
    });
  }

  return permissions;
};
