/**
 * Helper functions for API requests.
 */

import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiConfig from "@configurations/apiConfig";
import msalInstance from "@app/msalSetup";
import { loginRequest } from "@configurations/authConfig";

/**
 * Creates a BaseQuery for RTK Query API slice with the authorization header.
 * @returns BaseQuery
 */
export const createBaseQuery = (useAuth: boolean) =>
  useAuth
    ? fetchBaseQuery({
        baseUrl: apiConfig.url,
        prepareHeaders: async (headers, { getState }) => {
          // Obtain access token for the API and set it in the authorization header
          let token = await getAccessToken();
          headers.set("Authorization", `Bearer ${token}`);
        },
      })
    : fetchBaseQuery({
        baseUrl: apiConfig.url,
      });

/**
 * Async function that obtains access token to the Dolphin API by using the MSALs acquireTokenSilent-method.
 *
 * @returns bearerToken: string
 */
export const getAccessToken = async () => {
  return await msalInstance
    .acquireTokenSilent(loginRequest)
    .then((accessTokenResponse) => {
      let bearerToken = accessTokenResponse.accessToken;
      return bearerToken;
    });
};

/**
 * Async function that parses userOid from MSAL accessToken
 */
export const userOidFromToken = async () => {
  const access_token = await getAccessToken();
  // Parse users object ID from access token
  const base64Url = access_token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );

  const jsonToken = JSON.parse(jsonPayload);
  const oid = jsonToken.oid;

  return oid;
};
