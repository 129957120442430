import { AxiosResponse } from "axios";
import {
  apiGET,
  apiPOST,
  apiPUT,
  apiDELETE,
} from "@features/common/baseApiService";
import {
  ICollection,
  ICollectionCreate,
  ICollectionMember,
  ICollectionUpdate,
} from "./types";

export async function getAllCollectionsRequest(): Promise<
  AxiosResponse<ICollection[]>
> {
  const resource = `collections`;
  const response = await apiGET<ICollection[]>(resource);
  return response;
}

export async function createCollectionRequest(
  payload: ICollectionCreate,
): Promise<AxiosResponse<ICollection>> {
  const resource = `collections`;
  const response = await apiPOST<ICollectionCreate, ICollection>(
    resource,
    payload,
  );
  return response;
}

export async function deleteCollectionRequest(
  id: string,
): Promise<AxiosResponse<any>> {
  const resource = `collections/${id}`;
  const response = await apiDELETE<string>(resource);
  return response;
}

export async function updateCollectionRequest(
  payload: ICollectionUpdate,
): Promise<AxiosResponse<string>> {
  const resource = `collections/${payload.id}`;
  const response = await apiPUT<ICollectionUpdate, string>(resource, payload);
  return response;
}

export async function createCollectionMemberRequest(
  payload: ICollectionMember,
  collectionId: number,
): Promise<AxiosResponse<string>> {
  const resource = `collections/${collectionId}/members`;
  const response = await apiPOST<ICollectionMember, string>(resource, payload);
  return response;
}

export async function deleteCollectionMemberRequest(
  collectionId: number,
  memberId: string,
): Promise<AxiosResponse<string>> {
  const resource = `collections/${collectionId}/members/${memberId}`;
  const response = await apiDELETE<string>(resource);
  return response;
}

export async function updateCollectionMemberRequest(
  payload: ICollectionMember,
  collectionId: number,
): Promise<AxiosResponse<string>> {
  const resource = `collections/${collectionId}/members/${payload.id}`;
  const response = await apiPUT<ICollectionMember, string>(resource, payload);
  return response;
}
