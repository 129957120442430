import { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";

import { FormControl, FormLabel, Grid } from "@mui/material";

import { RootState } from "@app/store";
import { getSelectedCustomerId } from "@features/customers/customerSlice";
import { formControlStyle, formLabelStyle } from "@features/common/styles";
import { useAppDispatch } from "@features/common/StoreHook";
import SortTable, { IProperty } from "@features/common/Table/SortTable";
import AddDeleteButtonGroup from "@features/common/ButtonGroups/AddDeleteButtonGroup";
import ExportExcel from "@features/common/ExportExcel";
import {
  fetchSecrets,
  getAllSecrets,
  selectSecretsStatus,
  getSelectedSecretId,
  selectSecret,
} from "./secretsSlice";
import { ISecret } from "./types";
import { AddSecretDialog } from "./AddSecretDialog";
import { permissionsApi } from "@features/authorization/authService";
import { checkPermissions } from "@features/authorization/types";
import { toast } from "react-toastify";

/**
 *
 * In Secrets view, this is a table that lists available secrets in keyvault(s).
 * Table pagination enabled & "infinite scroll" enabled
 *
 *
 */

export const SecretSelector = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const customerId = useSelector(getSelectedCustomerId);
  const secrets: ISecret[] = useSelector(
    (state: RootState) => getAllSecrets(state),
    shallowEqual,
  );
  const { data } = permissionsApi.useGetCustomerPermissionsQuery(customerId);
  const secretPermissions = checkPermissions("secrets", data);
  const isLoading = useSelector(selectSecretsStatus) === "loading";
  const selectedSecretId: string | undefined = useSelector(getSelectedSecretId);
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);

  const handleRadioButtonChange = (id: string) => {
    dispatch(selectSecret(id));
  };

  const secretProperties: IProperty<ISecret>[] = [
    { key: "name", label: t("label-secret-name") },
    { key: "type", label: t("label-secret-type") },
    { key: "status", label: t("label-secret-link-status") },
  ];

  useEffect(() => {
    dispatch(fetchSecrets(customerId));
    dispatch(selectSecret(""));
  }, []);

  const handleAdd = () => {
    secretPermissions.create
      ? setAddDialogOpen(true)
      : toast.error("No create permission");
  };

  return (
    <>
      <FormControl component="fieldset" sx={formControlStyle}>
        <FormLabel component="legend" sx={formLabelStyle}>
          {t("title-secrets")}
        </FormLabel>
        {!isLoading && (
          <Grid
            container
            justifyContent="flex-end"
            style={{ paddingBottom: "1rem" }}
          >
            <ExportExcel
              data={secrets}
              fileName="secrets"
              coloredButton={true}
            />
          </Grid>
        )}
        <SortTable
          properties={secretProperties}
          idProperty={"name"}
          data={secrets}
          isLoading={isLoading}
          selectedId={selectedSecretId}
          handleChange={handleRadioButtonChange}
          pagination={true}
        />
        <AddDeleteButtonGroup
          disabled={false}
          handleAdd={handleAdd}
          //handleDelete={() => {}}
        />
        <AddSecretDialog
          onCancel={() => setAddDialogOpen(false)}
          customerId={customerId}
          show={addDialogOpen}
        />
      </FormControl>
    </>
  );
};
