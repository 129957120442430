import { TextField, Grid, FormControl, FormLabel } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { formControlStyle, formLabelStyle } from "@features/common/styles";
import { getCustomerObj } from "@features/customers/customerSlice";
import { useEffect, useState } from "react";

/**
 *
 * Reads info from Azure Keyvault and shows relevant info on screen
 * This is just a data viewer it doesn't have any proper logic/functionality
 *
 */

export const KeyvaultInfo = () => {
  const { t } = useTranslation();
  const customer = useSelector(getCustomerObj);

  const [prodName, setProdName] = useState<string>("");
  const [devName, setDevName] = useState<string>("");

  const parseUrl = (url: string) => {
    const startIndex = url.indexOf("//") + 2;
    const endIndex = url.indexOf(".");
    const extractedString = url.substring(startIndex, endIndex);
    return extractedString;
  };

  useEffect(() => {
    if (customer) {
      const prodname = customer.keyvault_prod_base_url
        ? parseUrl(customer.keyvault_prod_base_url)
        : "";
      setProdName(prodname);
      const devname = customer.keyvault_test_base_url
        ? parseUrl(customer.keyvault_test_base_url)
        : "";
      setDevName(devname);
    }
  }, [customer]);

  return (
    <>
      <FormControl component="fieldset" sx={formControlStyle}>
        <FormLabel component="legend" sx={formLabelStyle}>
          {t("label-keyvault-info")}
        </FormLabel>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t("label-keyvault-name-production")}
              inputProps={{ readOnly: true }}
              value={prodName}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              value={customer?.keyvault_prod_base_url}
              label={t("label-keyvault-address-production")}
              inputProps={{ readOnly: true }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t("label-keyvault-name-test")}
              inputProps={{ readOnly: true }}
              value={devName}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              value={customer?.keyvault_test_base_url}
              label={t("label-keyvault-address-test")}
              inputProps={{ readOnly: true }}
              fullWidth
            />
          </Grid>
        </Grid>
      </FormControl>
    </>
  );
};
