/**
 * Contains the API queries for environments endpoints.
 * Here we use RTK Query for data fetching.
 */
import { oivaApi } from "@features/common/oivaApi";
import { GalleryApplication, VMApplication } from "./types";
import { toast } from "react-toastify";

/**
 * RTK Query API for data fetching.
 */

export const environmentsApi = oivaApi
  .enhanceEndpoints({ addTagTypes: ["VMApplication"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getVMApplications: builder.query<VMApplication[], any>({
        query: ({ customerId, vmId }) => ({
          url: `/customers/${customerId}/environments/vm_applications/${vmId}`,
        }),
        providesTags: [{ type: "VMApplication", id: "LIST" }],
        // Display a toast if the query fails
        async onQueryStarted(ids, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (err) {
            displayFetchErrorToast(err);
          }
        },
      }),
      getGalleryApplications: builder.query<GalleryApplication[], any>({
        query: ({ customerId }) => ({
          url: `/customers/${customerId}/environments/vm_applications`,
        }),
        // Display a toast if the query fails
        async onQueryStarted(ids, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (err) {
            displayFetchErrorToast(err);
          }
        },
      }),
      installApplication: builder.mutation<void, any>({
        query: ({ customerId, vmId, applicationId, version }) => ({
          url: `/customers/${customerId}/environments/vm_applications/${vmId}`,
          method: "PUT",
          body: { name: applicationId, version },
        }),
        invalidatesTags: [{ type: "VMApplication", id: "LIST" }],
      }),
      uninstallApplication: builder.mutation<void, any>({
        query: ({ customerId, vmId, applicationId, version }) => ({
          url: `/customers/${customerId}/environments/vm_applications/${vmId}`,
          method: "DELETE",
          body: { name: applicationId, version },
        }),
        invalidatesTags: [{ type: "VMApplication", id: "LIST" }],
      }),
    }),
  });

const displayFetchErrorToast = (error: any) => {
  toast.error("Could not fetch applications", {
    toastId: "applications-fetch-error",
  });
};

export const {
  useGetVMApplicationsQuery,
  useGetGalleryApplicationsQuery,
  useInstallApplicationMutation,
  useUninstallApplicationMutation,
} = environmentsApi;
