import { useState } from "react";
import LanguageIcon from "@mui/icons-material/Language";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Box,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";

interface IComponentProps {
  languages: { id: string; value: string; label: string }[];
  changeLanguage: (langCode: string) => void;
  currentLang: string;
}

/**
 * Language popover menu, displayed instead of LanguageListMenu with sm and xs screens.
 *
 * Includes a tooltip for the icon.
 *
 * @param props
 * @returns
 */
const LanguagePopoverMenu = (props: IComponentProps) => {
  const { languages, changeLanguage, currentLang } = props;
  const [anchorEl, setanchorEl] = useState<Element | null>(null);
  const { t } = useTranslation();

  return (
    <Box sx={{ display: { xs: "block", md: "none" } }}>
      <Tooltip title={t("Change language")}>
        <IconButton
          aria-owns={Boolean(anchorEl) ? "menu-language" : undefined}
          aria-haspopup="true"
          onClick={(e) => setanchorEl(e.currentTarget)}
          color="inherit"
        >
          <LanguageIcon />
        </IconButton>
      </Tooltip>
      <Menu
        disableAutoFocusItem
        id="menu-language"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={(e) => setanchorEl(null)}
      >
        {languages &&
          languages.map((l) => (
            <MenuItem
              key={l.id}
              selected={l.id === currentLang}
              onClick={() => changeLanguage(l.id)}
            >
              <ListItemText>
                <Typography variant="h3">{l.label}</Typography>
              </ListItemText>
            </MenuItem>
          ))}
      </Menu>
    </Box>
  );
};

export default LanguagePopoverMenu;
