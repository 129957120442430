import { TimelineEvent } from "../../types";
import { numberTooltipFormatter } from "../utils";

export const calculatePercentage = (input: number, data: any) => {
  const total = data.reduce((a: any, b: any) => a + (b["value"] || 0), 0);
  return `${((input / total) * 100).toFixed(0)}%`;
};

function getFreeTime(startDate: Date, endDate: Date) {
  const difference =
    new Date(endDate).getTime() - new Date(startDate).getTime();
  return difference;
}

function filterEventsByVmName(vm: string, events: any) {
  return events.filter((e: TimelineEvent) => e.vmId === vm);
}

function calculateRunTime(events: any) {
  let executedRunTime = 0;
  for (let event of events) {
    if (!!event.actual_start_time && !!event.actual_stop_time) {
      executedRunTime +=
        new Date(event.actual_stop_time).getTime() -
        new Date(event.actual_start_time).getTime();
    }
  }
  return executedRunTime;
}

function calculateScheduledTime(
  events: TimelineEvent[],
  startDate: Date,
  endDate: Date,
) {
  let reserved = 0;
  for (let event of events) {
    if (!!event.scheduled_stop_time) {
      if (
        new Date(event.scheduled_start_time) < startDate &&
        new Date(event.scheduled_stop_time) > endDate
      ) {
        reserved += getFreeTime(startDate, endDate);
      } else if (
        new Date(event.scheduled_start_time) > startDate &&
        new Date(event.scheduled_stop_time) > endDate
      ) {
        reserved +=
          new Date(event.scheduled_stop_time).getTime() - startDate.getTime();
      } else if (
        new Date(event.scheduled_start_time) < startDate &&
        new Date(event.scheduled_stop_time) < endDate
      ) {
        reserved +=
          endDate.getTime() - new Date(event.scheduled_start_time).getTime();
      } else {
        reserved +=
          new Date(event.scheduled_stop_time).getTime() -
          new Date(event.scheduled_start_time).getTime();
      }
    }
  }
  return reserved;
}

export const generateUtilizationData = (
  option: string,
  selectedVm: string,
  events: any,
  startDate: Date,
  endDate: Date,
) => {
  const eventsByVm = filterEventsByVmName(selectedVm, events);
  const freeTime = getFreeTime(startDate, endDate);
  const runTime = calculateRunTime(eventsByVm);
  const scheduledTime = calculateScheduledTime(eventsByVm, startDate, endDate);

  let unused: number = 0;
  let reserved: number = 0;
  let scheduled: number = scheduledTime;
  let executed: number = runTime;
  let overtime: number = 0;

  if (scheduledTime >= freeTime) {
    unused = 0;
    scheduled = freeTime;
    reserved = freeTime - executed;
    if (reserved < 0) {
      reserved = 0;
      executed = freeTime;
    }
  } else {
    reserved = scheduled - executed;
    if (reserved < 0) {
      overtime = reserved * -1;
      executed = executed - overtime;
      reserved = 0;
    }
    unused = freeTime - reserved - executed - overtime;
  }

  if (option === "used") {
    return [
      {
        name: "unused_time",
        value: numberTooltipFormatter(
          unused === 0 ? unused : unused / 1000 / 60,
        ),
      },
      {
        name: "reserved_time",
        value: numberTooltipFormatter(
          reserved === 0 ? reserved : reserved / 1000 / 60,
        ),
      },
      {
        name: "executed_time",
        value: numberTooltipFormatter(
          executed === 0 ? executed : executed / 1000 / 60,
        ),
      },
      {
        name: "overtime",
        value: numberTooltipFormatter(
          overtime === 0 ? overtime : overtime / 1000 / 60,
        ),
      },
    ];
  } else if (option === "scheduled") {
    return [
      {
        name: "unused_time",
        value: numberTooltipFormatter(
          unused === 0 ? unused : unused / 1000 / 60,
        ),
      },
      {
        name: "scheduled_time",
        value: numberTooltipFormatter(
          scheduled === 0 ? scheduled : scheduled / 1000 / 60,
        ),
      },
    ];
  }
};
