import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import dayjs from "dayjs";
import { useTheme } from "@mui/system";

interface IComponentProps<T> {
  data: T[];
  disabled?: boolean;
  fileName?: string;
  coloredButton?: boolean;
  dataKeys?: string[];
}

const ExportExcel = <T extends Record<string, any>>(
  props: IComponentProps<T>,
) => {
  const { data, disabled, coloredButton, dataKeys } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  const extractDataKeys = () => {
    if (!!data) {
      return Object.keys(data[0]);
    }
    return [];
  };

  const exportExcel = async () => {
    if (!!data) {
      const keys = !!dataKeys ? dataKeys : extractDataKeys();
      // Create a new workbook & add a worksheet
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(props.fileName || "Sheet");
      // Add data to the worksheet
      worksheet.columns = keys.map((key) => ({ header: key, key: key }));
      worksheet.addRows(data);
      // Generate the XLSX file and save the workbook to a file
      const buffer = await workbook.xlsx.writeBuffer();
      const date = dayjs(new Date()).format("YYYY-MM-DDTHH-mm-ss");
      const fileName = (props.fileName || "dataset") + `-${date}.xlsx`;
      saveAs(new Blob([buffer]), fileName);
    }
  };

  if (disabled || !data) {
    return <></>;
  }

  return (
    <Tooltip title={t("label-export-excel")}>
      <IconButton onClick={exportExcel}>
        <FileCopyIcon
          sx={{ color: coloredButton ? theme.palette.primary.dark : "" }}
        />
      </IconButton>
    </Tooltip>
  );
};

export default ExportExcel;
