const local = {
  //url: "http://localhost:8000",
  url: "https://oiva-api-test.azurewebsites.net",
};

const test = {
  url: "https://oiva-api-test.azurewebsites.net",
};

const prod = {
  url: "https://oiva-api-prod.azurewebsites.net",
};
const config =
  process.env.REACT_APP_ENV === "prod"
    ? prod
    : process.env.REACT_APP_ENV === "test"
    ? test
    : local;

// Solve changing to staging this way since deployments slots + react app with app service
// env variables makes it difficult to change from staging to prod dynamically
if (window.location.hostname.includes("-staging.azurewebsites.net")) {
  config.url = config.url.replace(
    ".azurewebsites.net",
    "-staging.azurewebsites.net",
  );
}

const apiConfig = config;
export default apiConfig;
