import { useTranslation } from "react-i18next";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { formatToNDecimals } from "../utils";
import {
  getRelevantDatakeys,
  getHeaderTranslations,
  renderMinSuffix,
  renderTotalRow,
} from "./stampsUtils";

type ComponentProps = {
  data: any;
  dataType: string;
};

const DataTable = (props: ComponentProps) => {
  const { data, dataType } = props;
  const { t } = useTranslation();
  let dataKeys = getRelevantDatakeys(data[0], dataType);
  const headerTranslationLabels = getHeaderTranslations(dataKeys);

  return (
    <>
      <Table aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "10px" }}>#</TableCell>
            {headerTranslationLabels.length !== 0
              ? headerTranslationLabels.map((label: any) => (
                  <TableCell key={label}>{t(label)}</TableCell>
                ))
              : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((node: any, id: number) => {
            return (
              <TableRow key={id}>
                <TableCell key={id}>{id + 1}</TableCell>
                {dataKeys.map((key: any, id: any) => {
                  if (key === "start_time" || key === "end_time") {
                    return (
                      <TableCell key={id}>
                        {new Date(node[key]).toLocaleString()}
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell key={id}>
                      {formatToNDecimals(node[key], 2) +
                        renderMinSuffix(key, node[key])}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
          {renderTotalRow(data, dataKeys, t)}
        </TableBody>
      </Table>
    </>
  );
};

export default DataTable;
