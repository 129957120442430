/**
 * This is a *very* case-specific workaround for a 3rd party component (React FullCalendar) that forces specific styles for
 * components that are passed into it. Actually, no components are even passed. Just metadata. The component then creates
 * those components itself (in this case: buttons).
 *
 * For whatever reason, it was not designed such that the end user could pass their own buttons to the root component.
 * So, stupid design choices require stupid solutions.
 */

export function injectCustomButtonStyles(mode: string) {
  let buttonBgColor = undefined;
  let buttonSelectedBgColor = undefined;
  if (mode === "dark") {
    buttonBgColor = "background-color: #000000";
    buttonSelectedBgColor = "rgb(139 139 139)";
  } else {
    buttonBgColor = "background-color: #206FB7";
    buttonSelectedBgColor = "rgb(47, 81, 138)";
  }

  const stylesheetCustomButtons = `{
    height: 37px;
    color: white;
    border: 0;
    padding-left: 10px;
    padding-right: 10px;
    ${buttonBgColor}
  }`;

  const stylesheetViewButtons = `{
    text-transform: capitalize;
    height: 37px;
    color: white;
    border: 0;
    padding-left: 10px;
    padding-right: 10px;
    ${buttonBgColor}
  }`;

  const stylesheetArrowButtons = `{
    border: 0;
    height: 37px;
    color: white;
    ${buttonBgColor}
  }`;

  return `
    .fc-selectDateButton-button.fc-button.fc-button-primary ${stylesheetCustomButtons}
    .fc-refreshButton-button.fc-button.fc-button-primary ${stylesheetCustomButtons}
    .fc-resourceTimelineDay-button.fc-button.fc-button-primary ${stylesheetViewButtons}
    .fc-resourceTimelineWeek-button.fc-button.fc-button-primary ${stylesheetViewButtons}
    .fc-resourceTimelineMonth-button.fc-button.fc-button-primary ${stylesheetViewButtons}
    .fc-prev-button.fc-button.fc-button-primary ${stylesheetArrowButtons}
    .fc-next-button.fc-button.fc-button-primary ${stylesheetArrowButtons}
    :root {
      --fc-button-active-bg-color: ${buttonSelectedBgColor};
    }
  `;
}
