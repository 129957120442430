import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  Grid,
  Tooltip,
  DialogTitle,
  IconButton,
  DialogContent,
} from "@mui/material";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import TocIcon from "@mui/icons-material/Toc";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import ExportExcel from "@features/common/ExportExcel";

/**
 * A dialog component that displays a full-screen chart or table.
 */

interface FullScreenChartDialogProps {
  title: string;
  open: boolean;
  handleClose: () => void;
  chart: React.ReactNode;
  table?: React.ReactNode;
  isTableAvailable?: boolean;
  excelData?: any;
  excelDataKeys?: string[];
}

const FullScreenChartDialog = (props: FullScreenChartDialogProps) => {
  const {
    title,
    open,
    handleClose,
    chart,
    table,
    isTableAvailable,
    excelData,
  } = props;
  const { t } = useTranslation();
  const [displayMode, setDisplayMode] = useState<string>("chart"); // "chart" | "table"

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={open}
        onClose={handleClose}
      >
        <Grid container alignItems={"center"}>
          <Grid item>
            <DialogTitle id="dialog-title" variant="h5">
              {title}
            </DialogTitle>
          </Grid>
          <Grid item xs justifyContent={"flex-start"}>
            <Tooltip title={t("label-display-chart")}>
              <IconButton
                sx={{ marginRight: "10px" }}
                onClick={() => setDisplayMode("chart")}
              >
                <EqualizerIcon />
              </IconButton>
            </Tooltip>
            {isTableAvailable && (
              <Tooltip title={t("label-display-table")}>
                <IconButton onClick={() => setDisplayMode("table")}>
                  <TocIcon />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
          <Grid item justifyContent={"flex-end"} marginRight={1}>
            {excelData && (
              <ExportExcel
                data={excelData}
                fileName={title}
                dataKeys={props.excelDataKeys}
              />
            )}
            <IconButton onClick={handleClose} sx={{ marginLeft: "10px" }}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          <Box sx={{ height: "70vh", margin: "1vh" }}>
            {displayMode === "chart" ? (
              chart
            ) : displayMode === "table" ? (
              table
            ) : (
              <></>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FullScreenChartDialog;
