import { Grid, Typography } from "@mui/material";
import FormNumberField from "./FormNumberField";

interface RecurrenceProps {
  isEditing?: boolean;
  control: any;
}

export const MonthlyRecurrenceSelector = (props: RecurrenceProps) => {
  const { isEditing, control } = props;

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <Typography>Recur day</Typography>
      </Grid>
      <Grid item>
        <FormNumberField
          name="day_number"
          control={control}
          label={""}
          readOnly={!isEditing}
        />
      </Grid>
      <Grid item>
        <Typography>of every</Typography>
      </Grid>
      <Grid item>
        <FormNumberField
          name="frequency"
          control={control}
          label={""}
          readOnly={!isEditing}
        />
      </Grid>
      <Grid item>
        <Typography>month(s)</Typography>
      </Grid>
    </Grid>
  );
};
