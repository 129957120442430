import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl, FormLabel, Grid } from "@mui/material";
import { toast } from "react-toastify";

import { RootState } from "@app/store";
import { useAppDispatch } from "@features/common/StoreHook";
import { formControlStyle, formLabelStyle } from "@features/common/styles";
import FormTextField from "@features/common/FormFields/FormTextField";
import FormTextArea from "@features/common/FormFields/FormTextArea";
import EditButtonGroup from "@features/common/ButtonGroups/EditButtonGroup";
import {
  getCollectionById,
  selectCollectionsStatus,
  updateCollection,
} from "./collectionsSlice";
import CollectionValues from "./CollectionValues";

const validationSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  description: yup.string().nullable(),
});

interface CollectionEditProps {
  collectionId: string;
}

const CollectionEdit = (props: CollectionEditProps) => {
  const { collectionId } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const collection = useSelector((state: RootState) =>
    getCollectionById(state, parseInt(collectionId)),
  );
  const isSubmitting = useSelector(selectCollectionsStatus) === "submitting";

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      id: collection?.id,
      name: collection?.name,
      description: collection?.description,
    },
  });

  const handleUpdate = (formData: any) => {
    dispatch(updateCollection(formData))
      .unwrap()
      .then(() => {
        setIsEditing(false);
        toast.success("Collection updated successfully");
      })
      .catch(() => {
        toast.error("Failed to update collection");
      });
  };

  const handleCancel = () => {
    reset({
      id: collection?.id,
      name: collection?.name,
      description: collection?.description,
    });
    setIsEditing(false);
  };

  return (
    <>
      <FormControl component="fieldset" sx={formControlStyle}>
        <FormLabel component="legend" sx={formLabelStyle}>
          {t("label-collection")}
        </FormLabel>
        <Grid container spacing={2}>
          <Grid container item xs={12} sm={6} spacing={2}>
            <Grid item xs={12}>
              <FormTextField
                name="id"
                control={control}
                label={t("label-collection-id")}
                readOnly={true}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name="name"
                control={control}
                label={t("label-collection-name")}
                readOnly={!isEditing || isSubmitting}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextArea
              name="description"
              control={control}
              label={t("label-collection-description")}
              readOnly={!isEditing || isSubmitting}
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <EditButtonGroup
              disabled={isSubmitting}
              isEditing={isEditing}
              handleEdit={() => setIsEditing(true)}
              handleCancel={handleCancel}
              handleSave={handleSubmit(handleUpdate)}
            />
          </Grid>
          <Grid item xs={12}>
            {collection && (
              <CollectionValues
                collectionMembers={collection?.members}
                collectionId={collection?.id}
              />
            )}
          </Grid>
        </Grid>
      </FormControl>
    </>
  );
};

export default CollectionEdit;
