import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { useTranslation } from "react-i18next";

import { formatToNDecimals, chartColors } from "../utils";

interface StampsStatus {
  ok: number;
  nok: number;
}

interface StampsStatusChartProps {
  stampLoopStatus: StampsStatus | null;
}

const StampsStatusChart = (props: StampsStatusChartProps) => {
  const { stampLoopStatus } = props;
  const { t } = useTranslation();
  const legend = ["label-run-successful", "label-run-unsuccessful"];
  const colors = [chartColors.green, chartColors.red];

  if (!stampLoopStatus) {
    return <></>;
  }

  const stampStatusData = [
    {
      label: t("label-run-successful"),
      name: "ok",
      value: stampLoopStatus?.ok || 0,
    },
    {
      label: t("label-run-unsuccessful"),
      name: "nok",
      value: stampLoopStatus?.nok || 0,
    },
  ];

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={400} height={300}>
          <Legend
            wrapperStyle={{ fontSize: "90%" }}
            payload={legend.map((translationKey: string, index: number) => ({
              id: t(translationKey),
              value: t(translationKey),
              color: colors[index],
            }))}
          />
          <Pie
            data={stampStatusData}
            dataKey="value"
            cx="50%"
            cy="50%"
            fill="#82ca9d"
            label={true}
            isAnimationActive={false}
          >
            {stampStatusData.map((entry, index) => (
              <Cell key={entry.value} name={entry.label} fill={colors[index]} />
            ))}
          </Pie>
          <Tooltip cursor={{ fill: "#ebf4fb" }} formatter={formatToNDecimals} />
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};

export default StampsStatusChart;
