import { useTranslation } from "react-i18next";
import { Button, Grid, useTheme } from "@mui/material";

interface IProps {
  disabled: boolean;
  confirmButtonLabel: string;
  handleConfirm?: () => void;
  handleCancel?: () => void;
}

/**
 * Button group for rendering add/delete and cancel buttons in both cases of
 * adding and deleting roles in the ModifyRolesDialog.
 */

const ConfirmCancelButtonGroup = (props: IProps) => {
  const { disabled, confirmButtonLabel, handleCancel, handleConfirm } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid container direction="row" justifyContent="flex-end" gap={1}>
      <Button
        variant="contained"
        sx={{
          backgroundColor: theme.palette.secondary.dark,
        }}
        onClick={handleConfirm}
        disabled={disabled}
      >
        {t(confirmButtonLabel)}
      </Button>
      <Button
        variant="outlined"
        disabled={disabled || !handleCancel}
        onClick={handleCancel}
        sx={
          theme.palette.mode === "dark"
            ? {
                backgroundColor: theme.palette.secondary.light,
              }
            : undefined
        }
      >
        {t("button_form_cancel")}
      </Button>
    </Grid>
  );
};

export default ConfirmCancelButtonGroup;
