import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseQuery } from "@features/common/apiUtils";

/**
 * This is the base API setup for using RTK Query with OIVA API.
 * Endpoints are injected through feature specific API files,
 * and queries should be imported to components from those files.
 */

export const oivaApi = createApi({
  reducerPath: "oivaApi",
  baseQuery: createBaseQuery(process.env.NODE_ENV === "test" ? false : true),
  // endpoints are injected through feature specific api files
  endpoints: () => ({}),
});
