import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FormControl, FormLabel, Grid, Typography } from "@mui/material";

import { RootState } from "@app/store";
import { getAllVMs, fetchVMs } from "@features/environments/environmentsSlice";
import { getSelectedCustomerId } from "@features/customers/customerSlice";
import { useAppDispatch } from "@features/common/StoreHook";
import { IVirtualMachine } from "@features/environments/types";
import { formLabelStyle, formControlStyle } from "@features/common/styles";
import EditButtonGroup from "@features/common/ButtonGroups/EditButtonGroup";
import { permissionsApi } from "@features/authorization/authService";
import { checkPermissions } from "@features/authorization/types";
import { ITask } from "../types";
import { getSelectedTask, updateTask } from "../tasksSlice";
import { LeftRightSelector } from "./LeftRightSelector";

/**
 *
 */

// leftGlobal and rightGlobal is the "upper" level components state, i.e. total number of virtual machines in left or right side
// passed down to this component to manage state when state is submitted to API

export const Environments = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const customerId = useSelector(getSelectedCustomerId);
  const virtualmachines = useSelector((state: RootState) => getAllVMs(state));
  const taskToUpdate: ITask | undefined = useSelector((state: RootState) =>
    getSelectedTask(state),
  );
  const [leftListFull, setLeftListFull] = useState<string[]>([]);
  const [rightListFull, setRightListFull] = useState<string[]>([]);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { data, error, isLoading } =
    permissionsApi.useGetCustomerPermissionsQuery(customerId);
  const taskPermissions = checkPermissions("tasks", data);

  useEffect(() => {
    if (virtualmachines) {
      setLeftListFull([]);
      setRightListFull([]);

      const vmNamesLeft: string[] = virtualmachines
        .map((vm: IVirtualMachine) => {
          if (taskToUpdate?.vm_names.includes(vm.name)) {
            return vm.name;
          } else return "";
        })
        .filter((str) => str !== "");

      const vmNamesRight: string[] = virtualmachines
        .map((vm: IVirtualMachine) => vm.name)
        .filter((str) => str !== "" && !taskToUpdate?.vm_names.includes(str));

      setRightListFull(vmNamesRight);
      setLeftListFull(vmNamesLeft);
    }
  }, [virtualmachines]);

  useEffect(() => {
    dispatch(fetchVMs(customerId));
  }, []);

  useEffect(() => {
    if (taskToUpdate) {
      setSubmitDisabled(false);
    } else setSubmitDisabled(true);
  }, [taskToUpdate]);

  const handleSubmitSelected = () => {
    if (taskPermissions.update) {
      if (taskToUpdate) {
        const taskToUpdateCopy: ITask = {
          ...taskToUpdate,
          vm_names: leftListFull,
        };

        dispatch(updateTask(taskToUpdateCopy))
          .unwrap()
          .then(() => {
            setIsEditing(false);
            toast.success("Task environments updated successfully");
          })
          .catch(() => {
            toast.error("Failed to update task environments");
          });
      }
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    const vmNamesLeft: string[] = virtualmachines
      .map((vm: IVirtualMachine) =>
        taskToUpdate?.vm_names.includes(vm.name) ? vm.name : "",
      )
      .filter((str) => str !== "");
    const vmNamesRight: string[] = virtualmachines
      .map((vm: IVirtualMachine) => vm.name)
      .filter((str) => str !== "" && !taskToUpdate?.vm_names.includes(str));
    setRightListFull(vmNamesRight);
    setLeftListFull(vmNamesLeft);
  };

  return (
    <FormControl component="fieldset" style={formControlStyle}>
      <FormLabel component="legend" sx={formLabelStyle}>
        {t("title-environments")}
      </FormLabel>
      <Grid container spacing={{ xs: 3, sm: 0 }}>
        <Grid item xs={12}>
          <Typography variant={"h6"}>Production</Typography>
          <LeftRightSelector
            vmCategory="Production"
            leftGlobal={leftListFull}
            rightGlobal={rightListFull}
            virtualmachines={virtualmachines}
            setleftGlobal={setLeftListFull}
            setrightGlobal={setRightListFull}
            isEditing={isEditing}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"h6"}>Development</Typography>
          <LeftRightSelector
            vmCategory="Development"
            leftGlobal={leftListFull}
            rightGlobal={rightListFull}
            virtualmachines={virtualmachines}
            setleftGlobal={setLeftListFull}
            setrightGlobal={setRightListFull}
            isEditing={isEditing}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"h6"}>Onsite</Typography>
          <LeftRightSelector
            vmCategory="Onsite"
            leftGlobal={leftListFull}
            rightGlobal={rightListFull}
            virtualmachines={virtualmachines}
            setleftGlobal={setLeftListFull}
            setrightGlobal={setRightListFull}
            isEditing={isEditing}
          />
        </Grid>
        <Grid item xs={12}>
          <EditButtonGroup
            disabled={submitDisabled}
            isEditing={isEditing}
            handleEdit={() => setIsEditing(!isEditing)}
            handleSave={handleSubmitSelected}
            handleCancel={handleCancel}
          />
        </Grid>
      </Grid>
    </FormControl>
  );
};
