import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "@app/store";
import { getAllSubnetsRequest } from "./subnetsApiService";
import { ISubnet } from "../types";

const subnetsAdapter = createEntityAdapter<ISubnet>({
  selectId: (item) => item.subnet_name,
});

const initialState = subnetsAdapter.getInitialState({
  status: "",
});

const subnetsSlice = createSlice({
  name: "subnets",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubnets.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchSubnets.fulfilled,
        (state, { payload }: PayloadAction<ISubnet[]>) => {
          subnetsAdapter.setAll(state, payload);
          state.status = "";
        },
      )
      .addCase(fetchSubnets.rejected, (state) => {
        state.status = "";
      });
  },
});

export const fetchSubnets = createAsyncThunk(
  "subnets/fetchSubnets",
  async (customerId: string) => {
    const res = await getAllSubnetsRequest(customerId);
    return res.data;
  },
);

export const {} = subnetsSlice.actions;
export default subnetsSlice.reducer;

const subnetSelector = subnetsAdapter.getSelectors(
  (state: RootState) => state.subnets,
);

export const getAllSubnets = (state: RootState) =>
  subnetSelector.selectAll(state);
export const selectSubnetStatus = (state: RootState) => state.subnets.status;

export const selectSubnetByName = (
  state: RootState,
  subnetName: string | undefined,
) => {
  return subnetName ? subnetSelector.selectById(state, subnetName) : undefined;
};
