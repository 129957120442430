import { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import { FormControl, FormLabel, TextField, Grid } from "@mui/material";
import { toast } from "react-toastify";

import { RootState } from "@app/store";
import { formControlStyle, formLabelStyle } from "@features/common/styles";
import { useAppDispatch } from "@features/common/StoreHook";
import { getSelectedCustomerId } from "@features/customers/customerSlice";
import EditButtonGroup from "@features/common/ButtonGroups/EditButtonGroup";
import { permissionsApi } from "@features/authorization/authService";
import { checkPermissions } from "@features/authorization/types";

import { IRole, IUser } from "../types";
import { getAllRoles } from "./rolesSlice";
import {
  addUserRole,
  deleteUserRole,
  AddUserRoleData,
  DeleteUserRoleData,
} from "./userSlice";
import { getSelectedUser } from "./userSlice";
import AddDeleteRoleButtonGroup from "./AddDeleteRoleButtonGroup";
import { ModifyRolesDialog } from "./ModifyRolesDialog";

/**
 *
 * Window that allows for adding and removing roles from User selected from table
 * Child components are the actual dialogs that show the role options (Add & Delete dialog with respectively used & not used roles)
 *
 * one state manager sets initial role_id to -1, this is an assumption that actual role_ids cant be -1
 * as such there is a check that if role_id of selected role is -1, the "submit" buttons dont do anything
 *
 */

export const UserEditor = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const customerId: string = useSelector(getSelectedCustomerId);
  const selectedUser: IUser | undefined = useSelector(getSelectedUser);
  const roles: IRole[] = useSelector(
    (state: RootState) => getAllRoles(state),
    shallowEqual,
  );

  const {
    data,
    error,
    isLoading: sLoadingPermission,
  } = permissionsApi.useGetCustomerPermissionsQuery(customerId);
  const userPermissions = checkPermissions("users", data);
  const rolePermissions = checkPermissions("roles", data);

  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [roleModdedUser, setRoleModdedUser] = useState<IUser>(); // this is when role ids are translated to names and this is the resulting user object
  const [notUsedRoles, setNotUsedRoles] = useState<IRole[]>([]); // this is for the dialog when roles can be added to user, to keep track that already used roles arent re-added
  const [usedRoles, setusedRoles] = useState<IRole[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [addedRole, setAddRole] = useState<string>("");
  const [deletedRole, setDeleteRole] = useState<string>("");

  const extractTextRoles = (rolesArray: string[]) => {
    let result: string[] = [];
    rolesArray.forEach((roleNum: string) => {
      if (roles) {
        roles.forEach((roleObj) => {
          //@ts-ignore
          if (roleNum == roleObj.role_id) {
            // roleNum -> string, roleobj.role_id -> number. this comparison is intentional (although some cast could be used before to avoid the @ts ignore)
            result.push(roleObj.role_name);
          }
        });
      }
    });
    return result;
  };

  const onAddDialogCancel = () => {
    setAddRole("");
    setAddDialogOpen(false);
  };
  const onDeleteDialogCancel = () => {
    setDeleteRole("");
    setDeleteDialogOpen(false);
  };

  const handleModificationAdd = (data: string) => {
    setAddRole(data);
  };

  const handleModificationDelete = (data: string) => {
    setDeleteRole(data);
  };

  const submitAddRole = () => {
    if (selectedUser && addedRole) {
      const roleToAdd = roles.find(
        (role) => role.role_id === parseInt(addedRole),
      );
      if (roleToAdd) {
        const data: AddUserRoleData = {
          customerId: customerId,
          userId: selectedUser.id,
          payload: roleToAdd,
        };

        dispatch(addUserRole(data))
          .unwrap()
          .then(() => {
            toast.success("Role added successfully");
          })
          .catch(() => {
            toast.error("Failed to add role");
          });
        onAddDialogCancel();
      }
    }
  };

  const handleAdd = () => {
    rolePermissions.create
      ? setAddDialogOpen(true)
      : toast.error("No create permission");
  };

  const handleDelete = () => {
    rolePermissions.delete
      ? setDeleteDialogOpen(true)
      : toast.error("No delete permission");
  };

  const submitDeleteRole = () => {
    if (selectedUser && deletedRole) {
      const data: DeleteUserRoleData = {
        customerId: customerId,
        userId: selectedUser.id,
        roleId: parseInt(deletedRole),
      };

      dispatch(deleteUserRole(data))
        .unwrap()
        .then(() => {
          toast.success("Role deleted successfully");
        })
        .catch(() => {
          toast.error("Failed to delete role");
        });
      onDeleteDialogCancel();
    }
  };

  // user.roles = array of numbers, which map to role strings. This function converts the mapping to the UI.
  useEffect(() => {
    if (selectedUser && roles) {
      const newRoles = extractTextRoles(selectedUser.roles);
      let rolesNotInUse: IRole[] = [];
      let rolesInUse: IRole[] = [];
      roles.forEach((role) => {
        // keep track of which roles are in use for current user and which arent
        // so we know which we can add and delete
        if (selectedUser.roles.includes(role.role_id.toString())) {
          rolesInUse.push(role);
        } else {
          rolesNotInUse.push(role);
        }
      });
      setNotUsedRoles(rolesNotInUse);
      setusedRoles(rolesInUse);
      const newUser: IUser = {
        ...selectedUser,
        roles: newRoles,
      };
      setRoleModdedUser(newUser);
    }
  }, [selectedUser, roles]);

  const handleCancel = () => {
    setIsEditing(false);
  };

  const mappedNotUsedRoles = notUsedRoles.map((role: any) => ({
    id: role.role_id,
    value: role.role_id.toString(),
    label: role.role_name,
  }));
  const mappedUsedRoles = usedRoles.map((role: any) => ({
    id: role.role_id,
    value: role.role_id.toString(),
    label: role.role_name,
  }));

  return (
    <>
      <FormControl component="fieldset" sx={formControlStyle}>
        <FormLabel component="legend" sx={formLabelStyle}>
          {t("label-user-information")}
        </FormLabel>
        <Grid container spacing={2}>
          <Grid
            container
            item
            spacing={2}
            xs={12}
            sm={6}
            direction={"column"}
            justifyContent={"center"}
          >
            <Grid item xs={4} sm={2}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t("label-user-name")}
                value={roleModdedUser?.name || ""}
                inputProps={{ readOnly: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={4} sm={2}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t("label-email")}
                value={roleModdedUser?.email || ""}
                inputProps={{ readOnly: !isEditing }}
                fullWidth
              />
            </Grid>
            <Grid item xs={4} sm={2}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t("label-type")}
                value={roleModdedUser?.type || ""}
                inputProps={{ readOnly: true }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset" sx={formControlStyle}>
              <FormLabel component="legend" sx={formLabelStyle}>
                {t("label-rolegroups")}
              </FormLabel>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t("label-role")}
                value={roleModdedUser?.roles.join(", ") || ""}
                inputProps={{ readOnly: !isEditing }}
                fullWidth
                multiline
                rows={4}
              />
              <AddDeleteRoleButtonGroup
                disabled={false}
                handleAdd={handleAdd}
                handleDelete={handleDelete}
              />
            </FormControl>
          </Grid>
          <EditButtonGroup
            isEditing={isEditing}
            disabled={true}
            handleEdit={() => setIsEditing(true)}
            handleCancel={handleCancel}
          />
        </Grid>

        <ModifyRolesDialog
          dialogOpen={addDialogOpen}
          onCancel={onAddDialogCancel}
          data={mappedNotUsedRoles}
          title="label-add-role"
          setParentRoles={handleModificationAdd}
          onSubmit={submitAddRole}
          selectedRole={addedRole}
        />
        <ModifyRolesDialog
          dialogOpen={deleteDialogOpen}
          onCancel={onDeleteDialogCancel}
          data={mappedUsedRoles}
          title="label-delete-role"
          setParentRoles={handleModificationDelete}
          onSubmit={submitDeleteRole}
          selectedRole={deletedRole}
        />
      </FormControl>
    </>
  );
};
