import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAppDispatch } from "@features/common/StoreHook";
import { FormControl, FormLabel, Grid } from "@mui/material";
import { toast } from "react-toastify";

import { RootState } from "@app/store";
import { formControlStyle, formLabelStyle } from "@features/common/styles";
import FormTextField from "@features/common/FormFields/FormTextField";
import FormTextArea from "@features/common/FormFields/FormTextArea";
import EditButtonGroup from "@features/common/ButtonGroups/EditButtonGroup";
import {
  selectTranslationById,
  selectTranslationsStatus,
  updateTranslation,
} from "./translationsSlice";
import { Translation } from "./types";

interface TextEditProps {
  selectedId: string;
}

const validationSchema = yup.object({
  text_id: yup.string().required(),
  value_en: yup.string(),
  value_fi: yup.string(),
});

const TextEdit = ({ selectedId }: TextEditProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const translation = useSelector((state: RootState) =>
    selectTranslationById(state, selectedId),
  );
  const isSubmitting = useSelector(selectTranslationsStatus) === "submitting";

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      text_id: selectedId,
      value_en:
        translation?.values.find((value) => value.language_code === "en-US")
          ?.value || "",
      value_fi:
        translation?.values.find((value) => value.language_code === "fi-FI")
          ?.value || "",
    },
  });

  const handleUpdate = (data: any) => {
    const translationData: Translation = {
      text_id: data.text_id,
      values: [
        { language_code: "en-US", value: data.value_en || "" },
        { language_code: "fi-FI", value: data.value_fi || "" },
      ],
    };
    dispatch(updateTranslation(translationData))
      .unwrap()
      .then(() => {
        setIsEditing(false);
        toast.success(t("Translation updated successfully"));
      })
      .catch(() => {
        toast.error(t("Failed to update translation"));
      });
  };

  const handleCancel = () => {
    setIsEditing(false);
    reset({
      text_id: selectedId,
      value_en:
        translation?.values.find((value) => value.language_code === "en-US")
          ?.value || "",
      value_fi:
        translation?.values.find((value) => value.language_code === "fi-FI")
          ?.value || "",
    });
  };

  return (
    <>
      <FormControl component="fieldset" sx={formControlStyle}>
        <FormLabel component="legend" sx={formLabelStyle}>
          {t("label-text")}
        </FormLabel>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormTextField
              name="text_id"
              control={control}
              label={t("label-text-id")}
              readOnly={true}
            />
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormTextArea
                name="value_en"
                control={control}
                label={t("label-text-value-en")}
                readOnly={!isEditing}
                rows={4}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextArea
                name="value_fi"
                control={control}
                label={t("label-text-value-fi")}
                readOnly={!isEditing}
                rows={4}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <EditButtonGroup
              disabled={isSubmitting}
              isEditing={isEditing}
              handleEdit={() => setIsEditing(true)}
              handleCancel={handleCancel}
              handleSave={handleSubmit(handleUpdate)}
            />
          </Grid>
        </Grid>
      </FormControl>
    </>
  );
};

export default TextEdit;
