/**
 * This module contains commonly recurring constants that can be easily imported
 * when they are needed in the context of unit tests.
 *
 * Note that you may override these variables in some other file if you wish.
 */

export const customerId = "df108a09-d381-4818-b770-51850b2bd5cd";
export const taskName = "test task";
export const customerName = "Alexandra Oy";
export const taskId = "aaaa-bb-cc-dddd";
export const expectedUnitTestError =
  "This bad request was purposefully triggered by MSW during unit test.";

export const roles = [
  {
    role_name: "Administrator",
    role_id: 219,
  },
  {
    role_name: "Coordinator",
    role_id: 221,
  },
  {
    role_name: "CustomerUser",
    role_id: 222,
  },
  {
    role_name: "DashboardUser",
    role_id: 223,
  },
  {
    role_name: "DataReader",
    role_id: 224,
  },
  {
    role_name: "DataWriter",
    role_id: 225,
  },
  {
    role_name: "Developer",
    role_id: 220,
  },
];

/**
 * Useful when testing a component that assumes customerSlice to have content upon rendering.
 * Can be passed in the `preloadedState` argument of renderWithProviders().
 *
 * PLEASE do not alter the values. If you need to modify them, create a separate <component>TestUtils.ts
 * file for your test.
 */
export const stateCustomer = {
  selectedCustomer: customerId,
  status: "",
  ids: [customerId],
  entities: {
    "df108a09-d381-4818-b770-51850b2bd5cd": {
      recording_allowed: false,
      vpn_used: false,
      customer_logo_url: "/static/media/MostLogo.03f1fdc36b39b69b4d31.png",
      tech_customer_name: "AlexandraOy",
      resourcegroup_name: "AlexandraOy-resourcegroup",
      specific_vpn_device_in_use: false,
      short_customer_name: "Alexandr",
      vnet_name: "AlexandraOy-vnet",
      on_site_implementation: false,
      customer_description: "test desc",
      short_lowercase_customer_name: "alexandr",
      vnet_address_space: "10.1.7.0/24",
      teams_site_url: "",
      customer_additional_information: "",
      data_storage_account: "alexandr9915datastorage",
      prod_vm_count: 1,
      keyvault_prod_base_url: "https://alexandr-prod-keyvault.vault.azure.net/",
      business_data_collection_used: true,
      name: "Alexandra Oy",
      diagnostics_storage_account: "alexandr2179diagnosticst",
      test_vm_count: 0,
      keyvault_test_base_url: "https://alexandr-test-keyvault.vault.azure.net/",
      business_data_database_server_name:
        "alexandra-business-data-sql-server.database.windows.net",
      id: customerId,
      screenshots_allowed: false,
      jbox_vm_count: 0,
      is_deleted: false,
      business_data_database_name: "Alexandra-Business-Data",
      lowercase_name: "alexandraoy",
      privx_used: true,
      business_data_queue_storage_account: "bidatawriterprodfunc774",
    },
  },
};

export const stateRoles = {
  selectedRole: "",
  status: "",
  ids: [219, 220, 221, 222, 223, 224, 225],
  entities: {
    219: {
      role_name: "Administrator",
      role_id: 219,
    },
    220: {
      role_name: "Developer",
      role_id: 220,
    },
    221: {
      role_name: "Coordinator",
      role_id: 221,
    },
    222: {
      role_name: "CustomerUser",
      role_id: 222,
    },
    223: {
      role_name: "DashboardUser",
      role_id: 223,
    },
    224: {
      role_name: "DataReader",
      role_id: 224,
    },
    225: {
      role_name: "DataWriter",
      role_id: 225,
    },
  },
};

export const stateTasksExist = {
  selectedTask: taskId,
  status: "",
  ids: [taskId],
  entities: {
    "aaaa-bb-cc-dddd": {
      customer_guid: customerId,
      task_guid: taskId,
      task_name: taskName,
      task_description: "my desc",
      task_type: "",
      screenshots_allowed: true,
      recording_allowed: true,
      vm_names: ["Alexandra-Prod01"],
      priority: 1,
      start_script_path: "my string path",
      technical_contact_email: "foo@email.com",
      business_contact_email: "foo@email.com",
      customer_email: "foo@email.com",
      customer_error_email: "foo@email.com",
      task_logo_url: "wow@photo.com",
      o365_most_username: "",
      o365_most_server: "",
      o365_most_port: "",
      o365_most_password: "",
      o365_customer_username: "",
      o365_customer_server: "",
      o365_customer_port: "",
      o365_customer_password: "",
      keyvault_prod_base_url: "",
      keyvault_test_base_url: "",
      on_site_implementation: false,
    },
  },
};

export const stateTasksNoSelection = {
  selectedTask: "",
  status: "",
  ids: [taskId],
  entities: {
    "aaaa-bb-cc-dddd": {
      customer_guid: customerId,
      task_guid: taskId,
      task_name: taskName,
      task_description: "my desc",
      task_type: "",
      screenshots_allowed: true,
      recording_allowed: true,
      vm_names: ["Alexandra-Prod01"],
      priority: 1,
      start_script_path: "my string path",
      technical_contact_email: "foo@email.com",
      business_contact_email: "foo@email.com",
      customer_email: "foo@email.com",
      customer_error_email: "foo@email.com",
      task_logo_url: "wow@photo.com",
      o365_most_username: "",
      o365_most_server: "",
      o365_most_port: "",
      o365_most_password: "",
      o365_customer_username: "",
      o365_customer_server: "",
      o365_customer_port: "",
      o365_customer_password: "",
      keyvault_prod_base_url: "",
      keyvault_test_base_url: "",
      on_site_implementation: false,
    },
  },
};

export const stateSchedules = {
  selectedSchedule: "dddd-ee-ff-gggg",
  status: "",
  ids: ["dddd-ee-ff-gggg", "xxxx-yy-zz-qqqq"],
  entities: {
    "dddd-ee-ff-gggg": {
      task_name: taskName,
      schedule_guid: "dddd-ee-ff-gggg",
      customer_guid: customerId,
      task_guid: taskId,
      start_datetime: new Date("2023-03-17T17:54:00"),
      end_datetime: new Date("2023-03-17T17:54:00"),
      daily_recurrence_guid: "a5dbb73c-0496-4308-960f-9bf60e8c6d19",
      weekly_recurrence_guid: null,
      monthly_recurrence_guid: null,
      next_run: undefined,
      event_created_for_next_run: false,
      is_activated: true,
      database_timestamp: undefined,
      start_time_utc: new Date("2023-03-17T15:54:00+00:00"),
      end_time_utc: new Date("2023-03-17T15:54:00+00:00"),
      next_run_utc: new Date("2024-12-06T15:54:00+00:00"),
      database_timestamp_utc: new Date("2024-11-06T15:55:44.461465+00:00"),
      start_window_in_seconds: 0,
      target_vm: "Alexandr-Prod03",
      cron_recurrence_guid: null,
      timezone: "Europe/Helsinki",
      daily_recurrence: { frequency: 1 },
      weekly_recurrence: null,
      monthly_recurrence: null,
      cron_recurrence: null,
    },
  },
};
