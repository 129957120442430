import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SelectChangeEvent } from "@mui/material/Select";
import { Box } from "@mui/material";
import { Typography, Grid } from "@mui/material";

import ChartSetSelect from "../ChartSetSelect";
import VmUtilizationChart from "./VmUtilizationChart";
import { generateUtilizationData } from "./vmUtilizationUtils";
import ChartWrapper from "../ChartWrapper";
import VmUtilizationTable from "./VmUtilizationTable";
import { TimelineEvent } from "../../types";

interface VmUtilizationProps {
  events: TimelineEvent[];
  selectedVm: string;
  startDate: Date;
  endDate: Date;
  title: string;
}

/**
 * Pre-processes the VM utilization data and renders the chart
 */

const VmUtilization = (props: VmUtilizationProps) => {
  const { t } = useTranslation();
  const { events, selectedVm, startDate, endDate, title } = props;
  const legendUsedTime = [
    "label-unused-time",
    "label-reserved-time",
    "label-executed-time",
    "label-overtime",
  ];
  const legendScheduledTime = ["label-unused-time", "label-scheduled-time"];
  const setOptions = [
    { label: "label-used-time", value: "used" },
    { label: "label-scheduled-time", value: "scheduled" },
  ];
  const [option, setOption] = useState("used");
  const [legend, setLegend] = useState<string[]>(legendUsedTime);
  const [dataSet, setDataSet] = useState<any>(
    generateUtilizationData(option, selectedVm, events, startDate, endDate),
  );

  useEffect(() => {
    setDataSet(
      generateUtilizationData(option, selectedVm, events, startDate, endDate),
    );
  }, [selectedVm, startDate, endDate]);

  const handleSetChange = (event: SelectChangeEvent<string>) => {
    const inputOption = event.target.value;
    if (inputOption === "used") {
      setDataSet(
        generateUtilizationData("used", selectedVm, events, startDate, endDate),
      );
      setLegend(legendUsedTime);
    } else if (inputOption === "scheduled") {
      setDataSet(
        generateUtilizationData(
          "scheduled",
          selectedVm,
          events,
          startDate,
          endDate,
        ),
      );
      setLegend(legendScheduledTime);
    }
    setOption(inputOption);
  };

  const formatTimespan = (timespan: any) => {
    return `${timespan["start"].toLocaleDateString("fi-FI")} - ${timespan[
      "end"
    ].toLocaleDateString("fi-FI")}`;
  };

  return (
    <>
      <ChartWrapper
        title={title}
        chart={
          <>
            <ChartSetSelect
              style={{ marginTop: "10px", width: "40%" }}
              selectedItem={option}
              handleSelectItem={handleSetChange}
              selectItems={setOptions.map((option: any) => ({
                ...option,
                id: option.label,
                label: t(`${option.label}`),
              }))}
              textFieldLabel={t("label-view")}
            />
            <Box height="90%">
              <Box height="70%">
                <VmUtilizationChart data={dataSet} legend={legend} />
              </Box>
              <Grid
                container
                spacing={1}
                marginTop={1}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid item>
                  <Typography variant="button">
                    {t("label-virtual-machine")}:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{selectedVm}</Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="button">
                    {t("label-timespan")}:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    {formatTimespan({
                      start: new Date(startDate),
                      end: new Date(endDate),
                    })}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </>
        }
        table={
          <VmUtilizationTable
            data={dataSet}
            typeTranslations={
              option === "used" ? legendUsedTime : legendScheduledTime
            }
          />
        }
        isTableAvailable={true}
        excelData={dataSet}
      />
    </>
  );
};

export default VmUtilization;
