import { Theme } from "@mui/material";
import { createTheme } from "@mui/material/styles";

export const generalFontStack = [
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(",");
export const headingFontStack = generalFontStack;

export const baseTheme: Theme = createTheme({
  colorSchemes: {
    dark: {
      palette: {
        primary: {
          main: "#000000", // Darkest grey (header color)
          dark: "rgb(139 139 139)", // actually, this is LIGHT grey. Think of it as "highlighted" instead of "dark"
          light: "#272727", // Light grey shade
          contrastText: "#ffffff", // White
        },
        secondary: {
          main: "#4f4f4f", // Very light grey, used for e.g. selection highlighting
          dark: "#000000",
          light: "#757575", // Silhouette color for forms and input fields
          contrastText: "#000000",
        },
      },
    },
    light: {
      palette: {
        primary: {
          main: "#206FB7", // MOST Digital dark-ish blue (header color)
          dark: "rgb(47, 81, 138)", // Very dark blue
          light: "#fff", // White,
          contrastText: "#ffffff", // White
        },
        secondary: {
          main: "#e1f5fe", // Very light blue, used for e.g. selection highlighting
          dark: "#000000",
          light: "#757575", // Silhouette color for forms and input fields
          contrastText: "#000000",
        },
      },
    },
  },
  components: {
    // Fix for a bug where a '--Paper-overlay' component is added
    // which makes the header background color act strange
    // https://github.com/mui/material-ui/issues/43683#issuecomment-2492787970
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
  },
  // @ts-ignore
  typography: {
    fontFamily: generalFontStack,
    h1: {
      fontFamily: headingFontStack,
      fontSize: 42,
      fontStyle: "normal",
      fontWeight: 200,
    },
    h2: {
      fontFamily: headingFontStack,
      fontSize: 30,
      fontStyle: "normal",
      fontWeight: 400,
    },
    h3: {
      fontFamily: generalFontStack,
      fontSize: 20,
      fontStyle: "normal",
      fontWeight: 400,
    },
    h4: {
      fontFamily: generalFontStack,
      fontSize: 18,
      fontStyle: "normal",
      fontWeight: 400,
    },
  },
});
