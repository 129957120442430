import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  Box,
  Typography,
  DialogActions,
  CircularProgress,
  Alert,
} from "@mui/material";
import { buttonGroupContainerStyle } from "../../../common/ButtonGroups/styles";

/**
 * Dialog for confirming uninstallation of a VM application.
 */

interface Props {
  show: boolean;
  appName: string;
  onConfirm: () => void;
  onClose: () => void;
  loading: boolean;
  vmStatus?: string;
}

const ConfirmUninstallDialog = (props: Props) => {
  const { show, appName, onConfirm, onClose, loading, vmStatus } = props;
  const { t } = useTranslation();

  return (
    <>
      <Dialog open={show} onClose={onClose} maxWidth="md">
        <DialogTitle>
          <Box display="flex" alignItems="center">
            {t("label-uninstall-vm-app")}
          </Box>
        </DialogTitle>
        <DialogContent dividers sx={{ padding: "16px 32px" }}>
          {vmStatus?.includes("deallocated") && (
            <Alert severity="warning" sx={{ marginBottom: "10px" }}>
              Please check that VM is running before attempting to uninstall an
              app.
            </Alert>
          )}
          <Typography>
            {t("label-about-to-perform-action")}
            <span style={{ fontWeight: 700 }}>
              {" "}
              {t("label-uninstall-vm-app")}:
            </span>
          </Typography>
          <ul>
            <li>
              <Typography>{appName}</Typography>
            </li>
          </ul>
          <Typography marginTop={3}>
            {t("label-action-confirmation-are-you-sure")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Box sx={{ ...buttonGroupContainerStyle, marginTop: "8px" }}>
            <Button
              variant="contained"
              onClick={() => onConfirm()}
              color="error"
              disabled={loading}
              startIcon={loading && <CircularProgress size={24} />}
            >
              {t("label-button-uninstall")}
            </Button>
            <Button
              variant="outlined"
              onClick={onClose}
              disabled={loading}
              sx={{
                backgroundColor: "white",
                color: "secondary.contrastText",
                borderColor: "secondary.light",
              }}
            >
              {t("label-cancel")}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmUninstallDialog;
