import { oivaApi } from "@features/common/oivaApi";

/**
 * API query for fetching secrets from OIVA keyvault.
 * RTK Query is used for data fetching.
 */

export const oivaSecretsApi = oivaApi.injectEndpoints({
  endpoints: (builder) => ({
    getSecret: builder.query<string, string>({
      query: (secretName) => ({
        url: `/oiva_secrets/${secretName}`,
      }),
    }),
  }),
});

export const { useGetSecretQuery } = oivaSecretsApi;
