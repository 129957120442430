import { AxiosResponse } from "axios";
import { apiGET, apiPOST } from "@features/common/baseApiService";
import { IUser, IUserCreate, InternalUser } from "../types";

export async function getAllUsersRequest(
  customerId: string,
): Promise<AxiosResponse<IUser[]>> {
  const resource = `customers/${customerId}/users/`;
  const response = await apiGET<IUser[]>(resource);
  return response;
}

export async function getInternalUsersRequest(
  customerId: string,
): Promise<AxiosResponse<InternalUser[]>> {
  const resource = `customers/${customerId}/users/internal`;
  const response = await apiGET<InternalUser[]>(resource);
  return response;
}

// response now from testing is error because cant add user to AD group
// so hard to figure out what responsetype should be
// swagger schema says "string" but leaving it as any now
export async function postUserRequest(
  customerId: string,
  payload: IUserCreate,
): Promise<AxiosResponse<any[]>> {
  const resource = `customers/${customerId}/users`;
  const response = await apiPOST<IUserCreate, any>(resource, payload);
  return response;
}
