import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";

import { useAppDispatch } from "@features/common/StoreHook";
import FormTextField from "@features/common/FormFields/FormTextField";
import FormTextArea from "@features/common/FormFields/FormTextArea";
import SaveCancelButtonGroup from "@features/common/ButtonGroups/SaveCancelButtonGroup";
import {
  selectTranslationsStatus,
  createTranslation,
} from "./translationsSlice";

interface AddTextProps {
  show: boolean;
  handleCancel: () => void;
}

const validationSchema = yup.object({
  text_id: yup.string().required(),
  value_en: yup.string(),
  value_fi: yup.string(),
});

const AddText = (props: AddTextProps) => {
  const { show, handleCancel } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isSubmitting = useSelector(selectTranslationsStatus) === "submitting";

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      text_id: "",
      value_en: "",
      value_fi: "",
    },
  });

  const handleFormSubmit = (formData: any) => {
    const { text_id, value_en, value_fi } = formData;
    const payload = {
      text_id,
      values: [
        { language_code: "en-US", value: value_en || "" },
        { language_code: "fi-FI", value: value_fi || "" },
      ],
    };
    dispatch(createTranslation(payload))
      .unwrap()
      .then(() => {
        handleClose();
        toast.success("Translation created successfully");
      })
      .catch(() => {
        toast.error("Failed to create translation");
      });
  };

  const handleClose = () => {
    reset();
    handleCancel();
  };

  return (
    <>
      <Dialog open={show} maxWidth="sm" fullWidth>
        <DialogTitle>{t("title-add-new-text")}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ marginTop: "1px" }}>
            <Grid item xs={12}>
              <FormTextField
                name="text_id"
                control={control}
                label={t("label-text-id")}
                readOnly={isSubmitting}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextArea
                name="value_en"
                control={control}
                label={t("label-text-value-en")}
                readOnly={isSubmitting}
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextArea
                name="value_fi"
                control={control}
                label={t("label-text-value-fi")}
                readOnly={isSubmitting}
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <SaveCancelButtonGroup
                disabled={isSubmitting}
                handleCancel={handleClose}
                handleSave={handleSubmit(handleFormSubmit)}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddText;
