import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend, {
  HttpBackendOptions,
  RequestResponse,
} from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import apiConfig from "@configurations/apiConfig";
import { getAccessToken } from "@features/common/apiUtils";
import axios from "axios";

/**
 * Typing for backend callbacks, original RequestCallback type in HttpBackendOptions is
 * type RequestCallback = (error: any, response: RequestResponse) => void;
 * The type is missing option for response = null in the case of an error, hence the override here.
 *  */
type RequestCallback = (error: any, response: RequestResponse | null) => void;

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector) // detects user language, more info: https://github.com/i18next/i18next-browser-languageDetector
  .init({
    /** backend options */
    backend: {
      loadPath: `${apiConfig.url}/translations/{{lng}}`,
      crossDomain: true,
      /**
       * Custom request function for fetching translations from the loadPath URL with a Bearer token added to the request
       * More info on the parameters etc. https://github.com/i18next/i18next-http-backend
       */
      request: async (
        options: HttpBackendOptions,
        url: string,
        payload: string | {},
        callback: RequestCallback,
      ) => {
        try {
          const token = await getAccessToken();
          const res = await axios.get(url, {
            headers: { Authorization: `Bearer ${token}` },
          });
          callback(null, { status: 200, data: res.data });
        } catch (err: any) {
          callback(err, {
            status: err?.response?.request?.status || 500,
            data: "",
          });
        }
      },
    },
    /** i18next options */
    fallbackLng: "en-US",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    keySeparator: false, // we do not use keys in form messages.welcome
    react: {
      useSuspense: true, // suspenses until request to get translations either succeeds or fails
    },
  });

export default i18n;
