import { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  FormControl,
  FormLabel,
  Grid,
  TextField,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { RootState } from "@app/store";
import SortTable, { IProperty } from "@features/common/Table/SortTable";
import { useAppDispatch } from "@features/common/StoreHook";
import AddDeleteButtonGroup from "@features/common/ButtonGroups/AddDeleteButtonGroup";
import { formControlStyle, formLabelStyle } from "@features/common/styles";
import { ConfirmActionDialog } from "@features/common/ConfirmAction";
import {
  selectAllTranslations,
  selectTranslationsStatus,
  fetchTranslations,
  deleteTranslation,
} from "./translationsSlice";
import TextEdit from "./TextEdit";
import AddText from "./AddText";

/**
 * Text (translation) management component
 */

interface TableTextProperties {
  text_id: string;
  value_en: string;
  value_fi: string;
}

const Texts = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [selectedId, setSelectedId] = useState<string>("");
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");

  const translations = useSelector(
    (state: RootState) => selectAllTranslations(state),
    shallowEqual,
  );
  const isLoading = useSelector(selectTranslationsStatus) === "loading";

  useEffect(() => {
    dispatch(fetchTranslations());
  }, []);

  const handleDelete = () => {
    if (selectedId) {
      dispatch(deleteTranslation(selectedId))
        .unwrap()
        .then(() => {
          setSelectedId("");
          toast.success(t("Translation deleted successfully"));
        })
        .catch(() => {
          toast.error(t("Failed to delete translation"));
        });
    }
    setConfirmDialogOpen(false);
  };

  const properties: IProperty<TableTextProperties>[] = [
    { key: "text_id", label: t("label-text-id") },
    { key: "value_en", label: t("label-text-value-en") },
    { key: "value_fi", label: t("label-text-value-fi") },
  ];

  const filteredTranslations = !searchValue
    ? translations
    : translations.filter((translation) => {
        const searchVal = searchValue.trim().toLowerCase();
        return (
          translation.text_id.toLowerCase().includes(searchVal) ||
          translation.value_en.toLowerCase().includes(searchVal) ||
          translation.value_fi.toLowerCase().includes(searchVal)
        );
      });

  return (
    <>
      <FormControl component="fieldset" sx={formControlStyle}>
        <FormLabel component="legend" sx={formLabelStyle}>
          {t("label-texts")}
        </FormLabel>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          style={{ paddingBottom: "1rem" }}
        >
          <TextField
            id="search"
            label={t("label-search")}
            variant="outlined"
            size="small"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <SortTable
          properties={properties}
          idProperty="text_id"
          data={filteredTranslations}
          isLoading={isLoading}
          selectedId={selectedId}
          handleChange={(id: string) => setSelectedId(id)}
          maxHeight="70vh"
          pagination={true}
          rowCount={50}
        />
        <AddDeleteButtonGroup
          disabled={false}
          handleAdd={() => setAddDialogOpen(true)}
          handleDelete={
            selectedId ? () => setConfirmDialogOpen(true) : undefined
          }
        />
      </FormControl>
      {selectedId && <TextEdit key={selectedId} selectedId={selectedId} />}
      <AddText
        show={addDialogOpen}
        handleCancel={() => setAddDialogOpen(false)}
      />
      <ConfirmActionDialog
        show={confirmDialogOpen}
        action="DELETE"
        resource={selectedId}
        onConfirm={handleDelete}
        onCancel={() => setConfirmDialogOpen(false)}
      />
    </>
  );
};

export default Texts;
