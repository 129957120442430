import React from "react";
import { NavLink } from "react-router-dom";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { navigationLinkStyles } from "./style";
import { useTheme } from "@mui/material/styles";

interface INavigationLinkProps {
  to: string;
  label: string;
  hasPermission: boolean; // user permission to this link/route
}

/**
 * Link component for NavBar
 * @param props
 * @returns
 */
const NavigationLink = (props: INavigationLinkProps) => {
  const { to, label, hasPermission } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  return hasPermission ? (
    <NavLink
      to={to}
      style={({ isActive }) => ({
        textDecoration: "none",
        backgroundColor: isActive
          ? theme.palette.primary.dark
          : theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      })}
    >
      <Typography sx={navigationLinkStyles} variant="h3">
        {t(label)}
      </Typography>
    </NavLink>
  ) : null;
};

export default NavigationLink;
