import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Box, FormControl, FormLabel } from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  FormTextField,
  FormTextArea,
  FormSwitch,
} from "@features/common/FormFields";
import { formControlStyle, formLabelStyle } from "@features/common/styles";

import { switchStyle, resourceFieldStyle } from "../style";
import { IVirtualMachine } from "../types";
import ResourceControlButtonGroup from "./ResourceControlButtonGroup";
import EditButtonGroup from "../../common/ButtonGroups/EditButtonGroup";
import TypeSpecificInfo from "./TypeSpecificInfo";
import VMApplications from "./vmApplications/VMApplications";

interface IResourceContainerProps {
  resource?: IVirtualMachine;
}

/**
 * Form for displaying and editing resource's/vm's information.
 *
 * TODO:
 *  - Many of the buttons are placeholders at the moment and have been disabled until they can actually be used.
 * @param param0
 * @returns
 */

const validationSchema = yup.object({
  name: yup.string().required(),
  description: yup.string().nullable(),
  type: yup.string().required(),
  category: yup.string().required(),
  level: yup.string().required(),
  status: yup.string(),
  enable_shutdown: yup.boolean().nullable(),
});

const ResourceContainer = ({ resource }: IResourceContainerProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslation();
  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: resource?.name || "",
      description: resource?.description || "",
      type: resource?.type || "",
      status: resource?.status || "",
      category: resource?.category || "",
      level: resource?.level || "",
      enable_shutdown: resource?.enable_shutdown === "True" ? true : false,
    },
  });

  const handleCancel = () => {
    if (resource) {
      reset({
        ...resource,
        enable_shutdown: resource.enable_shutdown === "True" ? true : false,
      });
    }
    setIsEditing(false);
  };

  const handleSave = (data: any) => {
    console.log(data);
    setIsEditing(false);
    // TODO: handle submit logic (requires API endpoint)
  };

  return (
    <>
      {resource && (
        <Box>
          <FormControl component="fieldset" sx={formControlStyle}>
            <FormLabel component="legend" sx={formLabelStyle}>
              Virtual machine
            </FormLabel>
            <Grid container>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="name"
                    label={t("label-machine-name")}
                    readOnly={true}
                    control={control}
                    style={resourceFieldStyle}
                  />
                  <FormTextField
                    name="type"
                    label={t("label-type")}
                    readOnly={true}
                    control={control}
                    style={resourceFieldStyle}
                  />
                  <FormTextField
                    name="status"
                    label={t("label-status")}
                    readOnly={true}
                    control={control}
                    style={resourceFieldStyle}
                  />
                  <FormTextArea
                    name="description"
                    label={t("label-machine-description")}
                    readOnly={!isEditing}
                    control={control}
                    rows={4}
                    style={resourceFieldStyle}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TypeSpecificInfo readOnly={!isEditing} control={control} />
                  <FormSwitch
                    name="enable_shutdown"
                    label={t("label-shutdown-enabled")}
                    control={control}
                    disabled={!isEditing}
                    style={switchStyle}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <VMApplications
                  vmId={resource?.name}
                  vmCategory={resource?.category}
                  vmStatus={resource?.status}
                />
              </Grid>
              <Grid item xs={12} style={{ position: "absolute", bottom: 14 }}>
                <ResourceControlButtonGroup
                  handleStart={() => {}}
                  handleRestart={() => {}}
                  handleDeallocate={() => {}}
                  handleRedeploy={() => {}}
                  disabled={!isEditing}
                />
              </Grid>
              <EditButtonGroup
                isEditing={isEditing}
                disabled={true}
                handleEdit={() => setIsEditing(true)}
                handleSave={handleSubmit(handleSave)}
                handleCancel={handleCancel}
              />
            </Grid>
          </FormControl>
        </Box>
      )}
    </>
  );
};

export default ResourceContainer;
