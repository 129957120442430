import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  Box,
  Typography,
  Alert,
  DialogActions,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { buttonGroupContainerStyle } from "./ButtonGroups/styles";

/**
 *
 * Component to add to other views
 * prompts user thats doing a permanent action whether they are sure
 *
 * NOTE!
 * If the action is DELETE, the dialog will show a separate warning message & different colors and texts.
 * Separating the delete confirmation to its own dialog component could be a better approach in the future.
 *
 * params:
 *  - show: boolean --> dialog visible when true
 *  - action: string --> In the prompt the dialog tells what action the user is about to do (e.g. DELETE)
 *  - resource: string | undefined --> An identifier on what the user is about to perform action (e.g. name of task)
 *  - onConfirm: () => any  --> function to perform when confirm button is clicked
 *  - onCancel: () => any   --> function to perform when cancel button is clicked
 *
 *
 * Example usage pattern (dynamic)
 *  - shows how to use if need to change confirm function or action string etc.
 *
 *
 * const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
 * const [confirmActionState, setConfirmActionState] = useState<string>("");
 * const [onConfirmState, setOnConfirmStata] = useState<any>();
 *
 * const onDeleteClick = () => {
 *  setConfirmActionState("DELETE");
 *  setOnConfirmState(handleDelete);
 *  setConfirmDialogOpen(true);
 * }
 *
 * const onUpdateClick = () => {
 *  setConfirmActionState("UPDATAE");
 *  setOnConfirmStaate(handleUpdate);
 *  setConfirmDialogOpen(true);
 * }
 *
 * const handleUpdate = () => {
 *  // logic to do when update is pressed
 * }
 *
 * const handleDelete = () => {
 *  // logic to do when delete is pressed
 * }
 *
 * <Button onClick={onDeleteClick}> DELETE BUTTON </Button>
 *
 * <ConfirmActionDialog
 *     show={confirmDialogOpen}
 *     action={confirmActionState}
 *     resource={task.task_name}
 *     onConfirm={onConfirmState}
 *     onCancel={() => setConfirmDialogOpen(false)}
 *  />
 *
 */

export interface IActionConfirmationProps {
  show: boolean;
  action: string;
  resource?: string | undefined;
  onConfirm: () => any;
  onCancel: () => any;
}

export const ConfirmActionDialog = (props: IActionConfirmationProps) => {
  const { show, action, resource, onConfirm, onCancel } = props;
  const { t } = useTranslation();
  const isDeleteAction = action.toLowerCase() === "delete";

  return (
    <>
      <Dialog open={show} maxWidth="md" data-testid="root-dialog">
        <DialogTitle>
          <Box display="flex" alignItems="center">
            {isDeleteAction
              ? t("label-delete-item-confirmation-title")
              : t("label-confirm-action-title")}
          </Box>
        </DialogTitle>
        <DialogContent dividers sx={{ padding: "16px 32px" }}>
          <Typography>
            {t("label-about-to-perform-action")}{" "}
            <span style={{ fontWeight: 700 }}>{action}</span>.{" "}
            {resource && t("label-selected-resource") + ":"}
          </Typography>
          {resource && (
            <ul>
              <li>
                <Typography>{resource}</Typography>
              </li>
            </ul>
          )}

          <Typography marginTop={3}>
            {t("label-action-confirmation-are-you-sure")}
          </Typography>
          {isDeleteAction && (
            <Alert severity="warning" color="error" sx={{ marginTop: "10px" }}>
              {t("label-warning-permanent-action")}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Box sx={{ ...buttonGroupContainerStyle, marginTop: "8px" }}>
            {isDeleteAction ? (
              <Button
                variant="contained"
                onClick={() => onConfirm()}
                color="error"
              >
                {t("label-button-delete")}
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => onConfirm()}
                data-testid="button-confirm"
              >
                {t("label-button-confirm")}
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={() => onCancel()}
              sx={{
                backgroundColor: "white",
                color: "secondary.contrastText",
                borderColor: "secondary.light",
              }}
            >
              {t("label-cancel")}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};
