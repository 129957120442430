import { baseTheme } from "@app/theme";

export const taskBasicInfoStyle = {
  marginBottom: baseTheme.spacing(2),
};

export const taskDialogAddStyle = {
  display: "block",
  marginBottom: baseTheme.spacing(2),
};

export const taskAddDropdownStyle = {
  marginBottom: baseTheme.spacing(2),
  width: "100%",
};

export const formControlStyle = {
  border: `1px solid ${baseTheme.palette.primary.light}`,
  borderRadius: "3px",
  display: "block",
  margin: baseTheme.spacing(2, 2, 2, 2),
  padding: baseTheme.spacing(2),
  textAlign: "center" as const,
  color: "",
};
