import React, { ErrorInfo } from "react";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
interface AppInsightsErrorBoundaryProps {
  appInsights: ApplicationInsights;
  //onError: () => React.ReactNode;
  children?: React.ReactNode;
}

// React error boundary for handling exceptions and forwarding error data to AppInsight
export class ErrorBoundary extends React.Component<AppInsightsErrorBoundaryProps> {
  state = { hasError: false };

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true });

    this.props.appInsights.trackException({
      error: error,
      exception: error,
      severityLevel: SeverityLevel.Error,
      properties: { ...info },
    });
  }

  render() {
    // Can be used to render element when unhandled exception occurs, currently not in use
    /*
    if (this.state.hasError) {
      const { onError } = this.props

      return typeof onError === "function"
        ? onError()
        : React.createElement(onError);
    }
    */
    return this.props.children;
  }
}
