export const formatToNDecimals = (value: any, n: number) => {
  if (typeof value === "number" && value % 1 !== 0) {
    return value.toFixed(n);
  } else if (typeof value === "undefined") {
    return "";
  } else {
    return value;
  }
};

export const numberTooltipFormatter = (value: any) => {
  if (typeof value === "number" && value % 1 !== 0) {
    return parseFloat(value.toFixed(2));
  } else if (typeof value === "undefined") {
    return "";
  } else {
    return value;
  }
};

export const formatDateToFinnishStyle = (date: any) => {
  let dateObj = date;
  if (typeof dateObj === "string") {
    dateObj = new Date(date);
  }
  return dateObj.toLocaleDateString("fi-FI");
};

export const chartColors = {
  green: "#82ca9d",
  orange: "#FF8042",
  red: "#ff7961",
  yellow: "#FFBB28",
  wine: "#722F37",
  blue: "#8884d8",
};
