import StampsCountChart from "./StampsCountChart";
import StampsSumChart from "./StampsSumChart";
import StampsStatusChart from "./StampsStatusChart";
import StampsRuntimesChart from "./StampsRuntimesChart";
import ChartInfoBox from "../ChartInfoBox";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

const StampsChartsWrapper = (props: any) => {
  const {
    selectedViewOption,
    selectedStamp,
    selectedStampType,
    stamps,
    isLoading,
  } = props;
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" padding="20px">
        <CircularProgress />
      </Box>
    );
  }

  if (!stamps || stamps.length === 0) {
    return <ChartInfoBox severity="info" text={t("label-no-data")} />;
  }

  return (
    <>
      <StampsCountChart
        data={selectedViewOption === "count" ? stamps : null}
        selectedType={selectedStampType}
      />
      <StampsSumChart
        stampsToShow={selectedViewOption === "sum" ? stamps : null}
      />
      <StampsStatusChart
        stampLoopStatus={selectedViewOption === "status" ? stamps : null}
      />
      <StampsRuntimesChart
        data={
          selectedViewOption === "runtimes"
            ? stamps.filter((stamp: any) => stamp.name === selectedStamp) ||
              null
            : null
        }
      />
    </>
  );
};

export default StampsChartsWrapper;
