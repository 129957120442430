import TabView from "@features/common/TabView/TabView";
import { ITabItem } from "@features/common/TabView/TabView";

export const DashboardWindowPlaceholder = () => {
  const tabs: ITabItem[] = [
    /**
     * This is commented out for now, as the views are not ready.
     * Uncomment views when they've been implemented and are ready for use.
     * 
    {
      label: "menu-sub-consumption",
      path: "consumption",
      permissions: [],
    },*/
    {
      label: "menu-sub-automations",
      path: "automations",
      permissions: "",
    },
    {
      label: "menu-sub-event-logs",
      path: "eventlogs",
      permissions: "tasks",
    },
  ];

  return (
    <>
      <TabView tabs={tabs} primaryPermission="" />
    </>
  );
};
