// import { ITask, ITaskCreate, ITaskUpdate } from "./types";
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  getAllSecretsRequest,
  postSecretRequest,
  updateSecretRequest,
} from "./secretsApiService";
import { RootState } from "@app/store";
import { ISecret } from "./types";

//TODO replace all "any" with correct ISecret type

const secretsAdapter = createEntityAdapter<ISecret>({
  selectId: (secret) => secret.name,
});

const initialState = secretsAdapter.getInitialState({
  status: "",
  selectedSecret: "",
});

const secretsSlice = createSlice({
  name: "secrets",
  initialState,
  reducers: {
    selectSecret(state, action: PayloadAction<string>) {
      state.selectedSecret = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSecrets.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchSecrets.fulfilled,
        (state, { payload }: PayloadAction<ISecret[]>) => {
          secretsAdapter.setAll(state, payload);
          state.status = "";
        },
      )
      .addCase(fetchSecrets.rejected, (state) => {
        state.status = "";
      })
      .addCase(updateSecret.pending, (state) => {
        state.status = "submitting";
      })
      .addCase(
        updateSecret.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          secretsAdapter.upsertOne(state, payload);
          state.status = "";
        },
      )
      .addCase(updateSecret.rejected, (state) => {
        state.status = "";
      })
      .addCase(createSecret.pending, (state) => {
        state.status = "submitting";
      })
      .addCase(
        createSecret.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          secretsAdapter.upsertOne(state, payload);
          state.status = "";
        },
      )
      .addCase(createSecret.rejected, (state) => {
        state.status = "";
      });
  },
});

// TODO: later integrate better with Toasts?
export const fetchSecrets = createAsyncThunk(
  "secrets/fetchSecrets",
  async (customerId: string) => {
    try {
      const res = await getAllSecretsRequest(customerId);
      if (res) {
        return res.data;
      } else return res;
    } catch (err) {
      console.error(err);
      throw new Error("Failed to fetch secret data");
    }
  },
);

export interface SecretUpdateBundle {
  customerId: string;
  secretName: string;
  payload: ISecret;
}

export const updateSecret = createAsyncThunk(
  "secrets/updateSecret",
  async (data: SecretUpdateBundle) => {
    try {
      const res = await updateSecretRequest(
        data.customerId,
        data.secretName,
        data.payload,
      );
      if (res) {
        const newSecret: ISecret = {
          ...data.payload,
          production_value: "",
          test_value: "",
        };
        return newSecret;
      } else return res;
    } catch (err) {
      console.error(err);
      throw new Error("Failed to update secret");
    }
  },
);

export interface SecretCreateBundle {
  customerId: string;
  payload: ISecret;
}

export const createSecret = createAsyncThunk(
  "secrets/createSecret",
  async (data: SecretCreateBundle) => {
    try {
      const res = await postSecretRequest(data.customerId, data.payload);
      if (res) {
        const newSecret: ISecret = {
          ...data.payload,
          production_value: "",
          test_value: "",
        };
        return newSecret;
      } else return res;
    } catch (err) {
      console.error(err);
      throw new Error("Failed to add secret");
    }
  },
);

export const { selectSecret } = secretsSlice.actions;
export default secretsSlice.reducer;

const secretsSelectors = secretsAdapter.getSelectors(
  (state: RootState) => state.secrets,
);

// Selector for getting customer's tasks from the state
export const getAllSecrets = (state: RootState) => {
  return secretsSelectors.selectAll(state);
};

export const getSelectedSecretId = (state: RootState) => {
  return state.secrets.selectedSecret;
};

export const getSelectedSecret = (state: RootState) => {
  return secretsSelectors.selectById(state, state.secrets.selectedSecret);
};

export const selectSecretsStatus = (state: RootState) => state.secrets.status;
