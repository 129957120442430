import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from "recharts";
import { useTranslation } from "react-i18next";
import { chartColors, formatToNDecimals } from "../utils";
import { StampData } from "./types";

interface StampsSumChartProps {
  stampsToShow: StampData[];
}

const StampsSumChart = (props: StampsSumChartProps) => {
  const { stampsToShow } = props;
  const { t } = useTranslation();

  if (!stampsToShow || stampsToShow.length === 0) {
    return <></>;
  }

  const dataKeys = [
    {
      label: t("label-median-runtime"),
      value: "median_runtime",
      color: chartColors.blue,
    },
    {
      label: t("label-average-runtime"),
      value: "average_runtime",
      color: chartColors.green,
    },
    {
      label: t("label-runtime-highest"),
      value: "runtime_highest",
      color: chartColors.yellow,
    },
    {
      label: t("label-runtime-lowest"),
      value: "runtime_lowest",
      color: chartColors.orange,
    },
  ];

  return (
    <>
      <ResponsiveContainer>
        <BarChart
          data={stampsToShow}
          margin={{
            top: 10,
            right: 10,
            left: 0,
            bottom: 10,
          }}
        >
          <XAxis dataKey="name" tick={false} tickLine={false} />
          <YAxis
            domain={[0, "dataMax"]}
            allowDecimals={false}
            tick={{ fontSize: 12 }}
            unit={" min"}
          />
          <Tooltip
            cursor={{ fill: "#ebf4fb" }}
            formatter={(value) => formatToNDecimals(value, 1)}
          />
          <Legend wrapperStyle={{ marginBottom: "10px" }} />
          {dataKeys.map((dataKeyObject) => (
            <Bar
              key={dataKeyObject.value}
              name={dataKeyObject.label}
              dataKey={dataKeyObject.value}
              fill={dataKeyObject.color}
              unit={" " + t("label-minutes")}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default StampsSumChart;
