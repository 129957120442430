import { useTranslation } from "react-i18next";
import { Button, Box, useTheme } from "@mui/material";
import { buttonGroupContainerStyle } from "@features/common/ButtonGroups/styles";

interface IProps {
  disabled?: boolean;
  handleAdd?: () => void;
  handleDelete?: () => void;
}

const AddDeleteRoleButtonGroup = (props: IProps) => {
  const { disabled, handleAdd, handleDelete } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box sx={buttonGroupContainerStyle}>
      <Button variant="contained" onClick={handleAdd} disabled={disabled}>
        {t("label-add-role")}
      </Button>
      <Button
        variant="contained"
        sx={
          disabled
            ? {
                backgroundColor: "rgba(0, 0, 0, 0.12);",
                color: "white",
              }
            : {
                backgroundColor: "white",
                color: "secondary.contrastText",
                borderColor: "secondary.light",
              }
        }
        onClick={handleDelete}
        disabled={disabled}
      >
        {t("label-delete-role")}
      </Button>
    </Box>
  );
};

export default AddDeleteRoleButtonGroup;
