import { AxiosResponse } from "axios";
import { apiGET, apiPOST } from "@features/common/baseApiService";
import { ICustomer, CustomerCreate } from "./types";

export async function getAllCustomersRequest(): Promise<
  AxiosResponse<ICustomer[]>
> {
  const resource = `customers`;
  const response = await apiGET<ICustomer[]>(resource);
  return response;
}

export async function createCustomerRequest(
  customer: any,
): Promise<AxiosResponse<CustomerCreate>> {
  const resource = `customers/create_from_portal`;
  const response = await apiPOST<string, CustomerCreate>(resource, customer);
  return response;
}
