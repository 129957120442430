import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { Dialog, DialogContent, Grid, DialogTitle } from "@mui/material";

import SaveCancelButtonGroup from "@features/common/ButtonGroups/SaveCancelButtonGroup";
import { FormTextField, FormTextArea } from "@features/common/FormFields";
import { useAppDispatch } from "@features/common/StoreHook";
import { selectCollectionsStatus } from "./collectionsSlice";
import { ICollectionMemberCreate } from "./types";
import { createCollectionMember } from "./collectionsSlice";

interface AddCollectionValueProps {
  open: boolean;
  onClose: () => void;
  collectionId: number;
}

const validationSchema = yup.object({
  id: yup.string().required(),
  description: yup.string().nullable(),
  value_en: yup.string().required(),
  value_fi: yup.string().required(),
});

const AddCollectionValue = (props: AddCollectionValueProps) => {
  const { open, onClose } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isSubmitting = useSelector(selectCollectionsStatus) === "submitting";

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      id: "",
      description: "",
      value_en: "",
      value_fi: "",
    },
  });

  const handleFormSubmit = (formData: any) => {
    const newCollectionValue: ICollectionMemberCreate = {
      collectionId: props.collectionId,
      id: formData.id,
      description: formData.description,
      values: [
        { language_code: "en-US", value: formData.value_en },
        { language_code: "fi-FI", value: formData.value_fi },
      ],
    };
    dispatch(createCollectionMember(newCollectionValue))
      .unwrap()
      .then(() => {
        handleCancel();
        toast.success(t("Collection value created"));
      })
      .catch(() => {
        toast.error(t("Failed to create collection value"));
      });
  };

  const handleCancel = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>{t("title-add-new-collection-value")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} marginTop={0.5}>
          <Grid item xs={12}>
            <FormTextField
              name="id"
              control={control}
              label={t("label-collection-value-id")}
              readOnly={isSubmitting}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextArea
              name="description"
              control={control}
              label={t("label-collection-value-description")}
              readOnly={isSubmitting}
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextArea
              name="value_en"
              control={control}
              label={t("label-text-value-en")}
              readOnly={isSubmitting}
              rows={3}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextArea
              name="value_fi"
              control={control}
              label={t("label-text-value-fi")}
              readOnly={isSubmitting}
              rows={3}
            />
          </Grid>
          <Grid item xs={12}>
            <SaveCancelButtonGroup
              disabled={isSubmitting}
              handleCancel={handleCancel}
              handleSave={handleSubmit(handleFormSubmit)}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddCollectionValue;
