import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { TextField, Grid, FormControl, FormLabel } from "@mui/material";

import { RootState } from "@app/store";
import { formLabelStyle, formControlStyle } from "@features/common/styles";
import { useAppDispatch } from "@features/common/StoreHook";
import { DropdownSelect } from "@features/common/DropdownSelect";
import EditButtonGroup from "@features/common/ButtonGroups/EditButtonGroup";
import { useCollection } from "@features/common/collectionHook";
import FormTextField from "@features/common/FormFields/FormTextField";
import FormTextArea from "@features/common/FormFields/FormTextArea";
import FormCheckbox from "@features/common/FormFields/FormCheckbox";
import { permissionsApi } from "@features/authorization/authService";
import { checkPermissions } from "@features/authorization/types";
import { getSelectedCustomerId } from "@features/customers/customerSlice";

import { getSelectedTask, updateTask, selectTasksStatus } from "./tasksSlice";
import { ITask, ITaskUpdate } from "./types";
import { taskBasicInfoStyle } from "./styles";
import LoadingIndicatorBox from "@features/common/LoadingIndicatorBox/LoadingIndicatorBox";

/**
 *
 * View of Task information that user can update
 * Pre-populates fields with Task's information if task is selected
 * otherwise they are empty
 *
 */
type PriorityOptions = { [key: number]: string };

const validationSchema = yup.object({
  task_name: yup.string().required(),
  task_description: yup.string().nullable(),
  task_type: yup.string().nullable(),
  screenshots_allowed: yup.boolean().nullable(),
  recording_allowed: yup.boolean().nullable(),
  start_script_path: yup.string().nullable(),
  technical_contact_email: yup.string().email().nullable(),
  business_contact_email: yup.string().email().nullable(),
  customer_email: yup.string().email().nullable(),
  customer_error_email: yup.string().email().nullable(),
});

export const TaskBasicInfo = () => {
  // setup
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // vars
  const selectedCustomerId = useSelector(getSelectedCustomerId);
  const { data, isLoading } =
    permissionsApi.useGetCustomerPermissionsQuery(selectedCustomerId);
  const taskPermissions = checkPermissions("tasks", data);
  const taskToUpdate: ITask | undefined = useSelector((state: RootState) =>
    getSelectedTask(state),
  );
  const [priority, setPriority] = useState<any>("");
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const isSubmitting = useSelector(selectTasksStatus) === "submitting";

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      task_name: "",
      task_description: "",
      screenshots_allowed: false,
      recording_allowed: false,
      start_script_path: "",
      technical_contact_email: "",
      business_contact_email: "",
      customer_email: "",
      customer_error_email: "",
    },
  });

  const priorityValues = useCollection(12);
  const priorityOptions: PriorityOptions = {
    1: "Low",
    2: "Normal",
    3: "High",
  };

  // hooks

  useEffect(() => {
    if (taskToUpdate) {
      resetFormValues();
      if (taskToUpdate.priority) setPriority(taskToUpdate.priority);
    }
  }, [taskToUpdate]);

  //event handlers

  const resetFormValues = () => {
    if (taskToUpdate) {
      // setup the form with the task's information
      const {
        task_name,
        task_description,
        task_type,
        start_script_path,
        technical_contact_email,
        business_contact_email,
        customer_email,
        customer_error_email,
        screenshots_allowed,
        recording_allowed,
      } = taskToUpdate;
      reset({
        task_name,
        task_description,
        task_type,
        start_script_path,
        technical_contact_email,
        business_contact_email,
        customer_email,
        customer_error_email,
        screenshots_allowed,
        recording_allowed,
      });
    }
  };

  const handleDropdownChange = (event: string) => {
    const priorityNum =
      event === "Low"
        ? 1
        : event === "Normal"
        ? 2
        : event === "High"
        ? 3
        : null;
    setPriority(priorityNum);
  };

  const editTaskWrapper = (data: any) => {
    setIsEditing(false);
    if (taskToUpdate) {
      const taskUpdateObj: ITaskUpdate = {
        customer_guid: taskToUpdate?.customer_guid,
        task_guid: taskToUpdate?.task_guid,
        vm_names: taskToUpdate?.vm_names,
        task_name: data.task_name,
        task_description: data.task_description,
        task_type: data.task_type,
        start_script_path: data.start_script_path,
        technical_contact_email: data.technical_contact_email,
        business_contact_email: data.business_contact_email,
        customer_email: data.customer_email,
        customer_error_email: data.customer_error_email,
        screenshots_allowed: data.screenshots_allowed,
        recording_allowed: data.recording_allowed,
        priority: priority || taskToUpdate?.priority,
      };
      dispatch(updateTask(taskUpdateObj))
        .unwrap()
        .then(() => {
          toast.success(t("Task updated"));
          setIsEditing(false);
        })
        .catch((e) => {
          toast.error("Could not update task");
        });
    }
  };

  // when user is happy with the text field values, this fetches it and sends it to server
  const handleInternalSubmit = (data: any) => {
    taskPermissions.update
      ? editTaskWrapper(data)
      : toast.error("No update permission");
  };

  const handleCancel = () => {
    setIsEditing(false);
    if (taskToUpdate) {
      // TODO: task_type does not seem to be in the object that is returned from the API
      setPriority(taskToUpdate?.priority || "");
      resetFormValues();
    }
  };

  const handleEdit = () => {
    if (taskPermissions.update) {
      setIsEditing(!isEditing);
    } else {
      toast.error("No update permission");
    }
  };

  return (
    <FormControl component="fieldset" style={formControlStyle}>
      <FormLabel component="legend" sx={formLabelStyle}>
        {t("label-basic-info")}
      </FormLabel>
      {isLoading ? (
        <LoadingIndicatorBox show={isLoading} />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormTextField
              name="task_name"
              control={control}
              label={t("label-task-name")}
              readOnly={!isEditing}
              style={taskBasicInfoStyle}
            />
            <FormTextArea
              name="task_description"
              control={control}
              label={t("label-task-description")}
              readOnly={!isEditing}
              style={taskBasicInfoStyle}
            />
            <FormTextField
              name="start_script_path"
              control={control}
              label={t("label-script-path")}
              readOnly={!isEditing}
              style={taskBasicInfoStyle}
            />
            <TextField
              name="vm_names"
              data-testid="vm-names"
              label={t("label-vm-names")}
              value={taskToUpdate?.vm_names || ""}
              variant="outlined"
              disabled={true}
              style={taskBasicInfoStyle}
              fullWidth
            />
            <DropdownSelect
              style={{ ...taskBasicInfoStyle, width: "100%" }}
              setParentComponentState={handleDropdownChange}
              selectedItem={priority ? priorityOptions[priority] : ""}
              selectItems={priorityValues}
              menuId="BasicInfo_Priority_Select"
              textFieldLabel="Priority"
              disabled={!isEditing}
            />
            <FormCheckbox
              name="screenshots_allowed"
              control={control}
              label={t("label-screenshots-allowed")}
              disabled={!isEditing}
            />
            <FormCheckbox
              name="recording_allowed"
              control={control}
              label={t("label-video-recording-allowed")}
              disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="task_guid"
              data-testid="task-guid"
              label={t("label-task-guid")}
              value={taskToUpdate?.task_guid || ""}
              variant="outlined"
              disabled={true}
              style={taskBasicInfoStyle}
              fullWidth
            />
            <FormControl component="fieldset" sx={formControlStyle}>
              <FormLabel component="legend" sx={formLabelStyle}>
                {t("label-addresses")}
              </FormLabel>
              <Grid item padding={1}>
                <FormTextField
                  name="technical_contact_email"
                  control={control}
                  label={t("label-technical-contact-email")}
                  readOnly={!isEditing}
                  style={taskBasicInfoStyle}
                />
                <FormTextField
                  name="business_contact_email"
                  control={control}
                  label={t("label-business-contact-email")}
                  readOnly={!isEditing}
                  style={taskBasicInfoStyle}
                />
                <FormTextField
                  name="customer_email"
                  control={control}
                  label={t("label-customer-email")}
                  readOnly={!isEditing}
                  style={taskBasicInfoStyle}
                />
                <FormTextField
                  name="customer_error_email"
                  control={control}
                  label={t("label-customer-error-email")}
                  readOnly={!isEditing}
                  style={taskBasicInfoStyle}
                />
              </Grid>
            </FormControl>
          </Grid>
        </Grid>
      )}

      <EditButtonGroup
        disabled={isSubmitting || isLoading}
        isEditing={isEditing}
        handleEdit={handleEdit}
        handleCancel={handleCancel}
        handleSave={handleSubmit(handleInternalSubmit)}
      />
    </FormControl>
  );
};
