import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Dialog, DialogTitle, DialogContent } from "@mui/material";
import { DropdownSelect, SelectItem } from "@features/common/DropdownSelect";
import ConfirmCancelButtonGroup from "./ConfirmCancelButtonGroup";

interface RoleDialogProps {
  data: any[] | undefined;
  title: string;
  onCancel: () => void;
  onSubmit: () => void;
  dialogOpen: boolean;
  setParentRoles: (arg: any) => void; // state manager for parent component
  selectedRole: string | undefined;
}

/**
 * Shared component for adding and removing Roles from selected user
 * These could maybe have been separate components but they share layout so made it like this
 *
 * Basically a dropdown and some selection handling logic thats injected from parent
 *
 */

export const ModifyRolesDialog = (props: RoleDialogProps) => {
  const {
    data,
    title,
    onCancel,
    onSubmit,
    setParentRoles,
    dialogOpen,
    selectedRole,
  } = props;
  const { t } = useTranslation();
  const [selectItems, setSelectItems] = useState<SelectItem[]>([]);

  const handleDropdownStateChange = (event: any) => {
    setParentRoles(event);
  };

  useEffect(() => {
    if (data) {
      setSelectItems(data);
    }
  }, [data]);

  return (
    <>
      <Dialog open={dialogOpen} fullWidth>
        <DialogTitle>{t(title)}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} marginTop={1}>
              <DropdownSelect
                disabled={false}
                menuId={title}
                style={{ width: "100%" }}
                selectItems={selectItems}
                setParentComponentState={handleDropdownStateChange}
                selectedItem={selectedRole}
                textFieldLabel={t("label-role")}
              />
            </Grid>
            <Grid item xs={12}>
              <ConfirmCancelButtonGroup
                confirmButtonLabel={title}
                disabled={false}
                handleConfirm={onSubmit}
                handleCancel={onCancel}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
