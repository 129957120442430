import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ErrorsChart from "./Charts/ErrorsChart/ErrorsChart";
import {
  ActiveTimelineEvent,
  ActiveTimelineItem,
  EventType,
  TimelineEvent,
  TimelineResource,
} from "./types";
import FinishedStatusChart from "./Charts/FinishedStatusChart/FinishedStatusChart";
import { calculateErrorsByDay } from "./Charts/ErrorsChart/errorsChartUtils";
import { calculateFinishedStatusData } from "./Charts/FinishedStatusChart/finishedStatusUtils";
import VmUtilization from "./Charts/VmUtilizationChart/VmUtilization";
import StampsCharts from "./Charts/StampsChart/StampsCharts";
import ChartWrapper from "./Charts/ChartWrapper";
import RuntimesChartContainer from "./Charts/RuntimesChart/RuntimesChartContainer";
import ErrorsTable from "./Charts/ErrorsChart/ErrorsTable";

interface DataChartsProps {
  selectedItem: ActiveTimelineItem;
  startDate: Date;
  endDate: Date;
  events: TimelineEvent[];
}

const DataCharts = (props: DataChartsProps) => {
  const { selectedItem, startDate, endDate, events } = props;
  const { t } = useTranslation();

  const errorsByDay = calculateErrorsByDay(
    events,
    selectedItem,
    startDate,
    endDate,
  );

  const finishedStatusData = calculateFinishedStatusData(
    events,
    selectedItem,
    startDate,
    endDate,
  );

  const selectedVm =
    selectedItem.type === EventType.VM
      ? selectedItem.id
      : selectedItem.type === EventType.Task
      ? (selectedItem.item as TimelineResource)?.parentId
      : selectedItem.type === EventType.Event
      ? (selectedItem.item as ActiveTimelineEvent)?.vmId
      : "";

  return (
    <div>
      <Typography variant="h2">Data charts</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={4}>
          <RuntimesChartContainer
            selectedItem={selectedItem}
            startDate={startDate}
            endDate={endDate}
            events={events}
            title={t("datawindow-runtimes")}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <ChartWrapper
            title={t("datawindow-errors")}
            chart={<ErrorsChart data={errorsByDay} />}
            table={<ErrorsTable data={errorsByDay} />}
            isTableAvailable={true}
            excelData={errorsByDay}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <ChartWrapper
            title={t("datawindow-finished")}
            chart={<FinishedStatusChart data={finishedStatusData} />}
            isTableAvailable={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <VmUtilization
            events={events}
            selectedVm={selectedVm || ""}
            startDate={startDate}
            endDate={endDate}
            title={t("label-vm-utilization")}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <StampsCharts
            startDate={startDate}
            endDate={endDate}
            selectedItem={selectedItem}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default DataCharts;
