import { AxiosResponse } from "axios";
import { apiGET } from "@features/common/baseApiService";
import { StampData } from "./types";

// API currently returns a dictionary/object of StampData objects
// with the key being the stamp name
export interface StampDataRequest {
  [key: string]: StampData;
}

export async function getEventStampData(
  customerId: string,
  eventId: string,
  startDate: Date,
  endDate: Date,
): Promise<AxiosResponse<StampDataRequest>> {
  const resource = `customers/${customerId}/stampdata/event/${eventId}`;
  const response = await apiGET<StampDataRequest>(resource, {
    start_time: startDate,
    end_time: endDate,
  });
  return response;
}

export async function getTaskStampData(
  customerId: string,
  taskId: string,
  startDate: Date,
  endDate: Date,
): Promise<AxiosResponse<StampData[]>> {
  const resource = `customers/${customerId}/stampdata/task/${taskId}`;
  const response = await apiGET<StampData[]>(resource, {
    start_time: startDate,
    end_time: endDate,
  });
  return response;
}

export async function getVmStampData(
  customerId: string,
  vmId: string,
  startDate: Date,
  endDate: Date,
): Promise<AxiosResponse<StampData[]>> {
  const resource = `customers/${customerId}/stampdata/virtualmachine/${vmId}`;
  const response = await apiGET<StampData[]>(resource, {
    start_time: startDate,
    end_time: endDate,
  });
  return response;
}
