import { AxiosResponse } from "axios";
import {
  apiGET,
  apiPOST,
  apiPUT,
  apiDELETE,
} from "@features/common/baseApiService";
import { ITask, ITaskCreate, ITaskUpdate } from "./types";

export async function getAllTasksRequest(
  customerId: string,
): Promise<AxiosResponse<ITask[]>> {
  const resource = `customers/${customerId}/tasks/`;
  const response = await apiGET<ITask[]>(resource);
  return response;
}

export async function postTaskRequest(
  customerId: string,
  payload: ITaskCreate,
) {
  const resource = `customers/${customerId}/tasks/`;
  const response = await apiPOST<ITaskCreate, ITask>(resource, payload);
  return response;
}

export async function updateTaskRequest(
  customerId: string,
  payload: ITaskUpdate | ITask,
): Promise<AxiosResponse<ITask>> {
  const resource = `customers/${customerId}/tasks/${payload.task_guid}`;
  const response = await apiPUT<ITaskUpdate | ITask, ITask>(resource, payload);
  return response;
}

export async function deleteTaskRequest(
  customerId: string,
  taskId: string,
): Promise<AxiosResponse<any>> {
  const resource = `customers/${customerId}/tasks/${taskId}`;
  const response = await apiDELETE<any>(resource);
  return response;
}
