import React from "react";
import { CircularProgress } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const LoadingScreen = () => {
  return (
    <Card
      style={{
        marginTop: 12,
        marginBottom: 12,
        backgroundColor: "primary.light",
      }}
    >
      <CardContent>
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      </CardContent>
    </Card>
  );
};

export default LoadingScreen;
