import React, { useState } from "react";
import FullScreenChartDialog from "./FullScreenChartDialog";
import ChartCard from "./ChartCard";

/**
 * Wraps the chart and the table in a card component
 * and provides a full screen dialog for the chart
 */

interface ChartWrapperProps {
  title: string;
  chart: React.ReactNode;
  table?: React.ReactNode;
  isTableAvailable?: boolean;
  excelData?: any;
  excelDataKeys?: string[];
}

const ChartWrapper = (props: ChartWrapperProps) => {
  const { title, chart, table, isTableAvailable } = props;
  const [isFullScreen, setIsFullScreen] = useState(false);

  return (
    <>
      <FullScreenChartDialog
        handleClose={() => setIsFullScreen(false)}
        open={isFullScreen}
        title={title}
        chart={chart}
        table={table || <></>}
        isTableAvailable={(isTableAvailable && table !== undefined) || false}
        excelData={props.excelData}
        excelDataKeys={props.excelDataKeys}
      />
      <ChartCard title={title} openFullscreen={() => setIsFullScreen(true)}>
        {chart}
      </ChartCard>
    </>
  );
};

export default ChartWrapper;
