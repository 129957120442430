import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";

interface IProps {
  disabled: boolean;
  handleEdit?: () => void;
  handleSave?: () => void;
  handleCancel?: () => void;
  isEditing: boolean;
}

const EditButtonGroup = (props: IProps) => {
  const { disabled, isEditing, handleEdit, handleCancel, handleSave } = props;
  const { t } = useTranslation();

  return (
    <Grid container direction="row" justifyContent="flex-end" gap={1}>
      {!isEditing ? (
        <Button
          data-testid="edit-button"
          variant="contained"
          disabled={disabled || !handleEdit}
          onClick={handleEdit}
        >
          {t("button_form_edit")}
        </Button>
      ) : (
        <>
          <Button
            data-testid="save-button"
            variant="contained"
            disabled={disabled || !handleSave}
            onClick={handleSave}
          >
            {t("button_form_save")}
          </Button>
          <Button
            variant="outlined"
            disabled={disabled || !handleCancel}
            onClick={handleCancel}
            sx={
              disabled || !handleCancel
                ? {
                    backgroundColor: "rgba(0, 0, 0, 0.12);",
                    color: "white",
                  }
                : {
                    backgroundColor: "white",
                    color: "secondary.contrastText",
                    borderColor: "secondary.light",
                  }
            }
          >
            {t("button_form_cancel")}
          </Button>
        </>
      )}
    </Grid>
  );
};

export default EditButtonGroup;
