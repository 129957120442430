import NavigationLink from "./NavigationLink";
import { Box } from "@mui/system";
import { IRoute } from "../types";
import { navBarStyles } from "./style";

interface IProps {
  routes: IRoute[];
}

const NavBar = (props: IProps) => {
  const { routes } = props;

  return (
    <Box sx={{ display: { xs: "none", md: "block" } }}>
      <div style={navBarStyles}>
        {routes.map((item) => (
          <NavigationLink
            key={item.path}
            to={item.path}
            label={item.label}
            hasPermission={item.permission}
          />
        ))}
      </div>
    </Box>
  );
};

export default NavBar;
