import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { FormControl, FormLabel } from "@mui/material";

import { formControlStyle, formLabelStyle } from "@features/common/styles";
import SortTable, { IProperty } from "@features/common/Table/SortTable";
import AddDeleteButtonGroup from "@features/common/ButtonGroups/AddDeleteButtonGroup";
import { ConfirmActionDialog } from "@features/common/ConfirmAction";
import { useAppDispatch } from "@features/common/StoreHook";
import CollectionValue from "./CollectionValue";
import AddCollectionValue from "./AddCollectionValue";
import { ICollectionMember } from "./types";
import { deleteCollectionMember } from "./collectionsSlice";

interface CollectionValuesProps {
  collectionMembers: ICollectionMember[] | undefined;
  collectionId: number;
}

const CollectionValues = (props: CollectionValuesProps) => {
  const { collectionMembers, collectionId } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectedId, setSelectedId] = useState<string>("");
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);

  const properties: IProperty<ICollectionMember>[] = [
    { key: "id", label: t("label-collection-value-id") },
    { key: "description", label: t("label-collection-value-description") },
  ];

  const handleDelete = () => {
    const ids = { collectionMemberId: selectedId, collectionId };
    dispatch(deleteCollectionMember(ids))
      .unwrap()
      .then(() => {
        setSelectedId("");
        toast.success("Collection value deleted successfully");
      })
      .catch(() => {
        toast.error("Failed to delete collection value");
      });
    setConfirmDialogOpen(false);
  };

  return (
    <>
      <FormControl component="fieldset" sx={formControlStyle}>
        <FormLabel component="legend" sx={formLabelStyle}>
          {t("label-collection-values")}
        </FormLabel>
        <SortTable
          properties={properties}
          idProperty="id"
          selectedId={selectedId}
          isLoading={false}
          handleChange={(id: string) => setSelectedId(id)}
          maxHeight="70vh"
          data={collectionMembers || []}
        />
        <AddDeleteButtonGroup
          disabled={false}
          handleAdd={() => setAddDialogOpen(true)}
          handleDelete={
            selectedId ? () => setConfirmDialogOpen(true) : undefined
          }
        />
      </FormControl>
      {selectedId && (
        <CollectionValue
          key={selectedId}
          value={collectionMembers?.find(
            (m: ICollectionMember) => m.id === selectedId,
          )}
          collectionId={collectionId}
        />
      )}
      <AddCollectionValue
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        collectionId={collectionId}
      />
      <ConfirmActionDialog
        show={confirmDialogOpen}
        action="DELETE"
        resource={collectionMembers?.find((c) => c.id === selectedId)?.id}
        onConfirm={handleDelete}
        onCancel={() => setConfirmDialogOpen(false)}
      />
    </>
  );
};

export default CollectionValues;
