import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";

/**
 * Time field for schedule forms using react-hook-form
 *
 * @param control: react-hook-form control object
 */

interface IProps {
  name: string;
  control: any;
  label: string;
  style?: any;
  readOnly?: boolean;
}

const FormTimeField = (props: IProps) => {
  const { style, name, control, label, readOnly } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          helperText={error ? error.message : null}
          error={!!error}
          onChange={onChange}
          value={value ?? ""}
          fullWidth
          label={label}
          variant="outlined"
          sx={style}
          type="time"
          inputProps={{ readOnly: readOnly || false }}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
};

export default FormTimeField;
