import { LogLevel, Configuration } from "@azure/msal-browser";
import { log } from "console";

export const authority =
  "https://login.microsoftonline.com/31fe9eb3-165a-4e2a-b3ca-8015d5342ac3";
export const authorityDomain = "https://login.microsoftonline.com";

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
const msalConfigLocal: Configuration = {
  auth: {
    clientId: "435cc071-53f0-42a8-8da1-5538bd422663",
    authority: authority,
    knownAuthorities: [authorityDomain],
    redirectUri: "/",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: String,
        containsPii: Boolean,
      ) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

const msalConfigTest: Configuration = {
  auth: {
    clientId: "435cc071-53f0-42a8-8da1-5538bd422663",
    authority: authority,
    knownAuthorities: [authorityDomain],
    redirectUri: "/",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: String,
        containsPii: Boolean,
      ) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

const msalConfigProd: Configuration = {
  auth: {
    clientId: "707c3f7d-0899-4019-8915-9ea317ac9d9f",
    authority: authority,
    knownAuthorities: [authorityDomain],
    redirectUri: "/",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: String,
        containsPii: Boolean,
      ) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

const config =
  process.env.REACT_APP_ENV === "prod"
    ? msalConfigProd
    : process.env.REACT_APP_ENV === "test"
    ? msalConfigTest
    : msalConfigLocal;

export const msalConfig = config;

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 */

const testloginRequest = {
  scopes: [
    "api://mostdigital.fi/dock.api.test/Customers.Read",
    "api://mostdigital.fi/dock.api.test/Customers.ReadWrite",
    "api://mostdigital.fi/dock.api.test/Tasks.Read",
    "api://mostdigital.fi/dock.api.test/Tasks.ReadWrite",
  ],
};

const prodloginRequest = {
  scopes: [
    "api://mostdigital.fi/dock.api.prod/Customers.Read",
    "api://mostdigital.fi/dock.api.prod/Customers.ReadWrite",
    "api://mostdigital.fi/dock.api.prod/Tasks.Read",
    "api://mostdigital.fi/dock.api.prod/Tasks.ReadWrite",
  ],
};

const envloginRequest =
  process.env.REACT_APP_ENV === "prod" ? prodloginRequest : testloginRequest;

export const loginRequest = envloginRequest;
