import { AxiosResponse } from "axios";
import {
  apiGET,
  apiPOST,
  apiPUT,
  apiDELETE,
} from "@features/common/baseApiService";

export async function getAllRolesRequest(
  customerId: string,
): Promise<AxiosResponse<any[]>> {
  const resource = `customers/${customerId}/roles/`;
  const response = await apiGET<any[]>(resource);
  return response;
}

export async function addUserRoleRequest(
  customerId: string,
  userId: string,
  payload: any,
): Promise<AxiosResponse<any[]>> {
  const resource = `customers/${customerId}/userroles/${userId}`;
  const response = await apiPOST<any, any>(resource, payload);
  return response;
}

export async function deleteUserRoleRequest(
  customerId: string,
  userId: string,
  roleId: number,
): Promise<AxiosResponse<any[]>> {
  const resource = `customers/${customerId}/userroles/${userId}/roles/${roleId}`;
  const response = await apiDELETE<any>(resource);
  return response;
}
