import { Button } from "@mui/material";
import { PermissionDialog } from "./PermissionDialog";
import { useState } from "react";

export const PermissionButton = () => {
  const [open, setOpen] = useState<boolean>(false);
  let permissionArray = ["secrets", "tasks", "administration"]; // experimental
  return (
    <>
      <Button
        data-testid="permission-button"
        variant="contained"
        onClick={() => setOpen(!open)}
      >
        {" "}
        Permissions{" "}
      </Button>
      <PermissionDialog
        openParent={open}
        setOpenParent={setOpen}
        permissionsArray={permissionArray}
      />
    </>
  );
};
