export const schedulerTextFieldStyleLeft = {
  width: "45%",
  marginRight: "2px",
};

export const schedulerTextFieldStyleRight = {
  width: "45%",
  marginLeft: "2px",
};

export const schedulerGridStyle = {
  marginBottom: "20px",
};

export const schedulerEventsTableEmptinessIndicatorCell = {
  display: "flex",
  justifyContent: "center",
  height: "5vh",
  alignItems: "center",
};
