import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "@app/store";

import {
  getAllCollectionsRequest,
  createCollectionRequest,
  deleteCollectionRequest,
  updateCollectionRequest,
  createCollectionMemberRequest,
  deleteCollectionMemberRequest,
  updateCollectionMemberRequest,
} from "./collectionsApiService";
import {
  ICollection,
  ICollectionCreate,
  ICollectionUpdate,
  ICollectionMemberCreate,
  ICollectionMember,
  ICollectionMemberUpdate,
} from "./types";

const collectionsAdapter = createEntityAdapter<ICollection>();

const initialState = collectionsAdapter.getInitialState({
  status: "",
});

export const collectionsSlice = createSlice({
  name: "collections",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCollections.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchCollections.fulfilled,
        (state, { payload }: PayloadAction<ICollection[]>) => {
          collectionsAdapter.setAll(state, payload);
          state.status = "";
        },
      )
      .addCase(fetchCollections.rejected, (state) => {
        state.status = "";
      })
      .addCase(createCollection.pending, (state) => {
        state.status = "submitting";
      })
      .addCase(
        createCollection.fulfilled,
        (state, { payload }: PayloadAction<ICollection>) => {
          collectionsAdapter.addOne(state, payload);
          state.status = "";
        },
      )
      .addCase(createCollection.rejected, (state) => {
        state.status = "";
      })
      .addCase(deleteCollection.pending, (state) => {
        state.status = "deleting";
      })
      .addCase(deleteCollection.fulfilled, (state, { payload }) => {
        collectionsAdapter.removeOne(state, payload);
        state.status = "";
      })
      .addCase(deleteCollection.rejected, (state) => {
        state.status = "";
      })
      .addCase(updateCollection.pending, (state) => {
        state.status = "submitting";
      })
      .addCase(
        updateCollection.fulfilled,
        (state, { payload }: PayloadAction<ICollectionUpdate>) => {
          collectionsAdapter.updateOne(state, {
            id: payload.id,
            changes: {
              name: payload.name,
              description: payload.description,
            },
          });
          state.status = "";
        },
      )
      .addCase(updateCollection.rejected, (state) => {
        state.status = "";
      })
      .addCase(createCollectionMember.pending, (state) => {
        state.status = "submitting";
      })
      .addCase(
        createCollectionMember.fulfilled,
        (state, { payload }: PayloadAction<ICollectionMemberCreate>) => {
          const collection = state.entities[payload.collectionId];
          if (collection) {
            collection.members.push({
              id: payload.id,
              description: payload.description,
              values: payload.values,
              deleted: false,
            });
          }
          state.status = "";
        },
      )
      .addCase(createCollectionMember.rejected, (state) => {
        state.status = "";
      })
      .addCase(deleteCollectionMember.pending, (state) => {
        state.status = "deleting";
      })
      .addCase(deleteCollectionMember.fulfilled, (state, { payload }) => {
        const collection = state.entities[payload.collectionId];
        if (collection) {
          collection.members = collection.members.filter(
            (member) => member.id !== payload.collectionMemberId,
          );
        }
        state.status = "";
      })
      .addCase(deleteCollectionMember.rejected, (state) => {
        state.status = "";
      })
      .addCase(updateCollectionMember.pending, (state) => {
        state.status = "submitting";
      })
      .addCase(
        updateCollectionMember.fulfilled,
        (state, { payload }: PayloadAction<ICollectionMemberUpdate>) => {
          const collection = state.entities[payload.collectionId];
          if (collection) {
            const member = collection.members.find((m) => m.id === payload.id);
            if (member) {
              member.description = payload.description;
              member.values = payload.values;
            }
          }
          state.status = "";
        },
      )
      .addCase(updateCollectionMember.rejected, (state) => {
        state.status = "";
      });
  },
});

export default collectionsSlice.reducer;

const collectionSelectors = collectionsAdapter.getSelectors(
  (state: RootState) => state.collections,
);

export const selectAllCollections = (state: RootState) =>
  collectionSelectors.selectAll(state);

export const getCollectionById = (state: RootState, collectionId: number) => {
  return collectionSelectors.selectById(state, collectionId);
};

export const selectCollectionsStatus = (state: RootState) =>
  state.collections.status;

export const fetchCollections = createAsyncThunk(
  "collections/fetchCollections",
  async () => {
    const res = await getAllCollectionsRequest();
    return res.data;
  },
);

export const createCollection = createAsyncThunk(
  "collections/createCollection",
  async (collection: ICollectionCreate) => {
    const res = await createCollectionRequest(collection);
    return res.data;
  },
);

export const deleteCollection = createAsyncThunk(
  "collections/deleteCollection",
  async (collectionId: string) => {
    await deleteCollectionRequest(collectionId);
    return collectionId;
  },
);

export const updateCollection = createAsyncThunk(
  "collections/updateCollection",
  async (collection: ICollectionUpdate) => {
    await updateCollectionRequest(collection);
    return collection;
  },
);

export const createCollectionMember = createAsyncThunk(
  "collections/createCollectionMember",
  async (collectionMember: ICollectionMemberCreate) => {
    const collectionId = collectionMember.collectionId;
    const member: ICollectionMember = {
      id: collectionMember.id,
      description: collectionMember.description,
      values: collectionMember.values,
      deleted: false,
    };
    await createCollectionMemberRequest(member, collectionId);
    return collectionMember;
  },
);

export const deleteCollectionMember = createAsyncThunk(
  "collections/deleteCollectionMember",
  async ({
    collectionMemberId,
    collectionId,
  }: {
    collectionMemberId: string;
    collectionId: number;
  }) => {
    await deleteCollectionMemberRequest(collectionId, collectionMemberId);
    return { collectionMemberId, collectionId };
  },
);

export const updateCollectionMember = createAsyncThunk(
  "collections/updateCollectionMember",
  async (collectionMember: ICollectionMemberUpdate) => {
    const collectionId = collectionMember.collectionId;
    const member: ICollectionMember = {
      id: collectionMember.id,
      description: collectionMember.description,
      values: collectionMember.values,
      deleted: false,
    };
    await updateCollectionMemberRequest(member, collectionId);
    return collectionMember;
  },
);
