import { AxiosResponse } from "axios";
import { apiGET, apiPUT, apiPOST } from "@features/common/baseApiService";

// TODO replace any
export async function getAllSecretsRequest(
  customerId: string,
): Promise<AxiosResponse<any[]>> {
  const resource = `customers/${customerId}/secrets`;
  const response = await apiGET<any[]>(resource);
  return response;
}

export async function updateSecretRequest(
  customerId: string,
  secretName: string,
  payload: any,
): Promise<AxiosResponse<any[]>> {
  const resource = `customers/${customerId}/secrets/${secretName}`;
  const response = await apiPUT<any, any>(resource, payload);
  return response;
}

export async function postSecretRequest(
  customerId: string,
  payload: any,
): Promise<AxiosResponse<any[]>> {
  const resource = `customers/${customerId}/secrets`;
  const response = await apiPOST<any, any>(resource, payload);
  return response;
}
