import { AxiosResponse } from "axios";
import { apiGET } from "@features/common/baseApiService";
import { ISubnet } from "../types";

export async function getAllSubnetsRequest(
  customerId: string,
): Promise<AxiosResponse<ISubnet[]>> {
  const resource = `customers/${customerId}/environments/subnets`;
  const response = await apiGET<ISubnet[]>(resource);
  return response;
}
