import { blue } from "@mui/material/colors";
import { baseTheme } from "@app/theme";

/**
 * Styling for the FormControl that contains compute resources table and buttons
 */
export const formControlStyle = {
  border: `1px solid ${baseTheme.palette.secondary.light}`,
  borderRadius: "3px",
  display: "block",
  marginBottom: "16px",
  padding: baseTheme.spacing(2),
};

/**
 * Styling for the FormControlLabel that has a label for compute resources table and buttons
 */
export const formLabelStyle = {
  fontSize: "18px",
  padding: baseTheme.spacing(0, 2),
  textAlign: "center",
};

export const resourceFormStyle = {
  ...formControlStyle,
  "& .MuiTextField-root": {
    width: "100%",
  },
  "& .MuiInputBase-root": {
    marginBottom: 2,
    width: "100%",
  },
};

export const resourceFieldStyle = {
  marginBottom: "16px",
};

export const dialogFormStyle = {
  display: "block",
  marginBottom: "16px",
  marginTop: 2,
};

export const buttonGroupContainerStyle = {
  display: "flex",
  justifyContent: "end",
  gap: "10px",
  margin: "20px 0px 0px 0px",
  fontSize: "20px",
};

export const switchStyle = {
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: blue[700],
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: blue[700],
  },
};
