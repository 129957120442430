import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Dialog, DialogTitle, Button, DialogContent, Box } from "@mui/material";
import { SwitchMenu } from "./SwitchMenu";
import { IPermissions } from "./types";
import { getSelectedCustomerId } from "@features/customers/customerSlice";
import { permissionsApi } from "@features/authorization/authService";
import { checkPermissions } from "@features/authorization/types";

export const PermissionDialog = (props: any) => {
  const { openParent, setOpenParent, permissionsArray } = props;
  const selectedCustomerId = useSelector(getSelectedCustomerId);
  const {
    data: permissionDataCustomer,
    isLoading,
    isFetching,
  } = permissionsApi.useGetCustomerPermissionsQuery(selectedCustomerId);
  const {
    data: permissionDataOiva,
    isLoading: isLoadingOiva,
    isFetching: isFethingOiva,
  } = permissionsApi.useGetOivaPermissionsQuery();

  const [totalPermissions, setTotalPermissions] = useState<{
    [key: string]: IPermissions;
  }>({});

  // TODO this function is not actually used in SwitchMenu yet
  // intended to dispatch the site-wide permission change overrides or at least set the state and something else can then dispatch it
  const editTotalPermissions = (key: string, permissions: IPermissions) => {
    // let newPerms = { ...totalPermissions, key: permissions };
    // setTotalPermissions(newPerms);
    console.log("not implemented");
  };

  useEffect(() => {
    let fin: { [key: string]: IPermissions } = {};
    if (
      permissionDataCustomer &&
      permissionDataOiva &&
      !isLoading &&
      !isLoadingOiva
    ) {
      permissionDataCustomer.forEach((entry: string) => {
        const resource = entry.split("/")[0]; // entry is like "customer/read" --> we want to extract the part before /
        const result = checkPermissions(resource, permissionDataCustomer);
        if (!Object.keys(fin).includes(resource)) fin[resource] = result;
      });
      permissionDataOiva.forEach((entry: string) => {
        const resource = entry.split("/")[0]; // entry is like "customer/read" --> we want to extract the part before /
        const result = checkPermissions(resource, permissionDataOiva);
        if (!Object.keys(fin).includes(resource)) fin[resource] = result;
      });
    }

    setTotalPermissions(fin);
  }, [permissionDataCustomer, permissionDataOiva]);

  return (
    <>
      <Dialog open={openParent} maxWidth="lg">
        <DialogTitle>
          {"label-permissions"}
          <Button
            style={{ flex: "right", marginLeft: "100px" }}
            variant="contained"
            onClick={() => setOpenParent(false)}
          >
            Exit
          </Button>
        </DialogTitle>
        <DialogContent>
          {totalPermissions
            ? Object.keys(totalPermissions).map((key: string) => (
                <Box key={key}>
                  <SwitchMenu
                    key={key}
                    disabled={false}
                    style={{ width: "100%" }}
                    // selectItems={perms}
                    textFieldLabel={key}
                    menuId={key}
                    parentState={totalPermissions[key]}
                    setParentComponentState={editTotalPermissions}
                  />
                </Box>
              ))
            : null}
        </DialogContent>
      </Dialog>
    </>
  );
};
