import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid, Box, FormControl, FormLabel } from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { formLabelStyle } from "../style";
import { RootState } from "@app/store";
import { selectConnectionById } from "./connectionsSlice";
import { Connection } from "./types";
import EditButtonGroup from "@features/common/ButtonGroups/EditButtonGroup";
import ConnectionTypeSpecificInfo from "./ConnectionTypeSpecificInfo";
import { useCollection } from "@features/common/collectionHook";
import {
  FormTextField,
  FormTextArea,
  FormDropdownSelect,
} from "@features/common/FormFields";
import { formControlStyle } from "@features/common/styles";

interface IConnectionContainerProps {
  connectionId: string | undefined; // connection name is used as the id
}

/**
 * Form for displaying and editing a connection's information.
 *
 * TODO:
 *  - Many of the buttons are placeholders at the moment and have been disabled until they can actually be used.
 * @param
 * @returns
 */

const validationSchema = yup.object({
  name: yup.string().required(),
  description: yup.string().required(),
  type: yup.string().required(),
  status: yup.string().required(),
});

const ConnectionContainer = ({ connectionId }: IConnectionContainerProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslation();
  const initialConnection = useSelector((state: RootState) =>
    selectConnectionById(state, connectionId),
  );
  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: initialConnection?.name || "",
      description: initialConnection?.description || "",
      type: initialConnection?.type || "",
      status: initialConnection?.status || "",
    },
  });

  const [connection, setConnection] = useState<Connection | undefined>(
    initialConnection,
  );

  const connectionTypes = useCollection(6);
  const statusValues = useCollection(7);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.target;
    console.log(name, value, checked);
    if (connection) {
      if (name === "policy_based_traffic_selectors") {
        // special case for the checkbox
        setConnection({
          ...connection,
          [name]: checked,
        });
      } else {
        setConnection({
          ...connection,
          [name]: value,
        });
      }
      reset();
    }
  };

  const handleSave = (data: any) => {
    console.log("submitted", data);
  };

  const handleCancel = () => {
    setConnection(initialConnection);
    reset(initialConnection);
    setIsEditing(false);
  };

  return (
    <>
      {connection && (
        <Box>
          <FormControl component="fieldset" sx={formControlStyle}>
            <FormLabel component="legend" sx={formLabelStyle}>
              {t("label-connection-information")}
            </FormLabel>
            <Grid container>
              <Grid container spacing={2} marginBottom={1}>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="name"
                    control={control}
                    label={t("label-connection-name")}
                    readOnly={!isEditing}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="status"
                    control={control}
                    label={t("label-connection-status")}
                    readOnly={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextArea
                    name="description"
                    control={control}
                    label={t("label-connection-description")}
                    readOnly={!isEditing}
                    rows={4}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormDropdownSelect
                    name="type"
                    control={control}
                    label={t("label-connection-type")}
                    selectItems={connectionTypes}
                    disabled={!isEditing}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <ConnectionTypeSpecificInfo
                  readOnly={!isEditing}
                  connection={connection}
                  handleChange={handleChange}
                />
              </Grid>
              <EditButtonGroup
                isEditing={isEditing}
                disabled={false}
                handleEdit={() => setIsEditing(true)}
                handleSave={handleSubmit(handleSave)}
                handleCancel={handleCancel}
              />
            </Grid>
          </FormControl>
        </Box>
      )}
    </>
  );
};

export default ConnectionContainer;
