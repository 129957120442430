import React from "react";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material";

interface IProps {
  handleClick: () => void;
  label: string;
  disabled?: boolean;
  icon?: any;
  variant?: "contained" | "outlined" | "text";
}

type Props = IProps;

const LabelButton: React.FC<Props> = (props) => {
  const { handleClick, label, disabled, icon, variant } = props;
  const theme = useTheme();

  const computeStyles = () => {
    if (!variant || variant === "contained") {
      // Fill the button
      return {
        backgroundColor: theme.palette.primary.main,
      };
    } else {
      if (theme.palette.mode === "dark") {
        return {
          borderColor: theme.palette.secondary.light,
          color: theme.palette.primary.contrastText,
        };
      }
    }
    return {};
  };

  return (
    <Button
      variant={variant ? variant : "contained"}
      sx={computeStyles()}
      disabled={disabled}
      onClick={handleClick}
      data-testid={`${label.toLowerCase()}-button`}
    >
      {icon ? icon : label}
    </Button>
  );
};

export default LabelButton;
