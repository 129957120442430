import { AxiosResponse } from "axios";
import { apiGET } from "@features/common/baseApiService";
import { CloudSubscription } from "../types";

export async function getAllCloudSubscriptionsRequest(): Promise<
  AxiosResponse<CloudSubscription[]>
> {
  const resource = `customers/subscriptions`;
  const response = await apiGET<CloudSubscription[]>(resource);
  return response;
}
