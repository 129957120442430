import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Select,
  MenuItem,
  Button,
  Grid,
  FormHelperText,
  FormControl,
  CircularProgress,
  Alert,
  useTheme,
} from "@mui/material";

import {
  GalleryApplication,
  VMApplication,
} from "@features/environments/types";

/**
 * Dialog for installing VM applications.
 *
 */

interface Props {
  open: boolean;
  onClose: () => void;
  vmApplication?: GalleryApplication;
  handleInstall: (app: VMApplication) => void;
  loading?: boolean;
  vmStatus?: string;
}

const VMApplicationsInstall = (props: Props) => {
  const { open, onClose, vmApplication, handleInstall, loading, vmStatus } =
    props;
  const [selectedVersion, setSelectedVersion] = useState<string>(
    vmApplication?.versions[vmApplication?.versions.length - 1] || "",
  );
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();
  const theme = useTheme();
  const handleVersionChange = (version: string) => {
    setSelectedVersion(version);
  };

  const onInstall = () => {
    if (!selectedVersion || selectedVersion === "") {
      setError("Please select a version");
      return;
    }
    if (vmApplication && vmApplication.name) {
      handleInstall({
        name: vmApplication.name,
        version: selectedVersion,
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>{t("label-install-vm-app")}</DialogTitle>
      <DialogContent>
        {vmStatus?.includes("deallocated") && (
          <Alert severity="warning">
            Please check that VM is running before attempting to install an app.
          </Alert>
        )}
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant="head">{t("label-name")}</TableCell>
              <TableCell>{vmApplication?.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">{t("label-description")}</TableCell>
              <TableCell>{vmApplication?.description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">{t("label-version")}</TableCell>
              <TableCell>
                <FormControl sx={{ minWidth: 120 }} error={!!error}>
                  <Select
                    value={selectedVersion}
                    onChange={(e) => handleVersionChange(e.target.value)}
                    sx={{ minWidth: "110px" }}
                    disabled={loading}
                  >
                    {vmApplication?.versions.map((version) => (
                      <MenuItem key={version} value={version}>
                        {version}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{error}</FormHelperText>
                </FormControl>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          gap={1}
          marginTop={1}
        >
          <Button
            variant="contained"
            sx={{ backgroundColor: theme.palette.primary.main }}
            onClick={onInstall}
            disabled={loading}
            startIcon={loading && <CircularProgress size={24} />}
          >
            {t("label-button-install")}
          </Button>
          <Button
            variant="outlined"
            onClick={onClose}
            disabled={loading}
            sx={{
              backgroundColor: "white",
              color: "secondary.contrastText",
              borderColor: "secondary.light",
            }}
          >
            {t("button_form_cancel")}
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default VMApplicationsInstall;
