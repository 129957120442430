import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  getCustomerStampsRequest,
  getTaskStampsRequest,
  postStampRequest,
  deleteStampRequest,
  updateStampRequest,
} from "./stampsApiService";
import { RootState } from "@app/store";
import { AxiosResponse } from "axios";
import { IStamp } from "../types";

// TODO: Replace any
const stampsAdapter = createEntityAdapter<IStamp>({
  selectId: (stamp) => stamp.stamp_name,
});

const initialState = stampsAdapter.getInitialState({
  selectedStamp: "",
  status: "",
});

const stampSlice = createSlice({
  name: "stamps",
  initialState,
  reducers: {
    selectStamp(state, action: PayloadAction<string>) {
      state.selectedStamp = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStampsTask.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchStampsTask.fulfilled,
        (state, { payload }: PayloadAction<IStamp[]>) => {
          // todo replace any
          stampsAdapter.setAll(state, payload);
          state.status = "";
        },
      )
      .addCase(fetchStampsTask.rejected, (state) => {
        state.status = "";
      })
      .addCase(fetchStampsCustomer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchStampsCustomer.fulfilled,
        (state, { payload }: PayloadAction<IStamp[]>) => {
          // todo replace any
          stampsAdapter.setAll(state, payload);
          state.status = "";
        },
      )
      .addCase(fetchStampsCustomer.rejected, (state) => {
        state.status = "";
      })
      .addCase(createStamp.pending, (state) => {
        state.status = "submitting";
      })
      .addCase(
        createStamp.fulfilled,
        (state, { payload }: PayloadAction<IStamp>) => {
          stampsAdapter.addOne(state, payload);
          state.status = "";
        },
      )
      .addCase(createStamp.rejected, (state) => {
        state.status = "";
      })
      .addCase(updateStamp.pending, (state) => {
        state.status = "submitting";
      })
      .addCase(
        updateStamp.fulfilled,
        (state, { payload }: PayloadAction<IStamp>) => {
          stampsAdapter.upsertOne(state, payload);
          state.status = "";
        },
      )
      .addCase(updateStamp.rejected, (state) => {
        state.status = "";
      })
      .addCase(deleteStamp.pending, (state) => {
        state.status = "deleting";
      })
      .addCase(
        deleteStamp.fulfilled,
        (state, { payload }: PayloadAction<string>) => {
          stampsAdapter.removeOne(state, payload);
          state.status = "";
        },
      )
      .addCase(deleteStamp.rejected, (state) => {
        state.status = "";
      });
  },
});

// this is here because the asunc function below wanted only one argument so I wrapped them into an interface
export interface IdCollection {
  customerId: string;
  taskId: string;
}

export const fetchStampsTask = createAsyncThunk(
  "stamps/fetchStampsTask",
  async (ids: IdCollection) => {
    try {
      const res = await getTaskStampsRequest(ids.customerId, ids.taskId);
      if (res) return res.data;
      else return res;
    } catch (err) {
      console.error(err);
      throw new Error("failed to fetch stamps data");
    }
  },
);

export const fetchStampsCustomer = createAsyncThunk(
  "stamps/fetchStampsCustomer",
  async (customerId: string) => {
    // const resource_url = `customers/${customerId}/tasks/`;
    try {
      const res = await getCustomerStampsRequest(customerId);
      if (res) return res.data;
      else return res;
    } catch (err) {
      console.error(err);
      throw new Error("failed to fetch stamps data");
    }
  },
);

// this is here because the asunc function below wanted only one argument so I wrapped them into an interface
export interface StampDataCollection {
  payload: any;
}

export const createStamp = createAsyncThunk(
  "stamps/createStamp",
  async (data: StampDataCollection) => {
    const customerId = data.payload.customer_guid;
    const taskId = data.payload.task_guid;
    const payload = data.payload;
    await postStampRequest(customerId, taskId, payload);

    return payload;
  },
);

export const updateStamp = createAsyncThunk(
  "stamps/updateStamp",
  async (data: StampDataCollection) => {
    const stampName = data.payload.old_stamp_name;
    delete data.payload.old_stamp_name;
    const customerId = data.payload.customer_guid;
    const taskId = data.payload.task_guid;
    const payload = data.payload;

    await updateStampRequest(customerId, taskId, stampName, payload);

    return payload;
  },
);

export const deleteStamp = createAsyncThunk(
  "stamps/deleteStamp",
  async (data: StampDataCollection) => {
    const customerId = data.payload.customer_guid;
    const taskId = data.payload.task_guid;
    const stampName = data.payload.stamp_name;
    const res = await deleteStampRequest(customerId, taskId, stampName);
    return stampName;
  },
);

export const { selectStamp } = stampSlice.actions;
export default stampSlice.reducer;

const stampSelectors = stampsAdapter.getSelectors(
  (state: RootState) => state.stamps,
);

// // Selector for getting customer's tasks from the state
export const getAllStamps = (state: RootState, customerId: string) => {
  return stampSelectors
    .selectAll(state)
    .filter((stamp: any) => stamp.customer_guid === customerId);
};

export const getSelectedStamp = (state: RootState) => {
  return stampSelectors.selectById(state, state.stamps.selectedStamp);
};
// export const getSelectedTaskId = (state: RootState) => state.tasks.selectedTask;

// // expport const getSelectedTaskName = (state: RootState) => state.tasks.entities.

export const selectStampsStatus = (state: RootState) => state.stamps.status;
