import {
  CartesianGrid,
  ResponsiveContainer,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  LineChart,
  Line,
} from "recharts";
import { useTranslation } from "react-i18next";
import ChartInfoBox from "../ChartInfoBox";
import ErrorsChartTooltip from "./ErrorsChartTooltip";
import { EventErrorCount } from "./errorsChartUtils";

/**
 *  Component for displaying errors by day data in a line chart
 */

interface ErrorChartProps {
  data: EventErrorCount[];
}

const ErrorsChart = (props: ErrorChartProps) => {
  const { data } = props;
  const { t } = useTranslation();

  if (data.length === 0) {
    return <ChartInfoBox severity="info" text={t("label-no-data")} />;
  }

  return (
    <ResponsiveContainer>
      <LineChart
        data={data}
        margin={{
          top: 10,
          right: 10,
          left: 10,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="5 5" />
        <XAxis hide dataKey="date" />
        <YAxis />
        <Tooltip content={<ErrorsChartTooltip />} animationDuration={500} />
        <Legend wrapperStyle={{ fontSize: "90%", marginBottom: "-10px" }} />
        <Line name={t("label-error-count")} type="monotone" dataKey="errors" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ErrorsChart;
