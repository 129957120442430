/**
 *
 */
import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

interface IComponentProps {
  searchParameters: string;
  setSearchParameters: (s: string) => void;
}

/**
 * The search field component used by the CustomerDialog. The current state of the text field, as well as handler for changes is defined in the component calling it
 * @param props
 * @returns
 */
const SearchField = (props: IComponentProps) => {
  const { searchParameters, setSearchParameters } = props;
  const { t } = useTranslation();
  return (
    <Grid sx={{ margin: "16px" }}>
      <TextField
        sx={{ width: "100%" }}
        id="customer-search"
        data-testid="customer-search"
        label={t("label-search")}
        type="search"
        variant="outlined"
        value={searchParameters}
        onChange={(
          event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => setSearchParameters(event.target.value)}
      />
    </Grid>
  );
};

export default SearchField;
