import Subnets from "./Subnets";
import VNet from "./VNet";

const Networks = () => {
  return (
    <>
      <VNet />
      <Subnets />
    </>
  );
};

export default Networks;
