import { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid, Dialog, DialogTitle, DialogContent } from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import { DropdownSelect } from "@features/common/DropdownSelect";
import { useAppDispatch } from "@features/common/StoreHook";
import { getSelectedCustomerId } from "@features/customers/customerSlice";
import { RootState } from "@app/store";
import SaveCancelButtonGroup from "@features/common/ButtonGroups/SaveCancelButtonGroup";
import { FormDropdownSelect, FormTextField } from "@features/common/FormFields";

import { IRole, IUserCreate, InternalUser } from "../types";
import { fetchInternalUsers, getInternalUsers } from "./internalUserSlice";
import { getAllRoles } from "./rolesSlice";
import { addUserRole, AddUserRoleData, selectUsersStatus } from "./userSlice";
import { CreateUserDataBundle, createUser, fetchUsers } from "./userSlice";

interface AddUserDialogProps {
  onCancel: () => void;
  open: boolean;
}

/**
 *
 * Dialog window for adding User.
 *
 * Separates between "internal user" (== MostDigital user with no roles) and "guest user" (non MostDigital users)
 */

/**
 * TODOs
 * - errors not gracefully handled
 * - refactor internal user form and guest user form to separate components
 */

const internalUserValidationSchema = yup.object({
  user_id: yup.string().required(),
  role_id: yup.number().required(),
});

const guestUserValidationSchema = yup.object({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  email: yup.string().email().required(),
  role_id: yup.number().required(),
});

export const AddUserDialog = (props: AddUserDialogProps) => {
  const { onCancel, open } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const customerId: string = useSelector(getSelectedCustomerId);
  const internalUsers: InternalUser[] | undefined =
    useSelector(getInternalUsers);
  const roles: IRole[] = useSelector(
    (state: RootState) => getAllRoles(state),
    shallowEqual,
  );
  const isSubmitting = useSelector(selectUsersStatus) === "submitting";

  const [userTypeInternalSelected, setUserTypeInternalSelected] =
    useState<boolean>(false);

  const {
    handleSubmit: handleInternalUserSubmit,
    control: controlInternalUser,
    reset: resetInternalUser,
  } = useForm({
    resolver: yupResolver(internalUserValidationSchema),
    defaultValues: {
      user_id: "",
      role_id: undefined,
    },
  });

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(guestUserValidationSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      role_id: undefined,
    },
  });

  const userTypes = [
    { id: "guest", value: "Guest", label: "Guest" },
    { id: "internal", value: "Internal", label: "Internal" },
  ];

  useEffect(() => {
    dispatch(fetchInternalUsers(customerId));
  }, [customerId]);

  const handleUserTypeChange = (event: string) => {
    event === "Internal"
      ? setUserTypeInternalSelected(true)
      : setUserTypeInternalSelected(false);
  };

  const handleInternalUserFormSubmit = (data: any) => {
    const roleId = parseInt(data.role_id);
    const roleName = roles.find((role) => role.role_id === roleId)?.role_name;
    const dataBundle: AddUserRoleData = {
      customerId: customerId,
      userId: data.user_id,
      payload: { role_id: roleId, role_name: roleName || "" },
    };

    // this throws some errors now related to failed Azure AD stuff
    dispatch(addUserRole(dataBundle))
      .unwrap()
      .then(() => {
        dispatch(fetchUsers(customerId));
        toast.success("Role added to user");
      })
      .catch((err) => {
        console.error(err);
        toast.error("Failed to add role to user");
      });
    onCancel();
  };

  const handleGuestUserSubmit = (data: any) => {
    const roleId = parseInt(data.role_id);
    let userData: IUserCreate = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      role_id: roleId,
      type: "guest",
    };

    const dataBundle: CreateUserDataBundle = {
      payload: userData,
      customerId: customerId,
    };

    dispatch(createUser(dataBundle))
      .unwrap()
      .then(() => {
        dispatch(fetchUsers(customerId));
        toast.success("User created successfully");
        onCancel();
      })
      .catch((err) => {
        console.error(err);
        toast.error("Failed to create user");
      });
  };

  const handleCancel = () => {
    reset();
    resetInternalUser();
    onCancel();
  };

  const mappedRoles = roles.map((role) => ({
    id: role.role_id,
    value: role.role_id.toString(),
    label: role.role_name,
  }));

  // map the internal users to the format required by the dropdown,
  // and sort them alphabetically
  const mappedUsers = internalUsers
    .map((user) => ({
      id: user.user_id,
      value: user.user_id,
      label: user.user_name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <>
      <Dialog open={open} fullWidth>
        <DialogTitle>{t("label-add-new-user")}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} marginTop={1}>
              <DropdownSelect
                disabled={isSubmitting}
                menuId={"usertype"}
                style={{ width: "100%" }}
                selectItems={userTypes}
                setParentComponentState={handleUserTypeChange}
                selectedItem={userTypeInternalSelected ? "Internal" : "Guest"}
                textFieldLabel={t("label-type")}
              />
            </Grid>
            {userTypeInternalSelected ? (
              <>
                <Grid item xs={12}>
                  <FormDropdownSelect
                    name="user_id"
                    control={controlInternalUser}
                    label={t("label-internal-user")}
                    selectItems={mappedUsers}
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormDropdownSelect
                    name="role_id"
                    control={controlInternalUser}
                    label={t("label-role")}
                    selectItems={mappedRoles}
                    disabled={isSubmitting}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="first_name"
                    control={control}
                    label={t("label-user-first-name")}
                    readOnly={isSubmitting}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="last_name"
                    control={control}
                    label={t("label-user-last-name")}
                    readOnly={isSubmitting}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="email"
                    control={control}
                    label={t("label-email")}
                    readOnly={isSubmitting}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormDropdownSelect
                    name="role_id"
                    control={control}
                    label={t("label-role")}
                    selectItems={mappedRoles}
                    disabled={isSubmitting}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <SaveCancelButtonGroup
                disabled={isSubmitting}
                handleSave={
                  userTypeInternalSelected
                    ? handleInternalUserSubmit(handleInternalUserFormSubmit)
                    : handleSubmit(handleGuestUserSubmit)
                }
                handleCancel={handleCancel}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
