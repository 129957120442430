import { useTranslation } from "react-i18next";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { formatToNDecimals } from "../utils";
import { calculatePercentage } from "./vmUtilizationUtils";

type ComponentProps = {
  data: any;
  typeTranslations: any[];
};

const VmUtilizationTable = (props: ComponentProps) => {
  const { data, typeTranslations } = props;
  const { t } = useTranslation();
  const tableHeaderTitles = [
    "label-in-minutes",
    "label-in-hours",
    "label-percentage-share",
  ];

  const dataRows = data.map((dataType: any, index: number) => {
    return (
      <TableRow key={dataType + index}>
        <TableCell sx={{ width: "30vh" }}>
          {t(typeTranslations[index])}
        </TableCell>
        <TableCell>{dataType.value}</TableCell>
        <TableCell>{formatToNDecimals(dataType.value / 60, 1)}</TableCell>
        <TableCell>{calculatePercentage(dataType.value, data)}</TableCell>
      </TableRow>
    );
  });

  return (
    <>
      <Table aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "10px" }}></TableCell>
            {tableHeaderTitles.length !== 0
              ? tableHeaderTitles.map((key) => (
                  <TableCell key={key}>{t(key)}</TableCell>
                ))
              : null}
          </TableRow>
        </TableHead>
        <TableBody>{dataRows}</TableBody>
      </Table>
    </>
  );
};

export default VmUtilizationTable;
