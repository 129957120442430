import { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  Checkbox,
  FormControl,
  FormLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { lightBlue } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";

import { RootState } from "@app/store";
import { formControlStyle, formLabelStyle } from "@features/common/styles";
import { useAppDispatch } from "@features/common/StoreHook";
import { getSelectedCustomerId } from "@features/customers/customerSlice";
import AddDeleteButtonGroup from "@features/common/ButtonGroups/AddDeleteButtonGroup";
import {
  Order,
  stableSort,
  getComparator,
} from "@features/common/Table/SortTableHead/tableSortFunctions";
import LoadingIndicatorBox from "@features/common/LoadingIndicatorBox/LoadingIndicatorBox";
import { ConfirmActionDialog } from "@features/common/ConfirmAction";
import { permissionsApi } from "@features/authorization/authService";
import { checkPermissions } from "@features/authorization/types";
import ExportExcel from "@features/common/ExportExcel";
import { ITask, ISchedule } from "../types";
import { getSelectedTask } from "../tasksSlice";
import {
  fetchSchedules,
  ScheduleIdCollection,
  getTaskSchedules,
  selectSchedule,
  deleteSchedule,
  selectSchedulesStatus,
} from "./scheduleSlice";
import { AddSchedule } from "./AddSchedule";
import { Scheduler } from "./Scheduler";
import SchedulerTableHeader from "./SchedulerTableHeader";
import { schedulerEventsTableEmptinessIndicatorCell } from "./styles";

interface ScheduledEventProps {}

// Property interface for schedules table
interface IScheduleTableProperty {
  key:
    | "schedule_guid"
    | "startDatetime"
    | "startTime"
    | "endDate"
    | "endTime"
    | "recurrenceType";
  label: string;
}

export const ScheduledEvents = (props: ScheduledEventProps) => {
  // setup
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>("");
  const [selectedSchedules, setSelectedSchedules] = useState<string[]>([]);
  const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false);

  // vars
  const selectedTask: ITask | undefined = useSelector((state: RootState) =>
    getSelectedTask(state),
  );
  const customerId = useSelector(getSelectedCustomerId);
  const schedulesArray: ISchedule[] = useSelector(
    (state: RootState) =>
      getTaskSchedules(state, customerId, selectedTask?.task_guid),
    shallowEqual,
  );

  const isLoading =
    useSelector((state: any) => selectSchedulesStatus(state)) === "loading";

  const { data, isLoading: isLoadingPermissions } =
    permissionsApi.useGetCustomerPermissionsQuery(customerId);
  const schedulePermissions = checkPermissions("schedules", data);

  const scheduleProperties: IScheduleTableProperty[] = [
    { key: "startDatetime", label: t("label-start-date") },
    { key: "startTime", label: t("label-start-time") },
    { key: "endDate", label: t("label-end-date") },
    { key: "endTime", label: t("label-end-time") },
    { key: "recurrenceType", label: t("label-recurring") },
  ];

  const determineRecurrenceType = (schedule: any) => {
    const recurrence = schedule.daily_recurrence
      ? "recurrence_daily"
      : schedule.weekly_recurrence
      ? "recurrence_weekly"
      : schedule.monthly_recurrence
      ? "recurrence_monthly"
      : schedule.cron_recurrence
      ? "recurrence_cron"
      : "recurrence_none";
    return recurrence;
  };

  const formatData = (schedule: any) => {
    const formattedStartDate = dayjs(schedule.start_datetime).format(
      "YYYY-MM-DD",
    );
    const formattedStartTime = dayjs(schedule.start_datetime).format("HH:mm");
    const formattedEndDate = dayjs(schedule.end_datetime).format("YYYY-MM-DD");
    const formattedEndTime = dayjs(schedule.end_datetime).format("HH:mm");

    const recurrenceType = determineRecurrenceType(schedule);
    return {
      startDatetime: formattedStartDate,
      startTime: formattedStartTime,
      endDate: formattedEndDate,
      endTime: formattedEndTime,
      recurrenceType: t(recurrenceType),
      // fields below are used when exporting schedule information to excel
      taskName: selectedTask?.task_name || "",
      vmName: schedule.target_vm,
      frequency: schedule.daily_recurrence
        ? schedule.daily_recurrence.frequency
        : schedule.weekly_recurrence
        ? schedule.weekly_recurrence.frequency
        : schedule.monthly_recurrence
        ? schedule.monthly_recurrence.frequency
        : "",
      dayNumber: schedule.monthly_recurrence?.day_number || "",
      runsMonday:
        recurrenceType !== "recurrence_weekly"
          ? ""
          : schedule.weekly_recurrence.runs_monday,
      runsTuesday:
        recurrenceType !== "recurrence_weekly"
          ? ""
          : schedule.weekly_recurrence.runs_tuesday,
      runsWednesday:
        recurrenceType !== "recurrence_weekly"
          ? ""
          : schedule.weekly_recurrence.runs_wednesday,
      runsThursday:
        recurrenceType !== "recurrence_weekly"
          ? ""
          : schedule.weekly_recurrence.runs_thursday,
      runsFriday:
        recurrenceType !== "recurrence_weekly"
          ? ""
          : schedule.weekly_recurrence.runs_friday,
      runsSaturday:
        recurrenceType !== "recurrence_weekly"
          ? ""
          : schedule.weekly_recurrence.runs_saturday,
      runsSunday:
        recurrenceType !== "recurrence_weekly"
          ? ""
          : schedule.weekly_recurrence.runs_sunday,
      cronString: schedule.cron_recurrence?.cron_string || "",
      duration: schedule.cron_recurrence?.duration || "",
    };
  };

  const handleClickOpenAdd = () => {
    if (schedulePermissions.create) {
      setAddDialogOpen(true);
    } else {
      toast.error("No create permission");
    }
  };

  const handleClickCloseAdd = () => {
    setAddDialogOpen(false);
  };

  const handleCheckbox = (guid: string) => {
    if (selectedSchedules.includes(guid)) {
      setCheckboxChecked(false);
      dispatch(selectSchedule(""));
      setSelectedSchedules(selectedSchedules.filter((id) => id !== guid));
    } else {
      setCheckboxChecked(true);
      dispatch(selectSchedule(guid));
      setSelectedSchedules([...selectedSchedules, guid]);
    }
  };

  const onDeleteClick = () => {
    selectedSchedules.length > 0
      ? setConfirmDialogOpen(true)
      : toast.error("No schedule selected");
  };

  const handleDelete = () => {
    if (schedulePermissions.delete) {
      if (selectedSchedules.length > 0 && selectedTask && customerId) {
        dispatch(
          deleteSchedule({
            customerId: customerId,
            taskId: selectedTask.task_guid,
            scheduleIds: selectedSchedules,
          }),
        )
          .unwrap()
          .then(() => {
            toast.success("Schedule deleted successfully");
            setCheckboxChecked(false);
            setSelectedSchedules([]);
          })
          .catch((e) => {
            toast.error("Failed to delete schedule");
          });
        setConfirmDialogOpen(false);
      }
    } else {
      toast.error("No delete permission");
    }
  };

  useEffect(() => {
    if (selectedTask && customerId) {
      const idCollection: ScheduleIdCollection = {
        customerId: customerId,
        taskId: selectedTask.task_guid,
      };
      dispatch(fetchSchedules(idCollection));
    }
  }, []);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSchedules(
      event.target.checked
        ? schedulesArray.map((schedule) => schedule.schedule_guid)
        : [],
    );
  };

  const propertyMappedSchedules = schedulesArray.map((schedule) => ({
    schedule_guid: schedule.schedule_guid,
    ...formatData(schedule),
  }));

  const renderTableContent = () => {
    if (!isLoading && propertyMappedSchedules.length > 0) {
      return (
        <>
          <SchedulerTableHeader
            properties={scheduleProperties}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            checkedAmount={selectedSchedules.length}
            scheduleAmount={schedulesArray.length}
            handleSelectAll={handleSelectAll}
          />
          <TableBody>
            {stableSort(
              propertyMappedSchedules,
              getComparator(order, orderBy),
            ).map((schedule) => (
              <TableRow
                key={schedule.schedule_guid}
                data-testid="schedule-row"
                onClick={() => handleCheckbox(schedule.schedule_guid)}
                style={{
                  backgroundColor: selectedSchedules.includes(
                    schedule.schedule_guid,
                  )
                    ? theme.palette.secondary.main
                    : "",
                }}
              >
                <TableCell key={"id_field"}>
                  <Checkbox
                    key={schedule.schedule_guid}
                    name="schedule_checkbox"
                    checked={selectedSchedules.includes(schedule.schedule_guid)}
                    style={{
                      color: selectedSchedules.includes(schedule.schedule_guid)
                        ? theme.palette.primary.main
                        : theme.palette.primary.dark,
                    }}
                  />
                </TableCell>
                {scheduleProperties.map((property: IScheduleTableProperty) => (
                  <TableCell key={property.key}>
                    <Typography>{schedule[property.key]}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </>
      );
    }

    if (!isLoading && propertyMappedSchedules.length === 0) {
      return (
        <TableBody>
          <TableRow>
            <TableCell style={schedulerEventsTableEmptinessIndicatorCell}>
              {t("label-empty-table-text")}
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }
  };

  return (
    <>
      <FormControl
        component="fieldset"
        sx={{ ...formControlStyle, paddingTop: 0 }}
      >
        <FormLabel component="legend" sx={formLabelStyle}>
          {t("label-scheduled-events")}
        </FormLabel>
        {!isLoading && (
          <Grid
            container
            justifyContent="flex-end"
            sx={{ paddingBottom: "0.5rem" }}
          >
            <ExportExcel
              disabled={propertyMappedSchedules.length < 1}
              data={propertyMappedSchedules}
              fileName="schedules"
              coloredButton={true}
            />
          </Grid>
        )}
        <TableContainer sx={{ maxHeight: "45vh" }}>
          <Table stickyHeader size="small">
            {renderTableContent()}
          </Table>
        </TableContainer>
        <LoadingIndicatorBox show={isLoading} />

        <Grid container sx={{ justifyContent: "flex-end" }}>
          <AddDeleteButtonGroup
            disabled={isLoadingPermissions || confirmDialogOpen}
            handleAdd={handleClickOpenAdd}
            handleDelete={
              selectedSchedules.length > 0 ? onDeleteClick : undefined
            }
          />
        </Grid>
      </FormControl>
      <Dialog open={addDialogOpen} fullWidth={true} maxWidth="lg">
        <DialogTitle>Add New Schedule</DialogTitle>
        <DialogContent>
          <AddSchedule onCancel={handleClickCloseAdd} />
        </DialogContent>
      </Dialog>
      <ConfirmActionDialog
        show={confirmDialogOpen}
        action={"DELETE"}
        resource={`Schedule${selectedSchedules.length > 1 ? "s" : ""} ${
          selectedSchedules.length > 1
            ? "(" + selectedSchedules.length + ")"
            : ""
        } `}
        onConfirm={handleDelete}
        onCancel={() => setConfirmDialogOpen(false)}
      />
      {checkboxChecked ? <Scheduler /> : <></>}
    </>
  );
};
