import dayjs from "dayjs";
import { TimelineEvent, ActiveTimelineItem, EventType } from "../../types";

export interface EventErrorCount {
  date: string;
  errors: number;
}

export const calculateErrorsByDay = (
  events: TimelineEvent[],
  activeItem: ActiveTimelineItem,
  startDate: Date,
  endDate: Date,
) => {
  const eventReducer = (
    accumulatorMap: Map<string, EventErrorCount>,
    event: TimelineEvent,
  ) => {
    const isEventPartOfActiveItem: boolean =
      (activeItem.type === EventType.VM && activeItem.id === event.vmId) ||
      (activeItem.type === EventType.Task &&
        activeItem.id === event.resourceId) ||
      (activeItem.type === EventType.Event && activeItem.id === event.id);

    if (
      isEventPartOfActiveItem &&
      dayjs(event.scheduled_start_time).isAfter(startDate) &&
      dayjs(event.scheduled_stop_time).isBefore(endDate)
    ) {
      const key = dayjs(event.scheduled_start_time).format("YYYY-MM-DD");
      const count = accumulatorMap.has(key)
        ? accumulatorMap.get(key)?.errors || 0
        : 0;
      accumulatorMap.set(key, {
        date: key,
        errors: count + (event.errorCount || 0),
      });
    }
    return accumulatorMap;
  };

  const startMap = new Map<string, EventErrorCount>();
  let current = dayjs(startDate);
  const end = dayjs(endDate);

  // initialize map with all days between start and end date, with 0 errors
  while (current.isBefore(end)) {
    const key = current.format("YYYY-MM-DD");
    startMap.set(key, {
      date: key,
      errors: 0,
    });
    current = current.add(1, "day");
  }

  // reduce all events to error count by day
  const result = events.reduce(eventReducer, startMap);

  return Array.from(result.values());
};

export const formatDateToFinnishStyle = (date: string | Date) => {
  let dateObj = date;
  if (typeof dateObj === "string") {
    dateObj = new Date(date);
  }
  return dateObj.toLocaleDateString("fi-FI");
};
