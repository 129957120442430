import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createSelector,
} from "@reduxjs/toolkit";
import {
  getTranslationsRequest,
  updateTranslationRequest,
  createTranslationRequest,
  deleteTranslationRequest,
} from "./translationsApiService";
import { RootState } from "@app/store";
import { Translation } from "./types";

const translationAdapter = createEntityAdapter<Translation>({
  selectId: (translation) => translation.text_id,
});

const initialState = translationAdapter.getInitialState({
  status: "",
});

export const translationsSlice = createSlice({
  name: "translations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTranslations.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchTranslations.fulfilled,
        (state, { payload }: PayloadAction<Translation[]>) => {
          translationAdapter.setAll(state, payload);
          state.status = "";
        },
      )
      .addCase(fetchTranslations.rejected, (state) => {
        state.status = "";
      })
      .addCase(updateTranslation.pending, (state) => {
        state.status = "submitting";
      })
      .addCase(
        updateTranslation.fulfilled,
        (state, { payload }: PayloadAction<Translation>) => {
          translationAdapter.upsertOne(state, payload);
          state.status = "";
        },
      )
      .addCase(updateTranslation.rejected, (state) => {
        state.status = "";
      })
      .addCase(createTranslation.pending, (state) => {
        state.status = "submitting";
      })
      .addCase(
        createTranslation.fulfilled,
        (state, { payload }: PayloadAction<Translation>) => {
          translationAdapter.addOne(state, payload);
          state.status = "";
        },
      )
      .addCase(createTranslation.rejected, (state) => {
        state.status = "";
      })
      .addCase(deleteTranslation.pending, (state) => {
        state.status = "deleting";
      })
      .addCase(deleteTranslation.fulfilled, (state, { payload }) => {
        translationAdapter.removeOne(state, payload);
        state.status = "";
      })
      .addCase(deleteTranslation.rejected, (state) => {
        state.status = "";
      });
  },
});

export const translationActions = translationsSlice.actions;
export default translationsSlice.reducer;

export const selectTranslationsStatus = (state: RootState) =>
  state.translations.status;

const translationsSelectors = translationAdapter.getSelectors(
  (state: RootState) => state.translations,
);

export const selectTranslations = translationsSelectors.selectAll;

export const selectAllTranslations = createSelector(
  selectTranslations,
  (translations) =>
    translations.map((translation) => ({
      text_id: translation.text_id,
      value_en:
        translation.values.find((value) => value.language_code === "en-US")
          ?.value || "",
      value_fi:
        translation.values.find((value) => value.language_code === "fi-FI")
          ?.value || "",
    })),
);

export const selectTranslationById = (state: RootState, id: string) =>
  translationsSelectors.selectById(state, id);

export const fetchTranslations = createAsyncThunk(
  "translations/fetchTranslations",
  async () => {
    const response = await getTranslationsRequest();
    return response.data;
  },
);

export const updateTranslation = createAsyncThunk(
  "translations/updateTranslation",
  async (translation: Translation) => {
    await updateTranslationRequest(translation);
    return translation;
  },
);

export const createTranslation = createAsyncThunk(
  "translations/createTranslation",
  async (translation: Translation) => {
    const response = await createTranslationRequest(translation);
    return response.data;
  },
);

export const deleteTranslation = createAsyncThunk(
  "translations/deleteTranslation",
  async (id: string) => {
    await deleteTranslationRequest(id);
    return id;
  },
);
