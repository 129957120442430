import { useEffect, useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Button,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

import { RootState } from "@app/store";
import { getSelectedTask } from "../tasksSlice";
import { ITask, ISchedule } from "../types";

interface LeftRightProps {
  setleftGlobal: React.Dispatch<React.SetStateAction<string>>;
  isEditing?: boolean;
  isSubmitting?: boolean;
  selectedSchedule?: ISchedule;
  error: any;
  setError: any;
}

/**
 *
 * @param props
 * setleftGlobal => sets state of parent component so it knows what to dispatch to redux and API
 * isEditing => boolean that determines if the parent component is in "edit" mode or not
 *
 * @returns
 * Left and Right window that lists a selection of which virtual machine to use
 * Allows only one VM to the left ("Selected") side (as schema suggests it should be a string rather than list of strings)
 *
 * This component is visually similar to Environments/LeftRightSelector.tsx, but functions slightly differently so it should be its own component.
 * Perhaps could be renamed to avoid confusion with other LeftRightSelector
 *
 */
export const LeftRightSelector = (props: LeftRightProps) => {
  const { t } = useTranslation();

  const {
    setleftGlobal,
    isEditing,
    isSubmitting,
    selectedSchedule,
    error,
    setError,
  } = props;

  const [leftVal, setLeftVal] = useState<string>("");
  const [rightList, setRightList] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [leftButtonDisabled, setLeftButtonDisabled] = useState<boolean>(true);
  const [rightButtonDisabled, setRightButtonDisabled] = useState<boolean>(true);

  const selectedTask: ITask | undefined = useSelector((state: RootState) =>
    getSelectedTask(state),
  );

  // populate left and right vm names
  useEffect(() => {
    if (selectedSchedule) {
      if (selectedTask) {
        setRightList(
          selectedTask.vm_names.filter(
            (vm) => vm !== selectedSchedule.target_vm,
          ),
        );
      }
      if (selectedSchedule.target_vm) {
        setLeftVal(selectedSchedule.target_vm);
        setleftGlobal(selectedSchedule.target_vm);
      } else {
        setLeftVal("");
        setleftGlobal("");
      }
    } else {
      if (selectedTask) {
        setRightList(selectedTask.vm_names);
      }
    }
  }, [selectedSchedule, isEditing]);

  // extract the selected "option" from the Select menu (left or right TextField)
  const handleSelectedVM = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  // moves vm from right to left view
  // currently de-selects item after, this makes general state management easier
  const shiftVMLeft = () => {
    if (selectedOption && !leftVal) {
      setLeftVal(selectedOption);
      setleftGlobal(selectedOption);
      let list = rightList.filter((str) => str !== selectedOption);
      setRightList(list);
      setSelectedOption("");
      if (error) {
        setError("");
      }
    }
  };

  // moves vm from left to right view
  // currently de-selects item after, this makes general state management easier
  const shiftVMRight = () => {
    if (selectedOption) {
      let list = rightList;
      list.push(selectedOption);
      setRightList(list);
      setLeftVal("");
      setleftGlobal("");
      setSelectedOption("");
    }
  };

  useEffect(() => {
    if (leftVal === selectedOption && leftVal !== "") {
      setLeftButtonDisabled(true);
      setRightButtonDisabled(false);
    } else if (rightList.includes(selectedOption) && leftVal === "") {
      setLeftButtonDisabled(false);
      setRightButtonDisabled(true);
    } else {
      setLeftButtonDisabled(true);
      setRightButtonDisabled(true);
    }
  }, [selectedOption]);

  return (
    <>
      <Grid item container justifyContent={"center"} alignContent={"center"}>
        <Grid item justifyContent={"center"} xs={12} sm={5}>
          <Typography variant="subtitle1">
            {t("label-split-selected")}
          </Typography>
          {false ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : (
            <TextField
              fullWidth
              select
              data-testid="left-field"
              SelectProps={{
                native: true,
                multiple: true,
              }}
              disabled={false}
              onChange={handleSelectedVM}
              value={[selectedOption]}
              variant="outlined"
              error={!!error}
              helperText={error ?? null}
            >
              {leftVal ? (
                <option key={leftVal} value={leftVal}>
                  {leftVal}
                </option>
              ) : (
                <></>
              )}
            </TextField>
          )}
        </Grid>

        <Grid
          item
          xs={2}
          container
          justifyContent="center"
          direction="column"
          alignContent="center"
          spacing={1}
          paddingTop={2}
          paddingBottom={1}
        >
          <Grid item justifyContent={"center"}>
            <Button
              variant="contained"
              disabled={
                !isEditing ||
                isSubmitting ||
                rightButtonDisabled ||
                selectedOption === ""
              }
              onClick={shiftVMRight}
            >
              <ChevronRight />
            </Button>
          </Grid>

          <Grid item justifyContent={"center"}>
            <Button
              data-testid="shift-vm-left"
              variant="contained"
              disabled={
                !isEditing ||
                isSubmitting ||
                leftButtonDisabled ||
                selectedOption === ""
              }
              onClick={shiftVMLeft}
            >
              <ChevronLeft />
            </Button>
          </Grid>
        </Grid>

        <Grid item justifyContent={"center"} xs={12} sm={5}>
          <Typography variant="subtitle1">
            {t("label-split-available")}
          </Typography>
          {false ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : (
            <TextField
              select
              SelectProps={{
                native: true,
                multiple: true,
              }}
              fullWidth
              onChange={handleSelectedVM}
              value={[selectedOption]}
              variant="outlined"
            >
              {rightList.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </TextField>
          )}
        </Grid>
      </Grid>
    </>
  );
};
