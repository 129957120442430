import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { Provider } from "react-redux";
import { store } from "./store";
import { Layout } from "./Layout";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin, appInsights } from "../appInsights/AppInsights";
import { ErrorBoundary } from "../appInsights/AppInsightsErrorBoundary";
import "./i18nSetup";
import "../configurations/dayjsConfig";

type AppProps = {
  instance: IPublicClientApplication;
};

/**
 * The main App component. Wraps everything inside the Provider and MsalProvider components
 * @param instance: IPublicClientApplication, the msal instance used for authentication
 * @returns
 */
const App = ({ instance }: AppProps) => {
  return (
    <>
      <Provider store={store}>
        <MsalProvider instance={instance}>
          <AppInsightsContext.Provider value={reactPlugin}>
            <ErrorBoundary appInsights={appInsights}>
              <RouterProvider router={router} />
            </ErrorBoundary>
          </AppInsightsContext.Provider>
        </MsalProvider>
      </Provider>
      <ToastContainer position="bottom-right" />
    </>
  );
};

export default App;
