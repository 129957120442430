import { useTranslation } from "react-i18next";
import { Box, Button } from "@mui/material";
import { buttonGroupContainerStyle } from "./styles";

interface IProps {
  disabled: boolean;
  handleAdd?: () => void;
  handleDuplicate?: () => void;
  handleDelete?: () => void;
}

const AddDuplicateDeleteButtonGroup = (props: IProps) => {
  const { disabled, handleAdd, handleDuplicate, handleDelete } = props;
  const { t } = useTranslation();

  return (
    <Box sx={buttonGroupContainerStyle}>
      <Button
        data-testid="add-button"
        onClick={handleAdd}
        variant="contained"
        disabled={disabled || !handleAdd}
      >
        {t("button_table_addnew")}
      </Button>
      <Button
        onClick={handleDuplicate}
        variant="outlined"
        disabled={disabled || !handleDuplicate}
        sx={
          disabled || !handleDuplicate
            ? {
                backgroundColor: "rgba(0, 0, 0, 0.12);",
                color: "white",
              }
            : {
                backgroundColor: "white",
                color: "secondary.contrastText",
                borderColor: "secondary.light",
              }
        }
      >
        {t("button_table_duplicate")}
      </Button>
      <Button
        onClick={handleDelete}
        variant="contained"
        disabled={disabled || !handleDelete}
        sx={
          disabled || !handleDelete
            ? {
                backgroundColor: "rgba(0, 0, 0, 0.12);",
                color: "white",
              }
            : {
                backgroundColor: "white",
                color: "secondary.contrastText",
                borderColor: "secondary.light",
              }
        }
      >
        {t("button_table_delete")}
      </Button>
    </Box>
  );
};

export default AddDuplicateDeleteButtonGroup;
