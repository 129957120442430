import { useSelector, shallowEqual } from "react-redux";
import { useState, useEffect } from "react";
import {
  Grid,
  FormLabel,
  FormControl,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { useAppDispatch } from "@features/common/StoreHook";
import { getSelectedCustomerId } from "@features/customers/customerSlice";
import { formControlStyle, formLabelStyle } from "@features/common/styles";
import AddDeleteButtonGroup from "@features/common/ButtonGroups/AddDeleteButtonGroup";
import SortTable, { IProperty } from "@features/common/Table/SortTable";
import EditButtonGroup from "@features/common/ButtonGroups/EditButtonGroup";
import FormTextField from "@features/common/FormFields/FormTextField";
import FormTextArea from "@features/common/FormFields/FormTextArea";

import {
  fetchStampsTask,
  getAllStamps,
  IdCollection,
  selectStampsStatus,
  selectStamp,
  getSelectedStamp,
  deleteStamp,
  updateStamp,
  StampDataCollection,
} from "./stampsSlice";
import { getSelectedTaskId } from "../tasksSlice";
import { AddStamp } from "./AddStamp";
import { IStamp } from "../types";
import { permissionsApi } from "@features/authorization/authService";
import { checkPermissions } from "@features/authorization/types";
import { toast } from "react-toastify";
import { ConfirmActionDialog } from "@features/common/ConfirmAction";

const validationSchema = yup.object({
  stamp_name: yup.string().required(),
  stamp_screen_name: yup.string().required(),
  stamp_description: yup.string(),
});

const Stamps = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [addNewDialogVisible, setAddNewDialogVisible] =
    useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);

  const customerId = useSelector(getSelectedCustomerId);
  const stampsList: IStamp[] = useSelector(
    (state: any) => getAllStamps(state, customerId),
    shallowEqual,
  );
  const selectedStamp: IStamp | undefined = useSelector((state: any) =>
    getSelectedStamp(state),
  );
  const selectedTaskId = useSelector((state: any) => getSelectedTaskId(state));
  const isLoading = useSelector(selectStampsStatus) === "loading";
  const { data } = permissionsApi.useGetCustomerPermissionsQuery(customerId);
  const stampPermissions = checkPermissions("stampdata", data);
  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      stamp_name: "",
      stamp_screen_name: "",
      stamp_description: "",
    },
  });

  useEffect(() => {
    if (selectedStamp) {
      reset({
        stamp_name: selectedStamp.stamp_name,
        stamp_screen_name: selectedStamp.stamp_screen_name,
        stamp_description: selectedStamp.stamp_description,
      });
    }
  }, [selectedStamp]);

  useEffect(() => {
    if (selectedTaskId && customerId) {
      const idCollection: IdCollection = {
        customerId: customerId,
        taskId: selectedTaskId,
      };
      dispatch(fetchStampsTask(idCollection));
    }
  }, []);

  const handleSelectItem: (id: string) => void = (id) => {
    dispatch(selectStamp(id));
  };

  const handleAddNew = () => {
    if (stampPermissions.create) {
      setAddNewDialogVisible(true);
    } else {
      toast.error("No create permission");
    }
  };

  const handleCancelAddNew = () => {
    setAddNewDialogVisible(false);
  };

  const onDeleteClick = () => {
    selectedStamp
      ? setConfirmDialogOpen(true)
      : toast.error("No stamp selected");
  };

  const handleDelete = () => {
    if (stampPermissions.delete) {
      if (selectedStamp && customerId && selectedTaskId) {
        const data: StampDataCollection = {
          payload: selectedStamp,
        };
        dispatch(deleteStamp(data))
          .unwrap()
          .then(() => {
            toast.success(t("Stamp metadata deleted"));
          })
          .catch(() => {
            toast.error(t("Failed to delete stamp data"));
          });
        setConfirmDialogOpen(false);
      }
    } else {
      toast.error("No delete permission");
    }
  };

  const handleUpdate = (formData: any) => {
    if (stampPermissions.update) {
      if (selectedStamp && customerId && selectedTaskId) {
        const payload: any = {
          // this is transformed to IStamp in redux code, but as it contains old_stamp_name it is here any
          old_stamp_name: selectedStamp.stamp_name,
          customer_guid: customerId,
          task_guid: selectedTaskId,
          stamp_name: formData.stamp_name,
          stamp_screen_name: formData.stamp_screen_name,
          stamp_description: formData.stamp_description,
        };

        const data: StampDataCollection = {
          payload: payload,
        };
        dispatch(updateStamp(data));
        setIsEditing(false);
      }
    } else {
      toast.error("No update permission");
    }
  };

  const handleEdit = () => {
    if (stampPermissions.update) {
      setIsEditing(true);
    } else {
      toast.error("No update permission");
      setIsEditing(false);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    if (selectedStamp) {
      reset({
        stamp_name: selectedStamp.stamp_name,
        stamp_screen_name: selectedStamp.stamp_screen_name,
        stamp_description: selectedStamp.stamp_description,
      });
    }
  };

  const properties: IProperty<IStamp>[] = [
    { key: "stamp_name", label: t("label-stamp-name") },
    { key: "stamp_screen_name", label: t("label-stamp-screen-name") },
    { key: "stamp_description", label: t("label-stamp-description") },
  ];

  return (
    <>
      <FormControl component="fieldset" sx={formControlStyle}>
        <FormLabel component="legend" sx={formLabelStyle}>
          Task Stamps
        </FormLabel>
        <SortTable
          properties={properties}
          idProperty={"stamp_name"}
          handleChange={handleSelectItem}
          isLoading={isLoading}
          selectedId={selectedStamp?.stamp_name}
          data={stampsList}
        />
        <Grid container justifyContent="flex-end">
          <AddDeleteButtonGroup
            disabled={false}
            handleAdd={handleAddNew}
            handleDelete={selectedStamp && onDeleteClick}
          />
        </Grid>
      </FormControl>
      <ConfirmActionDialog
        show={confirmDialogOpen}
        action="DELETE"
        resource={selectedStamp?.stamp_name}
        onCancel={() => setConfirmDialogOpen(false)}
        onConfirm={handleDelete}
      />
      {selectedStamp !== undefined ? (
        <>
          <FormControl component="fieldset" sx={formControlStyle}>
            <FormLabel component="legend" sx={formLabelStyle}>
              Edit Stamp
            </FormLabel>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormTextField
                  name="stamp_name"
                  control={control}
                  label={t("label-stamp-name")}
                  readOnly={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField
                  name="stamp_screen_name"
                  control={control}
                  label={t("label-stamp-screen-name")}
                  readOnly={!isEditing}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextArea
                  name="stamp_description"
                  control={control}
                  label={t("label-stamp-description")}
                  readOnly={!isEditing}
                />
              </Grid>
              <Grid item xs={12}>
                <EditButtonGroup
                  disabled={false}
                  isEditing={isEditing}
                  handleEdit={handleEdit}
                  handleCancel={handleCancel}
                  handleSave={handleSubmit(handleUpdate)}
                />
              </Grid>
            </Grid>
          </FormControl>
        </>
      ) : null}
      <Dialog
        open={addNewDialogVisible}
        maxWidth="md"
        fullWidth
        sx={{ width: "100%" }}
      >
        <DialogTitle>Add a new stamp</DialogTitle>
        <DialogContent>
          <AddStamp onCancel={handleCancelAddNew} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Stamps;
