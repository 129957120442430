/**
 * The slice setup for customer related reducers
 */
import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { RootState } from "@app/store";
import { getEventLogsRequest, rerunEventRequest } from "./eventLogsApiService";
import { EventLog } from "./types";

const eventLogsAdapter = createEntityAdapter<EventLog>({
  selectId: (eventLog) => eventLog.name,
});

const initialState = eventLogsAdapter.getInitialState({
  status: "",
  selectedEvent: "",
});

const eventLogsSlice = createSlice({
  name: "eventLogs",
  initialState,
  reducers: {
    selectEvent(state, action: PayloadAction<string>) {
      state.selectedEvent = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEventLogs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchEventLogs.fulfilled,
        (state, { payload }: PayloadAction<EventLog[]>) => {
          eventLogsAdapter.setAll(state, payload);
          state.status = "";
        },
      )
      .addCase(fetchEventLogs.rejected, (state) => {
        eventLogsAdapter.removeAll(state);
        state.status = "";
      })
      .addCase(rerunEvent.pending, (state) => {
        state.status = "rerunning";
      })
      .addCase(rerunEvent.fulfilled, (state) => {
        state.status = "";
      })
      .addCase(rerunEvent.rejected, (state) => {
        state.status = "";
      });
  },
});

export default eventLogsSlice.reducer;
export const { selectEvent } = eventLogsSlice.actions;

export const fetchEventLogs = createAsyncThunk(
  "eventLogs/fetchEventLogs",
  async (
    payload: { customerId: string; eventId: string },
    { rejectWithValue },
  ) => {
    const { customerId, eventId } = payload;
    try {
      const res = await getEventLogsRequest(customerId, eventId);
      return res.data;
    } catch (err: any) {
      console.log(err);
      return rejectWithValue(
        `Failed to fetch event logs. (${err.status}) (${err.message})`,
      );
    }
  },
);

export const rerunEvent = createAsyncThunk(
  "eventLogs/rerunEvent",
  async (eventId: string, { rejectWithValue }) => {
    try {
      const res = await rerunEventRequest(eventId);
      return res.data;
    } catch (err: any) {
      console.log(err);
      return rejectWithValue(
        `Failed to rerun event. (${err.status}: ${err.message})`,
      );
    }
  },
);

const eventLogsSelectors = eventLogsAdapter.getSelectors(
  (state: RootState) => state.eventLogs,
);

export const selectEventLogs = (state: RootState) =>
  eventLogsSelectors.selectAll(state);

export const selectEventLogsStatus = (state: RootState) =>
  state.eventLogs.status;

export const selectSelectedEventId = (state: RootState) => {
  return state.eventLogs.selectedEvent;
};
