import { useState, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  TextField,
  Grid,
  FormControl,
} from "@mui/material";
import { LinkOff, Link } from "@mui/icons-material";

import { useAppDispatch } from "@features/common/StoreHook";
import SaveCancelButtonGroup from "@features/common/ButtonGroups/SaveCancelButtonGroup";
import { dialogFormStyle } from "@features/common/styles";
import { useCollection } from "@features/common/collectionHook";
import {
  FormTextField,
  FormTextArea,
  FormDropdownSelect,
} from "@features/common/FormFields";
import { ISecret } from "./types";
import {
  createSecret,
  SecretCreateBundle,
  selectSecretsStatus,
} from "./secretsSlice";

interface SecretDialogProps {
  customerId: string;
  onCancel: () => void;
  show: boolean;
}

// dialog for adding a new secret to Customers keyvault

const validationSchema = yup.object({
  name: yup.string().required(),
  status: yup.string().nullable(),
  description: yup.string().nullable(),
  additional_info: yup.string().nullable(),
  type: yup.string(),
  // production_value: yup.string(),
  // test_value: yup.string(),
});

export const AddSecretDialog = (props: SecretDialogProps) => {
  const { customerId, onCancel, show } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [linkActive, setLinkActive] = useState<boolean>(false);
  const [prodState, setProdState] = useState<string>("");
  const [devState, setDevState] = useState<string>("");

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      status: "",
      description: "",
      type: "",
      additional_info: "",
    },
  });

  const isSubmitting = useSelector(selectSecretsStatus) === "submitting";

  const secretTypes = useCollection(9);

  const handleDevChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDevState(event.target.value);
    if (linkActive) setProdState(event.target.value);
  };

  const handleProdChange = (event: ChangeEvent<HTMLInputElement>) => {
    setProdState(event.target.value);
    if (linkActive) setDevState(event.target.value);
  };

  const handleFormSubmit = (data: any) => {
    const payload: ISecret = {
      name: data.name,
      type: data.type,
      description: data.description,
      additional_info: data.additional_info,
      test_value: devState,
      production_value: prodState,
      status: null,
    };

    const dataBundle: SecretCreateBundle = {
      customerId: customerId,
      payload: payload,
    };

    dispatch(createSecret(dataBundle))
      .unwrap()
      .then(() => {
        toast.success(t("Secret added"));
        handleCancel();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleCancel = () => {
    setProdState("");
    setDevState("");
    reset();
    onCancel();
  };

  return (
    <Dialog open={show} fullWidth={true} maxWidth="lg">
      <DialogTitle>{t("label-add-new-secret")}</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset" sx={dialogFormStyle}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormTextField
                name="name"
                control={control}
                label={t("label-secret-name")}
                readOnly={isSubmitting}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormDropdownSelect
                name="type"
                control={control}
                label={t("label-secret-type")}
                selectItems={secretTypes}
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextArea
                name="description"
                control={control}
                label={t("label-secret-description")}
                rows={4}
                readOnly={isSubmitting}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextArea
                name="additional_info"
                control={control}
                label={t("label-additional-information")}
                readOnly={isSubmitting}
                rows={4}
              />
            </Grid>
            <Grid
              container
              item
              textAlign={"center"}
              alignItems={"center"}
              rowSpacing={1}
            >
              <Grid item xs={12} sm={5}>
                <TextField
                  value={prodState}
                  data-testid="production-keyvault-value"
                  label={t("label-production-keyvault-value")}
                  onChange={handleProdChange}
                  fullWidth
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  data-testid="link-button"
                  variant="contained"
                  onClick={() => setLinkActive(!linkActive)}
                  disabled={isSubmitting}
                  sx={{ backgroundColor: "primary.main" }}
                >
                  {linkActive ? <Link /> : <LinkOff />}
                </Button>
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextField
                  value={devState}
                  data-testid="development-keyvault-value"
                  label={t("label-development-keyvault-value")}
                  onChange={handleDevChange}
                  fullWidth
                  disabled={isSubmitting}
                />
              </Grid>
            </Grid>
          </Grid>
        </FormControl>
        <SaveCancelButtonGroup
          disabled={isSubmitting}
          handleSave={handleSubmit(handleFormSubmit)}
          handleCancel={handleCancel}
        />
      </DialogContent>
    </Dialog>
  );
};
