import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Paper, TextField, Grid, Typography } from "@mui/material";

import LabelButton from "@features/common/LabelButton/LabelButton";
import LoadingIndicatorBox from "@features/common/LoadingIndicatorBox/LoadingIndicatorBox";
import { useAppDispatch } from "@features/common/StoreHook";
import { getSelectedCustomerId } from "@features/customers/customerSlice";
import { ConfirmActionDialog } from "@features/common/ConfirmAction";
import { permissionsApi } from "@features/authorization/authService";
import { checkPermissions } from "@features/authorization/types";
import EventLogsList from "./EventLogsList";
import { logPaperStyle, logInfoPaperStyle } from "./style";
import {
  fetchEventLogs,
  selectEvent,
  selectEventLogsStatus,
  rerunEvent,
} from "./eventLogsSlice";

/**
 * Displays the event logs search bar and the event logs list.
 */

const EventLogs = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const isLoadingLogs = useSelector(selectEventLogsStatus) === "loading";
  const isRerunningEvent = useSelector(selectEventLogsStatus) === "rerunning";
  const customerId = useSelector(getSelectedCustomerId);

  const { data } = permissionsApi.useGetCustomerPermissionsQuery(customerId);
  const eventPermissions = checkPermissions("events", data);

  useEffect(() => {
    dispatch(selectEvent(""));
  }, [customerId]);

  const handleSubmit = () => {
    setIsError(false);
    dispatch(selectEvent(searchValue));
    dispatch(fetchEventLogs({ customerId, eventId: searchValue }))
      .unwrap()
      .catch((error) => {
        toast.error(error);
        setIsError(true);
      });
  };

  const onRerunEvent = () => {
    eventPermissions.rerun
      ? setConfirmDialogOpen(true)
      : toast.error("No rerun permission.");
  };

  const handleRerun = () => {
    dispatch(rerunEvent(searchValue))
      .unwrap()
      .then(() => toast.success("Event re-run created."))
      .catch((error) => {
        toast.error(error);
      });
    setConfirmDialogOpen(false);
  };

  return (
    <>
      <Paper sx={logPaperStyle}>
        <Grid
          container
          alignItems={"center"}
          gap={2}
          justifyContent={{ md: "center" }}
        >
          <TextField
            data-testid="search-field"
            sx={{ width: 450, marginRight: 2 }}
            size="small"
            label={t("label-event-id")}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <LabelButton
            handleClick={handleSubmit}
            label={t("label-search")}
            disabled={!searchValue || isLoadingLogs}
          />
          <LabelButton
            handleClick={onRerunEvent}
            disabled={!searchValue || isRerunningEvent}
            label={t("label-rerun-event")}
            variant="outlined"
          />
        </Grid>
      </Paper>
      <ConfirmActionDialog
        show={confirmDialogOpen}
        action={t("label-rerun-event")}
        resource={`${t("label-event-id")}: ${searchValue}`}
        onConfirm={handleRerun}
        onCancel={() => setConfirmDialogOpen(false)}
      />

      {isLoadingLogs ? (
        <LoadingIndicatorBox show={isLoadingLogs} />
      ) : isError ? (
        <Paper data-testid="logs-load-fail-box" sx={{ ...logInfoPaperStyle }}>
          <Typography>Could not load logs.</Typography>
        </Paper>
      ) : (
        <></>
      )}

      {!isLoadingLogs && !isError && <EventLogsList />}
    </>
  );
};

export default EventLogs;
