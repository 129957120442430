import { useEffect, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  NavLink,
  useLocation,
  useNavigate,
  Outlet,
  useParams,
} from "react-router-dom";
import TabPanel from "./TabPanel";
import { tabsStyle, tabStyle } from "./styles";
import { permissionsApi } from "@features/authorization/authService";
import { checkPermissions } from "@features/authorization/types";

const a11yProps = (index: number) => {
  return {
    id: `tab-view-${index}`,
    "aria-controls": `tab-panel-${index}`,
  };
};

export interface ITabItem {
  label: string;
  path: string;
  permissions: string;
  disabled?: boolean;
}

interface ITabViewProps {
  tabs: ITabItem[];
  primaryPermission: string;
}

/**
 * Renders sub navigation tabs and panel for each tab. Uses URL paths to determine selected tab to allow
 * navigating to a tab directly with a URL.
 * @param props
 * @returns
 */
const TabView = (props: ITabViewProps) => {
  const { tabs, primaryPermission } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const urlCustomerId = useParams().customerId; // customer id in the url params
  const navigate = useNavigate();

  const {
    data: permissionDataCustomer,
    isLoading,
    isFetching,
  } = permissionsApi.useGetCustomerPermissionsQuery(urlCustomerId || "");
  const {
    data: permissionDataOiva,
    isLoading: isLoadingOiva,
    isFetching: isFethingOiva,
  } = permissionsApi.useGetOivaPermissionsQuery();
  const [allowedHere, setAllowedHere] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<any>(false);
  const [tabsToShow, setTabsToShow] = useState<ITabItem[] | undefined>();

  useEffect(() => {
    if (tabsToShow && allowedHere) {
      // Is this intended? When tabsToShow is an empty array (and allowedHere is true), this returns 'true'
      const chosenPath = location.pathname.split("/").pop();
      const selectedTab = tabsToShow.find((tab) =>
        !tab.disabled ? tab.path === chosenPath : false,
      );

      if (!selectedTab) {
        // if the path did not have a valid path for tab,
        // redirect automatically to the first tab (where user has permission to)
        navigate(tabsToShow[0].path);
        setCurrentTab(tabsToShow[0].path);
      } else {
        setCurrentTab(selectedTab.path);
      }
    }
  }, [tabsToShow]);

  const checkIfAllowedHere = () => {
    if (primaryPermission && (permissionDataCustomer || permissionDataOiva)) {
      let perms = checkPermissions(primaryPermission, permissionDataCustomer);
      let permsOiva = checkPermissions(primaryPermission, permissionDataOiva);

      if (!perms.read && !permsOiva.read) {
        setAllowedHere(false);
        navigate("/");
      } else {
        setAllowedHere(true);
      }
    } else if (
      primaryPermission.length === 0 &&
      (permissionDataCustomer || permissionDataOiva)
    ) {
      setAllowedHere(true);
    } else setAllowedHere(false);
  };

  useEffect(() => {
    if (allowedHere) filterTabPermissions();
  }, [allowedHere]);

  useEffect(() => {
    if (
      !isLoading &&
      !isFetching &&
      (!!permissionDataCustomer || !!permissionDataOiva)
    ) {
      checkIfAllowedHere();
    }
  }, [tabs, isLoading, isFetching, permissionDataCustomer, permissionDataOiva]);

  const filterTabPermissions = () => {
    if (allowedHere) {
      let filteredTabs: ITabItem[] = [];
      tabs.forEach((tab) => {
        const feature = tab.permissions;
        if (feature.length > 0) {
          const tabPermissions = checkPermissions(
            feature,
            permissionDataCustomer,
          );
          const tabPermissionsOiva = checkPermissions(
            feature,
            permissionDataOiva,
          );
          if (tabPermissions.read || tabPermissionsOiva.read) {
            filteredTabs.push(tab);
          }
        } else filteredTabs.push(tab);
      });

      setTabsToShow(filteredTabs);
    } else {
      setTabsToShow([]);
    }
  };

  // Render list of tabs and a panel for selected tab
  return (
    <>
      <Box sx={{ boxShadow: 1 }}>
        <Tabs
          value={currentTab}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="tab view"
          textColor="inherit"
          sx={tabsStyle}
          onChange={(event, newValue) => setCurrentTab(newValue)}
        >
          {tabsToShow?.map((tab: ITabItem, index: number) => (
            <Tab
              key={tab.label}
              label={t(tab.label)}
              {...a11yProps(index)}
              sx={tabStyle}
              component={NavLink}
              to={tab.path}
              value={tab.path} // shows which tab is selected
              disabled={tab.disabled ? tab.disabled : false}
            />
          ))}
        </Tabs>
      </Box>

      <TabPanel>{allowedHere ? <Outlet /> : null}</TabPanel>
    </>
  );
};

export default TabView;
