import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, TextField, Box, FormControl, FormLabel } from "@mui/material";
import { formLabelStyle, resourceFormStyle } from "../style";
import { ISubnet } from "../types";
import EditButtonGroup from "../../common/ButtonGroups/EditButtonGroup";
import { useSelector } from "react-redux";
import { selectSubnetByName } from "./subnetsSlice";
import { RootState } from "@app/store";
import SelectMenu from "../SelectMenu";
import { useCollection } from "@features/common/collectionHook";

interface ISubnetContainerProps {
  subnetId: string | undefined; // subnet name is used as the id for now
}

/**
 * Form for displaying and editing a subnet's information.
 *
 * TODO:
 *  - Many of the buttons are placeholders at the moment and have been disabled until they can actually be used.
 *  - subnetTypes mock has to be replaced with the correct collection
 * @param param0
 * @returns
 */

const SubnetContainer = ({ subnetId }: ISubnetContainerProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslation();

  const initialSubnet = useSelector((state: RootState) =>
    selectSubnetByName(state, subnetId),
  );
  const [subnet, setSubnet] = useState<ISubnet | undefined>(initialSubnet);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (subnet) {
      setSubnet({
        ...subnet,
        [name]: value,
      });
    }
  };

  const handleCancel = () => {
    setSubnet(initialSubnet);
    setIsEditing(false);
  };

  return (
    <>
      {subnet && (
        <Box>
          <FormControl component="fieldset" sx={resourceFormStyle}>
            <FormLabel component="legend" sx={formLabelStyle}>
              {t("label-subnet")}
            </FormLabel>
            <Grid container>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={subnet?.subnet_name}
                    name="name"
                    label={t("label-subnet-name")}
                    InputProps={{ readOnly: !isEditing }}
                    onChange={handleChange}
                  />
                  <TextField
                    value={subnet?.subnet_address_space}
                    name="address_space"
                    label={t("label-address-space")}
                    InputProps={{ readOnly: !isEditing }}
                    onChange={handleChange}
                  />
                  <TextField
                    value={subnet?.subnet_type}
                    name="type"
                    label={t("label-subnet-type")}
                    disabled={true}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={subnet?.description}
                    name="description"
                    label={t("subnet-description")}
                    InputProps={{ readOnly: !isEditing }}
                    onChange={handleChange}
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>
              <EditButtonGroup
                isEditing={isEditing}
                disabled={true}
                handleEdit={() => setIsEditing(true)}
                handleCancel={handleCancel}
              />
            </Grid>
          </FormControl>
        </Box>
      )}
    </>
  );
};

export default SubnetContainer;
