import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Box } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";

import { useAppDispatch } from "@features/common/StoreHook";
import { getSelectedCustomerId } from "@features/customers/customerSlice";
import { ActiveTimelineItem, EventType, TimelineResource } from "../../types";
import ChartWrapper from "../ChartWrapper";
import {
  fetchStampData,
  selectStampData,
  selectStampDataStatus,
} from "./stampDataSlice";
import { StampData, StampLoop } from "./types";
import StampsChartsWrapper from "./StampsChartsWrapper";
import StampChartSelectsWrapper from "./StampChartSelectsWrapper";
import StampsTable from "./StampsTable";

interface StampsChartProps {
  startDate: Date;
  endDate: Date;
  selectedItem: ActiveTimelineItem;
}

export interface StampFetchPayload {
  customerId: string;
  startDate: Date;
  endDate: Date;
  eventId: string | null;
  taskId: string | null;
  vmId: string | null;
}

const StampsCharts = (props: StampsChartProps) => {
  const { startDate, endDate, selectedItem } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [selectedViewOption, setSelectedViewOption] = useState<string>("count");
  const [selectedStampType, setSelectedStampType] = useState<string>("double");
  const [selectedStamp, setSelectedStamp] = useState<string>("");

  const stamps: StampData[] = useSelector(selectStampData);
  const isLoadingstampData = useSelector(selectStampDataStatus) === "loading";
  const customerId = useSelector(getSelectedCustomerId);

  useEffect(() => {
    const payload: StampFetchPayload = {
      customerId,
      startDate: startDate,
      endDate: endDate,
      eventId: null,
      taskId: null,
      vmId: null,
    };
    if (selectedItem.type === EventType.Event) {
      payload.eventId = selectedItem.id;
    } else if (selectedItem.type === EventType.Task) {
      payload.taskId =
        (selectedItem.item as TimelineResource).task_guid || null;
    } else if (selectedItem.type === EventType.VM) {
      payload.vmId = selectedItem.id;
    }

    dispatch(fetchStampData(payload));
  }, [selectedItem, startDate, endDate]);

  useEffect(() => {
    if (stamps && stamps.length > 0) {
      const defaultDoubleStamp = stamps.find(
        (stamp: StampData) => !!stamp.hasOwnProperty("loop_information"),
      );
      if (defaultDoubleStamp) {
        setSelectedStamp(defaultDoubleStamp.name);
      }
    }
  }, [stamps]);

  const selectableDoubleStamps = () => {
    const doubleStamps: { label: string; value: string }[] = [];
    // map to label and value for Select component, remove duplicate names
    stamps.forEach((stamp: StampData) => {
      if (
        stamp.loop_information &&
        !doubleStamps.some((s) => s.value === stamp.name)
      ) {
        doubleStamps.push({
          label: stamp.name,
          value: stamp.name,
        });
      }
    });
    return doubleStamps;
  };

  const stampRuntimesData = stamps.reduce((acc: any[], stamp: StampData) => {
    if (stamp.loop_information) {
      stamp.loop_information.forEach((loop: any) => {
        acc.push({
          name: stamp.name,
          duration: loop.duration / 60, // runtime in minutes
          start_time: loop.start_time,
          end_time: loop.end_time,
        });
      });
    }
    return acc;
  }, []);

  const stampCountData = stamps.map((stamp: StampData) => {
    if (stamp.loop_information) {
      const median_runtime =
        stamp.median_runtime || stamp.loop_information?.[0].duration || 0;
      const average_runtime =
        stamp.average_runtime || stamp.loop_information?.[0].duration || 0;
      const runtime_highest =
        stamp.runtime_highest || stamp.loop_information?.[0].duration || 0;
      const runtime_lowest =
        stamp.runtime_lowest || stamp.loop_information?.[0].duration || 0;
      return {
        ...stamp,
        median_runtime: median_runtime / 60,
        average_runtime: average_runtime / 60,
        runtime_highest: runtime_highest / 60,
        runtime_lowest: runtime_lowest / 60,
      };
    }
    return stamp;
  });

  const stampLoopStatuses = stamps.reduce(
    (acc: { ok: number; nok: number }, stamp: StampData) => {
      if (stamp.loop_information && stamp.name === selectedStamp) {
        stamp.loop_information.forEach((loop: StampLoop) => {
          if (loop.status === "OK") {
            acc.ok += 1;
          } else {
            acc.nok += 1;
          }
        });
      }
      return acc;
    },
    { ok: 0, nok: 0 },
  );

  const stampSumData = stamps
    .filter((stamp: StampData) => stamp.name === selectedStamp)
    .map((stamp: StampData) => {
      const median_runtime =
        stamp.median_runtime || stamp.loop_information?.[0].duration || 0;
      const average_runtime =
        stamp.average_runtime || stamp.loop_information?.[0].duration || 0;
      const runtime_highest =
        stamp.runtime_highest || stamp.loop_information?.[0].duration || 0;
      const runtime_lowest =
        stamp.runtime_lowest || stamp.loop_information?.[0].duration || 0;
      return {
        ...stamp,
        median_runtime: median_runtime / 60,
        average_runtime: average_runtime / 60,
        runtime_highest: runtime_highest / 60,
        runtime_lowest: runtime_lowest / 60,
      };
    });

  const stampTableData = () => {
    if (selectedViewOption === "runtimes" && selectedStamp) {
      return stampRuntimesData.filter(
        (stamp: any) => stamp.name === selectedStamp,
      );
    } else if (selectedViewOption === "count") {
      return selectedStampType === "all"
        ? stampCountData
        : stampCountData.filter((stamp: StampData) => {
            if (selectedStampType === "double") {
              return Object.hasOwn(stamp, "loop_information");
            } else if (selectedStampType === "single") {
              return !Object.hasOwn(stamp, "loop_information");
            }
          });
    } else if (selectedViewOption === "sum") {
      return stampSumData.map((stamp: StampData) => ({
        name: stamp.name,
        task_name: stamp.task_name,
        total_count: stamp.total_count,
        median_runtime: stamp.median_runtime,
        average_runtime: stamp.average_runtime,
        runtime_highest: stamp.runtime_highest,
        runtime_lowest: stamp.runtime_lowest,
      }));
    }
  };

  const handleSelectView = (e: SelectChangeEvent<string>) => {
    setSelectedViewOption(e.target.value);
  };

  const handleSelectStampType = (e: SelectChangeEvent<string>) => {
    setSelectedStampType(e.target.value);
  };

  const handleSelectStamp = (e: SelectChangeEvent<string>) => {
    setSelectedStamp(e.target.value);
  };

  const stampDataToShow =
    selectedViewOption === "count"
      ? stampCountData
      : selectedViewOption === "runtimes"
      ? stampRuntimesData
      : selectedViewOption === "status"
      ? stampLoopStatuses
      : selectedViewOption === "sum"
      ? stampSumData
      : [];

  const excelDataKeys = {
    count: [
      "name",
      "task_name",
      "total_count",
      "median_runtime",
      "average_runtime",
      "runtime_highest",
      "runtime_lowest",
    ],
    runtimes: ["name", "duration", "start_time", "end_time"],
    status: undefined,
    sum: [
      "name",
      "task_name",
      "total_count",
      "median_runtime",
      "average_runtime",
      "runtime_highest",
      "runtime_lowest",
    ],
  };

  const excelData =
    selectedViewOption === "count" || selectedViewOption === "runtimes"
      ? stampTableData()
      : selectedViewOption === "status"
      ? [
          { name: t("label-run-successful"), value: stampLoopStatuses?.ok },
          { name: t("label-run-unsuccessful"), value: stampLoopStatuses?.nok },
        ]
      : stampDataToShow;

  return (
    <>
      <ChartWrapper
        title={t("window_label_stamps")}
        chart={
          <>
            {stamps && stamps.length > 0 && (
              <StampChartSelectsWrapper
                selectedViewOption={selectedViewOption}
                selectedStampType={selectedStampType}
                selectedStamp={selectedStamp}
                selectableDoubleStamps={selectableDoubleStamps()}
                handleSelectView={handleSelectView}
                handleSelectStampType={handleSelectStampType}
                handleSelectStamp={handleSelectStamp}
              />
            )}
            <Box height="90%" paddingTop="10px">
              <StampsChartsWrapper
                selectedViewOption={selectedViewOption}
                selectedStampType={selectedStampType}
                selectedStamp={selectedStamp}
                stamps={stampDataToShow}
                isLoading={isLoadingstampData}
              />
            </Box>
          </>
        }
        isTableAvailable={
          selectedViewOption === "count" ||
          selectedViewOption === "runtimes" ||
          selectedViewOption === "sum"
        }
        table={
          <StampsTable data={stampTableData()} dataType={selectedViewOption} />
        }
        excelData={excelData}
        excelDataKeys={
          excelDataKeys[selectedViewOption as keyof typeof excelDataKeys]
        }
      />
    </>
  );
};

export default StampsCharts;
