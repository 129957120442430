import { useState, useEffect } from "react";
import { Paper, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { getSelectedCustomerId } from "@features/customers/customerSlice";
import { logPaperStyle, logTextFileContainerStyle } from "./style";
import { selectSelectedEventId } from "./eventLogsSlice";
import { getEventLogBlobContent } from "./eventLogsApiService";

/**
 * Displays the event log. Displays only images and text files, if the type is neither, nothing is displayed.
 * If the event log is a text file, the content is fetched from the API and then displayed.
 */

interface IProps {
  filename: string;
  uri: string;
  name: string;
  type: string;
}

const EventLogDisplay = (props: IProps) => {
  const { filename, uri, name, type } = props;
  const [fileContent, setFileContent] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);

  const customerId = useSelector(getSelectedCustomerId);
  const eventId = useSelector(selectSelectedEventId);

  useEffect(() => {
    if (type.startsWith("text/")) {
      getEventLogBlobContent(customerId, eventId, filename)
        .then((res) => {
          setFileContent(res.data);
        })
        .catch((error) => {
          console.log("error", error);
          toast.error(`Could not load file content for ${name}`);
          setIsError(true);
        });
    }
  }, []);

  if (!type.startsWith("image/") && !type.startsWith("text/")) {
    return <></>;
  }

  return (
    <Paper sx={logPaperStyle}>
      {type.startsWith("image/") && <img src={uri} alt={name} width="100%" />}
      {type.startsWith("text/") && (
        <Box sx={logTextFileContainerStyle}>
          {!isError ? fileContent : "Could not load content."}
        </Box>
      )}
    </Paper>
  );
};

export default EventLogDisplay;
