import { useState, useRef, ChangeEvent, useMemo, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";
import dayjs from "dayjs";
import {
  DatesSetArg,
  EventClickArg,
  EventContentArg,
  EventHoveringArg,
} from "@fullcalendar/core";
import { ResourceApi, ResourceLabelContentArg } from "@fullcalendar/resource";
import { Box } from "@mui/system";
import { Typography, Popover, CircularProgress, useTheme } from "@mui/material";

import { dateInputWrapperStyle, fcStylesDark, fcStylesLight } from "./style";
import { selectStatusColor } from "./utils";
import {
  TimelineEvent,
  TimelineResource,
  ActiveTimelineItem,
  EventType,
} from "./types";
import EventPopoverContent from "./EventPopoverContent";
import { useTranslation } from "react-i18next";
import { injectCustomButtonStyles } from "./injectCustomButtonStyles";

/**
 * Component for dashboard's scheduled event timeline.
 *
 *
 * Currently uses HTML5 input field for date selection for simplicity. It might be better to use
 * a date picker library in the future as the HTML5 input field has some issues,
 * e.g. on Chrome the date picker changes selected date when the year/month is changed.
 */

interface Props {
  resources: TimelineResource[];
  events: TimelineEvent[];
  changeDates: (start: Date, end: Date) => void;
  isUpdatingEvents: boolean;
  refetchItems: () => void;
  setSelectedItem: (item: ActiveTimelineItem) => void;
  licenseKey?: string;
}

const Timeline = (props: Props) => {
  const {
    resources,
    events,
    changeDates,
    isUpdatingEvents,
    refetchItems,
    setSelectedItem,
    licenseKey,
  } = props;
  const [popoverEvent, setPopoverEvent] = useState<TimelineEvent | null>(null);
  const [popoverElement, setPopoverElement] = useState<HTMLElement | null>(
    null,
  );
  const calendarRef = useRef<FullCalendar>(null);
  const dateInputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();
  const theme = useTheme();

  const changeDate = (e: ChangeEvent<HTMLInputElement>) => {
    // Change the calendar date to the selected date from input field.
    const selectedDate = e.target.value;
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(selectedDate);
    }
  };

  const showSelectDate = () => {
    // HTML5 input field reference and date picker (the actual input field is hidden from view).
    if (dateInputRef.current) {
      dateInputRef.current.showPicker();
    }
  };

  const onEventMouseEnter = (info: EventHoveringArg) => {
    setPopoverElement(info.el);
    const event = events?.find(
      (e: TimelineEvent) => info.event._def.publicId.indexOf(e.id) > -1,
    );
    setPopoverEvent(event || null);
  };

  const onEventMouseLeave = () => {
    setPopoverEvent(null);
    setPopoverElement(null);
  };

  const onEventMouseClick = (info: EventClickArg) => {
    const {
      actual_start_time,
      actual_stop_time,
      scheduled_start_time,
      scheduled_stop_time,
      status,
      errorCount,
      type,
      taskId,
      vmId,
    } = info.event._def.extendedProps;
    const event = {
      actual_start_time,
      actual_stop_time,
      scheduled_start_time,
      scheduled_stop_time,
      status,
      errorCount,
      type,
      taskId,
      vmId,
      resourceIds: info.event._def.resourceIds || [],
    };
    setSelectedItem({
      id: info.event._def.publicId,
      type: EventType.Event,
      item: event,
    });
  };

  const handleResourceLabelClick = (resourceApi: ResourceApi) => {
    const info = resourceApi._resource;
    const type = !!info.parentId ? EventType.Task : EventType.VM;
    if (type === EventType.VM) {
      setSelectedItem({
        id: info.id,
        type,
        item: { id: info.id, title: info.title },
      });
    } else if (type === EventType.Task) {
      setSelectedItem({
        id: info.id,
        type,
        item: {
          id: info.id,
          title: info.title,
          parentId: info.parentId,
          task_guid: info.extendedProps.task_guid,
        },
      });
    }
  };

  const onCalendarDateChange = (info: DatesSetArg) => {
    const end = info.endStr;
    const start = info.startStr;
    changeDates(dayjs(start).toDate(), dayjs(end).toDate());
  };

  const renderResourceContent = (info: ResourceLabelContentArg) => {
    return (
      <div
        style={{ display: "inline-block", cursor: "pointer" }}
        onClick={() => handleResourceLabelClick(info.resource)}
      >
        {info.resource.title}
      </div>
    );
  };

  const renderEventContent = (eventInfo: EventContentArg) => {
    const event = events?.find(
      (e: TimelineEvent) => eventInfo.event._def.publicId.indexOf(e.id) > -1,
    );

    if (!event) {
      return <></>;
    }

    const plannedStartTime = new Date(event.scheduled_start_time).getTime();
    const plannedEndTime = new Date(
      event.scheduled_stop_time || event.scheduled_start_time,
    ).getTime();

    const actualStartTime = new Date(
      event.actual_start_time || event.scheduled_start_time,
    ).getTime();
    const actualEndTime = new Date(
      event.actual_stop_time ||
        event.actual_start_time ||
        event.scheduled_start_time,
    ).getTime();

    const eventStartTime =
      plannedStartTime <= actualStartTime ? plannedStartTime : actualStartTime;
    const eventEndTime =
      plannedEndTime >= actualEndTime ? plannedEndTime : actualEndTime;

    // Scheduler component shows 30 minute timeslots in day view. 24 hour slots in other view modes.
    const viewSlotTotalTime =
      eventInfo.view.type === "resourceTimelineDay"
        ? 30 * 60 * 1000
        : 24 * 60 * 60 * 1000; // 86400000

    const viewSlotStartTime =
      eventInfo.view.type === "resourceTimelineDay"
        ? dayjs(eventStartTime)
            .startOf("hour")
            .add(dayjs(eventStartTime).minute() >= 30 ? 30 : 0, "minutes")
            .valueOf()
        : dayjs(eventStartTime).startOf("day").valueOf();

    const viewTotalTime =
      Math.ceil((eventEndTime - viewSlotStartTime) / viewSlotTotalTime) *
      viewSlotTotalTime;

    const plannedStartPoint = plannedStartTime - viewSlotStartTime;
    const plannedRunTime = plannedEndTime - plannedStartTime;

    const actualStartPoint = actualStartTime - viewSlotStartTime;
    const actualRunTime = actualEndTime - actualStartTime;

    return (
      <div style={{ height: "20px", position: "relative" }}>
        <div
          style={{
            height: "95%",
            backgroundColor: "#80c5f6",
            width: `calc(100% * ${plannedRunTime} / ${viewTotalTime})`,
            left: `calc(100% * ${plannedStartPoint} / ${viewTotalTime})`,
            position: "absolute",
          }}
        ></div>
        <div
          style={{
            height: "70%",
            backgroundColor: selectStatusColor(event.status),
            width: `calc(100% * (${actualRunTime}) / ${viewTotalTime})`,
            left: `calc(100% * ${actualStartPoint} / ${viewTotalTime})`,
            top: "3px",
            position: "absolute",
            borderRadius: "0.15vh",
          }}
        ></div>
      </div>
    );
  };

  /**
   * Feel free to blame the developers of React FullCalendar for this abomination.
   */
  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = injectCustomButtonStyles(theme.palette.mode);
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, [theme.palette.mode]);

  /**
   * The useMemo hook prevents unnecessary re-renders of the calendar component
   * which causes performance issues when there are possibly
   * a lot of events on several VM's (e.g. PAM)
   */
  const memoizedFullCalendar = useMemo(
    () => (
      <FullCalendar
        ref={calendarRef}
        eventColor="#00000000"
        datesSet={onCalendarDateChange}
        eventOverlap={false}
        expandRows={false}
        slotEventOverlap={false}
        eventOrder={"start"}
        slotMinWidth={30}
        eventDisplay="block"
        height={"auto"}
        plugins={[resourceTimelinePlugin, interactionPlugin]}
        initialView="resourceTimelineWeek"
        resources={resources}
        events={isUpdatingEvents ? [] : events}
        customButtons={{
          selectDateButton: {
            text: t("label-select-date"),
            click: showSelectDate,
          },
          refreshButton: {
            text: t("label-refresh"),
            click: () => refetchItems(),
          },
        }}
        headerToolbar={{
          left: "prev,next selectDateButton",
          center: "title",
          right:
            "refreshButton resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth",
        }}
        firstDay={1}
        locale={"en-US"}
        resourceAreaWidth={"20%"}
        views={{
          resourceTimelineDay: {
            slotDuration: "00:30:00",
          },
          resourceTimelineWeek: {
            slotDuration: { days: 1 },
            slotLabelFormat: {
              day: "numeric",
              month: "numeric",
              weekday: "short",
            },
          },
          resourceTimelineMonth: {
            slotDuration: { days: 1 },
            slotMinWidth: 70,
            slotLabelFormat: {
              day: "numeric",
              month: "numeric",
              weekday: "short",
            },
          },
        }}
        eventMouseEnter={onEventMouseEnter}
        eventMouseLeave={onEventMouseLeave}
        eventClick={onEventMouseClick}
        eventContent={renderEventContent}
        resourceLabelContent={renderResourceContent}
        schedulerLicenseKey={licenseKey || ""}
      />
    ),
    [events, resources, isUpdatingEvents, licenseKey, theme.palette, t],
  );

  return (
    <>
      <Box sx={theme.palette.mode === "dark" ? fcStylesDark : fcStylesLight}>
        {isUpdatingEvents ? (
          <div style={{ position: "relative" }}>
            <Box
              sx={{
                left: "190px",
                top: "38px",
                position: "absolute",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <CircularProgress size="25px" />
              <Typography variant="h6" sx={{ marginLeft: "8px" }}>
                Updating...
              </Typography>
            </Box>
          </div>
        ) : null}
        <Typography sx={{ marginBottom: "8px" }} variant="h2">
          Dashboard Timeline
        </Typography>
        <Box sx={dateInputWrapperStyle}>
          <input
            type="date"
            id="date"
            name="date"
            ref={dateInputRef}
            onChange={changeDate}
          />
        </Box>
        {memoizedFullCalendar}
      </Box>
      <Popover
        open={Boolean(popoverElement)}
        anchorEl={popoverElement}
        sx={{
          pointerEvents: "none",
          marginTop: "25px",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        disableRestoreFocus
        transitionDuration={0}
      >
        <EventPopoverContent eventItem={popoverEvent} />
      </Popover>
    </>
  );
};

export default Timeline;
