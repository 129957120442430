import { TableCell, TableRow } from "@mui/material";
import { formatToNDecimals } from "../utils";

const calculateTotals = (data: any[]) => {
  let counts = {
    total_count: 0,
    value: 0,
    duration: 0,
  };

  if (!data || data.length === 0) {
    return counts;
  }

  // Loop through the given dataset and count the totals
  // of columns that are summable.
  for (let row of data) {
    counts.total_count += row?.total_count || 0;
    counts.value += row?.value || 0;
    counts.duration += row?.duration || 0;
  }

  return counts;
};

const renderTotalRow = (data: any[], dataKeys: string[], t: any) => {
  let counts = calculateTotals(data);
  let summableColumns: any[] = [];

  // If the currently iterated column in the dataset is a summable row
  // add it in the summableColumns
  for (let key of dataKeys) {
    if (key === "total_count" || key === "value" || key === "duration") {
      summableColumns.push({
        key: key,
        value: counts[key],
      });
    } else {
      summableColumns.push(null);
    }
  }

  // Explanation: First we check if there were any summable rows in the first place.
  // If there were none (all values are null), we don't render the sum row at all.
  // If summable rows exist: if the current cell that is being looped returns null,
  // just return an empty string inside the TableCell since it's a non-summable row.
  // When the loop hits a summable row, just return the value with the correct suffix.
  return summableColumns.every((element) => element === null) ? null : (
    <TableRow key="row_total">
      <TableCell key="total">{t("label-table-total")}</TableCell>
      {summableColumns.map((d, idx) => (
        <TableCell key={idx}>
          {d === null
            ? ""
            : formatToNDecimals(d["value"], 2) +
              renderMinSuffix(d["key"], d["value"])}
        </TableCell>
      ))}
    </TableRow>
  );
};

const renderMinSuffix = (key: string, value: any) => {
  // dataKeys names of columns where we want to add the 'min' suffix
  const needMinSuffix = [
    "average_runtime",
    "median_runtime",
    "runtime_highest",
    "runtime_lowest",
    "duration",
    "actual",
    "scheduled",
  ];
  if (!needMinSuffix.includes(key) || typeof value != "number") {
    return "";
  } else {
    return " min";
  }
};

const tableContent = [
  {
    label: "label-stamp-name",
    value: "name",
  },
  {
    label: "label-task-name",
    value: "task_name",
  },
  {
    label: "label-total-count",
    value: "total_count",
  },
  {
    label: "label-average-runtime",
    value: "average_runtime",
  },
  {
    label: "label-median-runtime",
    value: "median_runtime",
  },
  {
    label: "label-runtime-highest",
    value: "runtime_highest",
  },
  {
    label: "label-runtime-lowest",
    value: "runtime_lowest",
  },
  {
    label: "label-runtimes",
    value: "duration",
  },
  {
    label: "label-timestamp-start",
    value: "start_time",
  },
  {
    label: "label-timestamp-end",
    value: "end_time",
  },
];

const getRelevantDatakeys = (dataValues: any[], dataType: string) => {
  if (dataType === "count") {
    return [
      "name",
      "task_name",
      "total_count",
      "average_runtime",
      "median_runtime",
      "runtime_highest",
      "runtime_lowest",
    ];
  }

  let dataKeys: any[] = [];
  for (const [key, value] of Object.entries(dataValues)) {
    if (typeof value != "object" || value === null) {
      dataKeys.push(key);
    }
  }
  return dataKeys;
};

const getHeaderTranslations = (dataKeys: string[]) => {
  return tableContent
    .filter((content) => dataKeys.includes(content.value))
    .map((content) => content.label);
};

export {
  renderTotalRow,
  getHeaderTranslations,
  getRelevantDatakeys,
  renderMinSuffix,
};
