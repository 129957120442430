import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Grid } from "@mui/material";
import { popoverStyle, popoverTimeDisplayStyle } from "./style";
import { selectStatusColor } from "./utils";
import { TimelineEvent } from "./types";

interface Props {
  eventItem: TimelineEvent | null;
}

const EventPopoverContent = (props: Props) => {
  const { eventItem } = props;
  const { t } = useTranslation();

  if (!eventItem) {
    return <></>;
  }

  const timeElapsed =
    eventItem.actual_start_time && eventItem.actual_stop_time
      ? dayjs
          .duration(
            dayjs(eventItem.actual_stop_time).diff(
              dayjs(eventItem.actual_start_time),
            ),
          )
          .asMinutes()
          .toFixed(0)
      : "N/A";

  const scheduledStart = dayjs(eventItem.scheduled_start_time).format("HH:mm");
  const scheduledEnd = dayjs(eventItem.scheduled_stop_time).format("HH:mm");
  const actualStart = eventItem.actual_start_time
    ? dayjs(eventItem.actual_start_time).format("HH:mm")
    : "N/A";
  const actualEnd = eventItem.actual_stop_time
    ? dayjs(eventItem.actual_stop_time).format("HH:mm")
    : "N/A";

  const scheduledStartUTC = dayjs(eventItem.scheduled_start_time)
    .utc()
    .format("HH:mm");
  const scheduledEndUTC = dayjs(eventItem.scheduled_stop_time)
    .utc()
    .format("HH:mm");
  const actualStartUTC = eventItem.actual_start_time
    ? dayjs(eventItem.actual_start_time).utc().format("HH:mm")
    : "N/A";
  const actualEndUTC = eventItem.actual_stop_time
    ? dayjs(eventItem.actual_stop_time).utc().format("HH:mm")
    : "N/A";

  return (
    <div style={{ width: "300px", padding: "15px 20px" }}>
      <Grid container>
        <Grid container item>
          <Grid item xs={2}>
            <div
              style={{
                backgroundColor: selectStatusColor(eventItem.status),
                width: "14px",
                height: "14px",
                borderRadius: "7px",
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div style={popoverStyle}>
            <b>{t("label-time-reserved")}: </b>
            <span style={popoverTimeDisplayStyle}>
              {scheduledStart} - {scheduledEnd}
            </span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={popoverStyle}>
            {t("label-time-reserved-utc")}
            <span>
              {scheduledStartUTC} - {scheduledEndUTC}
            </span>
          </div>
        </Grid>
        {eventItem.type === "actual" && (
          <>
            <Grid item xs={12}>
              <div style={popoverStyle}>
                <b>{t("label-time-executed")}: </b>
                <span style={popoverTimeDisplayStyle}>
                  {actualStart} - {actualEnd}
                </span>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={popoverStyle}>
                {t("label-time-executed-utc")}
                <span>
                  {actualStartUTC} - {actualEndUTC}
                </span>
              </div>
            </Grid>
          </>
        )}
        {eventItem.type === "actual" && (
          <Grid item xs={12}>
            <div style={popoverStyle}>
              <b>{t("label-time-elapsed")} (min): </b>
              <span style={popoverTimeDisplayStyle}>{timeElapsed}</span>
            </div>
          </Grid>
        )}
        {eventItem.type === "actual" && (
          <Grid item xs={12}>
            <div style={popoverStyle}>
              <b>{t("label-error-count")}: </b>
              <span style={popoverTimeDisplayStyle}>
                {eventItem.errorCount || 0}
              </span>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default EventPopoverContent;
