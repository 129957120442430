import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getEventStampData,
  getTaskStampData,
  getVmStampData,
} from "./stampDataApiService";
import { RootState } from "@app/store";
import { StampData } from "./types";
import { StampFetchPayload } from "./StampsCharts";

const initStamps: StampData[] = [];

const stampDataSlice = createSlice({
  name: "stampData",
  initialState: {
    stamps: initStamps,
    status: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStampData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchStampData.fulfilled, (state, { payload }) => {
        state.stamps = payload || [];
        state.status = "";
      })
      .addCase(fetchStampData.rejected, (state) => {
        state.status = "";
      });
  },
});

export const selectStampData = (state: RootState) => {
  return state.stampData.stamps;
};

export const selectStampDataStatus = (state: RootState) => {
  return state.stampData.status;
};

export default stampDataSlice.reducer;

export const fetchStampData = createAsyncThunk(
  "stampData/fetchStampData",
  async (payload: StampFetchPayload) => {
    const { customerId, eventId, taskId, vmId, startDate, endDate } = payload;
    try {
      if (eventId) {
        const res = await getEventStampData(
          customerId,
          eventId,
          startDate,
          endDate,
        );
        const data = res.data;
        // API currently returns a dictionary/object of objects
        // e.g. { "stampName": { "stampData": "value" }, ... }
        if (data && typeof data === "object" && !Array.isArray(data)) {
          return Object.values(data);
        } else if (Array.isArray(data)) {
          return data;
        }
      } else if (taskId) {
        const res = await getTaskStampData(
          customerId,
          taskId,
          startDate,
          endDate,
        );
        return res.data;
      } else if (vmId) {
        const res = await getVmStampData(customerId, vmId, startDate, endDate);
        return res.data;
      }
    } catch (err) {
      console.error(err);
      throw new Error("Failed to fetch stamp data");
    }
  },
);
