import React, { useState } from "react";
import { TextField, MenuItem, Grid, Switch, Box } from "@mui/material";
import { IPermissions } from "./types";
import { toast } from "react-toastify";

interface ToggleProps {
  textFieldLabel: string;
  menuId: string;
  parentState: any;
  setParentComponentState: any;
  style: any;
  disabled: boolean;
}

export const SwitchMenu = (props: ToggleProps) => {
  const {
    textFieldLabel,
    menuId,
    parentState,
    setParentComponentState,
    style,
    disabled,
  } = props;

  const [menuItemSwitchStates, setMenuItemSwitchStates] =
    useState<IPermissions>(parentState); // TODO this setter will be used or deleted when switch flip is implemented

  const handleSelectedItem = (event: React.ChangeEvent<HTMLInputElement>) => {
    setParentComponentState(event.target.value);
  };

  const flipSwitch = (event: any) => {
    console.log("permission flip not implemented");
    toast.info("Permission flip not implemenmted");
  };

  return (
    <>
      <TextField
        sx={style}
        select
        name="basic-textfield"
        id={menuId}
        label={textFieldLabel}
        InputLabelProps={{ shrink: false }}
        value={textFieldLabel}
        onChange={handleSelectedItem}
        disabled={disabled}
      >
        {Object.entries(menuItemSwitchStates).map(([key, value]) => (
          <Grid container alignItems="center">
            <Grid item xs>
              <Box display="flex" alignItems="center">
                <MenuItem>{key}</MenuItem>
              </Box>
            </Grid>
            <Grid item>
              <Box display="flex" justifyContent="flex-end">
                <Switch id={key} checked={value} onChange={flipSwitch} />
              </Box>
            </Grid>
          </Grid>
        ))}
      </TextField>
    </>
  );
};
