import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  getTaskSchedulesRequest,
  updateScheduleRequest,
  postScheduleRequest,
  deleteScheduleRequest,
} from "./scheduleApiService";
import { RootState } from "@app/store";
import { ISchedule, IScheduleCreate, IScheduleUpdate } from "../types";

const scheduleAdapter = createEntityAdapter<ISchedule>({
  selectId: (schedule) => schedule.schedule_guid,
});

const initialState = scheduleAdapter.getInitialState({
  selectedSchedule: "",
  status: "",
});

const scheduleSlice = createSlice({
  name: "schedules",
  initialState,
  reducers: {
    selectSchedule(state, action: PayloadAction<string>) {
      state.selectedSchedule = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSchedules.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchSchedules.fulfilled,
        (state, { payload }: PayloadAction<ISchedule[]>) => {
          scheduleAdapter.setAll(state, payload);
          state.status = "";
        },
      )
      .addCase(fetchSchedules.rejected, (state) => {
        state.status = "";
      })
      .addCase(createSchedule.pending, (state) => {
        state.status = "submitting";
      })
      .addCase(
        createSchedule.fulfilled,
        (state, { payload }: PayloadAction<ISchedule>) => {
          scheduleAdapter.addOne(state, payload);
          state.status = "";
        },
      )
      .addCase(createSchedule.rejected, (state) => {
        state.status = "";
      })
      .addCase(updateSchedule.pending, (state) => {
        state.status = "submitting";
      })
      .addCase(
        updateSchedule.fulfilled,
        (state, { payload }: PayloadAction<ISchedule>) => {
          scheduleAdapter.upsertOne(state, payload);
          state.status = "";
        },
      )
      .addCase(updateSchedule.rejected, (state) => {
        state.status = "";
      })
      .addCase(deleteSchedule.pending, (state) => {
        state.status = "deleting";
      })
      .addCase(
        deleteSchedule.fulfilled,
        (state, { payload }: PayloadAction<string[]>) => {
          scheduleAdapter.removeMany(state, payload);
          state.status = "";
        },
      )
      .addCase(deleteSchedule.rejected, (state) => {
        state.status = "";
      });
  },
});

export interface ScheduleIdCollection {
  customerId: string;
  taskId: string;
}

export const fetchSchedules = createAsyncThunk(
  "schedules/fetchSchedules",
  async (customerAndTaskId: ScheduleIdCollection) => {
    try {
      const res = await getTaskSchedulesRequest(
        customerAndTaskId.customerId,
        customerAndTaskId.taskId,
      );
      if (res) return res.data;
      else return res;
    } catch (err) {
      console.error(err);
      throw new Error("failed to fetch schedule data");
    }
  },
);

export const createSchedule = createAsyncThunk(
  "schedules/createSchedule",
  async (payload: IScheduleCreate) => {
    const customerId = payload.customer_id;
    const taskId = payload.task_id;
    const res = await postScheduleRequest(customerId, taskId, payload);

    return res.data;
  },
);

export const updateSchedule = createAsyncThunk(
  "schedules/updateSchedule",
  async (payload: IScheduleUpdate) => {
    const customerId = payload.customer_id;
    const taskId = payload.task_id;
    const scheduleId = payload.schedule_id;
    const res = await updateScheduleRequest(
      customerId,
      taskId,
      scheduleId,
      payload,
    );
    return res.data;
  },
);

export interface DeleteSchedulePayload {
  customerId: string;
  taskId: string;
  scheduleIds: string[];
}

export const deleteSchedule = createAsyncThunk(
  "schedules/deleteSchedule",
  async (payload: DeleteSchedulePayload) => {
    const customerId = payload.customerId;
    const taskId = payload.taskId;
    const scheduleIds = payload.scheduleIds;
    try {
      const res = await Promise.all(
        scheduleIds.map((scheduleId) =>
          deleteScheduleRequest(customerId, taskId, scheduleId),
        ),
      );
      return scheduleIds;
    } catch (err) {
      console.error(err);
      throw new Error("Failed to delete schedule data");
    }
  },
);

export const { selectSchedule } = scheduleSlice.actions;
export default scheduleSlice.reducer;

const scheduleSelectors = scheduleAdapter.getSelectors(
  (state: RootState) => state.schedules,
);

// Selector for getting customer's tasks from the state
export const getTaskSchedules = (
  state: RootState,
  customerId: string,
  taskId: string | undefined,
) => {
  return scheduleSelectors
    .selectAll(state)
    .filter(
      (schedule: any) =>
        schedule.customer_guid === customerId && schedule.task_guid === taskId,
    );
};

export const getSelectedSchedule = (state: RootState) => {
  return scheduleSelectors.selectById(state, state.schedules.selectedSchedule);
};

export const selectSchedulesStatus = (state: RootState) =>
  state.schedules.status;
