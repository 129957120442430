import LoadingScreen from "@features/common/LoadingScreen";
import {
  Grid,
  List,
  ListItemButton,
  ListItemText,
  useTheme,
} from "@mui/material";
import CustomerDialogNoResults from "./CustomerDialogNoResults";
import { ICustomer } from "../types";
import { listItemButtonStyles, listItemTextStyles } from "./style";
import GroupsIcon from "@mui/icons-material/Groups";

interface IComponentProps {
  currentlyDisplayedCustomers: ICustomer[] | undefined;
  isLoading: boolean;
  selectedCustomer: ICustomer | null;
  handleSelectCustomer: (customer: ICustomer) => void;
}

/**
 *
 * @param props
 * @returns
 */
const CustomerList = (props: IComponentProps) => {
  const {
    currentlyDisplayedCustomers,
    isLoading,
    selectedCustomer,
    handleSelectCustomer,
  } = props;

  const theme = useTheme();

  const listStyles = {
    height: 203,
    maxHeight: 203,
    overflow: "auto",
    maxWidth: 330,
    "&& .Mui-selected, && .Mui-selected:hover": {
      bgcolor: theme.palette.primary.dark,
      "&, & .MuiListItemIcon-root": {
        color: "white",
      },
    },
    "& .MuiListItemButton-root:hover": {
      bgcolor: theme.palette.secondary.main,
    },
  };

  return (
    <Grid sx={{ margin: "16px", width: "330px" }}>
      {!currentlyDisplayedCustomers || isLoading ? (
        <LoadingScreen />
      ) : (
        <List sx={listStyles}>
          {currentlyDisplayedCustomers?.length === 0 ? (
            <CustomerDialogNoResults />
          ) : (
            currentlyDisplayedCustomers?.map((customer: ICustomer) => (
              <ListItemButton
                data-testid="customer-selection"
                sx={listItemButtonStyles}
                key={customer.id}
                divider={true}
                selected={customer === selectedCustomer}
                onClick={() => handleSelectCustomer(customer)}
              >
                <GroupsIcon sx={{ marginTop: "-0.25vh" }} />
                <ListItemText sx={listItemTextStyles} primary={customer.name} />
              </ListItemButton>
            ))
          )}
        </List>
      )}
    </Grid>
  );
};

export default CustomerList;
