import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  fetchCustomers,
  getSelectedCustomerId,
  selectCustomer,
} from "@features/customers/customerSlice";
import { useAppDispatch } from "@features/common/StoreHook";
import { permissionsApi } from "@features/authorization/authService";
import { selectTask } from "@features/tasks/tasksSlice";

const CustomerLayout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const urlCustomerId = useParams().customerId; // customer id in the url params
  const selectedCustomerId = useSelector(getSelectedCustomerId);
  const {
    data: data1,
    error: error1,
    isLoading: isLoading1,
  } = permissionsApi.useGetCustomerPermissionsQuery(selectedCustomerId);
  const {
    data: data2,
    error: error2,
    isLoading: isLoading2,
  } = permissionsApi.useGetOivaPermissionsQuery();

  const currentUrl = useLocation().pathname;
  const uuidRegex = new RegExp(
    /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/,
  );

  useEffect(() => {
    // TODO: check permissions
    if (urlCustomerId && uuidRegex.test(urlCustomerId)) {
      if (urlCustomerId !== selectedCustomerId) {
        // fetch the right customer, redirect on fail
        dispatch(fetchCustomers())
          .unwrap()
          .then(() => {
            dispatch(selectCustomer(urlCustomerId));
          })
          .catch((err) => {
            console.log("err", err);
            navigate("/");
          });
      }
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    // if the selected customer id changes
    // check if customer id matches the url customer id -> if not, redirect to the correct url
    if (
      urlCustomerId &&
      selectedCustomerId &&
      urlCustomerId !== selectedCustomerId
    ) {
      const redirectUrl = currentUrl.replace(urlCustomerId, selectedCustomerId);
      navigate(redirectUrl);
    }
    // reset selected task when customer changes
    dispatch(selectTask(""));
  }, [selectedCustomerId]);

  // if customer id in url params does not match the selected customer id in the store, do not render anything
  if (urlCustomerId !== selectedCustomerId) {
    return <></>;
  } else {
    return (
      <>
        <Outlet />
      </>
    );
  }
};

export default CustomerLayout;
