import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { formatDateToFinnishStyle } from "../utils";
import { EventErrorCount } from "./errorsChartUtils";

interface ErrorsTableProps {
  data: EventErrorCount[];
}

const ErrorsTable = (props: ErrorsTableProps) => {
  const { data } = props;
  const { t } = useTranslation();
  return (
    <>
      <Table aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell key="date">{t("label-date")}</TableCell>
            <TableCell key="count">{t("label-count")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((node: any, id: number) => {
            return (
              <TableRow key={"date" + id}>
                <TableCell>{formatDateToFinnishStyle(node["date"])}</TableCell>
                <TableCell>{node["errors"]}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default ErrorsTable;
