import { useSelector } from "react-redux";
import { getSelectedSecret } from "./secretsSlice";
import { KeyvaultInfo } from "./KeyvaultInfo";
import { SecretSelector } from "./SecretSelector";
import { SecretEditor } from "./SecretEditor";
import { ISecret } from "./types";

/**
 *
 * Main window for CustomerManagement / Secrets view
 * renders two main components (keyvaultinfo & secretselector table)
 * if a secret is selected from the table, then render also secret editor
 *
 *
 * TODO: error handling, integrate when toasts are setup. Interaction messages (success, fail, etc)
 */

export const Secrets = () => {
  const selectedSecret: ISecret | undefined = useSelector(getSelectedSecret);

  return (
    <>
      <KeyvaultInfo />
      <SecretSelector />
      {selectedSecret !== undefined && selectedSecret !== null ? (
        <SecretEditor key={selectedSecret.name} />
      ) : null}
    </>
  );
};
