import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";

interface IProps {
  disabled: boolean;
  handleSave?: () => void;
  handleCancel?: () => void;
}

const SaveCancelButtonGroup = (props: IProps) => {
  const { disabled, handleCancel, handleSave } = props;
  const { t } = useTranslation();

  return (
    <Grid container direction="row" justifyContent="flex-end" gap={1}>
      <Button
        data-testid="add-button"
        variant="contained"
        onClick={handleSave}
        disabled={disabled || !handleSave}
      >
        {t("button_form_save")}
      </Button>
      <Button
        variant="outlined"
        disabled={disabled || !handleCancel}
        onClick={handleCancel}
        data-testid="cancel-button"
        sx={{
          backgroundColor: "white",
          color: "secondary.contrastText",
          borderColor: "secondary.light",
        }}
      >
        {t("button_form_cancel")}
      </Button>
    </Grid>
  );
};

export default SaveCancelButtonGroup;
