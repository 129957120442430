import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  Breadcrumbs,
  Paper,
  Typography,
  Chip,
  Tooltip,
  IconButton,
  TextField,
  useTheme,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  ActiveTimelineItem,
  Breadcrumb,
  EventType,
  TimelineResource,
} from "./types";
import { makeBreadcrumbs } from "./utils";

/**
 *
 * Selector pane displays the selected resource (vm/task/event) and allows the user to copy event id.
 * Also has a select date fields for changing date range of displayed data charts (WIP).
 *
 * TODO:
 *  - Date range selection would be best done with a date range picker component from a library.
 *    As it is, the date range selection is not very user friendly and requires adding date range validation checks.
 */
interface Props {
  selectedItem: ActiveTimelineItem;
  resources: TimelineResource[];
  startDate: any;
  endDate: any;
  setStartDate: any;
  setEndDate: any;
}

const SelectorPane = (props: Props) => {
  const {
    selectedItem,
    resources,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const [activeItem, setActiveItem] =
    useState<ActiveTimelineItem>(selectedItem);
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  const [copySuccess, setCopySuccess] = useState<boolean>(false);

  const showDateSelection = activeItem.type !== EventType.Event;

  useEffect(() => {
    const crumbs = makeBreadcrumbs(resources, activeItem);
    setBreadcrumbs(crumbs);
  }, [activeItem]);

  const handleCopyToClipboard = (item: Breadcrumb) => {
    navigator.clipboard.writeText(item.id);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 2000);
  };

  return (
    <Paper
      sx={{
        padding: "10px",
        margin: "20px 0px",
        background: theme.palette.secondary.main,
      }}
    >
      <Typography sx={{ marginBottom: "8px" }} variant="h5">
        Selected resource
      </Typography>
      <Breadcrumbs sx={{ marginBottom: "10px" }}>
        {breadcrumbs.map((item: Breadcrumb, index: number, array: any) => (
          <span key={item.id}>
            <Chip
              label={item.title}
              onClick={() =>
                index !== array.length - 1 ? setActiveItem(item) : null
              }
              style={{
                background: theme.palette.primary.main,
                color: "#fff",
              }}
            />
            {index === array.length - 1 && (
              <Tooltip
                arrow
                placement="top"
                title={copySuccess ? "Copied!" : t("label_copy")}
                leaveDelay={copySuccess ? 1500 : 0}
              >
                <IconButton
                  size="small"
                  sx={{ marginLeft: "5px" }}
                  onClick={() => handleCopyToClipboard(item)}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            )}
          </span>
        ))}
      </Breadcrumbs>
      {showDateSelection ? (
        <>
          <Typography variant="h6" sx={{ marginBottom: "5px" }}>
            Show data for date range
          </Typography>
          <TextField
            label={t("label-begin-date")}
            size="small"
            type="date"
            value={dayjs(startDate).format("YYYY-MM-DD")}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ margin: "5px" }}
          />
          <TextField
            label={t("label-end-date")}
            size="small"
            type="date"
            value={dayjs(endDate).format("YYYY-MM-DD")}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ margin: "5px" }}
          />
        </>
      ) : null}
    </Paper>
  );
};

export default SelectorPane;
