import { useState } from "react";
import { useSelector } from "react-redux";
import { DialogContent, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import { DropdownSelect } from "@features/common/DropdownSelect";
import { RootState } from "@app/store";
import SaveCancelButtonGroup from "@features/common/ButtonGroups/SaveCancelButtonGroup";
import { useCollection } from "@features/common/collectionHook";
import { useAppDispatch } from "@features/common/StoreHook";

import { ITaskCreate } from "../types";
import { selectTasksStatus, createTask } from "../tasksSlice";
import { taskDialogAddStyle, taskAddDropdownStyle } from "../styles";
import FormTextField from "@features/common/FormFields/FormTextField";
import FormTextArea from "@features/common/FormFields/FormTextArea";

interface IAddTaskProps {
  customerId: string;
  onCancel: () => void;
}

const validationSchema = yup.object({
  task_name: yup.string().required(),
  task_description: yup.string().nullable(),
  start_script_path: yup.string().nullable(),
});

const AddTask = (props: IAddTaskProps) => {
  const { customerId, onCancel } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const priorityValues = useCollection(12);
  const [priority, setPriority] = useState<string>("");

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      task_name: "",
      task_description: "",
      start_script_path: "",
    },
  });

  // this is returned from the dropdown component and is a number but in string format
  // e.g. "0" or "2"
  const handleDropdownChange = (event: string) => {
    setPriority(event);
  };

  const handleFormSubmit = (data: any) => {
    // change the priority value to a number
    const priorityVal =
      priority === "Low"
        ? 1
        : priority === "Normal"
        ? 2
        : priority === "High"
        ? 3
        : null;

    const payload: ITaskCreate = {
      customer_guid: customerId,
      task_name: data.task_name,
      task_description: data.task_description,
      // Task logo and type are not currently in use, so they're not in the form
      // for now. They can be added later if needed.
      task_logo: null,
      task_type: null,
      priority: priorityVal,
      start_script_path: data.start_script_path,
    };

    dispatch(createTask(payload))
      .unwrap()
      .then(() => {
        toast.success(t("Task added"));
        // close the dialog
        onCancel();
      })
      .catch((e) => {
        toast.error("Could not add task");
      });
  };

  const isSubmitting =
    useSelector((state: RootState) => selectTasksStatus(state)) ===
    "submitting";

  return (
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ marginTop: 1 }}>
          <FormTextField
            name="task_name"
            control={control}
            label={t("label-task-name")}
            style={taskDialogAddStyle}
          />
          <FormTextArea
            name="task_description"
            control={control}
            label={t("label-task-description")}
            style={taskDialogAddStyle}
          />
          <DropdownSelect
            selectItems={priorityValues}
            selectedItem={priority}
            textFieldLabel={t("label-task-priority")}
            menuId="AddTaskDropdownSelectPriority"
            setParentComponentState={handleDropdownChange}
            style={taskAddDropdownStyle}
            disabled={false}
          />
          <FormTextField
            name="start_script_path"
            control={control}
            label={t("label-script-path")}
            style={taskDialogAddStyle}
          />
        </Grid>
      </Grid>
      <SaveCancelButtonGroup
        disabled={isSubmitting}
        handleCancel={onCancel}
        handleSave={handleSubmit(handleFormSubmit)}
      />
    </DialogContent>
  );
};

export default AddTask;
