import React, { useState } from "react";
import { AccountCircle, ExitToApp } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { containerStyle } from "./style";
import { useMsal } from "@azure/msal-react";

/**
 * Displays a profile icon button and a popover user menu in the header.
 * @returns
 */
const UserMenu = () => {
  const [anchorEl, setanchorEl] = useState<Element | null>(null);
  const { t } = useTranslation();
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const userName = activeAccount?.username;

  const handleLogout = () => {
    setanchorEl(null);
    instance.logout(); // TODO verify that it does everything necessary and correctly
    //dispatch(logoutUserActionCreator());
  };

  return (
    <div style={containerStyle}>
      <Tooltip title={t("Account")}>
        <IconButton
          aria-owns={Boolean(anchorEl) ? "menu-user" : undefined}
          aria-haspopup="true"
          onClick={(e) => setanchorEl(e.currentTarget)}
          color="inherit"
          sx={{ padding: "12px" }}
        >
          <AccountCircle />
        </IconButton>
      </Tooltip>
      <Menu
        disableAutoFocusItem
        id="menu-user"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setanchorEl(null)}
      >
        <MenuItem>
          <ListItemText>
            <Typography variant="h3">{userName}</Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem data-testid="logout-button" onClick={(e) => handleLogout()}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="h3">{t("Log out")}</Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserMenu;
