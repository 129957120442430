import { useTranslation } from "react-i18next";
import { Grid, Typography, FormControl, FormLabel } from "@mui/material";
import { formControlStyle, formLabelStyle } from "../style";
import { IVirtualMachine } from "../types";
import { useCollection } from "@features/common/collectionHook";
import FormDropdownSelect from "../../common/FormFields/FormDropdownSelect";

interface IComponentProps {
  readOnly: boolean;
  control: any;
}

const TypeSpecificInfo = (props: IComponentProps) => {
  const { readOnly, control } = props;
  const { t } = useTranslation();

  const environmentCategories = useCollection(3);
  const environmentLevels = useCollection(4);

  const customTooltip = (
    <>
      <Typography color="inherit">Within Azure these values mean:</Typography>
      <p>{"Lite = B1 ms"}</p>
      <p>{"Normal = B2 ms"}</p>
      <p>{"Heavy = Standard D2 v3"}</p>
      <p>{"Super Heavy = Standard D8s v3"}</p>
    </>
  );

  return (
    <Grid item xs={12}>
      <FormControl component="fieldset" sx={formControlStyle}>
        <FormLabel component="legend" sx={formLabelStyle}>
          {t("label-type-specified-information")}
        </FormLabel>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormDropdownSelect
              name="category"
              control={control}
              label={t("label-environment-category")}
              selectItems={environmentCategories}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <FormDropdownSelect
              name="level"
              control={control}
              label={t("label-environment-level")}
              selectItems={environmentLevels}
              disabled={readOnly}
              helpText={customTooltip}
            />
          </Grid>
        </Grid>
      </FormControl>
    </Grid>
  );
};

export default TypeSpecificInfo;
