import { Controller } from "react-hook-form";
import { FormControlLabel, Checkbox } from "@mui/material";

/**
 * Checkbox for forms using react-hook-form.
 *
 * @param control: react-hook-form control object
 */

interface IProps {
  name: string;
  control: any;
  label: string;
  style?: any;
  disabled?: boolean;
}

const FormCheckbox = (props: IProps) => {
  const { style, name, control, label, disabled } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <FormControlLabel
          control={
            <Checkbox
              name={name}
              onChange={onChange}
              checked={!!value}
              disabled={disabled || false}
              color="secondary"
              sx={{
                ...style,
                color: "secondary.light",
              }}
            />
          }
          label={label}
        />
      )}
    />
  );
};

export default FormCheckbox;
