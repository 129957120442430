import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { formatToNDecimals } from "../utils";
import { RuntimeData } from "./runtimesChartUtils";

interface RuntimeTableProps {
  data: RuntimeData[];
}

const RuntimeTable = (props: RuntimeTableProps) => {
  const { data } = props;
  const { t } = useTranslation();

  return (
    <>
      <Table aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell key={"scheduled-runtime"}>
              {t("label-scheduled-runtime")}
            </TableCell>
            <TableCell key={"actual-runtime"}>
              {t("label-actual-runtime")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((obj: any, id: number) => {
            return (
              <TableRow key={id}>
                <TableCell key={"scheduled-" + id}>
                  {obj["scheduled"]} {t("label-minutes")} (
                  {formatToNDecimals(obj["scheduled"] / 60, 1)}{" "}
                  {t("label-hours")})
                </TableCell>
                <TableCell key={"actual-" + id}>
                  {obj["actual"]} {t("label-minutes")} (
                  {formatToNDecimals(obj["actual"] / 60, 1)} {t("label-hours")})
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default RuntimeTable;
