import { useSelector } from "react-redux";
import { UserSelector } from "./UserSelector";
import { UserEditor } from "./UserEditor";
import { getSelectedUser } from "@features/customermanagement/UsersRoles/userSlice";

export const UsersRolesWindow = () => {
  const selectedUser: any | undefined = useSelector(getSelectedUser);

  return (
    <>
      <UserSelector />
      {/**
       * FYI:
       * The following check is pointless right now as UserSelector always resets the selectedUser value on each render,
       * so this always defaults to null.
       */}
      {selectedUser ? <UserEditor /> : null}
    </>
  );
};
