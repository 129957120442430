import DarkModeIcon from "@mui/icons-material/DarkMode";
import { IconButton, Tooltip, useColorScheme } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const DarkMode = () => {
  const { t } = useTranslation();
  const { mode, setMode } = useColorScheme();

  useEffect(() => {
    const currentSetting = localStorage.getItem("dark-mode");
    if (currentSetting == null || currentSetting == "false") {
      setMode("light");
    } else if (currentSetting == "true") {
      setMode("dark");
    }
  }, []);

  if (!mode) {
    return null;
  }

  const switchMode = () => {
    const currentSetting = localStorage.getItem("dark-mode");
    if (currentSetting !== "true") {
      setMode("dark");
      localStorage.setItem("dark-mode", "true");
    } else {
      setMode("light");
      localStorage.setItem("dark-mode", "false");
    }
  };

  return (
    <Tooltip title={t("label-dark-mode")}>
      <IconButton onClick={switchMode}>
        <DarkModeIcon style={{ color: "white" }} />
      </IconButton>
    </Tooltip>
  );
};

export default DarkMode;
