import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  FormControl,
  FormLabel,
  Typography,
  DialogActions,
  Alert,
  TextField,
  MenuItem,
} from "@mui/material";
import { toast } from "react-toastify";

import {
  FormCheckbox,
  FormDropdownSelect,
  FormTextField,
} from "@features/common/FormFields";
import { dialogFormStyle } from "@features/common/styles";
import SaveCancelButtonGroup from "@features/common/ButtonGroups/SaveCancelButtonGroup";
import { formControlStyle, formLabelStyle } from "@features/common/styles";
import { useAppDispatch } from "@features/common/StoreHook";
import LoadingIndicatorBox from "@features/common/LoadingIndicatorBox/LoadingIndicatorBox";
import { createCustomer, selectCustomersStatus } from "../customerSlice";
import { titleStyles } from "../customersDialog/style";
import {
  fetchSubscriptions,
  selectSubscriptionsStatus,
  selectAllSubscriptions,
} from "./subscriptionsSlice";

interface AddCustomerDialogProps {
  open: boolean;
  handleClose: () => void;
}

const validationSchema = yup.object({
  customer_name: yup.string().required(),
  vnet_address_space: yup.string().required(),
  privx_used: yup.boolean().required(),
  vpn_used: yup.boolean().required(),
  specific_vpn_device_in_use: yup.boolean().required(),
  on_site_implementation: yup.boolean().required(),
  nat_gateway_used: yup.boolean().required(),
  tenant_id: yup.string().required(),
  subscription_id: yup.string().required(),
});

const AddCustomerDialog = (props: AddCustomerDialogProps) => {
  const { open, handleClose } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isSubmitting = useSelector(selectCustomersStatus) === "submitting";
  const isLoadingSubscriptions =
    useSelector(selectSubscriptionsStatus) === "loading";
  const cloudSubscriptions = useSelector(selectAllSubscriptions);
  const [subscriptionsError, setSubscriptionsError] = useState<string | null>(
    null,
  );

  useEffect(() => {
    dispatch(fetchSubscriptions())
      .unwrap()
      .catch((err) => {
        setSubscriptionsError(t("Failed to load subscriptions"));
      });
  }, []);

  const { handleSubmit, control, reset, getValues, setValue, watch } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      customer_name: "",
      vnet_address_space: "",
      privx_used: false,
      vpn_used: false,
      specific_vpn_device_in_use: false,
      on_site_implementation: false,
      nat_gateway_used: false,
      tenant_id: "",
      subscription_id: "",
    },
  });

  useEffect(() => {
    reset({
      customer_name: "",
      vnet_address_space: "",
      privx_used: false,
      vpn_used: false,
      specific_vpn_device_in_use: false,
      on_site_implementation: false,
      nat_gateway_used: false,
      tenant_id: cloudSubscriptions[0]?.tenant_id || "",
      subscription_id: cloudSubscriptions[0]?.subscriptions[0] || "",
    });
  }, [cloudSubscriptions]);

  const handleAddCustomer = () => {
    handleSubmit((data) => {
      dispatch(createCustomer(data))
        .unwrap()
        .then(() => {
          toast.success(t("label-new-customer-queue-success"));
          handleClose();
          reset();
        })
        .catch((err) => {
          toast.error(t("label-new-customer-queue-failed"));
        });
    })();
  };

  const handleDialogClose = () => {
    handleClose();
    reset();
  };

  const tenantId = watch("tenant_id");
  const tenants = cloudSubscriptions.map((sub) => ({
    id: sub.tenant_id,
    value: sub.tenant_id,
    label: sub.tenant_id,
  }));
  const subscriptionsToSelect =
    cloudSubscriptions
      .find((sub) => sub.tenant_id === tenantId)
      ?.subscriptions.map((sub) => ({ id: sub, value: sub, label: sub })) || [];

  const onTenantFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setValue(
      "subscription_id",
      cloudSubscriptions.find((sub) => sub.tenant_id === e.target.value)
        ?.subscriptions[0] || "",
    );
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle sx={titleStyles}>
        {t("label-create-customer-title")}
      </DialogTitle>
      <DialogContent>
        <FormControl component="fieldset" style={dialogFormStyle}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                marginBottom={
                  isLoadingSubscriptions || subscriptionsError ? 0 : 1
                }
              >
                {t("Subscription details")}
              </Typography>
            </Grid>
            {isLoadingSubscriptions ? (
              <Grid item xs={12}>
                <LoadingIndicatorBox />
              </Grid>
            ) : subscriptionsError ? (
              <Grid item xs={12}>
                <Alert
                  severity="error"
                  sx={{ ".MuiSvgIcon-root": { fontSize: "22px" } }}
                >
                  {subscriptionsError}
                </Alert>
              </Grid>
            ) : (
              <>
                <Grid item xs={12}>
                  <Controller
                    name={"tenant_id"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        select
                        label={t("label-tenant-id")}
                        value={value || ""}
                        onChange={(e) => {
                          onChange(e);
                          onTenantFieldChange(e);
                        }}
                      >
                        {tenants.find((t) => t.value === value || !value) ? (
                          tenants.map((item) => (
                            <MenuItem key={item.id} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        )}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12} marginBottom={1}>
                  <FormDropdownSelect
                    key={watch("tenant_id")}
                    name="subscription_id"
                    label={t("label-subscription-id")}
                    control={control}
                    disabled={isSubmitting}
                    selectItems={subscriptionsToSelect}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Typography variant="h4" marginBottom={1}>
                {t("Customer details")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name="customer_name"
                label={t("customer-name")}
                control={control}
                readOnly={isSubmitting}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name="vnet_address_space"
                label={t("label-vnet-address-space")}
                control={control}
                readOnly={isSubmitting}
              />
            </Grid>
            <Grid item xs={12} marginTop={1}>
              <FormControl
                component="fieldset"
                sx={{ ...formControlStyle, padding: "8px" }}
              >
                <FormLabel component="legend" sx={formLabelStyle}>
                  {t("label-network-environment")}
                </FormLabel>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <FormCheckbox
                      name="vpn_used"
                      label={t("label-vpn-used")}
                      control={control}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormCheckbox
                      name="specific_vpn_device_in_use"
                      label={t("label-specific-vpn-device-in-use")}
                      control={control}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormCheckbox
                      name="on_site_implementation"
                      label={t("label-on-site-implementation")}
                      control={control}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormCheckbox
                      name="nat_gateway_used"
                      label={t("label-nat-gateway-used")}
                      control={control}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <SaveCancelButtonGroup
          disabled={isSubmitting}
          handleCancel={handleDialogClose}
          handleSave={handleAddCustomer}
        />
      </DialogActions>
    </Dialog>
  );
};

export default AddCustomerDialog;
