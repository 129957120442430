import {
  ResponsiveContainer,
  Legend,
  Tooltip,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { useTranslation } from "react-i18next";
import ChartInfoBox from "../ChartInfoBox";
import FinishedStatusChartTooltip from "./FinishedStatusChartTooltip";
import { chartColors } from "../utils";

export interface FinishedStatusChartProps {
  data: any;
}

const FinishedStatusChart = (props: FinishedStatusChartProps) => {
  const { data } = props;
  const legend = ["label-run-successful", "label-run-unsuccessful"];
  const { t } = useTranslation();
  const colors = [chartColors.green, chartColors.red];

  const errorTotal = () => {
    return data.reduce((acc: number, item: any) => acc + item["value"], 0);
  };

  if (!data) {
    return <ChartInfoBox severity="info" text={t("label-no-data")} />;
  }

  // if all chart data is 0, display info box
  if (errorTotal() === 0) {
    return (
      <ChartInfoBox
        severity="info"
        text={t("label-no-data")}
        additionalText={t("label-unable-to-display-errors")}
      />
    );
  }

  const customizedLabel = (props: any) => {
    return (
      <text
        x={props.x}
        y={props.y}
        fill={props.fill}
        textAnchor={props.x > props.cx ? "start" : "end"}
      >
        {`${props.value} ${t("label-pieces")}`}
      </text>
    );
  };

  return (
    <ResponsiveContainer>
      <PieChart>
        <Legend
          wrapperStyle={{ fontSize: "90%" }}
          payload={legend.map((translationKey: any, index: number) => ({
            id: t(translationKey),
            value: t(translationKey),
            color: colors[index],
          }))}
        />
        <Pie
          data={data}
          dataKey="value"
          isAnimationActive={false}
          label={customizedLabel}
        >
          <Cell key={data[0]["name"]} fill={colors[0]} />
          <Cell key={data[1]["name"]} fill={colors[1]} />
        </Pie>
        <Tooltip
          content={<FinishedStatusChartTooltip data={data} />}
          animationDuration={500}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default FinishedStatusChart;
