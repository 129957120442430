// MUI
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography/Typography";
import { TooltipProps } from "recharts";
import {
  ValueType,
  NameType,
} from "recharts/types/component/DefaultTooltipContent";
import { tooltipCardContentStyles } from "../style";

import { formatToNDecimals } from "../utils";

const RuntimesChartTooltip = (props: TooltipProps<ValueType, NameType>) => {
  const { payload } = props;
  if (!payload || payload.length === 0) {
    return null;
  }
  const cardStyles = { opacity: "0.85" };

  const reservedTimeStyles = { color: payload[0].color, fontSize: "2vh" };
  const actualTimeStyles = { color: payload[1].color, fontSize: "2vh" };
  return (
    <Card variant="elevation" elevation={10} style={cardStyles}>
      <CardContent sx={tooltipCardContentStyles}>
        <Typography variant="h1" component="h2" style={reservedTimeStyles}>
          {payload[0].name}: {formatToNDecimals(payload[0].value, 1)}
          {payload[0].unit}
        </Typography>
        <Typography variant="h1" component="h2" style={actualTimeStyles}>
          {payload[1].name}: {formatToNDecimals(payload[1].value, 1)}
          {payload[1].unit}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default RuntimesChartTooltip;
