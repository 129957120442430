import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import customerSlice from "@features/customers/customerSlice";
import tasksSlice from "@features/tasks/tasksSlice";
import environmentsSlice from "@features/environments/environmentsSlice";
import subnetsSlice from "@features/environments/networks/subnetsSlice";
import scheduleSlice from "@features/tasks/Scheduler/scheduleSlice";
import stampsSlice from "@features/tasks/Stamps/stampsSlice";
import collectionsSlice from "@features/admin/Collections/collectionsSlice";
import secretsSlice from "@features/customermanagement/Secrets/secretsSlice";
import userSlice from "@features/customermanagement/UsersRoles/userSlice";
import internalUserSlice from "@features/customermanagement/UsersRoles/internalUserSlice";
import rolesSlice from "@features/customermanagement/UsersRoles/rolesSlice";
import connectionsSlice from "@features/environments/connections/connectionsSlice";
import eventLogsSlice from "@features/dashboard/EventLogs/eventLogsSlice";
import { oivaApi as oivaBaseApi } from "@features/common/oivaApi";
import stampDataSlice from "@features/dashboard/Dashboard/Charts/StampsChart/stampDataSlice";
import translationsSlice from "@features/admin/Texts/translationsSlice";
import subscriptionsSlice from "@features/customers/addCustomerDialog/subscriptionsSlice";

//const oivaBaseApi = oivaApi(true);

export const store = configureStore({
  reducer: {
    customer: customerSlice,
    tasks: tasksSlice,
    environments: environmentsSlice,
    subnets: subnetsSlice,
    schedules: scheduleSlice,
    stamps: stampsSlice,
    collections: collectionsSlice,
    secrets: secretsSlice,
    users: userSlice,
    internalUsers: internalUserSlice,
    roles: rolesSlice,
    connections: connectionsSlice,
    eventLogs: eventLogsSlice,
    [oivaBaseApi.reducerPath]: oivaBaseApi.reducer,
    stampData: stampDataSlice,
    translations: translationsSlice,
    subscriptions: subscriptionsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(oivaBaseApi.middleware),
});

/**
 * This is a helper function used within RTL.
 * Not concerned with anything production related.
 * Do not modify unless necessary (e.g. adding a new reducer).
 *
 * Ref: https://redux.js.org/usage/writing-tests#integration-testing-connected-components-and-redux-logic
 */
export function setupTestStore(preloadedState?: Partial<RootState>) {
  return configureStore({
    reducer: {
      customer: customerSlice,
      tasks: tasksSlice,
      environments: environmentsSlice,
      subnets: subnetsSlice,
      schedules: scheduleSlice,
      stamps: stampsSlice,
      collections: collectionsSlice,
      secrets: secretsSlice,
      users: userSlice,
      internalUsers: internalUserSlice,
      roles: rolesSlice,
      connections: connectionsSlice,
      eventLogs: eventLogsSlice,
      [oivaBaseApi.reducerPath]: oivaBaseApi.reducer,
      stampData: stampDataSlice,
      translations: translationsSlice,
      subscriptions: subscriptionsSlice,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(oivaBaseApi.middleware),
    preloadedState,
  });
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
