export interface TimelineResource {
  id: string;
  title: string;
  parentId?: string;
  task_guid?: string;
}

export interface TimelineEvent {
  id: string;
  resourceId: string;
  status: number;
  errorCount: number;
  start: string;
  end: string;
  scheduled_start_time: string;
  scheduled_stop_time: string;
  actual_start_time: string;
  actual_stop_time: string;
  type: string;
  vmId: string;
}

export enum EventType {
  VM = "VM",
  Task = "Task",
  Event = "Event",
}

export interface ActiveTimelineEvent {
  status: number;
  errorCount: number;
  scheduled_start_time: string;
  scheduled_stop_time: string;
  actual_start_time: string;
  actual_stop_time: string;
  type: string;
  resourceIds: string[];
  taskId: string;
  vmId: string;
}

export interface ActiveTimelineItem {
  id: string;
  type: EventType;
  item?: TimelineResource | ActiveTimelineEvent;
}

export interface Breadcrumb {
  id: string;
  title: string;
  type: EventType;
}
