export const fcStylesLight = {
  ".fc table": {
    fontSize: "14px",
  },
  ".fc-timeline-event": {
    padding: 0,
    fontSize: "12px",
    lineHeight: "1",
    marginBottom: "2px",
    cursor: "pointer",
  },
  ".fc .fc-datagrid-cell-cushion": {
    padding: "4px 5px",
  },
  td: {
    color: "#1890ff",
  },
  th: {
    fontWeight: "normal",
  },
  thead: {
    color: "black",
  },
  ".fc-toolbar-title": {
    fontSize: "28px",
    color: "#242424",
    fontWeight: "normal",
  },
  ".fc .fc-button": {
    backgroundColor: "white",
    color: "black",
    padding: "5px",
    fontSize: "16px",
    fontFamily: "Roboto, Arial, Sans-Serif",
  },
  ".fc-button-group": {
    fontSize: "16px",
    fontFamily: "Roboto, Arial, Sans-Serif",
  },
  ".fc-timeline-slot": {
    marginRight: "20px",
  },
};

export const fcStylesDark = {
  ".fc table": {
    fontSize: "14px",
  },
  ".fc-timeline-event": {
    padding: 0,
    fontSize: "12px",
    lineHeight: "1",
    marginBottom: "2px",
    cursor: "pointer",
  },
  ".fc .fc-datagrid-cell-cushion": {
    padding: "4px 5px",
  },
  td: {
    color: "#8cc8ff",
  },
  th: {
    backgroundColor: "primary.light",
    fontWeight: "normal",
  },
  thead: {
    color: "white",
  },
  ".fc-toolbar-title": {
    fontSize: "28px",
    color: "#fff",
    fontWeight: "normal",
  },
  ".fc .fc-button": {
    backgroundColor: "#bdbdbd",
    color: "black",
    padding: "5px",
    fontSize: "16px",
    fontFamily: "Roboto, Arial, Sans-Serif",
  },
  ".fc-button-group": {
    fontSize: "16px",
    fontFamily: "Roboto, Arial, Sans-Serif",
  },
  ".fc-timeline-slot": {
    marginRight: "20px",
  },
};

export const popoverStyle = {
  display: "flex",
  alignItems: "baseline",
  justifyContent: "space-between",
  fontSize: "14px",
};

export const popoverTimeDisplayStyle = {
  fontSize: "20px",
  color: "#90CDF9",
  fontWeight: "500",
};

// style for a wrapper wrapping timeline's date input element and date picker
export const dateInputWrapperStyle = {
  position: "relative",
  overflow: "hidden",
  height: "1px",
  width: "1px",
  margin: "-1px",
  padding: 0,
  border: 0,
  top: "20px",
  left: "20px",
};
