import { EntityState } from "@reduxjs/toolkit";
export type Nullable<T> = T | null | undefined;

export type Role =
  | "all"
  | "administrators"
  | "developers"
  | "coordinators"
  | "customerusers"
  | "dashboarduser"
  | "datawriter"
  | "datareader";

export interface ITaskUpdate {
  customer_guid: string;
  task_guid: string;
  task_name: string;
  task_description: Nullable<string>;
  task_type: Nullable<string>;
  screenshots_allowed: Nullable<boolean>;
  recording_allowed: Nullable<boolean>;
  vm_names: string[];
  priority: Nullable<number>;
  start_script_path: Nullable<string>;
  technical_contact_email: Nullable<string>;
  business_contact_email: Nullable<string>;
  customer_email: Nullable<string>;
  customer_error_email: Nullable<string>;
}

export interface ITask {
  customer_guid: string;
  task_guid: string;
  task_name: string;
  task_description: Nullable<string>;
  task_type: Nullable<string>;
  screenshots_allowed: Nullable<boolean>;
  recording_allowed: Nullable<boolean>;
  vm_names: string[];
  priority: Nullable<number>;
  start_script_path: Nullable<string>;
  technical_contact_email: Nullable<string>;
  business_contact_email: Nullable<string>;
  customer_email: Nullable<string>;
  customer_error_email: Nullable<string>;
  task_logo_url: Nullable<string>;
  o365_most_username: Nullable<string>;
  o365_most_server: Nullable<string>;
  o365_most_port: Nullable<string>;
  o365_most_password: Nullable<string>;
  o365_customer_username: Nullable<string>;
  o365_customer_server: Nullable<string>;
  o365_customer_port: Nullable<string>;
  o365_customer_password: Nullable<string>;
  keyvault_prod_base_url: Nullable<string>;
  keyvault_test_base_url: Nullable<string>;
  on_site_implementation: boolean;
}

export function mapITaskToITaskUpdate(obj: ITask): ITaskUpdate {
  const result: ITaskUpdate = {
    customer_guid: obj.customer_guid,
    task_guid: obj.task_guid,
    task_name: obj.task_name,
    task_description: obj.task_description,
    task_type: obj.task_type,
    screenshots_allowed: obj.screenshots_allowed,
    recording_allowed: obj.recording_allowed,
    vm_names: obj.vm_names,
    priority: obj.priority,
    start_script_path: obj.start_script_path,
    technical_contact_email: obj.technical_contact_email,
    business_contact_email: obj.business_contact_email,
    customer_email: obj.customer_email,
    customer_error_email: obj.customer_error_email,
  };

  return result;
}

export interface ITaskCreate {
  customer_guid: string;
  task_name: string;
  task_description: Nullable<string>;
  task_type: Nullable<string>;
  task_logo: Nullable<File>;
  // screenshots_allowed: Nullable<boolean>;
  // recording_allowed: Nullable<boolean>;
  start_script_path: Nullable<string>;
  priority: Nullable<number>;
}

export interface ITasksState extends EntityState<ITask> {
  selectedTask: string;
  status: string;
}

// TODO: Did we decide to keep EntityState or some other way to keep track of state from old code?
export interface IScheduleState extends EntityState<ISchedule> {
  selectedSchedule: string;
  status: string;
}

export interface IScheduleFormObject {
  id: string;
  vmName: string[];
  taskName: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  recurrenceType: string;
  frequency: number;
  dayNumber: number;
  runsMonday: boolean;
  runsTuesday: boolean;
  runsWednesday: boolean;
  runsThursday: boolean;
  runsFriday: boolean;
  runsSaturday: boolean;
  runsSunday: boolean;
  cronString: string;
  duration: number;
}

export interface ISchedule {
  schedule_guid: string;
  customer_guid: string;
  task_guid: string;
  target_vm: Nullable<string>;
  task_name: string;
  start_datetime: Date;
  end_datetime: Date;
  daily_recurrence: Nullable<IDailyRecurrence>;
  weekly_recurrence: Nullable<IWeeklyRecurrence>;
  monthly_recurrence: Nullable<IMonthlyRecurrence>;
  cron_recurrence: Nullable<ICronRecurrence>;
  timezone: string;
  event_created_for_next_run: boolean;
  next_run?: Date;
}

export interface IScheduleCreate {
  customer_id: string;
  task_id: string;
  vm_name: Nullable<string>;
  start_time: Date;
  end_time: Date;
  daily_recurrence: Nullable<IDailyRecurrence>;
  weekly_recurrence: Nullable<IWeeklyRecurrence>;
  monthly_recurrence: Nullable<IMonthlyRecurrence>;
  cron_recurrence: Nullable<ICronRecurrence>;
  timezone: string;
}

export function mapIScheduleToIScheduleUpdate(obj: ISchedule): IScheduleUpdate {
  const result: IScheduleUpdate = {
    schedule_id: obj.schedule_guid,
    customer_id: obj.customer_guid,
    task_id: obj.task_guid,
    vm_name: obj.target_vm,
    start_time: obj.start_datetime,
    end_time: obj.end_datetime,
    daily_recurrence: obj.daily_recurrence,
    weekly_recurrence: obj.weekly_recurrence,
    monthly_recurrence: obj.monthly_recurrence,
    cron_recurrence: obj.cron_recurrence,
    time_zone: obj.timezone,
  };
  return result;
}

export interface IScheduleUpdate {
  schedule_id: string;
  customer_id: string;
  task_id: string;
  vm_name: Nullable<string>;
  start_time: Date;
  end_time: Date;
  daily_recurrence: Nullable<IDailyRecurrence>;
  weekly_recurrence: Nullable<IWeeklyRecurrence>;
  monthly_recurrence: Nullable<IMonthlyRecurrence>;
  cron_recurrence: Nullable<ICronRecurrence>;
  time_zone: string;
}

export interface IScheduleDelete {
  schedule_id: string;
  customer_id: string;
}

export interface IDailyRecurrence {
  frequency: number;
}

export interface IWeeklyRecurrence {
  frequency: number;
  runs_monday: boolean;
  runs_tuesday: boolean;
  runs_wednesday: boolean;
  runs_thursday: boolean;
  runs_friday: boolean;
  runs_saturday: boolean;
  runs_sunday: boolean;
}

export interface IMonthlyRecurrence {
  frequency: number;
  day_number: number;
}

export interface ICronRecurrence {
  cron_string: string;
  duration: number;
}

export interface IStamp {
  customer_guid: string;
  task_guid: string;
  stamp_name: string;
  stamp_screen_name: string;
  stamp_description: string;
}
