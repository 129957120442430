import { Grid, Typography } from "@mui/material";
import FormNumberField from "./FormNumberField";
import { FormCheckbox } from "@features/common/FormFields";

interface RecurrenceProps {
  isEditing?: boolean;
  control: any;
}

export const WeeklyRecurrenceSelector = (props: RecurrenceProps) => {
  const { isEditing, control } = props;

  return (
    <Grid container rowSpacing={1} rowGap={2} marginTop={1}>
      <Grid container alignItems="center" spacing={1} marginLeft={0.5}>
        <Grid item>
          <Typography>Recur every</Typography>
        </Grid>
        <Grid item>
          <FormNumberField
            name="frequency"
            control={control}
            label={""}
            readOnly={!isEditing}
          />
        </Grid>
        <Grid item>
          <Typography>week(s) on:</Typography>
        </Grid>
      </Grid>

      <Grid container marginLeft={2} gap={{ sm: 2 }}>
        <Grid item xs={11} sm={3} md={2} lg={2}>
          <Grid container direction="column">
            <Grid item>
              <FormCheckbox
                name="runs_monday"
                control={control}
                label={"Monday"}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item>
              <FormCheckbox
                name="runs_tuesday"
                control={control}
                label={"Tuesday"}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item>
              <FormCheckbox
                name="runs_wednesday"
                control={control}
                label={"Wednesday"}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item>
              <FormCheckbox
                name="runs_thursday"
                control={control}
                label={"Thursday"}
                disabled={!isEditing}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={11} sm md>
          <Grid container direction="column">
            <Grid item>
              <FormCheckbox
                name="runs_friday"
                control={control}
                label={"Friday"}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item>
              <FormCheckbox
                name="runs_saturday"
                control={control}
                label={"Saturday"}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item>
              <FormCheckbox
                name="runs_sunday"
                control={control}
                label={"Sunday"}
                disabled={!isEditing}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
