import {
  TimelineResource,
  ActiveTimelineItem,
  EventType,
  Breadcrumb,
} from "./types";

// status color mapping for timeline events
export const selectStatusColor: (status?: number) => string = (status) => {
  if (status === 4) {
    //finished with errors
    return "#ffeb3b";
  } else if (status === 5) {
    //finished
    return "#00e676";
  } else if (status === 6) {
    //suspended
    return "#ff7961";
  } else {
    //pending
    return "#80C5F6";
  }
};

// makes an array of item objects used for displaying breadcrumbs in selector pane
export const makeBreadcrumbs = (
  resources: TimelineResource[],
  item: ActiveTimelineItem,
) => {
  // makes an array of item objects used for displaying breadcrumbs
  // in order of VM / Task / Event depending on the item type
  if (item.type === EventType.Event) {
    if (!item.item) {
      return [];
    }
    const crumbs: Breadcrumb[] = makeBreadcrumbs(resources, {
      id: "resourceIds" in item.item ? item.item.resourceIds[0] : "",
      type: EventType.Task,
    });
    return crumbs.concat([{ ...item, title: item.id }]);
  } else if (item.type === EventType.Task) {
    const task = resources.find((r: TimelineResource) => r.id === item.id);
    if (!task || !task.parentId) {
      return [];
    }
    const crumbs: Breadcrumb[] = makeBreadcrumbs(resources, {
      id: task.parentId,
      type: EventType.VM,
    });
    return crumbs.concat([{ ...task, type: EventType.Task }]);
  } else if (item.type === EventType.VM) {
    const vm = resources.find((r: TimelineResource) => r.id === item.id);
    if (!vm) {
      return [];
    }
    return [{ ...vm, type: EventType.VM }];
  } else {
    return [];
  }
};
