import { TextField, MenuItem } from "@mui/material";

export type SelectItem = {
  id?: string | number;
  value: string;
  label: string;
};

interface ChartSetSelectProps {
  selectItems: SelectItem[];
  selectedItem: string | undefined | null;
  textFieldLabel: string;
  handleSelectItem: any;
  style?: any;
}

const ChartSetSelect = (props: ChartSetSelectProps) => {
  const { selectItems, selectedItem, textFieldLabel, handleSelectItem, style } =
    props;

  return (
    <>
      <TextField
        sx={style}
        select
        name="basic-textfield"
        size="small"
        label={textFieldLabel}
        value={selectedItem || ""}
        onChange={handleSelectItem}
      >
        {selectItems.map((item) => (
          <MenuItem key={item.id || item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};

export default ChartSetSelect;
