import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "@features/common/StoreHook";
import { getSelectedCustomerId } from "@features/customers/customerSlice";
import TabView from "@features/common/TabView";
import { ITabItem } from "@features/common/TabView/TabView";

import { fetchTasks } from "./tasksSlice";
import { getSelectedTaskId } from "./tasksSlice";

export const TaskWindow = () => {
  const dispatch = useAppDispatch();
  const customerId = useSelector(getSelectedCustomerId);
  const selectedTaskId = useSelector(getSelectedTaskId);

  const initialTabs: ITabItem[] = [
    {
      label: "menu-sub-task",
      path: "",
      permissions: "",
      disabled: false,
    },
    {
      label: "basic-info",
      path: "basicinfo",
      permissions: "",
      disabled: !selectedTaskId,
    },
    {
      label: "menu-sub-environments",
      path: "environments",
      permissions: "",
      disabled: !selectedTaskId,
    },
    {
      label: "menu-sub-scheduler",
      path: "schedules",
      permissions: "schedules",
      disabled: !selectedTaskId,
    },
    {
      label: "menu-sub-stamps",
      path: "stamps",
      permissions: "stampdata",
      disabled: !selectedTaskId,
    },
  ];
  const [tabs, setTabs] = useState<any>(initialTabs);

  useEffect(() => {
    if (customerId) {
      dispatch(fetchTasks(customerId));
    }
  }, [customerId]);

  useEffect(() => {
    // Updates Tab.disabled attribute based on if task is selected or not
    //
    let newtabs: ITabItem[] = [];
    tabs.forEach((tab: ITabItem) => {
      tab.label === "menu-sub-task"
        ? (tab.disabled = false)
        : (tab.disabled = !selectedTaskId);

      newtabs.push(tab);
    });
    setTabs(newtabs);
  }, [selectedTaskId]);

  return (
    <>
      <TabView tabs={tabs} primaryPermission="tasks" />
    </>
  );
};
