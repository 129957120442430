import { Grid, IconButton, TextField, Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { t } from "i18next";
import { Controller } from "react-hook-form";
import { FormTextField } from "@features/common/FormFields";

interface RecurrenceProps {
  isEditing?: boolean;
  control: any;
}

export const CRONRecurrenceSelector = (props: RecurrenceProps) => {
  const { isEditing, control } = props;

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <FormTextField
          name="cron_string"
          control={control}
          label={"CRON expression"}
          readOnly={!isEditing}
        />
      </Grid>
      <Grid item>
        <Controller
          name={"duration"}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              helperText={error ? error.message : null}
              error={!!error}
              onChange={onChange}
              value={value ?? null}
              fullWidth
              label={"Duration"}
              variant="outlined"
              sx={{ width: !error ? "100px" : "100%" }}
              type="number"
              inputProps={{ readOnly: !isEditing || false }}
            />
          )}
        />
      </Grid>
      <Grid item sx={{ marginLeft: "8px" }}>
        <Tooltip title={t("info-cron-recurrence-popover")}>
          <a href={"https://crontab.guru/"} target="_blank" rel="noreferrer">
            <IconButton size="large">
              <HelpOutlineIcon sx={{ fontSize: "28px" }} />
            </IconButton>
          </a>
        </Tooltip>
      </Grid>
    </Grid>
  );
};
