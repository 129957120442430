import { grey } from "@mui/material/colors";

export const tabsStyle = {
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: grey[500],
  },
};

export const tabStyle = {
  fontSize: "16px",
  minWidth: "160px",
  "&.Mui-disabled": {
    opacity: 0.2,
  },
};
